import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";

export async function getEmailLog(
  offset: number,
  limit: string | number,
  search: string,
  sort: Option | undefined,
  filter: string,
): Promise<EmailLog | null> {
  const response = await apiClient.get(urls.emailLogList(offset,limit,search, sort, filter));
  if (response && response.data && response.data.data) return response.data.data;
  return null;
}

export function useGetEmailLog(emailLogStatus: EmailStatus[]): UseQueryResult<EmailLog> {
  const {
    emailLogListPaginationOffset,
    emailLogPaginationOffset,
    subjectSearch,
    sortOptionEmailLog,
  } = useAdminStore();

  const EmailLog = emailLogStatus.filter(item=>item.checked===true).map(item=>item.name).join(",");

  return useQuery(
    [
      "EmailLog",
      emailLogListPaginationOffset,
      emailLogPaginationOffset,
      subjectSearch,
      sortOptionEmailLog,
      emailLogStatus,
    ],
    async () => {
      const emailLogList = await getEmailLog(
        emailLogListPaginationOffset,
        emailLogPaginationOffset,
        subjectSearch,
        sortOptionEmailLog,
        EmailLog
      );
      return emailLogList;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
