import React, { useEffect } from "react";
import Header from "./components/Header";
import "./admin.scss";
import AdminContent from "./components/AdminContent";

const Admin = () => {

  return (
    <div className="admin-wrapper">
      <Header />   
      <AdminContent/>
    </div>
  );
};

export default Admin;
