import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";
import { handleDownload } from "utils/downloadFile";

export async function getAdminSkillExport(selectedSkill:AdminSkill|undefined):Promise<any> {
  if(selectedSkill){
  const response = await apiClient.get(urls.exportSkills(selectedSkill.skill_id));
  if (response && response.data && response.data.data )
    return response.data.data;
  }
  return null;
}

export function useGetSkillExport():UseQueryResult<any> {
  const {initiateSkillExportById,setInitiateSkillExportById,selectedSkill}= useAdminStore();
  return useQuery(["AdminSkillExport"], async () => {
    const result= await getAdminSkillExport(selectedSkill);
    if(result && result.file_url){
      await handleDownload(result);
    }
    setInitiateSkillExportById(false);
    return result;
  },{
    enabled: initiateSkillExportById === true && selectedSkill !== undefined,
  });
}

