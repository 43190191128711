import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateCategory(
    categoryName:string,
    categoryId:number,
){
    try{
    const requestObject = {
        category_name: categoryName,
      }
    const response = await apiClient.put(
        urls.AdminCategoryById(categoryId),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useUpdateCategory():UseMutationResult<
    any,
    ApiError | {message: string},
    {categoryName:string;categoryId:number}
    >{
        const {searchkey,categoryListPaginationOffset, categories} = useAdminStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            categoryName,
            categoryId,
        }: {
            categoryName: string;
            categoryId:number;
        }) => {
            const result = await updateCategory(categoryName, categoryId);
            queryClient.invalidateQueries(["AdminCategory",searchkey,categoryListPaginationOffset,categories]);
            queryClient.invalidateQueries(["GetCategoryById"]);
            return result;
        }
    );
    }