import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";

export async function getAllUsers():Promise<AllUsers|null> {
  const response = await apiClient.get(urls.users());
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetAllUsers():UseQueryResult<AllUsers> {
    const {setAllUsers, allUsers} = useAdminStore();
  return useQuery(["allUsers"], async () => {
    const data= await getAllUsers();
    if (data != null) {
        const userValue = data?.users?.map((item: any) => {
          if (item?.first_name || item?.last_name) {
            return {
              value: item.email,
              label: `${item.first_name} ${item.last_name}`,
              checked: false,
            };
          } 
          else {
            return {
              value: item.email,
              label: item.email,
              checked: false,
            };
          }
        });
        setAllUsers(userValue ?? []);
        return allUsers;
      }
  },{
    refetchOnWindowFocus:false
  });
}