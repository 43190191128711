import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";

export async function getAllProviders():Promise<Array<Tag>|null> {
  const response = await apiClient.get(urls.certificationProviders());
  if (response && response.data && response.data.data)
    return response.data.data.certification_issuers;
  return null;
}

export function useGetProviders():UseQueryResult<Array<any>> {

  return useQuery(["CertificationProviders",], async () => {
   return await getAllProviders();
  });
}

