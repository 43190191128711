import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useSkillStore } from "store/useSkillStore";


export async function getCategorySkills(skillSearch:string,filteredCategories:string):Promise<Categories|null> {
  const response = await apiClient.get(urls.categorySkills(skillSearch,filteredCategories));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetCategorySkills():UseQueryResult<Categories> {
    const {setCategorySkills,skillSearchKey, categories} = useSkillStore();
    const filteredCategories = categories.reduce(function(item, val, index) {
      var comma = val.checked ? val.name+"," : "";
      return item + comma ;
    }, '');
    
  return useQuery(["categoryWiseSkills",skillSearchKey,categories], async () => {
    const categorySkills =  await getCategorySkills(skillSearchKey,filteredCategories);
    if(categories){
      setCategorySkills(categorySkills?.categories??[]);
    }
    
    return categorySkills;
  },{
    refetchOnWindowFocus:false,
  });
}
