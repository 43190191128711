import InputField from "components/common-components/InputField";
import React from "react";
import "../../components/AdminSkillsWrapper/SkillsGridView/EditSkillModal/admin-skill-modal.scss";
import { useSaveCategory } from "api/admin/useSaveCategory";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { INPUT_MAX_LENGTH } from "utils/constants";
const CreateCategory = ({ closeToggleCategory }: { closeToggleCategory: () => void }) => {
  const { mutateAsync: saveCategory } = useSaveCategory();
  const [categoryName, setCategoryName] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const saveCategoryName = async () => {
    if (categoryName === "") {
      setErrorMessage("Enter category name");
      return;
    }
    const response = await saveCategory({ category_name: categoryName });
    if (response.data) {
      showSuccessToast("Category added");
      setCategoryName("");
      closeToggleCategory();
    }else if(response.response.data.message){
      showErrorToast(response.response.data.message);
    } else {
      showErrorToast("Something went wrong");
    }
  };
  return (
    <div className="skill-btn-search">
      <InputField
        type="text"
        label="New Category Name"
        placeholder="New Category Name"
        value={categoryName}
        maxLength={INPUT_MAX_LENGTH}
        onChange={(e: any) => {
          setErrorMessage("");
          setCategoryName(e.target.value);
        }}
        error={errorMessage}
      />
      <div className="skill-check-btn">
        <button className="app-btn icon-with-border" onClick={saveCategoryName}>
          <svg className="svg-icon tick-icon">
            <use href="#tickIcon">
              <title>Add Icon</title>
            </use>
          </svg>
        </button>
        <button className="app-btn icon-with-border" onClick={closeToggleCategory}>
          <svg className="svg-icon close-icon">
            <use href="#closeIcon">
              <title>Close Icon</title>
            </use>
          </svg>
        </button>
      </div>
      <button className="icon-only add-category-close" onClick={closeToggleCategory}>
        <svg className="svg-icon close-icon">
          <use href="#closeIcon">
            <title>Close Icon</title>
          </use>
        </svg>
      </button>
      <div className="footer-btns">
        <button className="app-btn " onClick={closeToggleCategory}>
          Cancel
        </button>
        <button className="app-btn primary" onClick={saveCategoryName}>Add Category</button>
      </div>
    </div>
  );
};
export default CreateCategory;
