import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useUserStore } from "store/useUserStore";


export function PrivateRoute({ allowedRole,children }: { allowedRole:string,children: ReactElement }) {
 
  const { accessToken ,userInfo } = useUserStore();
  if (accessToken.length === 0 && userInfo.id === 0 ) {
    return <Navigate to="/" state={{ from: window.location }} />;
  }
  if(userInfo && userInfo.roles.includes(allowedRole)){
    return children;
  }else{
    return <Navigate to="*" />;
  }
}
