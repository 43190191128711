import React, { useState } from "react";
import "./header.scss";
import NotificationSection from "../Notification";
import ReviewSkillModal from "../AdminSkillsWrapper/SkillsGridView/ReviewSkillModal";
import AdminModal from "components/common-components/AdminModel";
import RejectReasonModal from "../AdminSkillsWrapper/SkillsGridView/RejectReasonModal";

import AdminProfile from "components/Profile/adminProfile";
import { useApproveRejectSkill } from "api/admin/useSkillApproveReject";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { ErrorMessages, messages } from "utils/messageConstants";
import { useUpdateNotificationForAdmin } from "api/admin/useUpdateAdminNotification";
const Header = () => {
  const {mutateAsync:reviewSkill,isLoading}= useApproveRejectSkill();
  const {mutateAsync:updateNotification}= useUpdateNotificationForAdmin();
  const [toggleReviewModal, setToggleReviewModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [skillUnderReview,setSkillUnderReview]= useState<NotificationPayload|undefined>(undefined);
  const [selectedCategory, setCategory] = useState<Category | undefined>(undefined);
  const [rejectReason,setRejectReason] = useState('');
  const [skillName, setSkillName] = useState(()=>{
    if(skillUnderReview){
      return skillUnderReview.skillName;
    }else return "";
  });
  const [errorMessage, setErrorMessage] = useState("");
  const handleReviewModal = () => {
    setToggleReviewModal(!toggleReviewModal);
  };
  const onSkillReviewClicked=(payload:NotificationPayload)=>{
    setSkillUnderReview(payload);
    setToggleReviewModal(true);
    setErrorMessage('');
    setCategory(undefined);
    setRejectReason("");
    setSkillName(payload.skillName);
  }
  const updateNotificationStatus = async()=>{
    if(skillUnderReview &&  skillUnderReview?.notififcationId){
      await updateNotification({
        notificationId:skillUnderReview.notififcationId
      });
    }
  }
  const reviewAndApproveSkill = async()=>{
    const skill:any = skillUnderReview;
    if(skillName.length === 0){
      setErrorMessage(ErrorMessages.SKILL_NAME_REQUIRED);
      return;
    }
    else if(selectedCategory === undefined){
      setErrorMessage(ErrorMessages.SELECT_CATEGORY);
      return;
    }else{
      const response = await reviewSkill({
        categoryId:selectedCategory.id,
        reason:'',
        skillId:skill.skillId,
        skillName:skillName,
        status:true,
        notificationId:skillUnderReview?.notififcationId??0,
      });
      if(response.data){
        showSuccessToast(messages.SKILL_APPROVED);
        await updateNotificationStatus();
        setSkillName('');
        setToggleReviewModal(false);
        setSkillUnderReview(undefined);
      }else if(response.response.data.message){
        showErrorToast(response.response.data.message);
      }else {
        showErrorToast(messages.SKILL_APPROVAL_FAILED)
      }
      
    }
  }
  const reviewAndRejectSkill = async()=>{
    if(skillUnderReview){
      if(rejectReason.length === 0){
        setErrorMessage(ErrorMessages.REJECT_REASON_REQUIRED);
        return;
      }else{
        const response = await reviewSkill({
          categoryId:1,
          reason:rejectReason,
          skillId:skillUnderReview.skillId,
          skillName:skillUnderReview.skillName,
          status:false,
          notificationId:skillUnderReview?.notififcationId??0,
        });
        if(response.data){
          showSuccessToast(messages.SKILL_REJECTED);
          await updateNotificationStatus();
          setRejectReason('');
          setRejectModal(false);
          setSkillUnderReview(undefined);
        }else if(response.response.data.message){
          showErrorToast(response.response.data.message);
        }else {
          showErrorToast(messages.SKILL_REJECT_FAILED);
        }
      }
    }
  }
  return (
    <>
    <div className="layout-header">
      <div className="header-left-portion">
        <div className="logo-container">
          <svg className="svg-icon logo-icon">
            <use href="#logoIcon"></use>
          </svg>
        </div>
      </div>
      <div className="header-right-portion">
        <NotificationSection handleReviewModal={handleReviewModal}
          onSkillReviewClicked={(notificationPayload)=>{
            onSkillReviewClicked(notificationPayload);
          }}/>
        <AdminProfile/>
      </div>
    </div>
          {toggleReviewModal && skillUnderReview !== undefined? (
            <AdminModal
              title="Review a Skill"
              size=""
              handleClose={() => handleReviewModal()}
              isBackArrow={true}
              placementFull="placement-full"
              headerWrapper=""
              withEditBtn={false}
              hidefooter={false}
              primaryButton="Approve"
              secondaryButton="Reject"
              isHeaderBottom={false}
              showClose={true}
              setToggleReviewModal={setToggleReviewModal}
              setRejectModal={()=>{
                setErrorMessage("");
                setRejectModal(true);
              }}
              onProceed={reviewAndApproveSkill}
              btnLoaderIcon={isLoading}
              
            >
              <ReviewSkillModal
              skillName={skillName}
               errorMessage={errorMessage}
               setErrorMessage={setErrorMessage}
               setSkillName={setSkillName}
               setCategory={setCategory}
               skillUnderReview={skillUnderReview}/>
            </AdminModal>
          ) : (
            ""
      )}
      {rejectModal ?
        <AdminModal
          title="Are you sure want to reject this skill?"
          size=""
          handleClose={() => setRejectModal(false)}
          isBackArrow={false}
          placementFull=""
          headerWrapper=""
          withEditBtn={false}
          hidefooter={false}
          primaryButton="Reject"
          secondaryButton="Cancel"
          isHeaderBottom={false}
          placement="placement-bottom"
          setRejectModal={setRejectModal}
          onProceed={reviewAndRejectSkill}
          btnLoaderIcon={isLoading}
        >
          <RejectReasonModal 
            error={errorMessage}
            rejectReason={rejectReason}
            setRejectReason={(value)=>{
              setRejectReason(value);
              setErrorMessage('');
            }}/>
        </AdminModal>
        : ''}
    </>
  );
};
export default Header;

