import { useMsal } from "@azure/msal-react";
import ProfileImage from "components/ProfileImage";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearAllStores } from "store";
import { useUserStore } from "store/useUserStore";

const AdminProfile = () => {

  const { instance } = useMsal();
  const { setAccessToken, userInfo } = useUserStore();
  const navigate = useNavigate();
  const closeRef=useRef(null);
  
  const [profileToggle, setProfileToggle] = useState(false);
  const handleProfileToggle = () => {
    setProfileToggle(!profileToggle);
  };

  const handleLogout = async() => { 
    instance.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}/logout-callback`,
    }).then(()=>{
      clearAllStores();
      setAccessToken("");
      navigate("/");
    })
  };

  const onLogout = async() => {
    await handleLogout();
  };
  const handleClickOutside = (event: { target: any; }) => {
    if (closeRef.current && !(closeRef.current as HTMLElement).contains(event.target)) {
      setProfileToggle(false);
    }
  };
  const close = (event:any) => {
    if(event.key === 'Escape'){
      setProfileToggle(false);
    }
  }
  
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    window.addEventListener('keydown', close)
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      window.removeEventListener('keydown', close)
    };
    
  }, [profileToggle]);
 
  return (
    <div className={`profile-layout ${profileToggle ? "active" : ""}`} ref={closeRef}>
      <button className="icon-only profile-header-wrapper" onClick={handleProfileToggle}>
        <div className="profile-detail">
          <div className="profile-name">{userInfo.first_name} {userInfo.last_name}</div>
          <div className="profile-designation">{userInfo.designation}</div>
        </div>
        <div className="profile-img-holder">
             <ProfileImage/>
        </div> 
        <span className="dropdown-arrow">
          <svg className="svg-icon dropdown-icon">
            <use href="#dropdownArrowIcon"></use>
          </svg>
        </span>
      </button>
      <div className="dropdown-wrapper">
        <ul className="logout-wrapper">
          <li className="profile-detail-mobile">
            <div className="profile-name">{userInfo.first_name} {userInfo.last_name}</div>
            <div className="profile-designation">{userInfo.designation}</div>
          </li>
          <li className="logout-item">
            <button className="icon-only layout-name" onClick={onLogout}>
              Logout
              <span>
                <svg className="svg-icon logout-icon">
                  <use href="#logoutIcon">
                    <title>Logout Icon</title>
                  </use>
                </svg>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminProfile;
