import { useEffect, useState, useRef } from "react";
import "./user-listing.scss";
import { useGetAdminCertificationsUsers } from "api/admin/useGetAdminCertificationUsers";
import SkillPagination from "components/common-components/SkillPagination";
import { PAGINATION_ITEMS_PER_PAGE } from "utils/constants";
import { useAdminStore } from "store/adminStore";
import { getFormattedExpiryDate } from "utils/dateUtil";
import UserProfileImage from "components/UserImage";
import SortButton from "components/sortButton";
import { Sort } from "utils/constants";


type SortStatus = {
  [key: string]: string;
};

type SortStatusKeys = keyof SortStatus;

const CertificateUserListing = ({ downloadReport }: { downloadReport: () => void }) => {
  const { setCertificationPaginationOffset, setSortOptionCertification } = useAdminStore();
  const { data: certificationUsers } = useGetAdminCertificationsUsers();
  const [count, setCount] = useState(1);
  const parentRef = useRef<HTMLDivElement | null>(null);

    const sortByColumnsInitialState: SortStatus = {
      sortByEmployee: "asc",
      sortByDesignation: "disabled",
      sortByCertificationIssuer: "disabled",
      sortByCertificationExpiry: "disabled"
  };

  const [sortByColumn, setSortByColumn] = useState<SortStatus>(
    sortByColumnsInitialState
  );

  const sortByOnClick = (column: SortStatusKeys, columnNo: number) => {
    const sortOrder =
      sortByColumn[column] === "asc" ? "desc" : "asc";
    const updatedSortByColumn = {
      ...Object.fromEntries(
        Object.keys(sortByColumn).map((key) => [key, "disabled"])
      ),
      [column]: sortOrder,
    };
    setSortByColumn(updatedSortByColumn);
    setSortOptionCertification(Sort[columnNo * 2 + (sortOrder === "asc" ? 0 : 1)]);
  };

  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.scrollTop = 0;
    }
  }, [count]);

  const renderSortButton = (
    column: SortStatusKeys,
    index: number
  ) => (
    <SortButton
      isAscending={sortByColumn[column]}
      onClick={() => sortByOnClick(column, index)}
      icon="#sortIcon"
    />
  );

  return (
    <div className="app-modal-content user-listing-app certificate-app sm">
      <div className="skill-heading">
        <div className="admin-skill-header"><span className="sortWrapper">NAME{renderSortButton("sortByEmployee", 1)}
        </span></div>
        <div className="admin-skill-designation"><span className="sortWrapper">DESIGNATION{renderSortButton("sortByDesignation", 2)}
        </span></div>
        <div className="admin-skill-bu"><span className="sortWrapper">ISSUED BY{renderSortButton("sortByCertificationIssuer", 10)}
        </span></div>
        <div className="admin-skill-level"><span className="sortWrapper">EXPIRY{renderSortButton("sortByCertificationExpiry", 18)}
        </span></div>
      </div>
      <div className="user-skill-item-wrapper" ref={parentRef}>
        {certificationUsers?.users.map((certification, index) => (
          <div className="user-skill-row">
            <div className="user-skill-detail">
              <div className="profile-img-holder">
                <UserProfileImage
                  first_name={certification.first_name}
                  ssoId={certification.sso_login_id}
                />
              </div>
              <div className="user-skill-name-id">
                <div className="user-skill-name">
                  {certification.first_name} {certification.last_name}
                </div>
                <div className="user-skill-id">ID: {certification.employee_id}</div>
              </div>
            </div>
            <div className="user-skill-designation">{certification.designation}</div>
            <div className="user-skill-bu">{certification.issued_by}</div>
            <div className="user-skill-level">
              <span className="mobile-only">Expiry: </span>
              {getFormattedExpiryDate(certification.expiry_date)}
            </div>
          </div>
        ))}
      </div>
      <SkillPagination
        itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
        onPageChange={(page) => {
          const offset = (page - 1) * PAGINATION_ITEMS_PER_PAGE;
          setCertificationPaginationOffset(offset);
          setCount(count+1)
        }}
        totalItems={certificationUsers?.total ?? 0}
      />
      <div className="download-footer-btn">
        <button className="app-btn icon-btn-text primary" onClick={downloadReport}>
          <svg className="svg-icon down-icon">
            <use href="#downIcon">
              <title>Add Icon</title>
            </use>
          </svg>
          <span className="button-text">Download Report</span>
        </button>
      </div>
    </div>
  );
};
export default CertificateUserListing;
