import React from 'react';

type Mail = {
    date: string;
    subject: string;
    total_recipients: number;
    failed_recipients: number;
    sent_recipients: number;
    status: string;
    custom_notification_id?: string;
};

type MailTableRowProps = {
    mailItems: Mail;
    index: number;
    offset: number;
    onClick: () => void;
};

const MailTableRow: React.FC<MailTableRowProps> = ({ mailItems, index, offset, onClick }) => {
    return (
    <tr>
        <td>{offset + index + 1}</td>
        <td>{mailItems.date}</td>
        <td>
            <div className="subject-wrapper">
                <span>
                    <svg className="svg-icon email-icon">
                        <use href="#emailIcon">
                            <title>Mail Icon</title>
                        </use>
                    </svg>
                </span>
                <span>{mailItems.subject}</span>
            </div>
        </td>
        <td>
            <div
              className={`status-update ${mailItems.status === "Sent" ? "success"
                  : mailItems.status === "Pending" ? "pending"
                  : mailItems.status === "In Progress" ? "in-progress"
                  : mailItems.status === "Failed" ? "failed"
                  : ""
                }`}
            >
                    <span className="status-icon">
                    <svg className="svg-icon">
                        <use
                            href={
                              mailItems.status === "Sent" ? "#circleTickIcon"
                              : mailItems.status === "Pending" ? "#pendingIcon"
                              : mailItems.status === "In Progress" ? "#inProgress"
                              : mailItems.status === "Failed" ? "#crossIcon"
                              : ""
                            }
                          >
                            <title>status Icon</title>
                        </use>
                    </svg>
                </span>
                <span className="status-name">{mailItems.status}</span>
            </div>
        </td>
        <td className='total-recipients'>{mailItems.total_recipients}</td>

        <td className='sent-recipients'>{mailItems.sent_recipients}</td>
        <td className='failed-recipients'>{mailItems.failed_recipients}</td>
        <td className="fixed-column">
                <div className="icon-wrapper">
                  <button
                    className="app-btn icon-only-sm ghost go-details"
                    onClick={onClick}
                  >
                    <svg className="svg-icon right-arrow-icon">
                      <use href="#nextArrowIcon">
                        <title>Go Details</title>
                      </use>
                    </svg>
                  </button>
                </div>
        </td>
    </tr>
);
}
export default MailTableRow;
