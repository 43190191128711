import React, { useState } from "react";
import "./Skills-grid.scss";
import UpdateSkillLevel from "components/UpdateSkillLevel";
import { useGetUserSkills } from "api/user/useGetUserSkills";
import { useSkillStore } from "store/useSkillStore";
import { SkillType } from "utils/constants";
import Modal from "components/common-components/Modal";
import ConfirmDeleteModal from "components/common-components/ConfirmDeleteModal";
import { messages } from "utils/messageConstants";
import { confirmReplaceSkill } from "utils/confirmModals";
interface Props {
  handleskillList: any;
  tabSwitch: string;
  dragSkill: any;
  currentSkill: any;
  setCurrentSkill: any;
  draggedSkills: Object[];
  setDraggedSkills: any;
  draggedSkillsPrimary: Object[];
  setDraggedSkillsPrimary: any;
  draggedSkillsSecondary: Object[];
  setDraggedSkillsSecondary: any;
}

const SkillsGrid: React.FC<Props> = ({
  handleskillList,
  tabSwitch,
  dragSkill,
  currentSkill,
  setCurrentSkill,
  draggedSkills,
  setDraggedSkills,
  draggedSkillsPrimary,
  setDraggedSkillsPrimary,
  draggedSkillsSecondary,
  setDraggedSkillsSecondary,
}) => {
  const [draggedState, setDraggedState] = useState(false);
  const [draggedStatePrimary, setDraggedStatePrimary] = useState(false);
  const [draggedStateSecondary, setDraggedStateSecondary] = useState(false);
  
  const { data } = useGetUserSkills();
  const additionalRef = React.useRef<HTMLDivElement>(null);
  const {
    primarySkill,
    secondarySkill,
    additionalSkill,
    setPrimarySkill,
    setSecondarySkill,
    addAdditionalSkill,
  } = useSkillStore();

  const handleOnDrop = async () => {
    const droppedItem: UserSkillItem = {
      id: 0,
      skill_name: dragSkill.skill_name,
      category_id: dragSkill.category_id,
      skill_id: dragSkill.skill_id,
      skill_rank: "",
      skill_type: SkillType.Additional,
    };
    addAdditionalSkill(droppedItem);
    setCurrentSkill(dragSkill.id);
    setDraggedSkills((prevSkills: Object[]) => [dragSkill, ...prevSkills]);
    setDraggedState(false);
  };

  const handleOnDropSecondary = async () => {
    if(secondarySkill!== undefined){
      const confirm = await confirmReplaceSkill();
      if(!confirm){
        return;
      }
    }
    const droppedItem: UserSkillItem = {
      id: 0,
      skill_name: dragSkill.skill_name,
      category_id: dragSkill.category_id,
      skill_id: dragSkill.skill_id,
      skill_rank: "",
      skill_type: SkillType.Secondary,
    };
    setSecondarySkill(droppedItem);
    setCurrentSkill(dragSkill.id);
    setDraggedSkillsSecondary([dragSkill]);
    setDraggedStateSecondary(false);
  };

  const handleOnDragOver = (e: any) => {
    e.preventDefault();
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
    setDraggedState(true);
  };

  const handleOnDragOverSecondary = (e: any) => {
    e.preventDefault();
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
    setDraggedStateSecondary(true);
  };
  const handleOnDragEnd = () => {
    setDraggedState(false);
  };

  const handleOnDragEndSecondary = () => {
    setDraggedStateSecondary(false);
  };

  const handleOnDropPrimary = async () => {
    if(primarySkill!== undefined){
      const confirm = await confirmReplaceSkill();
      if(!confirm){
        return;
      }
    }
    const droppedItem: UserSkillItem = {
      id: 0,
      category_id: dragSkill.category_id,
      skill_id: dragSkill.skill_id,
      skill_rank: "",
      skill_type: SkillType.Primary,
      skill_name: dragSkill.skill_name,
    };
    setPrimarySkill(droppedItem);
    setCurrentSkill(dragSkill.id);
    setDraggedSkillsPrimary(dragSkill);
    setDraggedStatePrimary(false);
  };
  const handleOnDragOverPrimary = (e: any) => {
    e.preventDefault();
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
    setDraggedStatePrimary(true);
  };
  const handleOnDragEndPrimary = () => {
    setDraggedStatePrimary(false);
  };
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const handleModal = () => {
    setshowDeleteModal(!showDeleteModal);
  };

  return (
    <>
      <div className={`skills-grid ${tabSwitch === "Skills" ? "show-this" : ""}`}>
        <div className="user-skills-wrapper main">
          <div className="skills-card-box"
            onDrop={handleOnDropPrimary}
            onDragOver={handleOnDragOverPrimary}
            onDragLeave={handleOnDragEndPrimary}
          >
            <h5 className="skill-type">
              <span>Primary Skill</span>

              <div className="tooltip-content">{messages.PRIMARY_SKILL_SUB_LABEL}</div>
            </h5>

            {primarySkill === undefined ? (
              <div
                className={`skill-placeholder droppable ${draggedStatePrimary ? "drop-over" : ""} `}
              >
                <div
                  className="user-skill-drop"
                  onDrop={handleOnDropPrimary}
                  onDragOver={handleOnDragOverPrimary}
                  onDragLeave={handleOnDragEndPrimary}
                >
                  <span className="desktop">Drag relevant skill from right panel and drop here</span>
                  <span className="mobile">Click to add relevant skill</span>
                  <button
                    className="mobile icon-only add-skill-btn"
                    onClick={() => handleskillList(SkillType.Primary)}
                  >
                    <svg className="svg-icon">
                      <use href="#plusIcon"></use>
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <UpdateSkillLevel
                skillsItem={primarySkill}
                currentSkill={currentSkill}
                skillType={SkillType.Primary}
              />
            )}
          </div>

          <div className="skills-card-box"
           onDrop={handleOnDropSecondary}
           onDragOver={handleOnDragOverSecondary}
           onDragLeave={handleOnDragEndSecondary}
          >
            <h5 className="skill-type">Secondary Skill</h5>
            {secondarySkill === undefined ? (
              <div
                className={`skill-placeholder droppable ${
                  draggedStateSecondary ? "drop-over" : ""
                } `}
              >
                <div
                  className="user-skill-drop"
                  onDrop={handleOnDropSecondary}
                  onDragOver={handleOnDragOverSecondary}
                  onDragLeave={handleOnDragEndSecondary}
                >
                  <span className="desktop">Drag relevant skill from right panel and drop here</span>
                  <span className="mobile">Click to add relevant skill</span>
                  <button
                    className="mobile icon-only add-skill-btn"
                    onClick={() => handleskillList(SkillType.Secondary)}
                  >
                    <svg className="svg-icon">
                      <use href="#plusIcon"></use>
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <UpdateSkillLevel
                skillsItem={secondarySkill}
                currentSkill={currentSkill}
                skillType={SkillType.Secondary}
              />
            )}
          </div>
        </div>
        <div className="user-skills-wrapper additional" ref={additionalRef}>
          <div className="title-wrapper">
            <h5 className="skill-type">
              Additional Skills <span className="skills-count">{additionalSkill?.length ?? 0}</span>
            </h5>
            <button className="app-btn add-btn" onClick={() => handleskillList(SkillType.Additional)}>
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <span className="btn-text">Add</span>
            </button>
          </div>
          <div className="additional-skills-wrapper">
            <div className="skills-card-box">
              <div className={`skill-placeholder droppable ${draggedState ? "drop-over" : ""} `}>
                <div
                  className="user-skill-drop"
                  onDrop={handleOnDrop}
                  onDragOver={handleOnDragOver}
                  onDragLeave={handleOnDragEnd}
                >
                  <span className="desktop">Drag relevant skill from right panel and drop here</span>
                  <span className="mobile">Click to add relevant skill</span>
                  <button
                    className="mobile icon-only add-skill-btn"
                    onClick={() => handleskillList(SkillType.Additional)}
                  >
                    <svg className="svg-icon">
                      <use href="#plusIcon"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {additionalSkill?.map((skillsItem: any, skillsIndex: number) => {
              return (
                <UpdateSkillLevel
                  key={skillsIndex}
                  skillsItem={skillsItem}
                  currentSkill={currentSkill}
                  skillType={SkillType.Additional}
                />
              );
            })}
          </div>
        </div>
      </div>
      {showDeleteModal ? (
        <Modal
          size="sm"
          title="Confirm Delete"
          secondaryButton="Cancel"
          primaryButton="Confirm"
          handleClose={handleModal}
          placement="placement-bottom"
        >
          <ConfirmDeleteModal />
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default SkillsGrid;
