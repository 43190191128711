import React, { useState } from "react";
import "./adminFilter.scss";
import RadioButton from "components/common-components/RadioButton";
import CategoryList from "../CategoryList";
import { useAdminStore } from "store/adminStore";
import { SortOptions } from "utils/constants";
import { deepClone } from "utils/userUtils";

interface Props {
  handleAdminFilter?: any;
}
const AdminFilter: React.FC<Props> = ({
  handleAdminFilter,
}: Props) => {
  const { businessUnits, categories: adminCategories,sortOption, setSortOption, setCategories,setBusinessUnits,setSkillListPaginationOffset } = useAdminStore();
  const [businessUnitsList, setBusinessUnitsList] = useState<BusinessUnit[] | undefined>(
    deepClone(businessUnits)
  );
  const [categories, setCategoryList] = useState<Category[] | undefined>(
    deepClone(adminCategories)
  );
  const [sort, setSort] = useState<Option | undefined>(
    deepClone(sortOption??{} as Option)
  );

  const [filterTab, setFilterTab] = useState("Category");
  const handleFilterTab = (filterMenu: string) => {
    setFilterTab(filterMenu);
  };

  const onCategoryChange = (category: Category) => {
    if (categories) {
      const filteredList = categories?.map((item) => {
        if (item.id === category.id) {
          return { ...category, checked: !category.checked };
        }
        return item;
      });
      setCategoryList(filteredList);
    }
  };

  const onBusinessUnitChange = (businessUnit: BusinessUnit) => {
    if (businessUnitsList) {
      const filteredList = businessUnitsList?.map((item) => {
        if (item.id === businessUnit.id) {
          return businessUnit;
        }
        return item;
      });
      setBusinessUnitsList(filteredList);
    }
  };

  const resetFilter = ()=>{
    const resetCategories = categories?.map((item:Category)=> ({...item,checked:false}))??[];
    const resetBusinessUnits = businessUnits?.map((item:BusinessUnit)=> ({...item,checked:false}))??[];
    setCategories(resetCategories);
    setBusinessUnits(resetBusinessUnits);
    setSort(undefined);
    setSortOption(undefined);
    setBusinessUnitsList(resetBusinessUnits);
    setCategoryList(resetCategories);
    setSkillListPaginationOffset(0);
  }
  const applyFilter = ()=>{
    if(categories && businessUnitsList){
    setCategories(categories);
    setBusinessUnits(businessUnitsList);
    }
    if(sort){
      setSortOption(sort);
    }
    setSkillListPaginationOffset(0);
    handleAdminFilter();
  }
  return (
    <div  className="admin-filter-drop-down">
      <div className="filter-header">
        <button className="back-btn" onClick={handleAdminFilter}>
          <svg className="svg-icon plus-icon">
            <use href="#backArrowIcon">
              <title>Back Button</title>
            </use>
          </svg>
          Filter
        </button>
      </div>
      <nav className="filter-nav">
        <div className="filter-menu">
          <div className="filter-menu-item  two-col">
            <button
              className={`filter-tab-btn ${filterTab === "Category" ? "active" : ""}`}
              onClick={() => handleFilterTab("Category")}
            >
              Category
            </button>
            <div
              className={`filter-menu-list ${filterTab === "Category" ? "active" : ""}`}
              style={{ "--itemCount": categories?.length ?? 15 } as React.CSSProperties}
            >
              <CategoryList
                categoryList={categories}
                onChange={(item) => {
                  onCategoryChange(item);
                }}
              />
            </div>
          </div>
          {/* <div className="filter-menu-item">
            <button
              className={`filter-tab-btn ${filterTab === "Business Unit" ? "active" : ""}`}
              onClick={() => handleFilterTab("Business Unit")}
            >
              Business Unit
            </button>
            <div className={`filter-menu-list ${filterTab === "Business Unit" ? "active" : ""}`}>
              <BusinessUnitsList
                businessUnits={businessUnitsList}
                onChange={(item) => onBusinessUnitChange(item)}
              />
            </div>
          </div> */}
          <div className="filter-menu-item">
            <button
              className={`filter-tab-btn ${filterTab === "Sort" ? "active" : ""}`}
              onClick={() => handleFilterTab("Sort")}
            >
              Sort
            </button>
            <div className={`filter-menu-list ${filterTab === "Sort" ? "active" : ""}`}>
              {
                SortOptions.map((item,index)=>(
                  <RadioButton key={index} label={item.label}  name="sortRadio" 
                  defaultChecked={item.value== sort?.value??false}
                  onChange={(e:any)=>{
                    setSort(item);
                  }}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </nav>
      <div className="filter-footer">
        <div className="btn-group align-right">
          <button className="app-btn min-width" onClick={resetFilter}>Reset</button>
          <button className="app-btn min-width primary" onClick={applyFilter}>Apply</button>
        </div>
      </div>
    </div>
  );
};
export default AdminFilter;
