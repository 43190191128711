import { useState } from "react";
import "./inputField.scss";
import { INPUT_MAX_LENGTH } from "utils/constants";
interface Props {
  id?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  value?: any;
  label?: string;
  onChange?: any;
  error?: string;
  defaultValue?: string;
  setSearchToggle?: any;
  isDisabled?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  mandatory?: boolean;
  errorIcon?:boolean;
}
const InputField: React.FC<Props> = (props) => {
  const {
    id,
    type,
    name,
    placeholder,
    value,
    label,
    onChange,
    error,
    defaultValue,
    setSearchToggle,
    isDisabled,
    maxLength,
    autoFocus,
    mandatory,
    errorIcon

  } = props;
  const handleOnFocus = () => {
    setSearchToggle && setSearchToggle(true);
  }
  const [inputError,setInputError]=useState('');
  const handleInputChange = (e:any) => {
    const {value} = e.target;
    if (maxLength && value.length > INPUT_MAX_LENGTH-1) {
      setInputError('Maximum allowed length is 70');
    } else {
      setInputError('');
      onChange(e);
    }
  };
  return (
    <div className="input-wrapper">
      {label && (
        <label>{label}{mandatory && <sup>*</sup>}</label>
      )}
      <input
        className="input-field"
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        autoFocus={autoFocus}
        onChange={handleInputChange}
        onFocus={handleOnFocus}
        disabled={isDisabled}
        maxLength={maxLength}
        spellCheck={true}
      />
      {error ? (
        <div className="validation-message">
          {errorIcon?<span className="validationText-icon">
          <svg className="svg-icon">
                                    <use href="#infoCircleIcon">
                                        <title>infoCircle icon</title>
                                    </use>
                                </svg>
                          
                  
            {error}</span>:<span className="validationText">{error}</span>}
        </div>
      ) : (
        ""
      )}
      {inputError.length > 0 ? (
        <div className="validation-message">
          <span className="validationText">{inputError}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default InputField;
