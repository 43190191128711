import React, { useState } from "react";
import "./adminContent.scss";
import ActionHeader from "../ActionHeader";
import AdminSkillsWrapper from "../AdminSkillsWrapper";
import AdminCertificationsWrapper from "../AdminCertificationsWrapper";
import { useAdminStore } from "store/adminStore";
import SkillQueryBuilder from "../AdminSkillsWrapper/UserDataDetails";
import UserDataDetails from "../AdminSkillsWrapper/UserDataDetails";
import AdminDomainsWrapper from "../AdminDomainsWrapper";
import AdminEmailLog from "../AdminEmailLog";
const AdminContent = () => {
  const{currentTab,setCurrentTab}=useAdminStore();
  const [tabSwitch, setTabSwitch] = useState('Skills');  
  
  return (
    <div className="admin-content">
    <ActionHeader tabSwitch={currentTab} setTabSwitch={setCurrentTab} />      
    {currentTab === "Skills" ? (
    // Render something for "Skills" tab
    <AdminSkillsWrapper tabSwitch={currentTab} />
  ) : currentTab === "Certifications" ? (
    // Render something for "Certifications" tab
    <AdminCertificationsWrapper tabSwitch={currentTab} />
  ) : currentTab === "Users" ? (
    // Render something for "Users" tab
    <UserDataDetails tabSwitch={currentTab} />
  ): currentTab === "Domains" ? (
    // Render something for "Users" tab
    <AdminDomainsWrapper tabSwitch={currentTab} />
  ): currentTab === "EmailLog" ? (<AdminEmailLog tabSwitch={currentTab} />): null}
  </div>
  );
};
export default AdminContent;
