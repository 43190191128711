import React, { useState } from 'react'
import"./domainDataModal.scss"
import InputField from 'components/common-components/InputField';
import PageLoader from 'components/common-components/PageLoader';
import { ErrorMessages, messages } from "utils/messageConstants";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { INPUT_MAX_LENGTH } from 'utils/constants';
import { useGetDomainById } from 'api/admin/useDomainById';
import { useUpdateAdminSubDomain } from 'api/admin/useUpdateAdminSubDomain';
import { useSaveAdminSubDomain } from 'api/admin/useSaveAdminSubDomain';
import { useUserStore } from "store/useUserStore";



  interface Props {
    setheaderState?:any;
    editModalState?:boolean;
    setEditModalState?:any;
    data?:any;
    setCategory?:any;
    category?:any;
    loading?: boolean;
    setLoading?: any;

  }
 
const AddSubDomainDataModal: React.FC<Props>  = ({setheaderState,editModalState, setEditModalState, data, setCategory,category,loading,setLoading}) => {
  const { data: Domain, isLoading,refetch,isFetching} = useGetDomainById(data?.id);
  const { mutateAsync: saveSubDomain } = useSaveAdminSubDomain();
  const [subDomainName, setSubDomainName] = useState(data?.subDomainName || "");
  const [errorMessage, setErrorMessage] = useState("");
  const [editSubDomain, setEditSubDomain] = useState<Boolean>(false);
  const [openEditSubDomainIndex, setOpenEditSubDomainIndex] = useState<number | null>(null);
  const { mutateAsync: updateSubDomainData } = useUpdateAdminSubDomain();
  const [editSubDomainName, setEditSubDomainName] = useState<any>()
  const [error, setError] = useState("");
  const { userManagedRole } = useUserStore();


  const handleEdit=()=>{
  setheaderState(true);
  setEditModalState(true)
  setLoading(false)
  }


  const handleSubDomainEdit = (subDomainIndex: number,subDomainItem: any) => {
    setOpenEditSubDomainIndex(subDomainIndex);
    setEditSubDomain(true);
    setEditSubDomainName(subDomainItem)
    setErrorMessage("")

  };

  const handleSkillClose = () => {
    setOpenEditSubDomainIndex(null);
    setEditSubDomain(false);
    setError("")
    setErrorMessage("")

  };

  const onSaveSubDomain = async () => {
    if (subDomainName.trim() === "") {
      setErrorMessage(ErrorMessages.SUB_DOMAIN_NAME_MISSING);
      return;
    }
      const response = await saveSubDomain({ subDomainName: subDomainName.trim(), domain_id: data?.id });
      if (response.data) {
        const updatedDomain = await refetch(); // Refetch category data
        setCategory({
          ...category ,
          sub_domain_count: updatedDomain.data?.sub_domain_count
        });
        showSuccessToast(messages.SUBDOMAIN_SAVE_SUCCESS);
        setErrorMessage("");
        setSubDomainName("")
      } else if(response?.response?.data?.message){
        setErrorMessage(response?.response?.data?.message);
     }else{
      setErrorMessage(messages.SUBDOMAIN_SAVE_DUPLICATE);
   }
 };

  const updateSubDomain = async()=>{
    if (editSubDomainName.sub_domain_name.trim() === "") {
      setError(ErrorMessages.SUB_DOMAIN_NAME_MISSING);
      return;
    }
      const response = await updateSubDomainData({subDomainId: editSubDomainName?.sub_domain_id, subDomainName: editSubDomainName.sub_domain_name.trim(), domainId: data?.id });
      if (response.data) {
        setEditSubDomainName({...editSubDomainName,sub_domain_name: response.data.sub_domain_name})
        showSuccessToast(messages.SUBDOMAIN_UPDATE_SUCCESS);
        setError("");
        setOpenEditSubDomainIndex(null);
        setEditSubDomain(false);
      } else if(response?.response?.data?.message){
        setError(response?.response?.data?.message);
     } 
          else {
        setError(messages.SUBDOMAIN_SAVE_DUPLICATE);
      }
    
};

if (loading && (isLoading || isFetching)) {
  return <PageLoader />;
}
  return (
    
    <div className="app-modal-content sm  add-skill-data">
   <div className={editModalState?'skill-data-heading edit-mode': 'skill-data-heading'}>
    <div className={editModalState?'skill-data-heading-th1 th-close':'skill-data-heading-th1 th-open'}> 
                <div className='skill-data-title'>SUB DOMAIN NAME</div>
                <div className='skill-data-subtitle'> USER COUNT</div>
   </div>
    <div className={editModalState?'skill-data-heading-th2 th-open':'skill-data-heading-th2 th-close'}>
                    <div className='skill-data-title'> SUB DOMAIN NAME</div>
                    <div className='skill-heading-edit-mode'>
                    <InputField
        type="text"
        value={subDomainName}
        placeholder="Add a new sub domain here"
        onChange={(e: any) => {
          setErrorMessage("");
          setSubDomainName(e.target.value);
        }}
        maxLength={INPUT_MAX_LENGTH}
        error={errorMessage}
      />
                          <button className='app-btn secondary addskillname-btn' title='add' onClick={onSaveSubDomain}>
                            <svg className="svg-icon plus-icon">
                              <use href="#plusIcon">
                                <title>Add Icon</title>
                              </use>
                            </svg>
                          </button>
                    </div>
       </div>
     </div>

      <div className={Domain && Domain.sub_domains && Domain.sub_domains.length > 9 ? 'skill-data-main hasScroll' : 'skill-data-main'}>
      
         {Domain?.sub_domains?.map((subDomainItem, subDomainIndex) => (
          <div className={editModalState?'skill-data-wrapper edit-mode':'skill-data-wrapper'}>            
                  <div className='skill-data-details' key={subDomainIndex} >
                    {subDomainIndex !== openEditSubDomainIndex?(                   
                       <div className='skill-data-details'>
                      <div className='skill-data-name'>{subDomainItem?.sub_domain_name}</div>
                      <div className={editModalState?'skill-data-count th-close':'skill-data-count th-open'}> {subDomainItem?.user_count}</div>
                      <div className={editModalState || subDomainIndex === openEditSubDomainIndex ?'skill-edit-btn th-open':'skill-edit-btn th-close'}>
                     <button className="app-btn icon-only-sm" title='edit' onClick={() => handleSubDomainEdit(subDomainIndex,subDomainItem)}>
                                <svg className="svg-icon edit-icon">
                                  <use href="#editIcon">
                                    <title>Edit Icon</title>
                                  </use>
                                </svg>
                              </button>
                      </div>
                      </div>
):null}
                 </div>
                  <div className={editSubDomain && subDomainIndex === openEditSubDomainIndex ?'skill-data-edit-wrapper th-open':'skill-data-edit-wrapper th-close'}>
                  <InputField type="text" value={editSubDomainName?.sub_domain_name}
                                        onChange={(e:any)=>{
                                          setEditSubDomainName({...editSubDomainName,sub_domain_name: e.target.value});
                                          setError("");
                                        }}
                                        maxLength={INPUT_MAX_LENGTH}
                                        error={error}
                                  ></InputField>
               <button className='app-btn icon-only-sm skildata-btn' title='close' onClick={handleSkillClose}>
                         <svg className="svg-icon close-icon">
                              <use href="#closeIcon">
                                <title>Close Icon</title>
                              </use>
                             </svg>
                      </button>
               <button className='app-btn icon-only-sm skildata-btn' title='add' onClick={updateSubDomain}>
                          <svg className="svg-icon plus-icon">
                              <use href="#tickIcon">
                                <title>Tick Icon</title>
                              </use>
                            </svg>
                      </button>
                  </div>
          </div>  ))}
     </div>
     {editModalState === false && userManagedRole === false?        
     <div className='skill-data-botttom'>
                   
                   <div className='modification-status-holder '>
                       <div className='skill-edit-btn th-open th-open'>
                                 <button className="app-btn secondary  edit-icon-btn " onClick={()=>{
                                   handleEdit();}}>
                                                 <svg className="svg-icon edit-icon">
                                                   <use href="#editIcon">
                                                     <title>Edit Icon</title>
                                                   </use>
                                                 </svg>
                                                 <span>Edit</span>
                               </button>
                         </div>
                   </div>
                  </div>
           :      
           <div className='skill-data-botttom'>        
          </div>
}
      
  </div>
  )
}

export default  AddSubDomainDataModal
