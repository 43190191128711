import React, { useState } from "react";
import "./footer.scss";
import Modal from "components/common-components/Modal";
import AddCustom from "./AddCustom";
import { useSaveCustomSkill } from "api/user/useSaveCustomSkill";
import { showSuccessToast } from "utils/toastUtil";
import { ErrorMessages, messages } from "utils/messageConstants";
const Footer = () => {
  const { mutateAsync: save,isLoading } = useSaveCustomSkill();
  const [showModal, setShowModal] = useState(false);
  const [customSkill, setCustomSkill] = useState<string>("");
  const [error, setError] = useState("");
  const handleModal = () => {
    setCustomSkill("");
    setShowModal(!showModal);
  };

  const saveCustomSkill = async () => {
    try
    {
      if(isLoading) return;
      if (customSkill.trim() === "") {
        setError("Skill name is mandatory");
        return;
      }
      const response = await save({ customSkill: customSkill });
      if (response.data && response.data.id) {
        showSuccessToast(messages.SKILL_SAVE_SUCCESS);
        setShowModal(false);
      } else if(response.response.data.message){
        setError(response.response.data.message);
      }else{
        setError(ErrorMessages.FAILED_TO_SAVE);
      }
  }catch(e){
    setError(ErrorMessages.FAILED_TO_SAVE);
    setShowModal(false);
  }
  }
  return (
    <>
      <div className="layout-footer">
        <p className="main-footer-content">Didn't find the right skills in the bucket? </p>
        <button className="app-btn primary icon-btn-text" onClick={handleModal}>
          <svg className="svg-icon plus-icon">
            <use href="#plusIcon"></use>
          </svg>
          <span className="button-text">Add a Custom Skill</span>
        </button>
      </div>
      {showModal ? (
        <Modal
          size="sm"
          title="Add Custom Skill"
          primaryButton="Continue"
          footerMessage="This custom skill will be added to your profile after verification"
          messageIcon="infoIcon"
          handleClose={async () => {
            if(!isLoading){
              setShowModal(false);
              setError("");
            }
          }}
          onProceed={saveCustomSkill}
          isBackArrow={false}
          placement="placement-bottom"
          btnLoaderIcon={isLoading}
        >
          <AddCustom
            onChange={(skill) => {
              setError("");
              setCustomSkill(skill);
            }}
            value={customSkill}
            error={error}
          />
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default Footer;
