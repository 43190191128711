import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";


export async function getAdminPrimarySkills():Promise<Status|null> {
  const response = await apiClient.get(urls.status());
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetPrimarySkillCount():UseQueryResult<Status> {
    const {setStatus}= useAdminStore();
  return useQuery(["adminPrimarySkills"], async () => {
    const data= await getAdminPrimarySkills();
    if(data!=null){
        setStatus(data);
    }
    return data;
  },{
    refetchOnWindowFocus:false
  });
}
