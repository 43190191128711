import React from 'react';
import InputField from 'components/common-components/InputField';

interface SubjectSectionProps {
    subject: string;
    setSubject: (subject: string) => void;
    errorMessage: any
    setErrorMessage: any  
}

const SubjectSection: React.FC<SubjectSectionProps> = ({subject, setSubject, errorMessage, setErrorMessage }) => {    
    return(
        <div className={`compose-subject-section ${errorMessage.subjectError? 'error' : ''}`}>
        <span className='subject-tag'>Subject</span>
        <div className='subject-input-section '>
        <InputField
        type="text"
        label="Name of the skill"
        value={subject}
        placeholder="Enter the subject here"
        onChange={(e: any) => {
          setErrorMessage({...errorMessage, subjectError:""});
          setSubject(e.target.value);
        }}
        error={`${errorMessage.subjectError?'Please fill this field to send the mail':''}`}
        errorIcon={true}
        />
        </div>
    </div>
);
            }
export default SubjectSection;
