import React from "react";
import "../../searchPanel.scss"
import { useAdminStore } from "store/adminStore";

interface Props {
    from?: string;
    isSkillPage?: boolean;
}

const UserCount: React.FC<Props> = ({
    from,
    isSkillPage,
}) => {

    const {
        groupByCategory,
        groupByDomains,
        status,
        currentTab
    } = useAdminStore();

    const getUserCount = () => {
        if (status) {
            return `${status.current_users_with_any_skill}/${status.current_active_users}`;
        }
        return "0";
    };

    const getUserCountCertification = () => {
        if (status) {
            return `${status.current_users_with_any_certificate}/${status.current_active_users}`;
        }
        return "0";
    };

    const getUserCountDomain = () => {
        if (status) {
            return `${status.current_user_with_any_sub_domain}/${status.current_active_users}`;
        }
        return "0";
    };

    const getUserCountQueryBuilder = () => {
        if (status) {
            return `${status?.current_active_app_users}/${status?.current_active_users}`;
        }
        return "0";
    };

    return (
        <>
            {isSkillPage ? (groupByCategory || from == 'userData' ? null :
                <div className="primary-skill-wrapper purple-shade">
                    <span className="skill-count">{getUserCount()}</span>Users
                </div>
            ) : (currentTab === 'Certifications' ? <div className="primary-skill-wrapper domain-primary-skill-wrapper blue-shade">
                <span className="skill-count">{getUserCountCertification()}</span>Users
            </div> : (currentTab === 'Domains' ? (groupByDomains || from == 'userData' ? null :
                <div className="primary-skill-wrapper domain-primary-skill-wrapper blue-shade">
                    <span className="skill-count">{getUserCountDomain()}</span>Users
                </div>) : (from == "userData" ? <div className="primary-skill-wrapper domain-primary-skill-wrapper blue-shade">
                    <span className="skill-count blue-shade">{getUserCountQueryBuilder()}</span>Users
                </div> : null)
            ))}

        </>
    );
};
export default UserCount;
