const Avatar = ({ name }:{name:string}) => {
    const firstLetter = name ? name.charAt(0).toUpperCase() : '';
    const defaultStyle = {
        borderRadius: '50%',
        backgroundColor: '#9b4dca',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        width: '100%',
        height: '100%',
     
      };
    return (
      <div style={defaultStyle} className="user-image">
        {firstLetter}
      </div>
    );
  };

export default Avatar;
  