import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";


export async function getAdminDomain(search:string,offset:number):Promise<AdminDomainList|null> {
  const response = await apiClient.get(urls.adminDomain(search,offset));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetAdminDomain():UseQueryResult<AdminDomainList> {
  const {subDomainSearch,domainListPaginationOffset, domains} = useAdminStore();
  return useQuery(["AdminDomain",subDomainSearch,domainListPaginationOffset,domains], async () => {
    const DomainSubDomain =  await getAdminDomain(subDomainSearch,domainListPaginationOffset);
    return DomainSubDomain;
  },{
    refetchOnWindowFocus:false
  });
}
