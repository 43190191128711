import React, { useEffect, useState } from "react";
import { useAdminStore } from "store/adminStore";
import UserProfileImage from "components/UserImage";
import SortButton from "components/sortButton";
import PageLoader from "components/common-components/PageLoader";
import { useGetQueryBuilderFilterCertificationUsers } from "api/admin/useGetQueryBuilderFilterCertificationUser";
import { Sort, USERFILTERCERTIFICATIONCOLUMN } from "utils/constants";
import CommonTable from "../CommonTable";

interface Props {
  tabSwitch?: any;
  onUpdateDefaultUserList?: any;
  setUserId?: any;
  setSkillState?: any;
  setLoading?: any;
  setLimit?: any;
}

type SortStatus = {
  [key: string]: string;
};

type SortStatusKeys = keyof SortStatus;

const UserCertificationTable: React.FC<Props> = ({
  onUpdateDefaultUserList,
  setUserId,
  setSkillState,
  setLoading,
  setLimit
}) => {
  const {
    userDesignation,
    filterEnable,
    department,
    certificationIssuer,
    certificationName,
    certificationStatus,
    setSortOptionCertification,
    nonInitiated
  } = useAdminStore();

  const sortByColumnsInitialState: SortStatus = {
    sortBy: "disabled",
    sortByEmployee: "asc",
    sortByDesignation: "disabled",
    sortByDepartment: "disabled",
    sortByCertificationName: "disabled",
    sortByCertificationIssuer: "disabled",
    sortByCertificationStatus: "disabled"
  };

  const [sortByColumn, setSortByColumn] = useState<SortStatus>(
    sortByColumnsInitialState
  );

  const {
    isLoading: filterCertificationUserLoading,
    data: filterCertificationUser
  } = useGetQueryBuilderFilterCertificationUsers(
    certificationName,
    certificationIssuer,
    certificationStatus,
    userDesignation,
    department,
    nonInitiated
  );

  const sortByOnClick = (column: SortStatusKeys, columnNo: number) => {
    const sortOrder = sortByColumn[column] === "asc" ? "desc" : "asc";
    const updatedSortByColumn = {
      ...Object.fromEntries(
        Object.keys(sortByColumn).map(key => [key, "disabled"])
      ),
      [column]: sortOrder
    };
    setSortByColumn(updatedSortByColumn);
    setSortOptionCertification(
      Sort[columnNo * 2 + (sortOrder === "asc" ? 0 : 1)]
    );
  };

  useEffect(() => {
    setSortByColumn(sortByColumnsInitialState);
    setSortOptionCertification({
      label: "first_name_last_name_asc",
      value: "first_name_last_name:asc"
    });
  }, [filterEnable]);

  useEffect(() => {
    if (!filterCertificationUserLoading) {
      onUpdateDefaultUserList(filterCertificationUser);
      setLimit(filterCertificationUser?.limit)
    }
  }, [filterCertificationUserLoading, filterCertificationUser, onUpdateDefaultUserList]);

  const openModal = () => {
    setSkillState(true);
  };

  const renderUserCertifications = (isMobile: boolean = false) => {
    const certifications = filterCertificationUser?.user_certifications || [];
    const offset = filterCertificationUser?.offset || 0;
    if (filterCertificationUserLoading) {
      return <PageLoader />;
    }
    else if (filterCertificationUser?.user_certifications.length === 0) {
      return <td className="nodata-container" colSpan={8}>No Data</td>
    }
    else {
      return certifications.map((item: any, index: number) => {
        if (isMobile) {
          return (
            <div key={index} className="user-skill-details">
              <div className="image-section">
                <button className="icon-only" onClick={() => { openModal(); setUserId(item.id); setLoading(true) }}>
                  <UserProfileImage first_name={item.first_name} ssoId={item.sso_login_id} />
                </button>
              </div>
              <div className="user-detail-section">
                <span className="user-title">{item.first_name} {item.last_name}</span>
                <div className="user-sub-details">
                  <span className="user-id">Id:{item.employee_id}</span>
                  <span className="user-designation">{item.designation}</span>
                </div>
                <div className='box-holder'>
                  <span className='left-section' > {item.certification_name === null ? 'NA' : item.certification_name}</span>
                  <span className='right-section'> {item.certification_status === null ? 'NA' : item.certification_status}</span>
                </div>
                <div className="user-sub-details">
                  <span className="user-id">Issued by: {item.certification_issuer_name === null ? 'NA' : item.certification_issuer_name}</span>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <tr key={index}>
              <td>{offset + index + 1}</td>
              <td>{item.employee_id}</td>
              <td>
                <span className="userwrapper">
                  <div className="profile-img-holder">
                    <button className="icon-only" onClick={() => { openModal(); setUserId(item.id); setLoading(true); }}>
                      <UserProfileImage first_name={item.first_name} ssoId={item.sso_login_id} />
                    </button>
                  </div>
                  <span>{item.first_name} {item.last_name}</span>
                </span>
              </td>
              <td>{item.designation}</td>
              <td>{item.department || '-'}</td>
              <td>{item.certification_name || '-'}</td>
              <td>{item.certification_issuer_name || '-'}</td>
              <td>{item.certification_status || '-'}</td>
            </tr>
          );
        }
      });
    }
  };

  const renderSortButton = (
    column: SortStatusKeys,
    index: number,
  ) => (
    <SortButton
      isAscending={sortByColumn[column]}
      onClick={() => sortByOnClick(column, index)}
      icon="#sortIcon"
    />
  );

  return (
    <>
      <div className="user-details-Wrappper">
        <div className="user-details-Table certification-filter">
          {filterCertificationUserLoading ? (
            <PageLoader />
          ) : (
            <CommonTable
              columns={USERFILTERCERTIFICATIONCOLUMN}
              data={filterCertificationUser?.user_certifications}
              renderSortButton={renderSortButton}
              renderRow={renderUserCertifications}
              placeholder={" No Users(s) Found"}
            />
          )}
        </div>
      </div>
      <div className="user-details-mobile-Wrappper">
        <div className="user-section">
          {renderUserCertifications(true)}
        </div>
      </div>
    </>
  );
};
export default UserCertificationTable;
