import React from "react";

interface SortButtonProps {
  isAscending: any;
  onClick: () => void;
  icon: string;
}

const SortButton: React.FC<SortButtonProps> = ({ isAscending, onClick, icon }) => {
  return (
      <button className={`app-btn link-btn ${isAscending === "disabled" ? "sort-ascend" : (isAscending === "asc") ? "sort-ascend selectedColor" : "sort-ascend sort-descend selectedColor"}`}
      onClick={onClick}>
      <span className="sort-highlight">
      <svg className="svg-icon">
        <use href={icon}>
          <title>sort Icon</title>
        </use>
      </svg>
      </span>
    </button>
  );
};
export default SortButton;
