import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import { useUserStore } from "store/useUserStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveCategory(
    category_name:string,
){
    try{
    const requestObject = {
        category_name
    };
    const response = await apiClient.post(
        urls.categories(),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useSaveCategory():UseMutationResult<
    any,
    ApiError | {message: string},
    {category_name:string}
    >{
        const {searchkey,categoryListPaginationOffset, categories} = useAdminStore();
        const {accessToken} = useUserStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            category_name,
        }: {
            category_name: string;
        }) => {
            const result = await saveCategory(category_name,);
            queryClient.invalidateQueries(["categories",accessToken]);
            queryClient.invalidateQueries(["adminCategories",accessToken])
            queryClient.invalidateQueries(["AdminCategory",searchkey,categoryListPaginationOffset,categories]);

            return result;
        }
    );
    }