import React from "react";
import "./faq-accordion.scss";

const FaqAccordion = () => {

  const openMail=()=>{
    const mailAddress ="skillmatrix@experionglobal.com";
    const mailtoLink = `mailto:${mailAddress}`;
    window.open(mailtoLink, '_blank');
  }

  return (
    <div className="faq-accordion-wrapper">
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq1" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq1">
          <div className="label-header">
            <span className="label-title">What is Skill Matrix Portal/ Skill Portal?</span>
            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>
            A skill matrix portal is a digital tool that helps us to manage and track the skills and
            competencies of all Experion employees.
          </span>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq2" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq2">
          <div className="label-header">
            <span className="label-title">Why Skill Matrix Portal?</span>
            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>
            Skill matrix portal will help , to better understand the workforce, identify areas for
            improvement, and develop a more skilled and capable workforce.
          </span>
          <span>
            Based on the skills and competencies of employees, we can plan training and development
            programs to improve employee skills and fill any gaps.
          </span>
          <span>
            Well managed Skill database is always an asset to any organization which elevates our
            market position.
          </span>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq3" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq3">
          <div className="label-header">
            <span className="label-title">
              What is Primary skill? How many primary skills can I add?
            </span>

            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>
            Primary Skill is the main skill set you are using currently in your projects in
            Experion. You can enter ONLY ONE primary skill.
          </span>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq4" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq4">
          <div className="label-header">
            <span className="label-title">
              What is Secondary Skill? How many secondary skills can I add?
            </span>

            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>
            A secondary skillset refer to a skill or ability that complement / enhance your primary
            skillset. This could be something you are using in Experion, or you possess from our
            previous experience.
          </span>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq5" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq5">
          <div className="label-header">
            <span className="label-title">What is Skill/proficiency level?</span>

            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>
            Skill/Proficiency levels provide a useful framework for assessing skills, and
            identifying areas for growth and development.
          </span>
          <span>
            They help individuals understand their current level of expertise, track their progress,
            and communicate their abilities to others
          </span>
          <span>Proficiency Level we are using in current context are defined in the system</span>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq6" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq6">
          <div className="label-header">
            <span className="label-title">How often should I update my Skills?</span>

            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>
            You can edit/update your skills at any point of time . However, we recommend to review
            and update the skill/certification on a quarterly basis.
          </span>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq7" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq7">
          <div className="label-header">
            <span className="label-title">Will I get reminder for my certification expiry?</span>

            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>Yes, you will get a reminder a month prior to expiry.</span>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq8" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq8">
          <div className="label-header">
            <span className="label-title">Can I edit my Skill details ?</span>

            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>Yes, You can edit your Skills any time.</span>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq9" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq9">
          <div className="label-header">
            <span className="label-title">
              What should I do if I cannot find the skill I am looking for?
            </span>

            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>
            You can add your skill using ‘ Add Custom Skills’. This will be verified by the admin
            and get added to your skill base automatically post approval.
          </span>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input
          type="checkbox"
          className="accordion-radio"
          id="faq10"
          name="faq-accordion-group"
        />
        <label className="accordion-title" htmlFor="faq10">
          <div className="label-header">
            <span className="label-title">Whom should I contact for my queries?</span>

            <span className="app-btn accordion-arrow-icon">
              <svg className="svg-icon icon-plus">
                <use href="#plusIcon"></use>
              </svg>
              <svg className="svg-icon zoom-out">
                <use href="#minusIcon">
                  <title>Minus</title>
                </use>
              </svg>
            </span>
          </div>
        </label>
        <div className="accordion-content">
          <span>
            You can contact <a href="#" onClick={openMail}>skillmatrix@experionglobal.com </a>using the Contact button in the
            portal.
          </span>
        </div>
      </div>
    </div>
  );
};
export default FaqAccordion;
