import React from 'react';

interface Column {
    header: string;
    className: string;
    sortKey?: string;
    index?: any;
}

interface Props {
    columns: Column[];
    data: any; // Adjust the type according to your data structure
    renderSortButton: (sortKey: string, index: number) => React.ReactNode;
    renderRow: any // Adjust the type according to your data structure;
    placeholder: string;
    fixedColumn?: number;
}

const CommonTable: React.FC<Props> = ({ columns, data, renderSortButton, renderRow, placeholder, fixedColumn }) => {
    if (!data || data.length === 0) {
        return <p>{placeholder}</p>;
    }
    return (
        <div className="user-details-Table">
            <table>
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th key={index} className={`${col.className} ${fixedColumn && index >= columns.length - fixedColumn ? 'fixed-column' : ''}`}>
                                <span className="sortWrapper">
                                    {col.header}
                                    {col.sortKey && renderSortButton(col.sortKey, col.index)}
                                </span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {renderRow()}
                </tbody>
            </table>
        </div>
    );
};

export default CommonTable;
