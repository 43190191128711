import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";
import { handleDownload } from "utils/downloadFile";

export async function getAllDomains():Promise<any> {
  const response = await apiClient.get(urls.exportAllDomains());
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useAllDomainsExport():UseQueryResult<any> {
  const {initiateSkillExport,setInitiateSkillExport}= useAdminStore();
  return useQuery(["AllDomainsExport"], async () => {
    const result= await getAllDomains();
    if(result && result.file_url){
      await handleDownload(result);
    }
    // setInitiateSkillExport(false);
    return result;
  },{
    enabled: initiateSkillExport === true,
  });
}