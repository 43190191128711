import React, { useEffect, useState } from "react";
import { useGetEmailLogById } from "api/admin/useEmailLogById";
import PageLoader from "components/common-components/PageLoader";

interface Props {
    selectedRecipients: { value: string, label: string }[];
    setSelectedRecipients: React.Dispatch<React.SetStateAction<{ value: string; label: string; }[]>>
    placeholder: string;
    customNotificationId: number | string;
    userType: string;
    setLimit: any;
    onUpdateRecipientList?: any;
    undeliveredEmailStatus: EmailStatus[];
    selectAll: boolean;
    setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
    allRecipients: { value: string, label: string }[];
    setAllRecipients: React.Dispatch<React.SetStateAction<{ value: string; label: string; }[]>>;
}

const UndeliveredMails: React.FC<Props> = ({
    placeholder,
    customNotificationId,
    userType, setLimit, onUpdateRecipientList,
    undeliveredEmailStatus,
    allRecipients,
    setAllRecipients,
    selectAll,
    setSelectAll,
    selectedRecipients,
    setSelectedRecipients


}) => {
    const { isLoading: mailLogRecipientsLoading, data: mailLogRecipientsData } =
        useGetEmailLogById(customNotificationId, userType, undeliveredEmailStatus);

    const [visibleRecipients, setVisibleRecipients] = useState<{ value: string; label: string }[]>([]);

    useEffect(() => {
        if (!mailLogRecipientsLoading && mailLogRecipientsData) {
          const recipients = mailLogRecipientsData.custom_notification_data?.recipients_details.map(
            (item) => ({ value: item.email, label: `${item.first_name} ${item.last_name}` })
          ) || [];
          setAllRecipients(recipients);
          setVisibleRecipients(recipients);
          onUpdateRecipientList(mailLogRecipientsData);
          setLimit(mailLogRecipientsData?.limit);
      
          // Check if all visible recipients are selected
          const allVisibleSelected = recipients.length > 0 && recipients.every(recipient => 
            selectedRecipients.some(selected => selected.value === recipient.value)
          );
          setSelectAll(allVisibleSelected);
        }
      }, [mailLogRecipientsLoading, mailLogRecipientsData, onUpdateRecipientList, setLimit, selectedRecipients]);



      const checkboxSelectAll = () => {
        if (selectAll) {
          setSelectedRecipients(prev => prev.filter(recipient => 
            !visibleRecipients.some(visible => visible.value === recipient.value)
          ));
        } else {
          const newSelectedRecipients = [...selectedRecipients];
          visibleRecipients.forEach(recipient => {
            if (!newSelectedRecipients.some(selected => selected.value === recipient.value)) {
              newSelectedRecipients.push(recipient);
            }
          });
          setSelectedRecipients(newSelectedRecipients);
        }
        setSelectAll(!selectAll);
      };

      const toggleCheckbox = (email: string, fullName: string) => {
        const selectedIndex = selectedRecipients.findIndex(recipient => recipient.value === email);
        let newSelected: typeof selectedRecipients;
      
        if (selectedIndex === -1) {
          newSelected = [...selectedRecipients, { value: email, label: fullName }];
        } else {
          newSelected = selectedRecipients.filter(recipient => recipient.value !== email);
        }
      
        setSelectedRecipients(newSelected);
      
        const allVisibleSelected = visibleRecipients.every(recipient => 
          newSelected.some(selected => selected.value === recipient.value)
        );
        setSelectAll(allVisibleSelected);
      };    

    if (mailLogRecipientsLoading) {
        return <PageLoader />
    }

    const renderMails = (isMobile: boolean = false) => {
        if (mailLogRecipientsLoading) {
            return <PageLoader />
        }
        const offset = mailLogRecipientsData?.offset || 0;

        if (isMobile) {
            return (
                <div className="mail-log-details">
                    {mailLogRecipientsData?.custom_notification_data?.recipients_details.map((mailItems, mailIndex) => (
                        <label className="mail-card-box detailed flex" key={mailIndex}>
                            <div className="checkbox-wrapper pb-0">
                                <label className="app-checkbox">
                                    <input
                                        type="checkbox"
                                        name="undelivered-mails"
                                        id={`mail-checkbox-${mailIndex}`}
                                        checked={selectedRecipients.some(recipient => recipient.value === mailItems.email)}
                                        onChange={() => toggleCheckbox(mailItems.email, `${mailItems.first_name} ${mailItems.last_name}`)}
                                    />
                                </label>
                            </div>
                            <div className="undelivered-card-box">
                                <div className="mail-card-head">
                                    <h4 className="mail-subject">
                                        <span className="mail-subject-text">{mailItems.first_name ||'NA'} {mailItems.last_name}</span>
                                    </h4>
                                </div>
                                <div>
                                    Emp ID: <span className="value">{mailItems.employee_id || 'NA'}</span>
                                </div>

                                <div className="mail-card-bottom">
                                    <div className="email-id">{mailItems.email}</div>
                                    <div
                                        className={`status-update ${mailItems.status === "Sent"
                                            ? "success"
                                            : mailItems.status === "Pending"
                                                ? "pending"
                                                : mailItems.status === "Failed"
                                                    ? "failed"
                                                    : ""
                                            }`}
                                    >
                                        <span className="status-icon">
                                            <svg className="svg-icon">
                                                <use
                                                    href={
                                                        mailItems.status === "Sent"
                                                            ? "#circleTickIcon"
                                                            : mailItems.status === "Pending"
                                                                ? "#pendingIcon"
                                                                : mailItems.status === "Failed"
                                                                    ? "#crossIcon"
                                                                    : ""
                                                    }
                                                >
                                                    <title>status Icon</title>
                                                </use>
                                            </svg>
                                        </span>
                                        <span className="status-name">{mailItems.status}</span>
                                    </div>
                                </div>
                                <div>
                                    <span className="label-text">Reason</span>
                                    <p>{mailItems.error_type}</p>
                                </div>
                            </div>
                        </label>
                    ))}
                </div>
            );
        } else {
            return (
                <>
                    {mailLogRecipientsData?.custom_notification_data?.recipients_details.map((mailItems, mailIndex) => (
                        <tr key={mailIndex}>
                            <td>
                                <div className="checkbox-wrapper pb-0">
                                    <label className="app-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={selectedRecipients.some(recipient => recipient.value === mailItems.email)}
                                            onChange={() => toggleCheckbox(mailItems.email, `${mailItems.first_name} ${mailItems.last_name}`)}
                                        />
                                    </label>
                                </div>
                            </td>
                            <td>{offset + mailIndex + 1}</td>
                            <td>{mailItems.employee_id || '-' }</td>
                            <td>{mailItems.first_name|| '-' } {mailItems.last_name}</td>
                            <td>{mailItems.email}</td>
                            <td>{mailItems.error_type}</td>
                        </tr>
                    ))}
                </>
            );
        }
    };

    return (
        <>
            <div className="email-details-desktop undelivered-email-id">
                {(!mailLogRecipientsData?.custom_notification_data?.recipients_details || mailLogRecipientsData?.custom_notification_data?.recipients_details.length === 0) ? (
                    <p>{placeholder}</p>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th className="select">
                                    <div className="checkbox-wrapper pb-0">
                                        <label className="app-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={checkboxSelectAll}
                                            />
                                        </label>
                                    </div>
                                </th>
                                <th className="sl-no">SL No</th>
                                <th>Emp ID</th>
                                <th>
                                    <span className="sortWrapper">Employee Name</span>
                                </th>
                                <th>Email ID</th>
                                <th className="col-reason">Reason</th>
                            </tr>
                        </thead>
                        <tbody>{renderMails()}</tbody>
                    </table>
                )}
            </div>
            <button
                className="btn-select-all select-all-btn"
                onClick={checkboxSelectAll}
                disabled={!mailLogRecipientsData}
            >
                Select all
            </button>
            <div className="email-details-mobile">
                {!mailLogRecipientsData?.custom_notification_data?.recipients_details || mailLogRecipientsData?.custom_notification_data?.recipients_details.length === 0 ? (
                    <p>{placeholder}</p>
                ) : (
                    renderMails(true)
                )}
            </div>
        </>
    );
}

export default UndeliveredMails;