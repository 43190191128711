import "./confirm-delete-modal.scss";

const ConfirmReplaceSkill = () => {
  return (
    <div className="app-modal-content confirm-delete-popup">
      <p className="sub-content">Are you sure that you want to replace this skill?</p>
    </div>
  );
};

export default ConfirmReplaceSkill;

