import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";

export async function getAdminSkillList():Promise<any|null> {
  const response = await apiClient.get(urls.SkillList());
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetSkillList():UseQueryResult<any> {
  return useQuery(["AdminSkillList"], async () => {
    return  await getAdminSkillList();
  },{
    refetchOnWindowFocus:false
  });
}