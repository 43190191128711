import { useGetEmailLogById } from 'api/admin/useEmailLogById';
import PageLoader from 'components/common-components/PageLoader';
import React, { useEffect } from 'react'

interface Props {
    placeholder: string;
    customNotificationId: number | string;
    userType: string;
    setLimit: any;
    onUpdateRecipientList?: any;

}

const DeliveredMails: React.FC<Props> = ({ placeholder, customNotificationId,
    userType, setLimit, onUpdateRecipientList }) => {

    const { isLoading: mailLogRecipientsLoading, data: mailLogRecipientsData } =
        useGetEmailLogById(customNotificationId, userType,);

    useEffect(() => {
        if (!mailLogRecipientsLoading) {
            onUpdateRecipientList(mailLogRecipientsData);
            setLimit(mailLogRecipientsData?.limit)
        }
    }, [mailLogRecipientsLoading, mailLogRecipientsData, onUpdateRecipientList]);

    if (mailLogRecipientsLoading) {
        return <PageLoader />
    }

    const renderMails = (isMobile: boolean = false) => {
        const offset = mailLogRecipientsData?.offset || 0;
        if (isMobile) {
            return (
                <div className="mail-log-details">
                    {mailLogRecipientsData?.custom_notification_data?.recipients_details.map((mailItems, mailIndex) => (
                        <div className="mail-card-box detailed" key={mailIndex}>
                            <div className="mail-card-head">
                                <h4 className="mail-subject">
                                        <span className="mail-subject-text">{mailItems.first_name ||'NA'} {mailItems.last_name}</span>
                                </h4>
                            </div>
                            <div>
                                Emp ID: <span className="value">{mailItems.employee_id || 'NA'}</span>
                            </div>

                            <div className="mail-card-bottom">
                                <div className="email-id">{mailItems.email}</div>
                                <div
                                    className={`status-update ${mailItems.status === "Sent"
                                        ? "success"
                                        : mailItems.status === "Pending"
                                            ? "pending"
                                            : mailItems.status === "Failed"
                                                ? "failed"
                                                : ""
                                        }`}
                                >
                                    <span className="status-icon">
                                        <svg className="svg-icon">
                                            <use
                                                href={
                                                    mailItems.status === "Sent"
                                                        ? "#circleTickIcon"
                                                        : mailItems.status === "Pending"
                                                            ? "#pendingIcon"
                                                            : mailItems.status === "Failed"
                                                                ? "#crossIcon"
                                                                : ""
                                                }
                                            >
                                                <title>status Icon</title>
                                            </use>
                                        </svg>
                                    </span>
                                    <span className="status-name">{mailItems.status}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <>
                    {mailLogRecipientsData?.custom_notification_data?.recipients_details.map((mailItems, mailIndex) => (
                        <tr key={mailIndex}>
                            <td>{offset + mailIndex + 1}</td>
                            <td>{mailItems.employee_id || '-' }</td>
                            <td>{mailItems.first_name|| '-' } {mailItems.last_name}</td>
                            <td>{mailItems.email}</td>
                        </tr>
                    ))}
                </>
            );
        }
    };
    return (
        <>
            <div className="email-details-desktop normal-text">
                {(!mailLogRecipientsData?.custom_notification_data?.recipients_details || mailLogRecipientsData?.custom_notification_data?.recipients_details.length === 0) ? (
                    <p>{placeholder}</p>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th className="sl-no">SL No</th>
                                <th>Emp ID</th>
                                <th>
                                    <span className="sortWrapper">Employee Name</span>
                                </th>
                                <th>Email ID</th>
                            </tr>
                        </thead>
                        <tbody>{renderMails()}</tbody>
                    </table>
                )}
            </div>
            <div className="email-details-mobile">
                {!mailLogRecipientsData?.custom_notification_data?.recipients_details || mailLogRecipientsData?.custom_notification_data?.recipients_details.length === 0 ? (
                    <p>{placeholder}</p>
                ) : (
                    renderMails(true)
                )}
            </div>
        </>
    );
}


export default DeliveredMails