import React, { useEffect, useRef, useState } from "react";
import "./notification.scss";
import { useGetUserNotifications } from "api/user/useGetNotifications";
import { UserNotifications } from "utils/constants";
import { getTimeDifference } from "utils/dateUtil";
import { useUpdateAllNotifications } from "api/user/useUpdateAllNotifications";
const NotificationSection = () => {
  const {mutateAsync:updateAllNotifications} = useUpdateAllNotifications();
  const [showNotifications, setShowNotifications]= useState(false);
  const {data:notifications} = useGetUserNotifications();
  const closeRef = useRef(null);

  const markAllNotificationsAsRead= async()=>{
    if(notifications){
      const ids = notifications.map((item)=>item.id);
      await updateAllNotifications({notificationIds:ids});
    }
  }
  const handleClickOutside = (event: { target: any; }) => {
    if (closeRef.current && !(closeRef.current as HTMLElement).contains(event.target)) {
      setShowNotifications(false);
    }
  };
  const close = (event:any) => {
    if(event.key === 'Escape'){
       setShowNotifications(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    window.addEventListener('keydown', close)
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      window.removeEventListener('keydown', close)
    };
    
  }, [showNotifications]);
 
  const onNotificationClicked = ()=>{
    setShowNotifications((prev)=>!prev)
  }
  const isNewNotifcations = notifications && notifications.length>0;
  return (
    <div ref={closeRef} className={`user-notification-dropdown ${showNotifications? 'dropdown-open':''}`}> 
      {/* active class used for notification dot , dropdown-open class to open the notification  */}
      <button
       onClick={onNotificationClicked}
       className={`icon-only notification-nav nav-dropdown-btn ${isNewNotifcations?'active':''}`}>
        <svg className="svg-icon notification-icon">
          <use href="#notificationIcon"></use>
        </svg>
        <span className="menu-arrow"> </span>
      </button>
      <div className="sub-menu-header-menu-wrapper type1 mega-sub-menu">
        <div className="notification-header">
          <div className="notification-header-title">
            <button className="icon-only back-arrow" onClick={() => setShowNotifications(false)}>
              <svg className="svg-icon back-arrow-icon">
                <use href="#backArrowIcon"></use>
              </svg>
            </button>
            <div className="page-header">Notifications<div className="status-count">{notifications?.length ?? 0}</div></div>
          </div>
          <button className="app-btn link-btn underline" onClick={markAllNotificationsAsRead}>Mark all as read</button>
        </div>
       <NotificationList notifications={notifications}/>
      </div>
    </div>
  );
};
const NotificationList = ({notifications}:{notifications:UserNotifications[]|undefined})=>{

  if(notifications){
  return(
    <div className="notification-main-content">
      {notifications.length === 0 &&(
        <div className="notification-item">
            No new notifications
          </div>
      )}
      {notifications.map((notification:UserNotifications,index)=>(
        <NotificationItem notification={notification} key={index}/>
      ))}
        </div>
  )
      }
      return null;
}

const NotificationItem = ({notification}:{notification:UserNotifications})=>{
  if(notification.payload.templateName!=='admin-rejected-skill'){
    return (
      <div className="notification-item item" key={notification.id}>
            The skill <span className="bold-name">'{notification.payload.skillName}'</span> you added
            has been <span className="bold-name">'Approved' </span> under the category  <span className="bold-name">'{notification.payload.category}'</span>
            <span className="notification-date date-check"> {getTimeDifference(notification.updated_at)} </span>
      </div>
    );
  }else{
  return(
      <div className="notification-item item" key={notification.id}>
            The skill <span className="bold-name">'{notification.payload.skillName}'</span> you added
            has been <span className="bold-name">'Rejected'</span>
            <span className="notification-date date-check"> {getTimeDifference(notification.updated_at)} </span>
      </div>
  );
  }
}
export default NotificationSection;
