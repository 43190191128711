import React, { ReactNode } from "react";
import "./radioButton.scss";

interface Props {
  label?: string;
  name?: string;
  id?: string;
  defaultChecked?: any;
  onChange?: any;
}

const RadioButton: React.FC<Props> = ({ label, name, id, defaultChecked, onChange }) => {

  return (
    <div className="radio-btn-wrapper">
      <label htmlFor={id} className="app-radio-btn">
        <input
          type="radio"
          name={name}
          key={id}
          id={id}
          checked={defaultChecked}
          onChange={onChange}
        />
        <span className="radio-btn-label">{label}</span>
      </label>
    </div>
  );
};
export default RadioButton;
