import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import { useUserStore } from "store/useUserStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateAdminNotification(
    id:number,
){
    try{
    const requestObject = {
        notifications: [
          {
            notification_id: id,
            is_seen: true
          }
        ]
      }
    const response = await apiClient.put(
        urls.adminNotifications(),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useUpdateNotificationForAdmin():UseMutationResult<
    any,
    ApiError | {message: string},
    {notificationId:number}
    >{
        const {skillAdded,categories,businessUnits,sortOption,searchkey} = useAdminStore();
        const {accessToken}=useUserStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            notificationId,
        }: {
            notificationId:number;
        }) => {
            const result = await updateAdminNotification(notificationId);
            queryClient.invalidateQueries(["AdminSkills",skillAdded,categories,businessUnits,sortOption,searchkey]);
            queryClient.invalidateQueries(["adminNotifications",accessToken]);
            return result;
        }
    );
    }