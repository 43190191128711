import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useUserStore } from "store/useUserStore";
import { useAdminStore } from "store/adminStore";


export async function getAdminDomains():Promise<Array<AdminDomain>|null>{
  const response = await apiClient.get(urls.allDomains());
  if (response && response.data && response.data.data)
    return response.data.data.domains as AdminDomain[];
  return null;
}

export function useGetAdminDomains():UseQueryResult<Array<AdminDomain>> {
  const {setDomains} = useAdminStore();
  const {accessToken} = useUserStore();
  return useQuery(["adminDomains",accessToken], async () => {
    const domains = await getAdminDomains();
    const filterDomains = domains?.map((item)=>({...item, checked:false}));
    setDomains(filterDomains??[]);
    return filterDomains;
  },{
    refetchOnWindowFocus:false,
  });
}
