import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";

export async function getQueryBuilderFilterUsers(offset:number, limit: string|number,skill_names: string,skill_levels: string, skill_types:string, designations: string, departments: string, sort: Option | undefined, search: string):Promise<QueryBuilderFilterUser|null> {
  const response = await apiClient.get(urls.AdvanceUserFilterList(offset, limit,skill_names,skill_levels, skill_types, designations,departments,sort, search));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetQueryBuilderFilterUsers(skill_names: any,skill_levels: any, skill_types:any, designations: any, department_value:any):UseQueryResult<QueryBuilderFilterUser> {
  const {queryBuilderListPaginationOffset, queryBuilderPaginationOffset, userSearch,sortOptionSkills,skillName, skillLevel, skillType, userDesignation, department, setFilterUserCount} = useAdminStore();
  const SkillNames =  skill_names.map((item: any)=>item.value).join(",");
  const skillLevels = skill_levels.map((item: any) => item.value).join(",");
  const skillTypes = skill_types.map((item: any) => item.value).join(",");
  const Designations =  designations.map((item: any)=>item.value).join(","); 
  const Departments =  department_value.map((item: any)=>item.value).join(",");
  return useQuery(["QueryBuilderFilterUserList",queryBuilderListPaginationOffset, queryBuilderPaginationOffset, userSearch, sortOptionSkills, skillName, skillLevel, skillType, userDesignation, department], async () => {
    const filterUsers = await getQueryBuilderFilterUsers(
      queryBuilderListPaginationOffset,
      queryBuilderPaginationOffset,
      SkillNames,
      skillLevels,
      skillTypes,
      Designations,
      Departments,
      sortOptionSkills,
      userSearch
    );
    setFilterUserCount(filterUsers?.filtered_user_count?? 0)
    return filterUsers;
  },{
    refetchOnWindowFocus:false
  });
}