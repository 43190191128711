import React, { useState } from "react";
import "./adminSubdomainWrapper.scss";
import SearchPanel from "../ActionHeader/SearchPanel";
import { useGetBusinessUnits } from "api/useGetBusinessUnits";
import { useGetAdminCategories } from "api/admin/useGetCategories";
import { useGetPrimarySkillCount } from "api/admin/useGetPrimarySkills";
import { useGetSkillExport } from "api/admin/useExportAllSkills";
import { useAdminStore } from "store/adminStore";
import AdminModal from "components/common-components/AdminModel";
import { ErrorMessages, messages } from "utils/messageConstants";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { useSaveSkill } from "api/admin/useSaveSkill";
import { useGetAllSkills } from "api/admin/useGetAllSkills";
import SkillLisiting from "../AdminSkillsWrapper/SkillListing";
import SubDomainGridView from "./SubdomainGridView";
import DomainLisiting from "./DomainListing";
import { useGetSubDomainExport } from "api/admin/useExportAllSubDomains";
import { useGetAdminDomains } from "api/admin/useGetDomains";

interface Props {
  tabSwitch?: any;
}
const AdminDomainsWrapper: React.FC<Props> = ({ tabSwitch }) => {
  const { data: domains } = useGetAdminDomains();
  const { mutateAsync: saveSkill } = useSaveSkill();
  const {data}= useGetPrimarySkillCount();
  const {refetch}=useGetSubDomainExport();

  const {setInitiateSkillExport, setSkillAdded,skillViewToggle,groupByDomains} = useAdminStore();
  const [editMode, setEditMode] = useState(false);

  const [toogleAddSkillModal, setToggleAddSkillModal] = useState(false);
  const [skillName, setSkillName] = useState("");
  const [selectedCategory, setCategory] = useState<Category | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddSkillModal = () => {
    setSkillName("");
    setErrorMessage("");
    setCategory(undefined);
    setSkillAdded(false);
    setToggleAddSkillModal(!toogleAddSkillModal);
  };
  
  const downloadSubDomainsReport = ()=>{
    setInitiateSkillExport(true);
    refetch();
    if(data){
      setInitiateSkillExport(false);
    }
  }
  const onSaveSkill = async () => {
    if (skillName.trim() === "") {
      setErrorMessage(ErrorMessages.SKILL_NAME_MISSING);
      return;
    }
    if (selectedCategory) {
      const response = await saveSkill({ skillName, categoryId: selectedCategory?.id });
      if (response.data) {
        setSkillAdded(true);
        showSuccessToast(messages.SKILL_SAVE_SUCCESS);
        setErrorMessage("");
        closeModal();
      } else if(response?.response?.data.message) {
        setErrorMessage(response?.response?.data.message);
      }else{
        setErrorMessage(messages.SKILL_SAVE_DUPLICATE)
      }
    } else {
      setErrorMessage(messages.SELECT_CATEGORY);
    }
  };
  const closeModal = () => {
    setToggleAddSkillModal(false);
  };
  return (
    <>
      <SearchPanel
        skillSViewToggle={skillViewToggle}
        tabSwitch={tabSwitch}
        setSkillSViewToggle={()=>console.log('')}
        searchPlaceholder="Search for a Sub Domain"
        pageName="SubDomains"
        isSkillPage={false}
        download={downloadSubDomainsReport}
        handleAdd={handleAddSkillModal}
        from="SubDomains"
      />
      <div className="admin-skills-wrapper">
        {groupByDomains?(<DomainLisiting editMode={editMode} />
):(
            <SubDomainGridView  handleAdd={handleAddSkillModal} />

    )}
      </div>
    </>
  );
};
export default AdminDomainsWrapper;
