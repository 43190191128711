/* eslint-disable no-unused-vars */
import CreatableSelect from "react-select/creatable";
import "./creatable-list.scss";
import { useEffect, useMemo, useState } from "react";
interface Props {
  certificationName?:string;
  placeholder?: string;
  inputLabel?: string;
  classNamePrefix?: string;
  error?:string;
  onValueChanged: (value: Option) => void;
  mandatory?: boolean;
  maxLength?:number;
  certificationMasters?: any;
  setSubDomainValue?: (value: Option | null ) => void;

}
const CreatableList: React.FC<Props> = ({
  certificationName,
  placeholder,
  inputLabel,
  classNamePrefix,
  error,
  onValueChanged,
  mandatory,
  maxLength,
  certificationMasters,
  setSubDomainValue

}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<Option | null>();

  
  const certificationList:Option[] = useMemo(()=>certificationMasters?.map((item:CertificationMaster)=>(
    {
    label:item.certification_name,
    value:item.certification_name,
  })) ?? [],[certificationMasters]);
  
  const [options, setOptions] = useState(certificationList);

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  useEffect(()=>{
    if(certificationMasters){
      setOptions(certificationList);
    }
  },[certificationList, certificationMasters]);
  

  useEffect(()=>{
    if(certificationList && certificationList.length> 0 && certificationName){
      const selectedOption = certificationList.find((item)=>item.value === certificationName);
        if(selectedOption){
          setValue(selectedOption);
          setSubDomainValue?.(null)
      }  
      }
  },[certificationList,certificationName]);

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue.trim());
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
      onValueChanged(newOption);
    }, 1000);
  };

  return (
    
    <div className={"creatableWrapper"}>      
      <label>{inputLabel}{ mandatory &&<sup>*</sup>}</label>
      <CreatableSelect
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={(newValue) => {
          if(newValue!==null){
            setValue(newValue)
            onValueChanged(newValue as Option);
          }
        }}
        onCreateOption={handleCreate}
        options={options}
        value={ value}
        placeholder={placeholder}
        classNamePrefix={classNamePrefix}
      />
       {error ? (
        <div className="validationMessage">
          <span className="validationText">{error}</span>
        </div>
      ) : (
        ""
      )}
      {/* <div className={"validationMessage"}> <span className={"validationText"}>Error</span> </div> */}
    </div>
  );
};
export default CreatableList;
