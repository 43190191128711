import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";
import { handleDownload } from "utils/downloadFile";

export async function getAdminSkillExport(domains:string,sort:string):Promise<any> {
  const response = await apiClient.get(urls.exportAllSubDomains(domains,sort));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetSubDomainExport():UseQueryResult<any> {
  const {initiateSkillExport,setInitiateSkillExport}= useAdminStore();
  const {domains,sortOption} = useAdminStore();
  const selectedDomains = domains.filter(item=>item.checked===true).map(item=>item.id).join(",");
  const sort= sortOption?.value ?? "";
  return useQuery(["AdminAllSubDomainsExport"], async () => {
    const result= await getAdminSkillExport(selectedDomains,sort);
    if(result && result.file_url){
      await handleDownload(result);
    }
    setInitiateSkillExport(false);
    return result;
  },{
    enabled: initiateSkillExport === true,
  });
}

