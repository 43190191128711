import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSkillStore } from "store/useSkillStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateCustomSkills(
    customSkills:CustomSkill[]
){
    const user_added_skills:CustomSkillEdit[] = customSkills.map((item:CustomSkill)=>({
        skill_name: item.skill_name,
        skill_id: item.id,
        is_custom_skill:true,
      }));
    const response = await apiClient.put(
        urls.skills(),
        {skills:user_added_skills},
    );
    return response.data;
}

export function useUpdateCustomSkills():UseMutationResult<
    any,
    ApiError | {message: string},
    {customSkills:CustomSkill[]}
    >{
        const {skillSearchKey,categories} = useSkillStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            customSkills,
        }: {
            customSkills: CustomSkill[];
        }) => {
            const result = await updateCustomSkills(customSkills);
            queryClient.invalidateQueries(["GetAllUserSkills"]);
            queryClient.invalidateQueries(["categoryWiseSkills",skillSearchKey,categories]);
            return result;
        }
    );
    }