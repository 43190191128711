import React from 'react';
import InputField from 'components/common-components/InputField';

interface SearchBarProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ value, onChange }) => (
    <div className="search-field">
        <InputField
            type="text"
            placeholder="Search by Emp Name"
            value={value}
            onChange={onChange}
        />
        <button className="icon-only search-btn">
            <svg className="svg-icon search-icon">
                <use href="#searchIcon"></use>
            </svg>
        </button>
    </div>
);

export default SearchBar;
