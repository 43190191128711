import { filter } from "d3";
import { graphConfig } from "./adLogin/authConfig";
import { PAGINATION_ITEMS_PER_PAGE, ISVERIFIED } from "./constants";
import { getCurrentDateTimeForExport } from "./dateUtil";

console.log('window.__RUNTIME_CONFIG__?.API_END_POINT -->',window.__RUNTIME_CONFIG__ );
const API_END_POINT =
  window.__RUNTIME_CONFIG__?.REACT_APP_API_BASE_URL ?? process.env.REACT_APP_API_BASE_URL;
const API_VERSION ='/api/v1/';
const prepareUrl = (url:string)=>`${API_END_POINT}${API_VERSION}${url}`

export const urls = {
  baseUrl: API_END_POINT,
  getProfileInfo: () => ``,
  categories: () => prepareUrl(`categories`),
  skills: () => prepareUrl(`skills`),
  categorySkills: (skillSearchKey: string, filteredCategories: string) =>
    prepareUrl(`category-skills?search_skills=${skillSearchKey}&categories=${filteredCategories}`),
  userSkills: () => prepareUrl(`user-skills`),
  deleteUserSkills: (skill_id: number) => prepareUrl(`user-skills/${skill_id}`),
  customSkill: () => prepareUrl("custom-skills"),
  certificationsList: (userId: number) => prepareUrl(`certifications?user_id=${userId}`),
  certifications: () => prepareUrl(`certifications`),
  certificationTags: () => prepareUrl("tags"),
  certificationView: (certificate_id: string | number, userId: number) =>
    prepareUrl(`certifications/${certificate_id}?user_id=${userId}`),
  certificationById: (certificate_id: string | number) =>
    prepareUrl(`certifications/${certificate_id}`),
  userDetails: () => graphConfig.graphMeEndpoint,
  userImage: () => graphConfig.graphProfileImageEndPoint,

  businessUnits: () => prepareUrl("business-units"),
  adminSkills: (
    categories: string,
    businessUnits: string,
    sort: string,
    search: string,
    offset: number
  ) => {
    const categoryIds = categories.length > 0 ? `categories=${categories}` : "";
    const buIds = businessUnits.length > 0 ? `&bu=${businessUnits}` : "";
    const sortOption = sort.length > 0 ? `&sort=${sort}` : "";
    const searchString = search.length > 0 ? `&search=${search}` : "";
    return prepareUrl(
      `skills?${categoryIds}${buIds}${sortOption}${searchString}&offset=${offset}&limit=${PAGINATION_ITEMS_PER_PAGE}`
    );
  },
  adminSkillsBubbleView: (categories: string) => {
    const categoryIds = categories.length > 0 ? `categories=${categories}` : "";
    return prepareUrl(`skills?${categoryIds}&offset=0&limit=-1`);
  },
  AdminSkillUsers: (skillId: number, offset: number, sort: Option | undefined) => {
    const sortOption = sort ? `&sort=${sort.value}` : "";
    return prepareUrl(
      `skills/${skillId}/users?offset=${offset}&limit=${PAGINATION_ITEMS_PER_PAGE}&sort=${sortOption}`
    );
  },
  AdminSkillById: (skillId: number) => prepareUrl(`skills/${skillId}`),
  adminCertifications: () => prepareUrl(`admin/certifications`),
  userInfo: () => prepareUrl(`user-info`),
  certificationMasters: () => prepareUrl(`certification-master/certifications`),
  certificationHolders: (searchkey: string, offset: number) => {
    const searchParam = searchkey.length > 0 ? `&search_certification=${searchkey}` : "";
    return prepareUrl(
      `certification-holders?limit=${PAGINATION_ITEMS_PER_PAGE}&offset=${offset}${searchParam}`
    );
  },
  notifications: () => prepareUrl(`notifications?notification_for=User`),
  customSkillDelete: (skill_id: number) => prepareUrl(`custom-skills/${skill_id}`),
  skillById: (skillId: number, isCustomSkill: boolean) =>
    prepareUrl(`skills/${skillId}?is_custom_skill=${isCustomSkill}`),
  skillUpdate: (skillId: number) => prepareUrl(`skills/${skillId}`),
  certificationUsers: (certificationId: number, offset: number, sort: Option | undefined) => {
    const sortOption = sort ? `&sort=${sort.value}` : "";
    return prepareUrl(
      `certifications/${certificationId}/users?offset=${offset}&limit=${PAGINATION_ITEMS_PER_PAGE}&sort=${sortOption}`
    );
  },
  status: () => prepareUrl("status"),
  adminNotifications: () => prepareUrl(`notifications?notification_for=Admin`),
  exportSkills: (skillId: number) =>
    prepareUrl(`skills/${skillId}/export?format=xls&date_time=${getCurrentDateTimeForExport()}`),
  skillApproveReject: (skillId: number) => prepareUrl(`skills/${skillId}/custom-skill`),
  exportAllSkills: (categories: string, businessUnits: string, sort: string) => {
    const categoryIds = categories.length > 0 ? `&categories=${categories}` : "";
    const buIds = businessUnits.length > 0 ? `&bu=${businessUnits}` : "";
    const sortOption = sort.length > 0 ? `&sort=${sort}` : "";
    return prepareUrl(
      `skills-export?format=xls&date_time=${getCurrentDateTimeForExport()}${categoryIds}${buIds}${sortOption}`
    );
  },
  exportAllCertifications: () =>
    prepareUrl(`certifications-export?format=xls&date_time=${getCurrentDateTimeForExport()}`),
  exportCertificationUsers: (certificationId: number) =>
    prepareUrl(
      `certifications/${certificationId}/export?format=xls&date_time=${getCurrentDateTimeForExport()}`
    ),
  certificationMaster: () => prepareUrl(`certification-master/certifications`),
  editCertificationMaster: (certificationId: number) =>
    prepareUrl(`certification-master/certifications/${certificationId}`),
  certificationProviders: () => prepareUrl(`certification-providers`),
  exportAllCategories: () =>
    prepareUrl(`categories-export?format=xls&date_time=${getCurrentDateTimeForExport()}`),
  adminCategory: (search: string, offset: number) => {
    const searchString = search.length > 0 ? `&search=${search}` : "";
    return prepareUrl(
      `categories-info?${searchString}&offset=${offset}&limit=${PAGINATION_ITEMS_PER_PAGE}`
    );
  },
  categoryById: (category_id: string | number) => prepareUrl(`categories/${category_id}`),
  AdminCategoryById: (category_id: number) => prepareUrl(`categories/${category_id}`),
  exportSkillsUsers: (
    skill_names: string,
    skill_levels: string,
    skill_types: string,
    designations: string,
    department: string,
    sort: Option | undefined
  ) => {
    const skillNames = skill_names.length > 0 ? `&skill_names=${skill_names}` : "";
    const skillLevels = skill_levels.length > 0 ? `&skill_levels=${skill_levels}` : "";
    const skillTypes = skill_types.length > 0 ? `&skill_types=${skill_types}` : "";
    const designation = designations.length > 0 ? `&designations=${designations}` : "";
    const sortOption = sort ? `&sort=${sort.value}` : "";
    const departments = department.length > 0 ? `&departments=${department}` : "";

    return prepareUrl(
      `advance-skill-filter-export?format=xls&date_time=${getCurrentDateTimeForExport()}${skillNames}${skillLevels}${skillTypes}${designation}${departments}${sortOption}`
    );
  },
  defaultUserList: (
    offset: number,
    limit: number | string,
    search: string,
    sort: Option | undefined
  ) => {
    const searchString = search.length > 0 ? `&search=${search}` : "";
    const sortOption = sort ? `&sort=${sort.value}` : "";
    return prepareUrl(
      `default-user-list?offset=${offset}&limit=${limit}${searchString}${sortOption}`
    );
  },
  AdvanceUserFilterList: (
    offset: number,
    limit: number | string,
    skill_names: string,
    skill_levels: string,
    skill_types: string,
    designations: string,
    department: string,
    sort: Option | undefined,
    search: string
  ) => {
    const skillNames = skill_names.length > 0 ? `&skill_names=${skill_names}` : "";
    const skillLevels = skill_levels.length > 0 ? `&skill_levels=${skill_levels}` : "";
    const skillTypes = skill_types.length > 0 ? `&skill_types=${skill_types}` : "";
    const designation = designations.length > 0 ? `&designations=${designations}` : "";
    const sortOption = sort?.value ? `&sort=${sort.value}` : "";
    const searchString = search.length > 0 ? `&search=${search}` : "";
    const departments = department.length > 0 ? `&departments=${department}` : "";
    return prepareUrl(
      `advance-skill-filter?offset=${offset}&limit=${limit}${skillNames}${skillLevels}${skillTypes}${designation}${departments}${sortOption}${searchString}`
    );
  },
  DesignationList: () => prepareUrl(`designations`),
  SkillList: () => prepareUrl(`skill-list?${ISVERIFIED}`),
  UserViewById: (userId: string | number | undefined | null) =>
    prepareUrl(`user-details/${userId}`),
  exportDefaultUsers: (sort: Option | undefined) => {
    const sortOption = sort ? `&sort=${sort.value}` : "";
    return prepareUrl(
      `default-user-list-export?format=xls&date_time=${getCurrentDateTimeForExport()}${sortOption}`
    );
  },
  DepartmentList: () => prepareUrl(`departments`),
  users: () => prepareUrl("all-users"),
  allAppUsersList: () => prepareUrl("app-users-list"),
  nonUsersList: () => prepareUrl("non-users"),
  allFilterList: (
    skill_names: string,
    skill_levels: string,
    skill_types: string,
    designations: string,
    department: string,
    sort: Option | undefined
  ) => {
    const skillNames = skill_names.length > 0 ? `&skill_names=${skill_names}` : "";
    const skillLevels = skill_levels.length > 0 ? `&skill_levels=${skill_levels}` : "";
    const skillTypes = skill_types.length > 0 ? `&skill_types=${skill_types}` : "";
    const designation = designations.length > 0 ? `&designations=${designations}` : "";
    const sortOption = sort ? `&sort=${sort.value}` : "";
    const departments = department.length > 0 ? `&departments=${department}` : "";
    return prepareUrl(
      `advance-skill-filter-recipients?${skillNames}${skillLevels}${skillTypes}${designation}${departments}${sortOption}`
    );
  },
  mail: () => prepareUrl(`custom-notifications`),
  domains: () => prepareUrl(`user-domains`),
  domainSubdomains: () => prepareUrl(`domains-sub-domains`),
  subDomainsList: (userId: number | string) => prepareUrl(`user-domains/${userId}`),
  DomainById: (user_domain_id: string | number, userId: number | string) =>
    prepareUrl(`user-domains/${user_domain_id}/${userId}`),
  subDomainView: (user_domain_id: string | number, userId: number) =>
    prepareUrl(`user-domains/${user_domain_id}/${userId}`),
  SubDomainById: (user_domain_id: string | number, userId: number | string) =>
    prepareUrl(`user-domains/${user_domain_id}/${userId}`),
  SubDomainStatus: () => prepareUrl(`user-domains-status`),
  adminSubDomains: (subDomains: string, sort: string, search: string, offset: number) => {
    const domainsIds = subDomains.length > 0 ? `domains=${subDomains}` : "";
    const sortOption = sort.length > 0 ? `&sort=${sort}` : "";
    const searchString = search.length > 0 ? `&search=${search}` : "";
    return prepareUrl(
      `sub-domains?${domainsIds}${sortOption}${searchString}&offset=${offset}&limit=${PAGINATION_ITEMS_PER_PAGE}`
    );
  },
  AdminSubDomainUsers: (sub_domain_id: number, offset: number, sort: Option | undefined) => {
    const sortOption = sort ? `&sort=${sort.value}` : "";
    return prepareUrl(
      `sub-domains/${sub_domain_id}/users?offset=${offset}&limit=${PAGINATION_ITEMS_PER_PAGE}&sort=${sortOption}`
    );
  },
  exportAllSubDomains: (domains: string, sort: string) => {
    const domainIds = domains.length > 0 ? `&categories=${domains}` : "";
    const sortOption = sort.length > 0 ? `&sort=${sort}` : "";
    return prepareUrl(
      `sub-domains-export?format=xls&date_time=${getCurrentDateTimeForExport()}${domainIds}${sortOption}`
    );
  },
  exportSubDomain: (sub_domain_Id: number) =>
    prepareUrl(
      `sub-domains/${sub_domain_Id}/export?format=xls&date_time=${getCurrentDateTimeForExport()}`
    ),
  allDomains: () => prepareUrl(`domains-list`),
  adminDomain: (search: string, offset: number) => {
    const searchString = search.length > 0 ? `&search=${search}` : "";
    return prepareUrl(
      `domains?${searchString}&offset=${offset}&limit=${PAGINATION_ITEMS_PER_PAGE}`
    );
  },
  domainById: (domain_id: string | number) => prepareUrl(`domains/${domain_id}`),
  saveDomains: () => prepareUrl(`domains`),
  AdminDomainById: (domain_id: string | number) => prepareUrl(`domains/${domain_id}`),
  AdminSubDomainById: (sub_domain_id: number) => prepareUrl(`sub-domains/${sub_domain_id}`),
  saveAdminSubDomains: () => prepareUrl(`sub-domains`),
  exportAllDomains: () =>
    prepareUrl(`domains-export?format=xls&date_time=${getCurrentDateTimeForExport()}`),
  AdvanceUserFilterCertificationList: (
    offset: number,
    limit: number | string,
    certification_names: string,
    certification_issuers: string,
    certification_status: string,
    designations: string,
    department: string,
    sort: Option | undefined,
    search: string,
    has_certification: Option[]
  ) => {
    const certificationNames =
      certification_names.length > 0 ? `&certification_names=${certification_names}` : "";
    const certificationIssuer =
      certification_issuers.length > 0 ? `&certification_issuers=${certification_issuers}` : "";
    const certificationStatus =
      certification_status.length > 0 ? `&certification_status=${certification_status}` : "";
    const designation = designations.length > 0 ? `&designations=${designations}` : "";
    const sortOption = sort?.value ? `&sort=${sort.value}` : "";
    const searchString = search.length > 0 ? `&search=${search}` : "";
    const departments = department.length > 0 ? `&departments=${department}` : "";
    const nonInitiated =
      has_certification.length > 0 ? `&has_certification=${false}` : `&has_certification=${true}`;

    return prepareUrl(
      `advance-certification-filter?offset=${offset}&limit=${limit}${certificationNames}${certificationIssuer}${certificationStatus}${designation}${departments}${sortOption}${searchString}${nonInitiated}`
    );
  },
  AdvanceUserFilterDomainList: (
    offset: number,
    limit: number | string,
    domain_names: string,
    sub_domain_names: string,
    domain_status: string,
    designation: string,
    department_value: string,
    min_duration: string,
    max_duration: string,
    non_initiated_domain: string,
    non_initiated: string,
    sort: Option | undefined,
    search: string
  ) => {
    const domainNames = domain_names.length > 0 ? `&domain_name=${domain_names}` : "";
    const subDomainNames =
      sub_domain_names.length > 0 ? `&sub_domain_names=${sub_domain_names}` : "";
    const domainStatus = domain_status.length > 0 ? `&is_active=${domain_status}` : "";
    const designations = designation.length > 0 ? `&designations=${designation}` : "";
    const departments = department_value.length > 0 ? `&departments=${department_value}` : "";
    const minDuration = min_duration.length > 0 ? `&min_duration=${min_duration}` : "";
    const maxDuration = max_duration.length > 0 ? `&max_duration=${max_duration}` : "";
    const proficiencyLevel =
      non_initiated_domain.length > 0 ? `&proficiency_level=${non_initiated_domain}` : "";
    const hasDomain =
      non_initiated.length > 0 ? `&has_sub_domain=${false}` : `&has_sub_domain=${true}`;
    const sortOption = sort?.value ? `&sort=${sort.value}` : "";
    const searchString = search.length > 0 ? `&search=${search}` : "";
    return prepareUrl(
      `advance-sub-domain-filter?offset=${offset}&limit=${limit}${domainNames}${subDomainNames}${domainStatus}${designations}${departments}${minDuration}${maxDuration}${proficiencyLevel}${hasDomain}${sortOption}${searchString}`
    );
  },
  exportCertificationsUsers: (
    certification_names: string,
    certification_issuers: string,
    certification_status: string,
    designations: string,
    department: string,
    sort: Option | undefined,
    has_certification: Option[]
  ) => {
    const certificationNames =
      certification_names.length > 0 ? `&certification_names=${certification_names}` : "";
    const certificationIssuers =
      certification_issuers.length > 0 ? `&certification_issuers=${certification_issuers}` : "";
    const certificationStatus =
      certification_status.length > 0 ? `&certification_status=${certification_status}` : "";
    const designation = designations.length > 0 ? `&designations=${designations}` : "";
    const sortOption = sort?.value ? `&sort=${sort.value}` : "";
    const departments = department.length > 0 ? `&departments=${department}` : "";
    const nonInitiated =
      has_certification.length > 0 ? `&has_certification=${false}` : `&has_certification=${true}`;
    return prepareUrl(
      `advance-certification-filter-export?format=xls&date_time=${getCurrentDateTimeForExport()}${certificationNames}${certificationIssuers}${certificationStatus}${designation}${departments}${sortOption}${nonInitiated}`
    );
  },
  exportDomainsUsers: (
    domain_names: string,
    sub_domain_names: string,
    proficiency_level: string,
    min_duration: string,
    max_duration: string,
    domain_status: string,
    designations: string,
    department: string,
    sort: Option | undefined,
    has_certification: Option[]
  ) => {
    const DomainNames = domain_names.length > 0 ? `&domain_name=${domain_names}` : "";
    const SubDomainNames =
      sub_domain_names.length > 0 ? `&sub_domain_names=${sub_domain_names}` : "";
    const ProficiencyLevel =
      proficiency_level.length > 0 ? `&proficiency_level=${proficiency_level}` : "";
    const MinDuration = min_duration.length > 0 ? `&min_duration=${min_duration}` : "";
    const MaxDuration = max_duration.length > 0 ? `&max_duration=${max_duration}` : "";
    const DomainStatus = domain_status.length > 0 ? `&is_active=${domain_status}` : "";
    const designation = designations.length > 0 ? `&designations=${designations}` : "";
    const sortOption = sort?.value ? `&sort=${sort.value}` : "";
    const departments = department.length > 0 ? `&departments=${department}` : "";
    const nonInitiated =
      has_certification.length > 0 ? `&has_certification=${false}` : `&has_certification=${true}`;

    return prepareUrl(
      `advance-sub-domain-filter-export?format=xls&date_time=${getCurrentDateTimeForExport()}${DomainNames}${SubDomainNames}${ProficiencyLevel}${MinDuration}${MaxDuration}${MaxDuration}${DomainStatus}${designation}${departments}${sortOption}${nonInitiated}`
    );
  },
  emailLogList: (
    offset: number,
    limit: number | string,
    search: string,
    sort: Option | undefined,
    filter: string
  ) => {
    const searchString = search.length > 0 ? `&search=${search}` : "";
    const sortOption = sort ? `&sort=${sort.value}` : "";
    const filterOption = filter ? `&filter=${filter}` : "";
    return prepareUrl(
      `custom-notifications?offset=${offset}&limit=${limit}${searchString}${sortOption}${filterOption}`
    );
  },
  emailLogById: (
    custom_notifications_id: string | number,
    user_type: string,
    offset: number,
    limit: number | string,
    search: string,
    filter: string
  ) => {
    const searchString = search.length > 0 ? `&search=${search}` : "";
    const filterOption = filter ? `&filter=${filter}` : "";
    return prepareUrl(
      `custom-notifications/${custom_notifications_id}?user_type=${user_type}&offset=${offset}&limit=${limit}${searchString}${filterOption}`
    );
  },
};