import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";
import { handleDownload } from "utils/downloadFile";

export async function getAllFilterCertificationsUser(
  certification_names: string,
  certification_issuers: string,
  certification_status: string,
  designations: string,
  departments: string,
  sort: Option | undefined,
  has_certification: Option[]
): Promise<any> {
  const response = await apiClient.get(
    urls.exportCertificationsUsers(
      certification_names,
      certification_issuers,
      certification_status,
      designations,
      departments,
      sort,
      has_certification
    )
  );
  if (response && response.data && response.data.data) return response.data.data;
  return null;
}

export function useAllFilterCertificationsUsersExport(
  certification_names: any,
  certification_issuers: any,
  certification_status: any,
  designations: any,
  department_value: any
): UseQueryResult<any> {
  const {
    initiateSkillExport,
    certificationIssuer,
    certificationName,
    certificationStatus,
    userDesignation,
    sortOptionCertification,
    department,
    nonInitiated,
  } = useAdminStore();
  const CertificationNames = certification_names.map((item: any) => item.value).join(",");
  const CertificationIssuer = certification_issuers.map((item: any) => item.value).join(",");
  const CertificationStatus = certification_status.map((item: any) => item.value).join(",");
  const Designations = designations.map((item: any) => item.value).join(",");
  const Departments = department_value.map((item: any) => item.value).join(",");
  return useQuery(
    [
      "AllFilteredCertificationsUsersExport",
      certificationIssuer,
      certificationName,
      certificationStatus,
      userDesignation,
      department,
      nonInitiated,
      sortOptionCertification,
    ],
    async () => {
      const result = await getAllFilterCertificationsUser(
        CertificationNames,
        CertificationIssuer,
        CertificationStatus,
        Designations,
        Departments,
        sortOptionCertification,
        nonInitiated
      );
      if (result && result.file_url) {
        await handleDownload(result);
      }
      return result;
    },
    {
      enabled: initiateSkillExport === true,
    }
  );
}
