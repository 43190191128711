import "./reject-reason.scss";
import InputField from "components/common-components/InputField";
import { RejectReasons } from "utils/constants";

const RejectReasonModal = ({
  rejectReason,
  error,
  setRejectReason
}:{
  rejectReason:string,
  error:string,
  setRejectReason:(value:string)=>void;
}) => {

  return (
    <div className="app-modal-content sm reject-reason-skill">
      <InputField 
      value={rejectReason}
      onChange={(e:any)=>{
        const {value} = e.target;
        setRejectReason(value);
      }}
      error={error}
      type="text"
      label="What is the Reason for rejection?"
      placeholder="Type here" />
      <div className="reject-btn-wrapper">
        <button className="app-btn only-btn-text" onClick={()=> setRejectReason(RejectReasons.IrrlevantSkill)}>
          <span className="button-text">{RejectReasons.IrrlevantSkill}</span>
        </button>
        <button className="app-btn only-btn-text" onClick={()=> setRejectReason(RejectReasons.LackOfEvidence)}>
          <span className="button-text">{RejectReasons.LackOfEvidence}</span>
        </button>
        <button className="app-btn only-btn-text" onClick={()=> setRejectReason(RejectReasons.OutdatedSkill)}>
          <span className="button-text">{RejectReasons.OutdatedSkill}</span>
        </button>
      </div>
    </div>
  );
};
export default RejectReasonModal;
