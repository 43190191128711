import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useUserStore } from "store/useUserStore";


export async function getUserNotifications():Promise<UserNotifications[]|null> {
  const response = await apiClient.get(urls.notifications());
  if (response && response.data && response.data.data )
    return response.data.data.notifications;
  return null;
}

export function useGetUserNotifications():UseQueryResult<Array<UserNotifications>> {
  const {accessToken} = useUserStore();
  return useQuery(["userNotifications",accessToken], async () => {
    return getUserNotifications();
  },{
    refetchOnWindowFocus:false,
  });
}
