import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserDetails } from "api/user/useGetUser";
import { useUserStore } from "store/useUserStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

  async function loginUser(
){
    try{

    const response = await apiClient.post(
        urls.userInfo(),
        {},
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useLoginUser():UseMutationResult<
    any,
    ApiError | {message: string},{user:string}
    >{
        const {setUserinfo}=useUserStore();
        return useMutation(
        async ({user}:{user:string}) => {
            const result = await loginUser();
            if(result.data){
                setUserinfo(result.data.user_info);
                return result.data.user_info;
            }
            return "";
        }
    );
    }