/* eslint-disable no-unused-vars */
import CreatableSelect from "react-select/creatable";
import "./creatable-list.scss";
import { useEffect, useMemo, useState } from "react";
import { useGetProviders } from "api/user/useGetProviders";
interface Props {
  providerName?:string;
  placeholder?: string;
  inputLabel?: string;
  classNamePrefix?: string;
  error?:string;
  onValueChanged: (value: Option) => void;
  mandatory?: boolean;
  maxLength?:number;
}
const CertificationProviders: React.FC<Props> = ({
  providerName,
  placeholder,
  inputLabel,
  classNamePrefix,
  error,
  onValueChanged,
  mandatory,
  maxLength
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<Option | null>();
  const {data:certificationProviders}= useGetProviders();
  
  const certificationProviderList:Option[] = useMemo(()=>certificationProviders?.map((item:CertificationProvider)=>({
    label:item.certification_issuer_name,
    value:item.certification_issuer_name,
  })) ?? [],[certificationProviders]);
  
  const [options, setOptions] = useState(certificationProviderList);

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  useEffect(()=>{
    if(certificationProviders){
      setOptions(certificationProviderList);
    }
  },[certificationProviderList, certificationProviders]);
  

  useEffect(()=>{
    if(certificationProviderList && certificationProviderList.length> 0 && providerName){
      const selectedOption = certificationProviderList.find((item)=>item.value === providerName);
      if(selectedOption){
        setValue(selectedOption);
      }
    }
  },[certificationProviderList,providerName]);

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue?.trim());
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
      onValueChanged(newOption);
    }, 1000);
  };
  return (
    
    <div className={"creatableWrapper"}>      
      <label>{inputLabel}{ mandatory &&<sup>*</sup>}</label>
      <CreatableSelect
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={(newValue) => {
          if(newValue!==null){
            setValue(newValue)
            onValueChanged(newValue as Option);
          }
        }}
        onCreateOption={handleCreate}
        options={options}
        value={value}
        placeholder={placeholder}
        classNamePrefix={classNamePrefix}
      />
       {error ? (
        <div className="validationMessage">
          <span className="validationText">{error}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default CertificationProviders;
