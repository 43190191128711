
import moment from 'moment';

export const DATE_FORMAT = "dd/MM/yyyy";

export const DATE_TIME_FORMAT ="yyyy-MM-dd hh:mm a";

export function getFormattedDate(date:string|null) {
  if(date==="NA") return date;
  else if(date!==null && date.length > 0 ){
     const formattedDateTime = moment(new Date(date)).format('DD/MM/yyyy');
     return formattedDateTime;
  }
  return '';
}

export const getCertificationDate = (certificationDate:CertificationDate,dateType:"IssueDate"|"ExpiryDate")=>{
    let date ='';
    if(dateType === "IssueDate"){
        date=certificationDate.issueYear+"-"+certificationDate.issueMonth?.value.padStart(2,"0")+"-01";
    }else if(dateType ==="ExpiryDate" && certificationDate.expiryYear!=="" && certificationDate.expiryMonth!=null){
        date=certificationDate.expiryYear+"-"+certificationDate.expiryMonth?.value.padStart(2,"0")+"-01";
    }
    return date;
}

function formatDate(dateString:string) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
  }

  export const getTimeDifference=(timestamp:string)=> {
   const timeDifference = calculateDifference(timestamp);
    if (timeDifference.minutes < 60) {
      if(timeDifference.minutes <= 0 ){
        return "now";
      }else{
        return `${timeDifference.minutes} minute${timeDifference.minutes === 1 ? '' : 's'} ago`;
      }
    } else if (timeDifference.hours < 24) {
      return `${timeDifference.hours} hour${timeDifference.hours === 1 ? '' : 's'} ago`;
    } else if(timeDifference.days >= 30){
        return formatDate(timestamp);
    }else {
      return `${timeDifference.days} day${timeDifference.days === 1 ? '' : 's'} ago`;
    }
  }
 

function calculateDifference(utcTimestamp:string) {
  const currentUtcDateTime = moment.utc();
  const timestampDateTime = moment.utc(utcTimestamp);

  const timeDifferenceMinutes = currentUtcDateTime.diff(timestampDateTime, 'minutes');
  const timeDifferenceHours = currentUtcDateTime.diff(timestampDateTime, 'hours');
  const timeDifferenceDays = currentUtcDateTime.diff(timestampDateTime, 'days');

  return {
    minutes: timeDifferenceMinutes,
    hours: timeDifferenceHours,
    days: timeDifferenceDays,
  };
}

export function getCurrentDateTimeForExport() {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = String(now.getFullYear());
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${day}-${month}-${year}_${hours}-${minutes}-${seconds}`;
}

export function getFormattedExpiryDate(dateTime:string|null) {
  if(dateTime!==null){
    if(dateTime === "NA"){
      return dateTime;
    }else if(dateTime.length > 0){
      const [date, time] = dateTime.split(' ');
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    }
    return "";
  }
  return "";
}

export   const getDate = (value: any) => {
  const years = Math.floor(value / 12);
  const remainingMonths = value % 12;
  return {years, remainingMonths}
  }

export const formatDateWithSuffix = (date: any) => {
  const day = date.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Converts 24-hour time to 12-hour time

  let suffix = "th";
  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return `Sent on: ${day}${suffix} ${month} ${year}, ${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;
};
