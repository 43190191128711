import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";


export async function getAdminSubDomains(domains:string,sort:string,search:string,offset:number):Promise<AdminSubDomains|null> {
  const response = await apiClient.get(urls.adminSubDomains(domains,sort,search,offset));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetAdminSubDomains():UseQueryResult<AdminSubDomains> {
  const {domains,sortOption,subDomainSearch,subDomainListPaginationOffset} = useAdminStore();
  const selectedDomains = domains.filter(item=>item.checked===true).map(item=>item.id).join(",");
  const sort= sortOption?.value ?? "";
  return useQuery(["AdminSubDomains",domains,sortOption,subDomainSearch,subDomainListPaginationOffset], async () => {
    const categorySkills =  await getAdminSubDomains(selectedDomains,sort,subDomainSearch,subDomainListPaginationOffset);
    return categorySkills;
  },{
    refetchOnWindowFocus:false
  });
}
