import React, { useEffect, useRef, useState } from "react";
import "./header.scss";
import { useUserStore } from "store/useUserStore";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { clearAllStores } from "store";
import ProfileImage from "components/ProfileImage";

const Header = () => {
  const { instance } = useMsal();
  const { setAccessToken, userInfo } = useUserStore();
  const { isFilterOpen } = useUserStore();
  const [profileToggle , setProfileToggle] = useState(false);
  const closeRef = useRef(null);
  const navigate = useNavigate();

  const handleLogout = async() => { 
    instance.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}/logout-callback`,
    }).then(()=>{
      clearAllStores();
      setAccessToken("");
      navigate("/");
    })
  };

  const onLogout = async() => {
    await handleLogout();
  };

  const handleProfileToggle =()=>{
    setProfileToggle(!profileToggle);
  }
 
  const handleClickOutside = (event: { target: any; }) => {
    if (closeRef.current && !(closeRef.current as HTMLElement).contains(event.target)) {
      setProfileToggle(false);
    }
  };

  const close= ()=>{
    setProfileToggle(false);
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    window.addEventListener('keydown', close)
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      window.removeEventListener('keydown', close)
    };
    
  }, []);

  return (
    <div className="layout-header">
      <div className="header-left-portion">
        <div className="logo-container">
          <svg className="svg-icon logo-icon">
            <use href="#logoIcon"></use>
          </svg>
        </div>
      </div>
    </div>
  );
};
export default Header;
