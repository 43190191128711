
export const messages = {
        SKILL_SAVE_SUCCESS:'Skill saved successfully!',
        SKILL_SAVE_DUPLICATE:'The skill already exists!',
        SELECT_CATEGORY: 'Please select a category',
        CERTIFICAION_SAVE_SUCCESS:'Saved Successfully',
        CERTIFICAION_SAVE_FAILED:'Failed to save. Please try again.',
        PRIMARY_SKILL_SUB_LABEL:'Skill aligned to the current project',
        CERTIFICATION_MASTER_SAVED:'Certification Master Saved Successfully',
        CERTIFICATION_MASTER_SAVE_FAILED:'Certification Master Save Failed',
        SKILL_APPROVED:'Skill approved successfully',
        SKILL_APPROVAL_FAILED: 'Skill approval Failed.Please try again later.',
        SKILL_REJECTED:'Skill rejected successfully',
        SKILL_REJECT_FAILED:'Skill reject failed.Please try again later.',
        SKILL_UPDATED:'Changes saved successfully',
        SKILL_ADDED: 'Skill added successfully',
        SKILL_DELETED:'Skill deleted successfully',
        CATEGORY_SAVE_SUCCESS:'Category saved successfully!',
        CATEGORY_UPDATE_SUCCESS:'Category updated successfully!',
        MAIL_SEND:'Email sent successfully',
        DOMAIN_SAVE_SUCCESS:'Domain saved successfully!',
        DOMAIN_UPDATE_SUCCESS:'Domain updated successfully!',
        MAIL_INITIATED:'Email initiated',
        SUBDOMAIN_SAVE_DUPLICATE:'The sub domain already exists!',
        SUBDOMAIN_SAVE_SUCCESS:'Sub domain saved successfully!',
        SUBDOMAIN_UPDATE_SUCCESS:'sub domain updated successfully!',
        MAIL_FAILED:'Mail send failed.Please try again later.',




    };
export const ErrorMessages = {
    SKILL_NAME_MISSING : "Enter skill name",
    SINGLE_PRIMARY_SKILL:"Only 1 Primary skill is allowed",
    SINGLE_SECONDARY_SKILL:"Only 1 Secondary skill is allowed",
    CERTIFICATION_ISSUE_DATE:"Issued Date should not exceed current date",
    CERTIFICATION_EXPIRY_DATE:"Expiry Date should be greater than Issued Date",
    SELECT_CATEGORY:'Please select a category',
    SKILL_NAME_REQUIRED:'Skill name is required',
    REJECT_REASON_REQUIRED:'Reject reason required',
    MAX_CHAR_EXCEEDED:'Maximum allowed char(s) is 70',
    FAILED_TO_SAVE:'Failed to save. Please try again.',
    CATEGORY_NAME_MISSING : "Enter category name",
    DOMAIN_NAME_MISSING : "Enter domain name",
    SUB_DOMAIN_NAME_MISSING : "Enter sub domain name",


}