import React, { useState } from "react";
import "./welcome-section.scss";
import { useUserStore } from "store/useUserStore";
interface Props {
  filterToggle?: any;
  setFilterToggle?: any;
}

const WelcomeSection: React.FC<Props> = ({ filterToggle, setFilterToggle }) => {
  const { userInfo } = useUserStore();

  const handleFilter = () => {
    setFilterToggle(!filterToggle);
  };

  return (
    <div className="welcome-section">
      <div className="welcome-user-profile">
        <div className="user-name">Hey {userInfo.first_name} 👋 </div>
        <div className="user-content">
          <span className="main-text">Please have a look at the skill groups below and pick the most relevant skills and drop them
            to the respective buckets in your profile panel</span>
          <span className="mobile-text">You can add your skills and certifications here.</span>
        </div>
      </div>
      <div className="filter-panel-wrapper">
        <button className="icon-only filter-panel-icon" onClick={handleFilter}>
          <svg className="svg-icon filter-icon">
            <use href="#filterIcon">
              <title>FilterIcon</title>
            </use>
          </svg>
          <span className="btn-text">Filter</span>
        </button>
      </div>
    </div>
  );
};
export default WelcomeSection;
