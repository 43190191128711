import React from "react";
import { emailLogFilterOptions, undeliveredEmailFilterOptions } from "utils/constants";

interface Props {
    activeTab: string;
    handleTabClick: (tab: string, userType: string) => void;
    userDataList: any; // Adjust the type according to your data structure
    setUndeliveredEmailStatus: React.Dispatch<React.SetStateAction<any>>;
    setRecipientEmailStatus: React.Dispatch<React.SetStateAction<any>>;
    setSubject: (tab: string) => void;
    setMessage: (tab: string) => void;
    mail: any;
}

const EmailTabs: React.FC<Props> = ({
    activeTab,
    handleTabClick,
    userDataList,
    setUndeliveredEmailStatus,
    setRecipientEmailStatus,
    setSubject,
    setMessage,
    mail,
}) => {
    return (
        <div className="app-c-tab type-2 placement-left">
            <button
                className={`app-c-tab-item ${activeTab === 'total' ? 'active' : ''}`}
                onClick={() => {
                    handleTabClick("total", "all");
                    setUndeliveredEmailStatus(undeliveredEmailFilterOptions);
                }}
            >
                Total <span className="count">({mail?.total_recipients})</span>
            </button>
            <button
                className={`app-c-tab-item ${activeTab === 'delivered' ? 'active' : ''}`}
                onClick={() => {
                    handleTabClick("delivered", "sent");
                    setUndeliveredEmailStatus(undeliveredEmailFilterOptions);
                    setRecipientEmailStatus(emailLogFilterOptions)
                }}
            >
                Delivered <span className="count">({mail?.sent_recipients})</span>
            </button>
            <button
                className={`app-c-tab-item ${activeTab === 'undelivered' ? 'active' : ''}`}
                onClick={() => {
                    handleTabClick("undelivered", "failed");
                    setSubject(userDataList?.custom_notification_data?.subject ?? "");
                    setMessage(userDataList?.custom_notification_data?.message ?? "")
                    setRecipientEmailStatus(emailLogFilterOptions)
                }}
            >
                Undelivered <span className="count">({mail?.failed_recipients})</span>
                
            </button>
        </div>
    );
};

export default EmailTabs;
