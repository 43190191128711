import ToogleBtn from "components/common-components/Toogle";
import "./addCertification.scss";
import InputField from "components/common-components/InputField";
import SelectBox from "components/common-components/ReactSelect";
import { INPUT_MAX_LENGTH, Months, getMonth } from "utils/constants";
import { useGetCertificationTags } from "api/user/useGetTags";
import React from "react";
import { useGetCertificateById } from "api/user/useGetCertificateById";
import { ActionMeta, MultiValue } from "react-select";
import CreatableList from "components/common-components/CreatableList";
import { deepClone } from "utils/userUtils";
import { useSkillStore } from "store/useSkillStore";
import CertificationTags from "components/common-components/CertificationTags";
import { useGetProviders } from "api/user/useGetProviders";
import CertificationProviders from "components/common-components/CertificationProviders";
import { useGetCertfificationMasters } from "api/user/useGetCertificationMaster";

const AddCertification = ({
  certificationError,
  setCertificationError,
  isEdit,
}: {
  certificationError: CertificationError;
  setCertificationError: (certificationError: CertificationError) => void;
  isEdit: boolean;
}) => {
  const {
    certification,
    certificationDate,
    setCertification,
    setCertificationDate,
  } = useSkillStore();
  
  const { data: tags } = useGetCertificationTags();
  const {data:issuedBy} = useGetProviders();
  const { data } = useGetCertificateById(certification?.id);
  const {data:certificationMasters}= useGetCertfificationMasters();

  const certificationTags = React.useMemo(() => {
    return tags?.map((item) => ({ label: item.name, value: item.id }));
  }, [tags]);

  const onTagChange = (selectedTag:MultiValue<Option>,actionMeta: ActionMeta<Option>) => {
    const tags = deepClone(certification.tags ?? []);
    if (actionMeta.action === "select-option" && actionMeta.option) {
      tags.push(actionMeta.option);
      setCertification({ ...certification, tags: tags });
    } else if (actionMeta.action === "remove-value" && actionMeta.removedValue) {
      const filtered = tags.filter((item) => item.value !== actionMeta.removedValue?.value);
      setCertification({ ...certification, tags: filtered });
    }
  };
  const onChange =(selectedTag:Option)=>{
    const tags = deepClone(certification.tags ?? []);
    tags.push(selectedTag);
    setCertification({ ...certification, tags: tags });

  }
  React.useEffect(() => {
    if (isEdit) {
      (async () => {
        if (isEdit && data?.certification_tags) {
          const tags = certificationTags?.filter((item) =>
            data.certification_tags.some((tag) => tag.tag_id === item.value)
          );
          if (tags) {
            const Tags: Option[] = tags.map((item) => ({
              label: item.label,
              value: item.value.toString(),
            }));
            setCertification({
              ...certification,
              tags: Tags,
            });
          }
        }
      })();
    }
  }, [certificationTags,data]);

  return (
    <div className="app-modal-content sm add-certificate">
      <form>
        <CreatableList
          inputLabel="Name of the Certification"
          placeholder="Enter Name of the Certification" 
          classNamePrefix="react-multi-select" 
          certificationName={certification.certification_name}
          onValueChanged={(option:Option)=>{
            setCertificationError({ ...certificationError, certificationName: "" });
            setCertification({ ...certification, certification_name: option.value });
          }}
          error={certificationError.certificationName}
          mandatory
          certificationMasters={certificationMasters}
          />
         <CertificationProviders
          inputLabel="Issued by"
          placeholder="Issued by Organization" 
          classNamePrefix="react-multi-select" 
          providerName={certification.issued_by}
          onValueChanged={(option:Option)=>{
            setCertificationError({ ...certificationError, issued_by: "" });
            setCertification({ ...certification, issued_by: option.value });
          }}
          error={certificationError.issued_by}
          mandatory
          />

        <div className="layout fr2 full-width">
          <div className="box pr-6">
            <label htmlFor="">Issued Date<sup>*</sup></label>
            <div className="layout fr2">
              <div className="box pr-1">
                <SelectBox
                  label=""
                  name="category"
                  placeholder="Month"
                  options={Months}
                  value={getMonth(certificationDate.issueMonth?.value ?? "")}
                  classNamePrefix="react-select-box"
                  isSearchable={false}
                  error={certificationError.issueMonth}
                  onChange={(item: Option) => {
                    setCertificationError({ ...certificationError, issueMonth: "" });
                    setCertificationDate({
                      ...certificationDate,
                      issueMonth: getMonth(item.value),
                    });
                  }}                  
                />
              </div>
              <div className="box pl-1">
                <InputField
                  type="text"
                  placeholder="Year"
                  value={certificationDate.issueYear}
                  maxLength={4}
                  onChange={(e: any) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setCertificationError({ ...certificationError, issueYear: "" });
                    setCertificationDate({ ...certificationDate, issueYear: value });
                  }}
                  error={certificationError.issueYear}
                />
              </div>
            </div>
          </div>
          <div className="box pl-5">
            <label htmlFor="">Expiration date<sup>*</sup></label>
            <div className="layout fr2">
              <div className="box pr-1">
                <SelectBox
                  label=""
                  value={getMonth(certificationDate.expiryMonth?.value ?? "")}
                  name="category"
                  placeholder="Month"
                  options={Months}
                  classNamePrefix="react-select-box"
                  isSearchable={false}
                  isDisabled={!certification.is_expirable}
                  onChange={(item: Option) => {
                    setCertificationError({ ...certificationError, expiryMonth: "" });
                    setCertificationDate({
                      ...certificationDate,
                      expiryMonth: getMonth(item.value),
                    });
                  }}
                  error={certificationError.expiryMonth}
                />
              </div>
              <div className="box pl-1  relative">
                <InputField
                  type="text"
                  placeholder="Year"
                  isDisabled={!certification.is_expirable}
                  value={certificationDate.expiryYear}
                  maxLength={4}
                  onChange={(e: any) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setCertificationError({ ...certificationError, expiryYear: "" });
                    setCertificationDate({ ...certificationDate, expiryYear: value });
                  }}
                  error={certificationError.expiryYear}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="no-expiry">
          <ToogleBtn
            toggleState={!certification.is_expirable}
            onToggleChange={() => {
              setCertification({
                ...certification,
                is_expirable: !certification.is_expirable,
              });
              setCertificationDate({
                ...certificationDate,
                expiryYear: "",
                expiryMonth: null,
              });
              setCertificationError({ ...certificationError, expiryMonth: "", expiryYear: "" });
            }}
          />
          <label className="app-checkbox">No Expiry</label>
        </div>
        <div className="layout fr2 full-width">
          <div className="box pr-6">
            <div className="box pr-1">
              <InputField
                type="text"
                label="Credential ID (Optional)"
                placeholder="Credential ID"
                value={certification.credential_id}
                onChange={(e: any) => {
                  setCertification({ ...certification, credential_id: e.target.value });
                }}
                maxLength={INPUT_MAX_LENGTH}
              />
            </div>
          </div>
          <div className="box pl-5">
            <div className="box pr-1">
              <InputField
                type="text"
                label="Credential URL (Optional)"
                placeholder="Certificate verification URL"
                value={certification.credential_url}
                error={certificationError.credentialUrl}
                onChange={(e: any) => {
                  setCertificationError({ ...certificationError, credentialUrl: "" });
                  setCertification({ ...certification, credential_url: e.target.value });
                }}
              />
            </div>
          </div>
        </div>

        <div className="input-tag-holder">
         <CertificationTags
            inputLabel="Relevant Tags (Optional)"
            placeholder="Type and press enter to add multiple tags" 
            classNamePrefix="react-multi-select"
            values={certification.tags}
            onMultiChange={(value: MultiValue<Option>, actionMeta: ActionMeta<Option>)=>{
              onTagChange(value, actionMeta);
            }}
            onChange={onChange}
          />
        </div>
      </form>
    </div>
  );
};
export default AddCertification;
