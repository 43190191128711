import { create } from 'zustand';
import produce from "immer";
import { persist } from "zustand/middleware";
import { getDefaultUser,getDefaultUserInfo } from 'utils/userUtils';


export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
  accessToken:'',
  isFilterOpen: false,
  userInfo:getDefaultUserInfo(),
  user:getDefaultUser(),
  idToken:'',
  userIdentityToken:'',
  userManagedRole: false,
  userToggleRole: false,
  clearStore:()=>
  set(
    produce(
      (state)=>{
        state.accessToken='';
        state.isFilterOpen= false;
        state.userInfo=getDefaultUserInfo();
        state.user=getDefaultUser();
        state.idToken='';
        state.userIdentityToken='';
        state.userManagedRole=false;
        state.userToggleRole=false;
      }
    )
  ),
  setUserManagedRole: (isManagedRole:boolean)=>
  set(
    produce((state)=>{
      state.userManagedRole = isManagedRole;
    })
  ),
  setUserToggleRole: (isToggle:boolean)=>
  set(
    produce((state)=>{
      state.userToggleRole = isToggle;
    })
  ),
  setuserIdentityToken:(token:string)=>
  set(
    produce(
      (state)=>{
        state.userIdentityToken = token;
      }
    )
  ),
  setIdToken:(token: string)=>
  set(
    produce(
      (state)=>{
        state.idToken=token;
      }
    )
  ),
  setUserinfo:(user:UserInfo)=>
  set(
    produce(
      (state)=>{
        state.userInfo = user;
      }
    )
  ),
  setFilterOpen: (isOpen:boolean)=>
    set(
      produce((state)=>{
        state.isFilterOpen = isOpen;
      })
    ),
  setAccessToken: (token: string) =>
    set(
      produce((state) => {
        state.accessToken = token;
      })
    ),
  setUser: (user: User) =>
    set(
      produce((state) => {
        state.user = user;
      })
    ),
}),{
  name:"user-store",
}
    )
)