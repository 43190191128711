import React from 'react'
import "./composeModal.scss"
import { MultiValueProps, OptionProps, components } from 'react-select'
import SelectBox from 'components/common-components/ReactSelect';
import 'quill/dist/quill.snow.css';
import { useAdminStore } from 'store/adminStore';
import { useGetAllUsers } from 'api/admin/useAllUser'
import QuillEditor from '../../../../../../components/QuillEditor'
import SenderSection from './senderSection';
import SubjectSection from './subjectSection';
import PageLoader from 'components/common-components/PageLoader';

interface ComposeProps {
    selectedRecipients: Option[];
    setSelectedRecipients: React.Dispatch<React.SetStateAction<Option[]>>;
    subject: string;
    setSubject: (subject: string) => void;
    message: string;
    setMessage: (message: string) => void;  
    errorMessage: any
    setErrorMessage: any 
}

const ComposeModal: React.FC<ComposeProps> = ({selectedRecipients, 
    setSelectedRecipients, 
    subject, 
    setSubject, 
    message, 
    setMessage,
    errorMessage,
    setErrorMessage,
}) => {
    const {
        allUsers,
    } = useAdminStore();
    const { data: allUser, isLoading } = useGetAllUsers();
      
      if (isLoading) {
        return <div><PageLoader/></div>;
      }
      
    const filterUsers = (inputValue: string) => {
        return allUsers ? allUsers.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        ) : [];
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<any>((resolve) => {
            setTimeout(() => {
                resolve(filterUsers(inputValue));
            }, 1000);
        });

    const handleRecipientClose = (removedRecipient: Option) => {
        const updatedRecipients = selectedRecipients.filter((recipient) => recipient?.value !== removedRecipient.value);
        setSelectedRecipients(updatedRecipients);
    };

    const MoreSelectedBadge: React.FC<MoreSelectedBadgeProps> = ({ items }) => {
        const title = items.join(", ");
        const length = items.length;
        const label = `+ ${length}`;

        const handleRemoveMoreSelected = () => {
            const updatedRecipients = selectedRecipients.slice(0, 5);
            setSelectedRecipients(updatedRecipients);
        };

        const handleRemoveMoreSelectedClose = (removedOption?: Option) => {
            const updatedRecipients = selectedRecipients.filter((recipient) => recipient !== removedOption);
            setSelectedRecipients(updatedRecipients);
        };

        const moreSelectedValues = selectedRecipients.slice(5);

        return (
            <div title={title} className="countSelect">
                <SelectBox
                    label=""
                    name="category"
                    options={moreSelectedValues}
                    placeholder={` ${label} more`}
                    value={null}
                    classNamePrefix="react-dropdowon-recipient"
                    isSearchable={false}
                    formatOptionLabel={(Option: Option) => (
                        <div className="addition-option-wrapper">
                            <div className='image-holder'> 
                                <span  title={Option.label}>{Option.label}</span>
                            </div>
                            <button
                                type="button"
                                className="close-button"
                                onClick={() => handleRemoveMoreSelectedClose(Option)}
                            >
                                <svg className="svg-icon ">
                                    <use href="#closeIcon">
                                        <title>close Icon</title>
                                    </use>
                                </svg>

                            </button>
                        </div>
                    )}
                />

                <button
                    type="button"
                    className="close-button"
                    onClick={handleRemoveMoreSelected}
                >
                    <svg className="svg-icon ">
                        <use href="#closeIcon">
                            <title>close Icon</title>
                        </use>
                    </svg>

                </button>

            </div>
        );
    };

    const Option: React.FC<OptionProps<Option>> = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <div className="image-option-wrapper">
                        <span className='image-label'>{props.data.label}</span>
                    </div>

                </components.Option>
            </div>
        );
    };

    const MultiValue: React.FC<MultiValueProps<Option, boolean>> = (props) => {
        const { index, getValue, data } = props;
        const maxToShow = 5;
        const overflow = getValue()
            .slice(maxToShow)
            .map((x) => x.label);
        return (
            <div className="multi-value-wrapper">
                {index < maxToShow ? (
                    <components.MultiValue {...props}>
                        <div className="image-option-wrapper">
                            <span className='image-label' title={props.data.label}>{props.data.label}</span>
                            <button
                                type="button"
                                className="close-button"
                                onClick={() => handleRecipientClose(props.data)}
                            >
                                <svg className="svg-icon ">
                                    <use href="#closeIcon">
                                        <title>close Icon</title>
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </components.MultiValue>
                ) : index === maxToShow ? (
                    <MoreSelectedBadge items={overflow} />
                ) : null}
            </div>
        );
    };
    return (
        <div className='compose-wrapper'>
            <SenderSection
                selectedRecipients={selectedRecipients}
                promiseOptions={promiseOptions}
                Option={Option}
                setSelectedRecipients={setSelectedRecipients}
                MultiValue={MultiValue}
                errorMessage={errorMessage} 
                setErrorMessage={setErrorMessage}      
                 />
            <SubjectSection
                subject={subject}
                setSubject={setSubject}
                errorMessage={errorMessage} 
                setErrorMessage={setErrorMessage}      
            />
            <div className={`compose-message-section ${errorMessage.bodyError? 'error' : ''}`}>
                <QuillEditor message={message} setMessage={setMessage}/>
                <div className='error-message'>
                    <svg className="svg-icon">
                        <use href="#infoCircleIcon">
                            <title>infoCircle icon</title>
                        </use>
                    </svg>
                    Please fill this field to send the mail
                </div>
            </div>
        </div>
    )
}
export default ComposeModal


