import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.scss'

interface QuillProps {
  message: string;
  setMessage: (message: string) => void;
}

const QuillEditor:React.FC<QuillProps> = ({message, setMessage}) => {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'color': []}],
      ['link'],
    ],
  },
  formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet', 'ordered',
    'link', 'color'
  ];
  return (
    <ReactQuill
      theme="snow"
      value={message || ''}
      onChange={(value) => setMessage(value)}
      modules={modules}
      formats={formats}
      placeholder="Type your text here..."
    />
  );
};

export default QuillEditor;

