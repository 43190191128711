import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveSkill(
    customSkill:string,
    categoryId:number
){
    try{
    const requestObject = {
        skill_name: customSkill,
        category_id: categoryId
      }
    const response = await apiClient.post(
        urls.skills(),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useSaveSkill():UseMutationResult<
    any,
    ApiError | {message: string},
    {skillName:string;categoryId:number}
    >{
        const {searchkey,categoryListPaginationOffset, categories} = useAdminStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            skillName,
            categoryId,
        }: {
            skillName: string;
            categoryId:number,
        }) => {
            const result = await saveSkill(skillName, categoryId);
            queryClient.invalidateQueries(["GetAdminSkills"]);
            queryClient.invalidateQueries(["AdminCategory",searchkey,categoryListPaginationOffset,categories]);
            queryClient.invalidateQueries(["GetCategoryById"]);
            return result;
        }
    );
    }