import React, { useEffect, useRef, useState } from "react";
import { View, initialView } from "utils/constants";
import { useAdminStore } from "store/adminStore";
import SelectBox from "components/common-components/ReactSelect";
import EmailContent from "./EmailContent";
import EmailTabs from "./EmailTabs";
import SearchBar from "./SearchBar";
import TabContent from "./EmailTabContent";
import FilterButton from "./FilterButton";
import "../recipent-modal.scss";
import EmailRecipientPagination from "./EmailRecipientPagination";



interface Props {
  customNotificationId: string | number;
  showEmail?: boolean;
  setShowEmail: (email: boolean) => void;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  emailLogStatus: EmailStatus[];
  setEmailLogStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
  undeliveredEmailStatus: EmailStatus[];
  setUndeliveredEmailStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
  selectedRecipients: { value: string, label: string }[];
  setSelectedRecipients: React.Dispatch<React.SetStateAction<{ value: string; label: string; }[]>>
  setSubject: (tab: string) => void;
  setMessage: (tab: string) => void;
  recipientEmailStatus: EmailStatus[]
  setRecipientEmailStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>
  mail: any;
  selectAll: boolean;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  allRecipients: { value: string, label: string }[];
  setAllRecipients: React.Dispatch<React.SetStateAction<{ value: string; label: string; }[]>>;
  recipientsCurrentPage: number;
  setRecipientsCurrentPage: (page: number) => void;
}

const RecipentsModalComponent: React.FC<Props> = ({
  customNotificationId,
  showEmail,
  setShowEmail,
  activeTab,
  setActiveTab,
  undeliveredEmailStatus,
  setUndeliveredEmailStatus,
  emailLogStatus,
  setEmailLogStatus,
  selectedRecipients,
  setSelectedRecipients,
  setMessage,
  setSubject,
  recipientEmailStatus,
  setRecipientEmailStatus,
  selectAll,
  setSelectAll,
  mail,
  allRecipients,
  setAllRecipients,
  recipientsCurrentPage,
  setRecipientsCurrentPage,
}) => {

  const {
    setEmailLogRecipientsListPaginationOffset,
    setEmailLogRecipientsPaginationOffset,
    employeeNameSearch,
    setEmployeeNameSearch,
  } = useAdminStore();
  const closeRef = useRef<HTMLDivElement | null>(null);
  const [viewLimit, setViewLimit] = useState<any>(initialView);
  const [userType, setUserType] = useState<string>('all');
  const [limit, setLimit] = useState<number>(25);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [userDataList, setDataUserList] = useState<any>(null);

  const handleTabClick = (tab: string, userType: string) => {
    setActiveTab(tab);
    setUserType(userType);
    resetPagination(tab);
    setSelectedRecipients([]);
    setSelectAll(false);
  };

  const resetPagination = (tab: any) => {
    if (activeTab !== tab) {
      setDataUserList(null)
    }
    setEmailLogRecipientsListPaginationOffset(0);
    setEmailLogRecipientsPaginationOffset(25);
    setRecipientsCurrentPage(1);
    setEmployeeNameSearch("");
    setViewLimit({ value: "25", label: "25" });
  };

  const handlePageChange = (page: number) => {
    const offset = (page - 1) * limit;
    setEmailLogRecipientsListPaginationOffset(offset);
  };

  const handleClickOutside = (event: { target: any }) => {
    if (closeRef.current && !(closeRef.current as HTMLElement).contains(event.target)) {
      setShowFilterOptions(false);
    }
  };

  const close = (event: any) => {
    if (event.key === "Escape") {
      setShowFilterOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    window.addEventListener("keydown", close);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      window.removeEventListener("keydown", close);
    };
  }, [showFilterOptions]);

  const onChangeSearchBar = (e: any) => {
    const { value } = e.target;
    setEmployeeNameSearch(value);
    setEmailLogRecipientsListPaginationOffset(0);
  }

  return (
    <div className="recipent-modal-wrapper">
      <EmailContent showEmail={showEmail} setShowEmail={setShowEmail}
        userDataList={userDataList} customNotificationId={customNotificationId} />
      <EmailTabs
        activeTab={activeTab} handleTabClick={handleTabClick}
        userDataList={userDataList} setUndeliveredEmailStatus={setUndeliveredEmailStatus}
        setRecipientEmailStatus={setRecipientEmailStatus} setSubject={setSubject}
        setMessage={setMessage} mail={mail} />
      <div className="recipent-modal-layout">
        <div className="recipent-modal-grid">
          <div className="recipent-grid-header">
            <SearchBar
              value={employeeNameSearch}
              onChange={onChangeSearchBar} />
            <FilterButton
              activeTab={activeTab} showFilterOptions={showFilterOptions}
              toggleFilterOptions={() => setShowFilterOptions(!showFilterOptions)}
              emailLogStatus={emailLogStatus} setEmailLogStatus={setEmailLogStatus}
              undeliveredEmailStatus={undeliveredEmailStatus}
              setUndeliveredEmailStatus={setUndeliveredEmailStatus}
              recipientEmailStatus={recipientEmailStatus}
              setRecipientEmailStatus={setRecipientEmailStatus}
              setActiveTab={setActiveTab}
              closeRef={closeRef} />
            {/* {
              userDataList?.custom_notification_data?.non_user_flag === true &&
              <div >
                <span>
                  <strong>
                    ! Some of the users have not logged in yet.
                  </strong>
                </span>
              </div>
            } */}
          </div>
          <div className="user-details-Wrappper">
            <div className="user-details-Table email-log-wrapper">
              <TabContent
                activeTab={activeTab}
                recipientEmailStatus={recipientEmailStatus} undeliveredEmailStatus={undeliveredEmailStatus}
                selectedRecipients={selectedRecipients} setSelectedRecipients={setSelectedRecipients}
                selectAll={selectAll} setSelectAll={setSelectAll}
                customNotificationId={customNotificationId} userType={userType}
                setLimit={setLimit} setDataUserList={setDataUserList}
                allRecipients={allRecipients} setAllRecipients={setAllRecipients} />
            </div>
            <div className="pagination-block">
              <div className="item-view-section">
                <span className="item-view-details">View</span>
                <div className="selection-box">
                  <SelectBox
                    name="category"
                    options={View}
                    value={View.find(option => option.value === viewLimit.value)}
                    classNamePrefix="react-select-box-page"
                    isSearchable={false}
                    onChange={(item: Option) => {
                      setViewLimit(item.value);
                      setEmailLogRecipientsListPaginationOffset(0);
                      setEmailLogRecipientsPaginationOffset(item.value);
                      setRecipientsCurrentPage(1);
                    }} />
                  <span className="dropdown-arrow">
                    <svg className="svg-icon">
                      <use href="#downArrowIcon">
                        <title>down icon</title>
                      </use>
                    </svg>
                  </span>
                </div>
              </div>
              <EmailRecipientPagination
                itemsPerPage={limit}
                totalItems={userDataList?.total_count ?? 0}
                onPageChange={handlePageChange}
                recipientsCurrentPage={recipientsCurrentPage}
                setRecipientsCurrentPage={setRecipientsCurrentPage}
                limit={limit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipentsModalComponent;
