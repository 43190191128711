import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useUserStore } from "store/useUserStore";
import { useAdminStore } from "store/adminStore";


export async function getAllBusinessUnits():Promise<Array<BusinessUnit>|null>{
  const response = await apiClient.get(urls.businessUnits());
  if (response && response.data && response.data.data)
    return response.data.data.business_units as BusinessUnit[];
  return null;
}

export function useGetBusinessUnits():UseQueryResult<Array<BusinessUnit>> {
  const {setBusinessUnits} = useAdminStore();
  const {accessToken} = useUserStore();
  return useQuery(["BusinessUnits",accessToken], async () => {
    const businessUnits = await getAllBusinessUnits();
    const filteredBusinessUnits = businessUnits?.map((item)=>({...item,checked:false}));
    setBusinessUnits(filteredBusinessUnits??[]);
    return businessUnits;
  },{
     refetchOnWindowFocus:false,
  });
}
