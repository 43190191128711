import React from 'react';
import AdminEmailLogFilter from 'feature/admin/components/AdminEmailLog/AdminEmailLogFilter';

interface FilterButtonProps {
    activeTab: string;
    showFilterOptions: boolean;
    toggleFilterOptions: () => void;
    emailLogStatus: EmailStatus[];
    setEmailLogStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
    undeliveredEmailStatus: EmailStatus[];
    setUndeliveredEmailStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
    recipientEmailStatus: EmailStatus[];
    setRecipientEmailStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
    setActiveTab: (tab: string) => void;
    closeRef: React.RefObject<HTMLDivElement>;
}

const FilterButton: React.FC<FilterButtonProps> = ({
    activeTab, showFilterOptions, toggleFilterOptions, emailLogStatus, setEmailLogStatus,
    undeliveredEmailStatus, setUndeliveredEmailStatus, recipientEmailStatus, setRecipientEmailStatus, setActiveTab,closeRef
}) => (
    <div className="admin-filter-wrapper" ref={closeRef}>
        {(activeTab === 'total' || activeTab === 'undelivered') && (
            <button className="app-btn secondary filter-panel-icon" onClick={toggleFilterOptions}>
                <svg className="svg-icon filter-icon">
                    <use href="#adminFilterIcon">
                        <title>FilterIcon</title>
                    </use>
                </svg>
                <span className="btn-text">Filter</span>
            </button>
        )}
        {showFilterOptions && (
            <AdminEmailLogFilter
                {...(activeTab === 'total' ? {
                    emailLogStatus, setEmailLogStatus,
                    recipientEmailStatus, setRecipientEmailStatus
                } : {
                    undeliveredEmailStatus, setUndeliveredEmailStatus
                })}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleAdminFilter={toggleFilterOptions}
            />
        )}
    </div>
);

export default FilterButton;
