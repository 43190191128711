import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useUserStore } from "store/useUserStore";

export async function getAdminNotifications():Promise<NotificationItem|null> {
  const response = await apiClient.get(urls.adminNotifications());
  if (response && response.data && response.data.data )
    return response.data.data.notifications;
  return null;
}

export function useGetAdminNotifications():UseQueryResult<Array<NotificationItem>> {
  const {accessToken} = useUserStore();
  return useQuery(["adminNotifications",accessToken], async () => {
    return getAdminNotifications();
  });
}
