interface SortOptions {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        label: string;
        value: string;
      };
    };
  };
}

export const isNumber = (value: any) => {
  try {
    return !isNaN(Number(value));
  } catch (e) {
    return false;
  }
};

export const sortOptionsMap: SortOptions = {
  Skills: {
    Id: {
      Ascending: {
        label: "employee_id_asc",
        value: "employee_id:asc",
      },
      Descending: {
        label: "employee_id_desc",
        value: "employee_id:desc",
      },
    },
    Employee: {
      Ascending: {
        label: "first_name_last_name_asc",
        value: "first_name_last_name:asc",
      },
      Descending: {
        label: "first_name_last_name_desc",
        value: "first_name_last_name:desc",
      },
    },
    Designation: {
      Ascending: {
        label: "designation_asc",
        value: "designation:asc",
      },
      Descending: {
        label: "designation_desc",
        value: "designation:desc",
      },
    },
    "Skill Name": {
      Ascending: {
        label: "skill_name_asc",
        value: "skill_name:asc",
      },
      Descending: {
        label: "skill_name_desc",
        value: "skill_name:desc",
      },
    },

    "Skill Type": {
      Ascending: {
        label: "skill_type_asc",
        value: "skill_type:asc",
      },
      Descending: {
        label: "skill_type_desc",
        value: "skill_type:desc",
      },
    },
    "Skill Level": {
      Ascending: {
        label: "skill_level_asc",
        value: "skill_level:asc",
      },
      Descending: {
        label: "skill_level_desc",
        value: "skill_level:desc",
      },
    },
    Department: {
      Ascending: {
        label: "department_asc",
        value: "department:asc",
      },
      Descending: {
        label: "department_desc",
        value: "department:desc",
      },
    },
  },
  Certifications: {
    Id: {
      Ascending: {
        label: "employee_id_asc",
        value: "employee_id:asc",
      },
      Descending: {
        label: "employee_id_desc",
        value: "employee_id:desc",
      },
    },
    Employee: {
      Ascending: {
        label: "first_name_last_name_asc",
        value: "first_name_last_name:asc",
      },
      Descending: {
        label: "first_name_last_name_desc",
        value: "first_name_last_name:desc",
      },
    },
    Designation: {
      Ascending: {
        label: "designation_asc",
        value: "designation:asc",
      },
      Descending: {
        label: "designation_desc",
        value: "designation:desc",
      },
    },
    Department: {
      Ascending: {
        label: "department_asc",
        value: "department:asc",
      },
      Descending: {
        label: "department_desc",
        value: "department:desc",
      },
    },
    "Issued By": {
      Ascending: {
        label: "certification_issuer_asc",
        value: "certification_issuer:asc",
      },
      Descending: {
        label: "certification_issuer_desc",
        value: "certification_issuer:desc",
      },
    },
    Expiry: {
      Ascending: {
        label: "certification_expiry_asc",
        value: "certification_expiry:asc",
      },
      Descending: {
        label: "certification_expiry_desc",
        value: "certification_expiry:desc",
      },
    },
    "Certification Name": {
      Ascending: {
        label: "certification_name_asc",
        value: "certification_name:asc",
      },
      Descending: {
        label: "certification_name_desc",
        value: "certification_name:desc",
      },
    },
    Status: {
      Ascending: {
        label: "certification_status_asc",
        value: "certification_status:asc",
      },
      Descending: {
        label: "certification_status_desc",
        value: "certification_status:desc",
      },
    },
  },
  Domains: {
    Id: {
      Ascending: {
        label: "employee_id_asc",
        value: "employee_id:asc",
      },
      Descending: {
        label: "employee_id_desc",
        value: "employee_id:desc",
      },
    },
    Employee: {
      Ascending: {
        label: "first_name_last_name_asc",
        value: "first_name_last_name:asc",
      },
      Descending: {
        label: "first_name_last_name_desc",
        value: "first_name_last_name:desc",
      },
    },
    Designation: {
      Ascending: {
        label: "designation_asc",
        value: "designation:asc",
      },
      Descending: {
        label: "designation_desc",
        value: "designation:desc",
      },
    },
    Department: {
      Ascending: {
        label: "department_asc",
        value: "department:asc",
      },
      Descending: {
        label: "department_desc",
        value: "department:desc",
      },
    },
    "Domain Name": {
      Ascending: {
        label: "domain_asc",
        value: "domain:asc",
      },
      Descending: {
        label: "domain_desc",
        value: "domain:desc",
      },
    },
    "Sub Domain Name": {
      Ascending: {
        label: "sub_domain_name_asc",
        value: "sub_domain_name:asc",
      },
      Descending: {
        label: "sub_domain_name_desc",
        value: "sub_domain_name:desc",
      },
    },
    "Proficiency Level": {
      Ascending: {
        label: "proficiency_level_asc",
        value: "proficiency_level:asc",
      },
      Descending: {
        label: "proficiency_level_desc",
        value: "proficiency_level:desc",
      },
    },
    Duration: {
      Ascending: {
        label: "duration_asc",
        value: "duration:asc",
      },
      Descending: {
        label: "duration_desc",
        value: "duration:desc",
      },
    },
    Status: {
      Ascending: {
        label: "status_asc",
        value: "status:asc",
      },
      Descending: {
        label: "status_desc",
        value: "status:desc",
      },
    },
  },
};
