import React, { useEffect, useRef, useState } from "react";
import "./header.scss";
import NotificationSection from "../Notification";
import FilterSidePanel from "../FilterSidepanel";
import { useUserStore } from "store/useUserStore";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { clearAllStores } from "store";
import HelpDropdown from "../HelpDropdown";
import apiClient from "utils/apiClient";
import ProfileImage from "components/ProfileImage";

const Header = () => {
  const { instance } = useMsal();
  const { setAccessToken, userInfo } = useUserStore();
  const { isFilterOpen } = useUserStore();
  const [profileToggle , setProfileToggle] = useState(false);
  const closeRef = useRef(null);
  const navigate = useNavigate();

  const handleLogout = async() => { 
    instance.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}/logout-callback`,
    }).then(()=>{
      clearAllStores();
      setAccessToken("");
      navigate("/");
    })
  };

  const onLogout = async() => {
    await handleLogout();
  };

  const handleProfileToggle =()=>{
    setProfileToggle(!profileToggle);
  }
 
  const handleClickOutside = (event: { target: any; }) => {
    if (closeRef.current && !(closeRef.current as HTMLElement).contains(event.target)) {
      setProfileToggle(false);
    }
  };

  const close= ()=>{
    setProfileToggle(false);
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    window.addEventListener('keydown', close)
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      window.removeEventListener('keydown', close)
    };
    
  }, []);

  return (
    <div className="layout-header">
      <div className="header-left-portion">
        <div className="logo-container">
          <svg className="svg-icon logo-icon">
            <use href="#logoIcon"></use>
          </svg>
        </div>
      </div>
      <div className="header-right-portion">
        <HelpDropdown/>
        <NotificationSection />

        <div ref={closeRef} className={`profile-layout ${profileToggle? 'active': ''}`}>
          <button className="icon-only profile-header-wrapper" onClick={handleProfileToggle}>
            <div className="profile-detail">
              <div className="profile-name">{userInfo.first_name} {userInfo.last_name}</div>
              <div className="profile-designation">{userInfo.designation}</div>
            </div>
             <div className="profile-img-holder">
              <ProfileImage/>
            </div> 
            <span className="dropdown-arrow" >
              <svg className="svg-icon dropdown-icon">
                <use href="#dropdownArrowIcon"></use>
              </svg>
            </span>
          </button>
          <div className="dropdown-wrapper">
            <ul className="logout-wrapper">
              <li className="profile-detail-mobile">
                <div className="profile-name">{userInfo.first_name} {userInfo.last_name}</div>
                <div className="profile-designation">{userInfo.designation}</div>
              </li>
              <li className="logout-item" >
              <button className="icon-only layout-name" onClick={onLogout}>Logout<span>
              <svg className="svg-icon logout-icon">
                        <use href="#logoutIcon">
                          <title>Logout Icon</title>
                        </use>
                      </svg>
              </span>
              </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {isFilterOpen && <FilterSidePanel />}
    </div>
  );
};
export default Header;
