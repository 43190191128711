import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveAdminSubDomain(
    subDomainName:string,
    domain_id:number
){
    try{
    const requestObject = {
        sub_domain_name: subDomainName,
        domain_id: domain_id
      }
    const response = await apiClient.post(
        urls.saveAdminSubDomains(),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useSaveAdminSubDomain():UseMutationResult<
    any,
    ApiError | {message: string},
    {subDomainName:string;domain_id:number}
    >{
        const {subDomainSearch,domainListPaginationOffset, domains} = useAdminStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            subDomainName,
            domain_id,
        }: {
            subDomainName: string;
            domain_id:number,
        }) => {
            const result = await saveAdminSubDomain(subDomainName, domain_id);
            queryClient.invalidateQueries(["GetAdminSubDomains"]);
            queryClient.invalidateQueries(["AdminDomain",subDomainSearch,domainListPaginationOffset,domains]);
            queryClient.invalidateQueries(["GetDomainById"]);
            return result;
        }
    );
    }