import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";


export async function getAdminCategory(search:string,offset:number):Promise<any|null> {
  const response = await apiClient.get(urls.adminCategory(search,offset));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetAdminCategory():UseQueryResult<any> {
  const {searchkey,categoryListPaginationOffset, categories} = useAdminStore();
  return useQuery(["AdminCategory",searchkey,categoryListPaginationOffset,categories], async () => {
    const categorySkills =  await getAdminCategory(searchkey,categoryListPaginationOffset);
    return categorySkills;
  },{
    refetchOnWindowFocus:false
  });
}
