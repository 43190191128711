import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";

export async function getAllFilterUsersList(skill_names: string,skill_levels: string, skill_types:string, designations: string, departments: string, sort: Option | undefined):Promise<AllUsers|null> {
  const response = await apiClient.get(urls.allFilterList(skill_names,skill_levels, skill_types, designations,departments,sort));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetAllFilterUsersList(skill_names: any,skill_levels: any, skill_types:any, designations: any, department_value:any):UseQueryResult<AllUsers | any> {
    const {sortOption} = useAdminStore();
    const SkillNames =  skill_names.map((item: any)=>item.value).join(",");
    const skillTypes =  skill_levels.map((item: any)=>item.value).join(",");
    const skillLevels =  skill_types.map((item: any)=>item.value).join(",");
    const Designations =  designations.map((item: any)=>item.value).join(","); 
    const Departments =  department_value.map((item: any)=>item.value).join(",");  
  return useQuery(["allFilterList"], async () => {
    const data= await getAllFilterUsersList(SkillNames,skillTypes, skillLevels, Designations,Departments,sortOption);
    if (data != null) {
        const userValue = data?.users?.map((item: any) => {
          if (item?.first_name || item?.last_name) {
            return {
              value: item.email,
              label: `${item.first_name} ${item.last_name}`,
              checked: false,
            };
          } 
          else {
            return {
              value: item.email,
              label: item.email,
              checked: false,
            };
          }
        });
        return userValue;
      }
  },{
    refetchOnWindowFocus:false,
  });
}