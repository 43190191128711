import React from "react";
import "../../searchPanel.scss"
import { useAdminStore } from "store/adminStore";
import InputField from "components/common-components/InputField";

interface Props {
    from?: string;
    isSkillPage?: boolean;
    setSearchToggle: (value: boolean) => void;
    searchPlaceholder?: string;
}

const SearchTab: React.FC<Props> = ({
    from,
    isSkillPage,
    setSearchToggle,
    searchPlaceholder

}) => {

    const {
        setSearchKey,
        setCategoryListPaginationOffset,
        groupByCategory,
        searchkey,
        groupByDomains,
        setSkillListPaginationOffset,
        certificationSearch,
        subDomainSearch,
        setQueryBuilderListPaginationOffset,
        setCertificationListPaginationOffset,
        setSubDomainListPaginationOffset,
        setSubDomainSearch,
        setCertificationSearch,
        setUserSearch,
        userSearch,
        skillViewToggle,
        currentTab,
        setSubjectSearch,
        setEmailLogListPaginationOffset,
        subjectSearch
    } = useAdminStore();

    return (
        <>
            {groupByCategory ?
                <div className="search-field">
                    <InputField
                        type="text"
                        value={searchkey}
                        onChange={(e: any) => {
                            const { value } = e.target;
                            setSearchKey(value);
                            setCategoryListPaginationOffset(0);
                        }}
                        label=""
                        placeholder={"Search for a Category"}
                        setSearchToggle={setSearchToggle}
                    />
                    <button className="icon-only search-btn">
                        <svg className="svg-icon search-icon">
                            <use href="#searchIcon"></use>
                        </svg>
                    </button>
                    <button className="icon-only back-btn" onClick={() => setSearchToggle(false)}>
                        <svg className="svg-icon back-icon">
                            <use href="#backArrowIcon"></use>
                        </svg>
                    </button>
                </div>
                : groupByDomains ?
                    <div className="search-field">
                        <InputField
                            type="text"
                            value={subDomainSearch}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                setSubDomainSearch(value);
                                setCategoryListPaginationOffset(0);
                            }}
                            label=""
                            placeholder={"Search for a Domain"}
                            setSearchToggle={setSearchToggle}
                        />
                        <button className="icon-only search-btn">
                            <svg className="svg-icon search-icon">
                                <use href="#searchIcon"></use>
                            </svg>
                        </button>
                        <button className="icon-only back-btn" onClick={() => setSearchToggle(false)}>
                            <svg className="svg-icon back-icon">
                                <use href="#backArrowIcon"></use>
                            </svg>
                        </button>
                    </div>
                    :
                    <div className="search-field">
                        <InputField
                            type="text"
                            isDisabled={skillViewToggle === "bubbleView" && currentTab === "Skills"}
                            value={from === 'userData' ? userSearch : (isSkillPage ? searchkey : (currentTab === "Certifications" ? certificationSearch : currentTab === "EmailLog" ? subjectSearch : subDomainSearch))}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                if (isSkillPage) {
                                    setSearchKey(value);
                                    setSkillListPaginationOffset(0);
                                }
                                else if (from === 'userData') {
                                    setUserSearch(value);
                                    setQueryBuilderListPaginationOffset(0);
                                }
                                else if (from === 'emailLog') {
                                    setSubjectSearch(value);
                                    setEmailLogListPaginationOffset(0);
                                }

                                else if (currentTab === "Certifications") {
                                    setCertificationSearch(value);
                                    setCertificationListPaginationOffset(0);
                                }
                                else {
                                    setSubDomainSearch(value)
                                    setSubDomainListPaginationOffset(0)
                                }
                            }}
                            label=""
                            placeholder={searchPlaceholder}
                            setSearchToggle={setSearchToggle}
                        />
                        <button className="icon-only search-btn">
                            <svg className="svg-icon search-icon">
                                <use href="#searchIcon"></use>
                            </svg>
                        </button>
                        <button className="icon-only back-btn" onClick={() => setSearchToggle(false)}>
                            <svg className="svg-icon back-icon">
                                <use href="#backArrowIcon"></use>
                            </svg>
                        </button>
                    </div>
            }
        </>
    );
};
export default SearchTab;
