import { useGetProfileImage } from "api/user/useGetProfileImage";
import Avatar from "components/Avatar";
import { useUserStore } from "store/useUserStore";

const ProfileImage = ()=>{
    const{userInfo}=useUserStore();
    const {data}=useGetProfileImage();
    if(data){
       return( 
       <img
        className="user-img"
        alt="user"
        src={data}/>
       );
    }else{
        return <Avatar name={userInfo.first_name}/>;
    }
}

export default ProfileImage;