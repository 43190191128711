import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useSkillStore } from "store/useSkillStore";


export async function getAllTags():Promise<Array<Tag>|null> {
  const response = await apiClient.get(urls.certificationTags());
  if (response && response.data && response.data.data)
    return response.data.data.tags;
  return null;
}

export function useGetCertificationTags():UseQueryResult<Array<Tag>> {
  const{setCertificationTags}=useSkillStore();
  return useQuery(["CertificationTags",], async () => {
    const tags=await getAllTags();
    const tagsNamePair:Option[] = tags?.map((item:Tag)=>({
      label:item.name,
      value:item.id.toString(),
    }))??[];
    setCertificationTags(tagsNamePair??[]);
    return tags;
  });
}

