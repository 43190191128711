import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function approveRejectSkill(
    skillId:number,
    skillName:string,
    categoryId:number,
    status:boolean,
    rejectReason:string,
    notifcationId:number,
){
    try{
    const requestObject = {
        skill_name: skillName,
        skill_id: skillId,
        is_custom_skill: true,
        category_id: categoryId,
        is_approved: status,
        comment:rejectReason,
        notification_id:notifcationId,
      }
    const response = await apiClient.put(
        urls.skillApproveReject(skillId),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useApproveRejectSkill():UseMutationResult<
    any,
    ApiError | {message: string},
    {skillId:number;skillName:string;categoryId:number;status:boolean;reason:string;notificationId:number}
    >{
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            skillId,
            skillName,
            categoryId,
            status,
            reason,
            notificationId
        }: {
            skillId:number;
            skillName: string;
            categoryId:number;
            status:boolean;
            reason:string;
            notificationId:number;
        }) => {
            const result = await approveRejectSkill(skillId,skillName, categoryId,status,reason,notificationId);
            queryClient.invalidateQueries(["ApproveRejectSkill"]);
            return result;
        }
    );
    }