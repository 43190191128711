import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateAdminSubDomain(
            subDomainId:number,
            subDomainName: string,
            domainId:number
){
    try{
    const requestObject = {
        sub_domain_name: subDomainName,
        domain_id: domainId,
        is_active: true,
      }
    const response = await apiClient.put(
        urls.AdminSubDomainById(subDomainId),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useUpdateAdminSubDomain():UseMutationResult<
    any,
    ApiError | {message: string},
    {subDomainId:number;subDomainName:string;domainId:number}
    >{
        const {skillAdded,domains,sortOption,subDomainSearch} = useAdminStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            subDomainId,
            subDomainName,
            domainId,
        }: {
            subDomainId:number;
            subDomainName: string;
            domainId:number;
        }) => {
            const result = await updateAdminSubDomain(subDomainId,subDomainName, domainId);
            queryClient.invalidateQueries(["AdminSubDomains",skillAdded,domains,sortOption,subDomainSearch]);
            queryClient.invalidateQueries(["GetDomainById"]);
            return result;
        }
    );
    }