import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";

export async function getEmailLogById(
  customNotificationId: string | number,
  userType: string,
  offset: number,
  limit: string | number,
  search: string,
  filter: string,
): Promise<EmailLogRecipients | null> {
  const response = await apiClient.get(
    urls.emailLogById(customNotificationId, userType, offset, limit, search,filter )
  );
  if (response && response.data && response.data.data) return response.data.data;
  return null;
}

export function useGetEmailLogById(
  customNotificationId: string | number | undefined,
  userType: string,
  status: EmailStatus[] = []
): UseQueryResult<EmailLogRecipients> {
    const {
      emailLogRecipientsListPaginationOffset,
      emailLogRecipientsPaginationOffset,
      employeeNameSearch,
    } = useAdminStore();
    const EmailLog = status?.filter(item=>item.checked===true).map(item=>item.name).join(",");
    
  return useQuery(
    [
      "GetEmailLogById",
      userType,
      emailLogRecipientsListPaginationOffset,
      emailLogRecipientsPaginationOffset,
      employeeNameSearch,
      status,
    ],

    async () => {
      return await getEmailLogById(
        customNotificationId ?? 0,
        userType,
        emailLogRecipientsListPaginationOffset,
        emailLogRecipientsPaginationOffset,
        employeeNameSearch,
        EmailLog
      );
    },
    {
      enabled: customNotificationId !== undefined,
      cacheTime: 0
    }
  );
}