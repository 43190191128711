import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";

export async function getAdminSkills(categories:string):Promise<AdminSkill[]|null> {
  const response = await apiClient.get(urls.adminSkillsBubbleView(categories));
  if (response && response.data && response.data.data )
    return response.data.data.skills;
  return null;
}

export function useGetAllSkills():UseQueryResult<AdminSkill[]> {
  const {setAllSkillsForBubbleView,categories}=useAdminStore();
  const selectedCategories = categories.filter(item=>item.checked===true).map(item=>item.id).join(",");
  return useQuery(["AdminSkillsCount",categories], async () => {
    const categorySkills =  await getAdminSkills(selectedCategories);
    if(categorySkills){
        const skills:SkillNameCount[] = categorySkills.map((item)=>({
          name:item.skill_name,
          count:item.user_count,
          skill_rank_beginner_count:item.skill_rank_beginner_count,
          skill_rank_intermediate_count:item.skill_rank_intermediate_count,
          skill_rank_expert_count:item.skill_rank_expert_count }));
          setAllSkillsForBubbleView(skills);
        return skills;
    }
    return skills;
  },{
    refetchOnWindowFocus:false,
  });
}

const skills = [
    {"name":"Javascript","count": 3},
    {"name":"HTML","count": 1},
    {"name":"CSS","count": 1},
    {"name":"Typescript","count": 2},
    {"name":"Figma","count": 6},
    {"name":"Photoshop","count": 8},
    {"name":"C","count": 12},
    {"name":"C++","count": 20},
    {"name":"Java","count": 13},
    {"name":"Bootstrap","count": 13},
    {"name":"React","count": 32},
    {"name":"Angular","count": 28},
    {"name":"ViewJS","count": 2},
    {"name":"Python","count": 4},
    {"name":"SVG","count": 3},
    {"name":"D3 js","count": 1}
 ];