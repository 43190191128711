import React, { useEffect, useState } from "react";
import "./filter-sidepanel.scss";
import UserDataFilter from "../UserDataFilter";
import { useAdminStore } from "store/adminStore";
import { SKILLLEVEL, SKILLTYPE } from "utils/constants";

interface Props {
  filterToggle?: boolean;
  setFilterToggle?: () => void;
}

const FilterSidePanel: React.FC<Props> = () => {
  const { setSkillLevel,skillLevel,skillName,setSkillName,skillType,setSkillType,
    setUserDesignation,userDesignation,setFilterEnable,setFilterEnableIcon,
    department,setDepartment,setQueryBuilderListPaginationOffset,setFilterSelection,
    certificationIssuer,certificationStatus,certificationName,setCertificationName,
    setCertificationStatus,setCertificationIssuer,filterSelection,filterEnable,
    nonInitiated,setNonInitiated,domainName,setDomainName,subdomainName,
    setSubDomainName,domainStatus,setDomainStatus,minDuration,setMinDuration,
    maxDuration,setMaxDuration,nonInitatedDomain,setNonInitatedDomain,
    setCurrentPage,setSortOptionCertification,setSortOptionDefaultSkills,
    setSortOptionDomain,setSortOptionSkills, filterToggle, setFilterToggle,
    setSortBy, setSortOrder
  } = useAdminStore();
  const [tempSkillLevel, setTempSkillLevel] = useState(skillLevel);
  const [tempSkillType, setTempSkillType] = useState(skillType);
  const [tempSkillName, setTempSkillName] = useState(skillName);
  const [tempUserDesignation, setTempUserDesignation] = useState(userDesignation);
  const [tempDepartment, setTempDepartment] = useState(department);
  const [tempCertificationName, setTempCertificationName] = useState(certificationName);
  const [tempCertificationIssuer, setTempCertificationIssuer] = useState(certificationIssuer);
  const [tempCertificationStatus, setTempCertificationStatus] = useState(certificationStatus);
  const [tempNonInitiated, setTempNonInitiated] = useState(nonInitiated);
  const [tempNonInitiatedDomain, setTempNonInitiatedDomain] = useState(nonInitatedDomain);
  const [tempDomainName, setTempDomainName] = useState(domainName);
  const [tempSubDomainName, setTempSubDomainName] = useState(subdomainName);
  const [tempMinDuration, setTempMinDuration] = useState(minDuration);
  const [tempMaxDuration, setTempMaxDuration] = useState(maxDuration);
  const [tempDomainStatus, setTempDomainStatus] = useState(domainStatus);

  useEffect(() => {
    setFilterEnableIcon(false);
    setTempSkillLevel([]);
    setTempSkillType([]);
    setTempUserDesignation([]);
    setTempSkillName([]);
    setTempDepartment([]);
    setTempCertificationIssuer([]);
    setTempCertificationName([]);
    setTempCertificationStatus([]);
    setSkillLevel([]);
    setSkillName([]);
    setSkillType([]);
    setUserDesignation([]);
    setDepartment([]);
    setCertificationName([]);
    setCertificationIssuer([]);
    setCertificationStatus([]);
    setFilterEnable(false);
    setTempNonInitiated([]);
    setNonInitiated([]);
    setDomainName([]);
    setSubDomainName([]);
    setDomainStatus([]);
    setMaxDuration([]);
    setMinDuration([]);
    setNonInitatedDomain([]);
    setQueryBuilderListPaginationOffset(0);
    setFilterSelection("Skills");
    setCurrentPage(1);
  }, [])


  const handleFilter = () => {
    setFilterToggle(!filterToggle);
  };
  function filterTempStateWithZustand(tempState: Array<Option>, zustandState: Array<Option>) {
    return tempState.filter(value => zustandState.includes(value));
  }

  useEffect(() => {
    if (filterToggle === true) {
      setTempSkillLevel(skillLevel);
      setTempSkillType(skillType);
      setTempUserDesignation(userDesignation);
      setTempSkillName(skillName);
      setTempDepartment(department);
      setTempCertificationIssuer(certificationIssuer);
      setTempCertificationName(certificationName);
      setTempCertificationStatus(certificationStatus);
      setTempNonInitiated(nonInitiated)
      setTempDomainName(domainName)
      setTempMaxDuration(maxDuration)
      setTempMinDuration(minDuration)
      setTempSubDomainName(subdomainName)
      setTempDomainStatus(domainStatus)
      setTempNonInitiatedDomain(nonInitatedDomain)
      setSortOptionDefaultSkills({
        label: "first_name_last_name_asc",
        value: "first_name_last_name:asc"
      });
      setSortBy([{ label: "Employee", value: "Employee" }]);
      setSortOrder([{ label: "Ascending", value: "Ascending" }]);
    }
  }, [filterToggle, filterSelection])


  const handleClose = () => {
    const stateVariables = [
      { tempState: tempSkillLevel, setState: setTempSkillLevel, zustandState: skillLevel },
      { tempState: tempSkillType, setState: setTempSkillType, zustandState: skillType },
      { tempState: tempUserDesignation, setState: setTempUserDesignation, zustandState: userDesignation },
      { tempState: tempSkillName, setState: setTempSkillName, zustandState: skillName },
      { tempState: tempDepartment, setState: setTempDepartment, zustandState: department },
      { tempState: tempCertificationName, setState: setTempCertificationName, zustandState: certificationName },
      { tempState: tempCertificationIssuer, setState: setTempCertificationIssuer, zustandState: certificationIssuer },
      { tempState: tempCertificationStatus, setState: setTempCertificationStatus, zustandState: certificationStatus },
      { tempState: tempNonInitiated, setState: setTempNonInitiated, zustandState: nonInitiated },
      { tempState: tempNonInitiatedDomain, setState: setTempNonInitiatedDomain, zustandState: nonInitatedDomain },
      { tempState: tempDomainName, setState: setTempDomainName, zustandState: domainName },
      { tempState: tempSubDomainName, setState: setTempSubDomainName, zustandState: subdomainName },
      { tempState: tempDomainStatus, setState: setTempDomainStatus, zustandState: domainStatus },
      { tempState: tempMinDuration, setState: setTempMinDuration, zustandState: minDuration },
      { tempState: tempMaxDuration, setState: setTempMaxDuration, zustandState: maxDuration },
    ];

    stateVariables.forEach(({ tempState, setState, zustandState }) => {
      const filteredState = filterTempStateWithZustand(tempState, zustandState);
      setState(filteredState);
    });
    setFilterToggle(!filterToggle);

    if (!filterEnable) {
      setFilterSelection("Skills");
    }
  };

  const handleChangeSkill = (selectedOptions: Option[]) => {
    setTempSkillName(selectedOptions.slice());
  };
  const handleChangeDesignation = (selectedOptions: Option[]) => {
    setTempUserDesignation(selectedOptions.slice());
  }
  const handleChangeDepartment = (selectedOptions: Option[]) => {
    setTempDepartment(selectedOptions.slice());
  };
  const handleChangeCertificationName = (selectedOptions: Option[]) => {
    setTempCertificationName(selectedOptions.slice());
  };
  const handleChangeCertificationIssuer = (selectedOptions: Option[]) => {
    setTempCertificationIssuer(selectedOptions.slice());
  };
  const handleChangeCertificationStatus = (selectedOptions: Option[]) => {
    setTempCertificationStatus(selectedOptions.slice());
  };
  const handleChangeDomainName = (selectedOptions: Option[]) => {
    setTempDomainName(selectedOptions.slice());
  };
  const handleChangeSubDomainName = (selectedOptions: Option[]) => {
    setTempSubDomainName(selectedOptions.slice());
  };
  const handleChangeDomainStatus = (selectedOptions: Option[]) => {
    setTempDomainStatus(selectedOptions.slice());
  };
  const handleChangeMinDuration = (selectedOptions: Option[]) => {
    setTempMinDuration(selectedOptions.slice());
  };
  const handleChangeMaxDuration = (selectedOptions: Option[]) => {
    setTempMaxDuration(selectedOptions.slice());
  };
  const handleChangeProfociencyLevel = (selectedOptions: Option[]) => {
    setTempNonInitiatedDomain(selectedOptions.slice());
  };

  const onChange = (item: Option) => {
    const isChecked = tempSkillLevel.filter((level: Option) => level.value == item.value);

    if (isChecked.length) {
      const updatedState = tempSkillLevel.filter((selectedItem: Option) => selectedItem.value !== item.value);
      setTempSkillLevel(updatedState);
    } else {
      setTempSkillLevel([...tempSkillLevel, item]);
    }
  };

  const handleRadioChange = (item: SkillType) => {
    const updatedState = [...tempSkillType];

    const existingItemIndex = updatedState.findIndex((selectedItem) => selectedItem.name === item.name);

    if (existingItemIndex !== -1) {
      updatedState[existingItemIndex] = item;
    } else {
      updatedState.push(item);
    }

    setTempSkillType(updatedState);
  };

  const nonInitatedCheck = (item: Option) => {
    const isChecked = tempNonInitiated.filter((level: Option) => level.value == item.value);

    if (isChecked.length) {
      const updatedState = tempNonInitiated.filter((selectedItem: Option) => selectedItem.value !== item.value);
      setTempNonInitiated(updatedState);
    } else {
      setTempNonInitiated([...tempNonInitiated, item]);
    }
  };

  const handleApply = () => {
    let shouldApplyFilters = false;
    let selection = "";

    if (filterSelection === "Skills") {
      shouldApplyFilters = !!tempSkillType.length || !!tempSkillName.length || !!tempUserDesignation.length || !!tempSkillLevel.length || !!tempDepartment.length;
      selection = "Skills";
    } else if (filterSelection === "Certifications") {
      shouldApplyFilters = !!tempCertificationName.length || !!tempCertificationIssuer.length || !!tempUserDesignation.length || !!tempCertificationStatus.length || !!tempDepartment.length || !!tempNonInitiated.length;
      selection = "Certifications";
    } else {
      shouldApplyFilters = !!tempDomainName.length || !!tempSubDomainName.length || !!tempUserDesignation.length || !!tempDomainStatus.length || !!tempDepartment.length || !!tempNonInitiated.length || !!tempNonInitiatedDomain.length || !!tempMaxDuration.length || !!tempMinDuration.length;
      selection = "Domains";
    }

    if (shouldApplyFilters) {
      setUserDesignation(tempUserDesignation);
      setDepartment(tempDepartment);
      setNonInitiated(tempNonInitiated);
      setFilterEnableIcon(true);
      setFilterEnable(true);
      handleFilter();
      setQueryBuilderListPaginationOffset(0);
      setFilterSelection(selection);

      switch (selection) {
        case "Skills":
          setSkillLevel(tempSkillLevel);
          setSkillType(tempSkillType);
          setSkillName(tempSkillName);
          setSortOptionSkills({
            label: "first_name_last_name_asc",
            value: "first_name_last_name:asc"
          });
          break;
        case "Certifications":
          setCertificationName(tempCertificationName);
          setCertificationIssuer(tempCertificationIssuer);
          setCertificationStatus(tempCertificationStatus);
          setSortOptionCertification({
            label: "first_name_last_name_asc",
            value: "first_name_last_name:asc"
          });
          break;
        case "Domains":
          setDomainName(tempDomainName);
          setSubDomainName(tempSubDomainName);
          setDomainStatus(tempDomainStatus);
          setMinDuration(tempMinDuration);
          setMaxDuration(tempMaxDuration);
          setNonInitatedDomain(tempNonInitiatedDomain);
          setSortOptionDomain({
            label: "first_name_last_name_asc",
            value: "first_name_last_name:asc"
          });
          break;
        default:
          break;
      }
    } else {
      // Reset filters logic
      setFilterEnable(false);
      setFilterEnableIcon(false);
      handleFilter();
    }
  };


  const resetFilters = () => {
    setFilterEnableIcon(false)
    setFilterSelection("Skills")
    setTempSkillLevel([])
    setTempSkillType([])
    setTempUserDesignation([])
    setTempSkillName([])
    setTempDepartment([]);
    setSkillLevel([]);
    setSkillName([]);
    setSkillType([]);
    setUserDesignation([]);
    setDepartment([]);
    setFilterEnable(false);
    setQueryBuilderListPaginationOffset(0);
    setCertificationIssuer([])
    setCertificationName([])
    setCertificationStatus([])
    setCurrentPage(1)
  };

  return (
    <div className="filter-panel-sidebar">
      <div className={`side-bar ${filterToggle ? 'active' : ''} `}>
        <div className="sidebar-header">
          <button className="icon-only back-arrow" title="Close" onClick={() => {
            handleFilter(); setFilterSelection("Skills");
 }}>
            <svg className="svg-icon back-arrow-icon">
              <use href="#backArrowIcon"></use>
            </svg>
          </button>
          <h4 className="sidebar-title">Filter</h4>
          <button className="icon-only close-icon" onClick={() => { handleClose() }}>
            <svg className="svg-icon sm">
              <use href="#closeIcon">
                <title>Close</title>
              </use>
            </svg>
          </button>
        </div>
        <div className="details-content">
          <UserDataFilter
            handleChangeSkill={handleChangeSkill}
            handleChangeDesignation={handleChangeDesignation}
            firstValue={tempSkillName}
            secondValue={tempUserDesignation}
            checkBoxOption={SKILLLEVEL}
            radioBoxOption={SKILLTYPE}
            checkSelectedValueState={tempSkillLevel}
            radioSelectedValueState={tempSkillType}
            onChange={onChange}
            handleRadioChange={handleRadioChange}
            thirdValue={tempDepartment}
            handleChangeDepartment={handleChangeDepartment}
            radiosetTempValue={setTempSkillType}
            handleChangeCertificationName={handleChangeCertificationName}
            handleChangeCertificationIssuer={handleChangeCertificationIssuer}
            handleChangeCertificationStatus={handleChangeCertificationStatus}
            tempCertificationName={tempCertificationName}
            tempCertificationIssuer={tempCertificationIssuer}
            tempCertificationStatus={tempCertificationStatus}
            tempNonInitiated={tempNonInitiated}
            nonInitatedCheck={nonInitatedCheck}
            handleChangeDomainName={handleChangeDomainName}
            handleChangeSubDomainName={handleChangeSubDomainName}
            handleChangeDomainStatus={handleChangeDomainStatus}
            handleChangeMinDuration={handleChangeMinDuration}
            handleChangeMaxDuration={handleChangeMaxDuration}
            handleChangeProfociencyLevel={handleChangeProfociencyLevel}
            tempNonInitiatedDomain={tempNonInitiatedDomain}
            tempDomainName={tempDomainName}
            tempSubDomainName={tempSubDomainName}
            tempDomainStatus={tempDomainStatus}
            tempMinDuration={tempMinDuration}
            tempMaxDuration={tempMaxDuration}
            setTempCertificationIssuer={setTempCertificationIssuer}
            setTempCertificationName={setTempCertificationName}
            setTempCertificationStatus={setTempCertificationStatus}
            setTempUserDesignation={setTempUserDesignation}
            setTempDepartment={setTempDepartment}
            setTempSkillName={setTempSkillName}
            setTempDomainName={setTempDomainName}
            setTempDomainStatus={setTempDomainStatus}
            setTempNonInitiatedDomain={setTempNonInitiatedDomain}
            setTempSubDomainName={setTempSubDomainName}
            setTempMaxDuration={setTempMaxDuration}
            setTempMinDuration={setTempMinDuration}
          ></UserDataFilter>
        </div>
        <div className="side-page-footer">
          <button className="app-btn secondary " title="Reset" onClick={() => {
            resetFilters()
          }}>Reset</button>
          <button className="app-btn  primary" title="Apply" onClick={() => {
            handleApply()
          }}>Apply</button>
        </div>
      </div>
      <div className="sidebar-backdrop"></div>
    </div>
  );
};
export default FilterSidePanel;