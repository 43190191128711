import React from "react";
import "../../searchPanel.scss"
import { useAdminStore } from "store/adminStore";
import { useUserStore } from "store/useUserStore";
import ComposeMail from "../ComposeMail";

interface Props {
    from?: string;
    handleEditMode?: () => void;
    downloadCategoriesExport?: () => void;
    downloadDomainsExport?: () => void;
    download?: () => void;
    editMode?: boolean
    handleAdd?: () => void;
    addButtonText?: string
}

const WebviewEditDownload: React.FC<Props> = ({
    from,
    handleEditMode,
    downloadCategoriesExport,
    downloadDomainsExport,
    download,
    editMode,
    handleAdd,
    addButtonText
}) => {
    const {
        groupByCategory,
        groupByDomains,
        skillViewToggle,
        currentTab,
    } = useAdminStore();
    const { userManagedRole } = useUserStore();

    return (
        <>
            <div className={`skill-right-field ${from === "userData" ? "user-tab" : ""}`}>
            {userManagedRole ? null :
              <ComposeMail from={from} />}
            {groupByCategory ?
              (
                <button className="app-btn secondary filter-panel-icon" onClick={downloadCategoriesExport}>
                  <svg className="svg-icon export-icon">
                    <use href="#downIcon">
                      <title>Export Icon</title>
                    </use>
                  </svg>
                  <span className="btn-text">Download</span>
                </button>
              ) :
              (groupByDomains ?
                <button className="app-btn secondary filter-panel-icon" onClick={downloadDomainsExport}>
                  <svg className="svg-icon export-icon">
                    <use href="#downIcon">
                      <title>Export Icon</title>
                    </use>
                  </svg>
                  <span className="btn-text">Download</span>
                </button>
              : (from === "emailLog" ? null :<button className="app-btn secondary filter-panel-icon" onClick={download}>
                  <svg className="svg-icon export-icon">
                    <use href="#downIcon">
                      <title>Export Icon</title>
                    </use>
                  </svg>
                  <span className="btn-text">Download</span>
                </button>)
              )}

            {userManagedRole ? null : groupByCategory || groupByDomains || from == "userData" || from == "SubDomains" || from === "emailLog"? null : <button
              className={`app-btn secondary filter-panel-icon ${editMode ? "edit-mode" : ""}`}
              onClick={handleEditMode}
              disabled={skillViewToggle === "bubbleView" && currentTab === "Skills"}
            >
              <svg className="svg-icon edit-icon">
                <use href="#editIcon">
                  <title>Edit Icon</title>
                </use>
              </svg>
              <span className="btn-text">Edit</span>
            </button>}

          {userManagedRole || from == "userData" || from === "emailLog" ? null : addButtonText && addButtonText.length > 0 && (
              <button
                className="app-btn primary addskill-btn"
                onClick={handleAdd}
                disabled={skillViewToggle === "bubbleView" && currentTab === "Skills" && !groupByCategory}
              >
                <svg className="svg-icon plus-icon">
                  <use href="#plusIcon">
                    <title>Add Icon</title>
                  </use>
                </svg>
                <span className="button-text">{addButtonText}</span>
              </button>
            )}
          </div>

        </>
    );
};
export default WebviewEditDownload;
