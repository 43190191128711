import React, { useEffect, useState } from "react";

import "./update-skill-level.scss";
import SelectSkillLevel from "components/SelectSkillLevel";
import SkillScore from "components/SkillScore";
import { useDeleteUserSkill } from "api/user/useDeleteUserSkill";
import { useSkillStore } from "store/useSkillStore";
import { SkillRank, SkillType } from "utils/constants";
import { confirmDeleteSkill } from "utils/confirmModals";
import { showSuccessToast } from "utils/toastUtil";
import { messages } from "utils/messageConstants";

interface Props {
  skillsItem: UserSkillItem;
  currentSkill: UserSkillItem;
  skillType: SkillType;
}

const UpdateSkillLevel: React.FC<Props> = ({ skillsItem, currentSkill, skillType }) => {
  const { setPrimarySkill, setSecondarySkill, deleteAdditionalSkill } = useSkillStore();
  const { mutateAsync: deleteUserSkill } = useDeleteUserSkill();
  const [showSkillLevel, setShowSkillLevel] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  let skillLevel = React.useRef(null);
  const [skillLevelPos, setSkillLevelPos] = useState({ x: 0, y: 0, visible: false });
  const [isEdit,setIsEdit] = useState(false);
  const modalRef = React.useRef(null);

  const triggerSkillLevel = (isEdit:boolean) => {
    let skillLevelObj: any = skillLevel.current;
    let xPos = skillLevelObj ? skillLevelObj.getBoundingClientRect().right : 0;
    let yPos = skillLevelObj ? skillLevelObj.getBoundingClientRect().top : 0;
    setShowOptions(false);
    setShowSkillLevel(true);
    setSkillLevelPos({ x: xPos, y: yPos, visible: true });
    setIsEdit(isEdit);

  };

  useEffect(() => {
    if (skillsItem.id === 0) {
      triggerSkillLevel(false);

    }
  }, [skillsItem]);

  useEffect(() => {
    const handleEscapeKeyPress = (event:any) => {
      if (event.key === 'Escape') {
        setShowOptions(false);
      }
    };

    const handleClickOutsideModal = (event:any) => {
      if (modalRef.current && !(modalRef.current as HTMLElement).contains(event.target)) {
        setShowOptions(false);
      }
    };

    if (showOptions) {
      document.addEventListener('keydown', handleEscapeKeyPress);
      document.addEventListener('click', handleClickOutsideModal);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
      document.removeEventListener('click', handleClickOutsideModal);
    };
  }, [showOptions]);

  const deleteSkillItem = async (skillItem: UserSkillItem) => {
    const res = await confirmDeleteSkill();
    if(res){
      setShowOptions(false);
      if (skillItem.skill_type === SkillType.Primary) {
        setPrimarySkill(undefined);
      } else if (skillItem.skill_type === SkillType.Secondary) {
        setSecondarySkill(undefined);
      } else if (skillItem.skill_type === SkillType.Additional) {
        deleteAdditionalSkill(skillItem);
      }
      await deleteUserSkill({ skillId: skillsItem.id });
      showSuccessToast(messages.SKILL_DELETED);
    }
    
  };
  const getSkillScore = (skillRank: string): number => {
    switch (skillRank) {
      case SkillRank.Beginner:
        return 1;
      case SkillRank.Intermediate:
        return 2;
      case SkillRank.Expert:
        return 3;
    }
    return 0;
  };
  const skillRank = skillsItem.skill_rank !== "" ? skillsItem.skill_rank : "Update Skill Level";

  return (
    <>
      <div className={`select-skill-overlay ${skillLevelPos.visible ? "active" : ""}`}></div>

      <div className={`skill-placeholder ${skillLevelPos.visible ? "droped verify" : ""}`} >
        <div className="update-skill-level" ref={skillLevel}>
          <div className="option-dropdown-wrapper active" ref={modalRef}>
            <button className="icon-only icon-option" onClick={() => setShowOptions(!showOptions)}>
              <svg className="svg-icon">
                <use href="#menuDotIcon"></use>
              </svg>
            </button>
            {showOptions && (
              <div className="option-dropdown">
                <ul>
                  <li>
                    <button className="app-btn link-btn" onClick={()=>triggerSkillLevel(true)}>
                      Edit
                    </button>
                  </li>
                  <li>
                    <button
                      className="app-btn link-btn"
                      onClick={async () => {
                        deleteSkillItem(skillsItem);
                      }}
                    >
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <SkillName skillName={skillsItem.skill_name}/>
          <div className="selected-skill-level">
            <h5 className="skill-sub-title">{skillRank}</h5>
            <div className="updated-score">
              <SkillScore scoreValue={getSkillScore(skillsItem.skill_rank)} />
                {skillsItem.is_verified === false? (
                  <span className="app-tag verify">
                  <svg className="svg-icon icon-alert">
                    <use href="#alertIcon"></use>
                  </svg>
                  Not Verified
                </span>
                ):null}
            </div>
          </div>
          
        </div>
        {showSkillLevel && (
            <SelectSkillLevel
              skillItem={skillsItem}
              skillLevelPos={skillLevelPos}
              setSkillLevelPos={setSkillLevelPos}
              isEdit={isEdit}
            />
          )}
      </div>
    </>
  );
};
const SkillName=({skillName}:{skillName:string})=>{
  if(skillName.length > 15){
    return(
      <h3 className="skill-title" title={skillName}>{skillName}</h3>
    )
  }else{
  return(
    <h3 className="skill-title">{skillName}</h3>
  )
  }
}
export default UpdateSkillLevel;
