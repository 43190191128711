import React, { ReactNode } from "react";
import "./checkbox.scss";

interface Props {
  label?: string;
  name?: string;
  id?: string;
  defaultChecked?: any;
  onChange?: any;
  value?: any;
  relevancy?: string;
}

const Checkbox: React.FC<Props> = ({
  label,
  name,
  id,
  defaultChecked,
  onChange,
  value,
  relevancy,
}) => {
  return (
    <div className="checkbox-wrapper">
      <label htmlFor={id} className="app-checkbox">
        <input type="checkbox" name={name} key={id} id={id} checked={value} onChange={onChange} />
        <span
          className={`checkbox-label ${
            relevancy === "type1"
              ? "relevancy type1"
              : relevancy === "type2"
              ? "relevancy type2"
              : relevancy === "type3"
              ? "relevancy type3"
              : ""
          }`}
        >
          {label}
        </span>
      </label>
    </div>
  );
};
export default Checkbox;
