import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";


export async function getCategoryById(categoryId:string|number):Promise<CategorySkillId|null> {
  const response = await apiClient.get(urls.categoryById(categoryId));
  if (response && response.data && response.data.data)
    return response.data.data;
  return null;
}

export function useGetCategoryById(categoryId:string|number|undefined):UseQueryResult<CategorySkillId> {
  return useQuery(["GetCategoryById"], async () => {
    return await getCategoryById(categoryId??0);
  },{
    enabled: categoryId !== undefined
  });
}
