import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";


export async function getAdminCertifications(search:string,offset:number):Promise<CertificationHolders|null> {
  const response = await apiClient.get(urls.certificationHolders(search,offset));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetAdminCertifications():UseQueryResult<CertificationHolders> {
  const {certificationSearch,certificationListPaginationOffset} = useAdminStore();
  return useQuery(["AdminCertifications",certificationSearch,certificationListPaginationOffset], async () => {
    return await getAdminCertifications(certificationSearch,certificationListPaginationOffset);
  },{
    refetchOnWindowFocus:false
  });
}
