import React, { useState } from "react";
import "./admin-skill-modal.scss";
import InputField from "components/common-components/InputField";
import CreateCategory from "feature/admin/components/CreateCategory";
import { useAdminStore } from "store/adminStore";
import { useSkillStore } from "store/useSkillStore";
import CategoryList from "feature/admin/components/CategoryList";
import { useGetCategories } from "api/useGetCategories";
import { deepClone } from "utils/userUtils";
import { INPUT_MAX_LENGTH } from "utils/constants";

const EditSkillModal = ({
  skillName,
  setCategory,
  setSkillName,
  errorMessage,
  setErrorMessage,
}: {
  skillName: string;
  setCategory: (category: Category | undefined) => void;
  setSkillName: (name: string) => void;
  errorMessage: string;
  setErrorMessage: (error: string) => void;
}) => {
  const {selectedSkill}= useAdminStore();
  const { data } = useGetCategories();
  const { categories } = useSkillStore();
  const [toggleCategory, setToggleCategory] = useState(false);

  const getInitialCategories =()=> categories?.map((item: Category) => {
    return { ...item, checked: item.id === selectedSkill?.category_id };
  });

  const [categoryList, setCategoryList] = useState<Category[] | undefined>(getInitialCategories());

  const handletoggleCategory = () => {
    setToggleCategory(true);
  };
  const closeToggleCategory = () => {
    setToggleCategory(false);
  };

  React.useEffect(() => {
    setCategoryList(deepClone(getInitialCategories()));
  }, [categories]);
  
  const unCheckCategories = (item: Category) => {
    if (categoryList) {
      const filteredList = categoryList.map((category) => ({
        ...category,
        checked: item.id === category.id,
      }));
      setCategoryList(filteredList);
    }
  };

  return (
    <div className="app-modal-content sm edit-skill">
      <InputField type="text"
      value={skillName}
      onChange={(e:any)=>{
        setSkillName(e.target.value);
        setErrorMessage("");
      }}
      label="Name of the skill"
      error={errorMessage === "Please select a category"? undefined : errorMessage}
      maxLength={INPUT_MAX_LENGTH}
      placeholder="Name of the skill" />
      <div className={`skill-checkbox-search-wrapper ${toggleCategory? 'back-drop': ''}`}>
        <div className="skill-btn-text-layout">
          <div className="skill-header">
            <h4 className="skill-main-header">Category</h4>
            <span className="skill-sub-header">Select which categories the skill belong to </span>
            {errorMessage === "Please select a category"?
            <div className="validationMessage">{errorMessage}</div> : null}
          </div>
          <button className="app-btn secondary icon-btn-text" onClick={handletoggleCategory}>
            <svg className="svg-icon plus-icon">
              <use href="#plusIcon">
                <title>Add Icon</title>
              </use>
            </svg>
            <span className="button-text">Create New category</span>
          </button>
        </div>
        {toggleCategory && (
          <CreateCategory closeToggleCategory={closeToggleCategory}/>
        )}
       
        <CategoryList
          categoryList={categoryList}
          onChange={(item) => {
            item.checked = !item.checked;
            setCategory(item.checked ? item : undefined);
            unCheckCategories(item);
          }}
        />
        
      </div>
    </div>
  );
};
export default EditSkillModal;
