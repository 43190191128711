import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useSkillStore } from "store/useSkillStore";
import { useUserStore } from "store/useUserStore";


export async function getCategories():Promise<Array<Category>|null>{
  const response = await apiClient.get(urls.categories());
  if (response && response.data && response.data.data)
    return response.data.data.categories as Category[];
  return null;
}

export function useGetCategories():UseQueryResult<Array<Category>> {
  const {setCategories} = useSkillStore();
  const {accessToken} = useUserStore();
  return useQuery(["categories",accessToken], async () => {
    const categories = await getCategories();
    const filterCategories = categories?.map((item)=>({...item, checked:false}));
    setCategories(filterCategories??[]);
    return filterCategories;
  },{
    refetchOnWindowFocus:false,
  });
}
