/* eslint-disable no-unused-vars */
import Select, { ActionMeta, MultiValue, PropsValue } from "react-select";
import "./react-select.scss";
type MultiOnChange = (val: MultiValue<Option>, actionMeta: ActionMeta<Option>) => void;
type SingleOnChange = (value: Option) => void;
interface Props {
  id?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  value: Option | null | PropsValue<Option> | any;
  state?: boolean;
  label?: string;
  validation?: boolean;
  isMulti?: boolean;
  options?: any;
  classNamePrefix?: string;
  onChange?: any;
  onMultiChange?: MultiOnChange;
  error?: string;
  isOptionDisabled?: any;
  isSearchable?: boolean;
  isDisabled?: boolean;
  mandatory?: boolean;
  formatOptionLabel?: any;
  
}

const SelectBox: React.FC<Props> = ({
  id,
  type,
  name,
  placeholder,
  value,
  state,
  label,
  validation,
  isMulti,
  options,
  classNamePrefix,
  onChange,
  onMultiChange,
  error,
  isOptionDisabled,
  isSearchable,
  isDisabled,
  mandatory,
  formatOptionLabel,
}) => {

  return (
    <div className={"selectBoxWrapper"}>
      {label && (
        <label>{label}{mandatory && <sup>*</sup>}</label>
      )}      
      <Select
        options={options}
        placeholder={placeholder}
        isMulti={isMulti}
        value={value}
        classNamePrefix={classNamePrefix}
        onChange={isMulti ? onMultiChange : onChange}
        isOptionDisabled={isOptionDisabled}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        menuPosition="fixed"
        openMenuOnClick={!isMulti}
        openMenuOnFocus={!isMulti}
        noOptionsMessage={({ inputValue }) => "No matching tag"}
        formatOptionLabel={formatOptionLabel}    
      />
      {error && (
        <div className={"validationMessage"}>
          <span className={"validationText"}>{error}</span>
        </div>
      )}      
    </div>
  );
};
export default SelectBox;
