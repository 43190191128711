import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";
import { handleDownload } from "utils/downloadFile";

export async function getAdminSubDomainUserExport(selectedSkill:AdminSubDomain|undefined):Promise<any> {
  if(selectedSkill){
  const response = await apiClient.get(urls.exportSubDomain(selectedSkill.sub_domain_id));
  if (response && response.data && response.data.data )
    return response.data.data;
  }
  return null;
}

export function useGetSubDomainUserExport():UseQueryResult<any> {
  const {initiateSkillExportById,setInitiateSkillExportById,selectedSubDomain}= useAdminStore();
  return useQuery(["AdminSubDomainExport"], async () => {
    const result= await getAdminSubDomainUserExport(selectedSubDomain);
    if(result && result.file_url){
      await handleDownload(result);
    }
    setInitiateSkillExportById(false);
    return result;
  },{
    enabled: initiateSkillExportById === true && selectedSubDomain !== undefined,
  });
}

