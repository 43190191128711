import { useEffect, useState } from "react";
import "./filter-sidepanel.scss";
import Checkbox from "components/common-components/Checkbox";
import { useUserStore } from "store/useUserStore";
import { useSkillStore } from "store/useSkillStore";
import { deepClone } from "utils/userUtils";

interface Props {
  filterToggle?: any;
  setFilterToggle?: any;
}
const FilterSidePanel: React.FC<Props> = ({ filterToggle, setFilterToggle }) => {
  const handleFilter = () => {
    setFilterToggle(!filterToggle);
  };
  const { setFilterOpen } = useUserStore();
  const { categories, setCategories } = useSkillStore();

  const initialCategories = categories?.map((item) => {
    return { ...item, checked: item.checked ?? false };
  });

  const [filterCategories, setFilterCategories] = useState<Category[] | undefined>(
    deepClone(initialCategories)
  );

  const resetFilters = () => {
    const resetCategories = categories?.map((item) => {
      return { ...item, checked: false };
    });
    setFilterCategories(resetCategories);
    setCategories(resetCategories ?? []);
  };

  const saveFilters = () => {
    const categories: Category[] | undefined = filterCategories?.map((item) => {
      return { ...item };
    });
    setFilterCategories(categories);
    setCategories(categories ?? []);
    setFilterOpen(false);
    setFilterToggle(!filterToggle);
  };

  const onChange = (category: Category) => {
    const categories: Category[] | undefined = filterCategories?.map((item) => {
      return item.name === category.name ? { ...item, checked: !item.checked } : item;
    });
    setFilterCategories(categories);
  };

  useEffect(()=>{
    if(categories){
      setFilterCategories(deepClone(initialCategories));
    }
  },[categories]);
  
  return (
    <div className="filter-panel-sidebar">
      {/* active class is using for to activate the sidebar */}
      <div className={`side-bar ${filterToggle ? "active" : ""} `}>
        <div className="sidebar-header">
          <button className="icon-only back-arrow" title="Close" onClick={handleFilter}>
            <svg className="svg-icon back-arrow-icon">
              <use href="#backArrowIcon"></use>
            </svg>
          </button>
          <h4 className="sidebar-title">Filter</h4>
          <button className="icon-only close-icon" onClick={handleFilter}>
            <svg className="svg-icon sm">
              <use href="#closeIcon">
                <title>Close</title>
              </use>
            </svg>
          </button>
        </div>
        <CategoriesList categories={filterCategories} onChange={(category) => onChange(category)} />
        <div className="side-page-footer">
          <button
            className="app-btn secondary"
            title="Reset"
            onClick={(e) => {
              resetFilters();
            }}
          >
            Reset
          </button>
          <button
            className="app-btn min-width primary"
            title="Apply"
            onClick={(e) => {
              saveFilters();
            }}
          >
            Apply
          </button>
        </div>
      </div>
      <div className="sidebar-backdrop"></div>
    </div>
  );
};

const CategoriesList = ({
  categories,
  onChange,
}: {
  categories: Category[] | undefined;
  onChange: (category: Category) => void;
}) => {
  if (categories && categories.length) {
    return (
      <div className="details-content">
        {categories.map((category, index) => (
          <Checkbox
            key={index}
            label={category.name}
            name="filter-checkbox"
            value={category.checked}
            id={index.toString()}
            onChange={(e: any) => onChange(category)}
          />
        ))}
      </div>
    );
  }
  return null;
};
export default FilterSidePanel;
