import React from "react";
import "./actionHeader.scss";
import { useAdminStore } from "store/adminStore";
import { useUserStore } from "store/useUserStore";

interface Props {
  tabSwitch?: any;
  setTabSwitch?: any;

}
const ActionHeader: React.FC<Props> = ({
  tabSwitch,
  setTabSwitch,
}) => {

  const handleTabSwitch = (handleTabSwitch: string) => {
    setTabSwitch(handleTabSwitch);
    setSearchKey("")
  };
  const {
    setGroupByCategory,
    setSearchKey,
    setCertificationSearch,
    setQueryBuilderPaginationOffset,
    setUserSearch,
    setSortOption,
    setGroupByDomains,
    setSubDomainSearch,
    setSortOptionSkills,
    setSortOptionCertification,
    setSortOptionDomain,
    setEmailLogPaginationOffset,
    setSubjectSearch,
    setSortOptionEmailLog,
    setEmailLogRecipientsPaginationOffset

  } = useAdminStore();

  const {userManagedRole} = useUserStore();

  return (
    <div className="admin-action-header">
      <div className="app-tab-wrapper admin-tabs">
        <nav>
          <ul>
            <li>
              <button className={`app-btn ${tabSwitch === 'Skills' ? 'active' : ''}`} onClick={() => {
                setSortOption(undefined); handleTabSwitch('Skills'); setCertificationSearch(""); setQueryBuilderPaginationOffset(25); setUserSearch(''); setGroupByDomains(false); setSubDomainSearch(""); setSortOptionSkills({
                  label: "first_name_last_name_asc",
                  value: "first_name_last_name:asc",
                });
                setEmailLogPaginationOffset(25);
                setEmailLogRecipientsPaginationOffset(25); 
              }}>Skills</button>
            </li>
            <li>
              <button className={`app-btn ${tabSwitch === 'Certifications' ? 'active' : ''}`} onClick={() => {
                setSortOption(undefined);
                handleTabSwitch('Certifications');
                setGroupByCategory(false);
                setSearchKey("");
                setQueryBuilderPaginationOffset(25);
                setUserSearch('');
                setGroupByDomains(false);
                setSubDomainSearch("");
                setSortOptionCertification({
                  label: "first_name_last_name_asc",
                  value: "first_name_last_name:asc",
                });
                setEmailLogPaginationOffset(25);
                setEmailLogRecipientsPaginationOffset(25); 
              }}>
                Certifications
              </button>
            </li>
            <li>
              <button className={`app-btn ${tabSwitch === 'Users' ? 'active' : ''}`} onClick={() => { 
                handleTabSwitch('Users');
                 setGroupByCategory(false);
                  setGroupByDomains(false); 
                  setSubDomainSearch(""); 
                  setQueryBuilderPaginationOffset(25);
                setEmailLogRecipientsPaginationOffset(25); 
                setEmailLogPaginationOffset(25); }}>
                Users
              </button>
            </li>
            {/* <li>
              <button className={`app-btn ${tabSwitch === 'Domains' ? 'active' : ''}`} onClick={() => {
                setSortOption(undefined);
                handleTabSwitch('Domains');
                setGroupByCategory(false);
                setQueryBuilderPaginationOffset(25);
                setSortOptionDomain({
                  label: "first_name_last_name_asc",
                  value: "first_name_last_name:asc",
                });
                setEmailLogPaginationOffset(25);
                setEmailLogRecipientsPaginationOffset(25); 
              }}>
                Domains
              </button>
            </li> */}
             {!userManagedRole &&  ( <li>
            <button className={`app-btn ${tabSwitch === 'EmailLog' ? 'active' : ''}`} onClick={() => { 
              handleTabSwitch('EmailLog'); 
              setGroupByCategory(false); 
              setGroupByDomains(false); 
              setSubjectSearch(""); 
              setQueryBuilderPaginationOffset(25);
              setEmailLogPaginationOffset(25);
              setEmailLogRecipientsPaginationOffset(25); 
              setSortOptionEmailLog({
                label: "date_asc",
                value:"date:asc"
              });}}
              >
                Email Logs
              </button>
            </li>)}
        
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default ActionHeader;
