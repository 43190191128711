import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";


export async function getDomainById(domainId:string|number):Promise<DomainSubDomainId|null> {
  const response = await apiClient.get(urls.domainById(domainId));
  if (response && response.data && response.data.data)
    return response.data.data;
  return null;
}

export function useGetDomainById(domainId:string|number|undefined):UseQueryResult<DomainSubDomainId> {
  return useQuery(["GetDomainById"], async () => {
    return await getDomainById(domainId??0);
  },{
    enabled: domainId !== undefined
  });
}
