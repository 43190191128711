import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateDomain(
    domainName:string,
    domainId:number,
){
    try{
    const requestObject = {
        domain_name: domainName,
      }
    const response = await apiClient.put(
        urls.AdminDomainById(domainId),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useUpdateDomain():UseMutationResult<
    any,
    ApiError | {message: string},
    {domainName:string;domainId:number}
    >{
        const {subDomainSearch,domainListPaginationOffset, domains} = useAdminStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            domainName,
            domainId,
        }: {
            domainName: string;
            domainId:number;
        }) => {
            const result = await updateDomain(domainName, domainId);
            queryClient.invalidateQueries(["AdminDomain",subDomainSearch,domainListPaginationOffset,domains]);
            queryClient.invalidateQueries(["GetDomainById"]);
            return result;
        }
    );
    }