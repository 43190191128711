import React, { useState } from "react";
import "../../AdminFilter/adminFilter.scss";
import RadioButton from "components/common-components/RadioButton";
import DomainList from "../../DomainList";
import { useAdminStore } from "store/adminStore";
import { SortOptionsDomain } from "utils/constants";
import { deepClone } from "utils/userUtils";

interface Props {
  handleAdminFilter?: any;
}
const AdminSubDomainFilter: React.FC<Props> = ({
  handleAdminFilter,
}: Props) => {
  const { domains: adminDomains,sortOption, setSortOption, setDomains,setSubDomainListPaginationOffset } = useAdminStore();
  const [domains, setDomainList] = useState<AdminDomain[] | undefined>(
    deepClone(adminDomains)
  );
  const [sort, setSort] = useState<Option | undefined>(
    deepClone(sortOption??{} as Option)
  );

  const [filterTab, setFilterTab] = useState("Domain");
  const handleFilterTab = (filterMenu: string) => {
    setFilterTab(filterMenu);
  };

  const onDomainChange = (domain: AdminDomain) => {
    if (domains) {
      const filteredList = domains?.map((item) => {
        if (item.id === domain.id) {
          return { ...domain, checked: !domain.checked };
        }
        return item;
      });
      setDomainList(filteredList);
    }
  };

  const resetFilter = ()=>{
    const resetDomains = domains?.map((item:AdminDomain)=> ({...item,checked:false}))??[];
    setDomains(resetDomains);
    setSort(undefined);
    setSortOption(undefined);
    setDomainList(resetDomains);
    setSubDomainListPaginationOffset(0);
  }
  const applyFilter = ()=>{
    if(domains){
      setDomains(domains);
    }
    if(sort){
      setSortOption(sort);
    }
    setSubDomainListPaginationOffset(0);
    handleAdminFilter();
  }
  return (
    <div  className="admin-filter-drop-down">
      <div className="filter-header">
        <button className="back-btn" onClick={() => handleAdminFilter()}>
          <svg className="svg-icon plus-icon">
            <use href="#backArrowIcon">
              <title>Back Button</title>
            </use>
          </svg>
          Filter
        </button>
      </div>
      <nav className="filter-nav">
        <div className="filter-menu">
          <div className="filter-menu-item  two-col">
            <button
              className={`filter-tab-btn ${filterTab === "Domain" ? "active" : ""}`}
              onClick={() => handleFilterTab("Domain")}
            >
              Domain
            </button>
            <div
              className={`filter-menu-list ${filterTab === "Domain" ? "active" : ""}`}
              style={{ "--itemCount": domains?.length ?? 15 } as React.CSSProperties}
            >
              <DomainList
                DomainList={domains}
                onChange={(item) => {
                  onDomainChange(item);
                }}
              />
            </div>
          </div>
          <div className="filter-menu-item">
            <button
              className={`filter-tab-btn ${filterTab === "Sort" ? "active" : ""}`}
              onClick={() => handleFilterTab("Sort")}
            >
              Sort
            </button>
            <div className={`filter-menu-list ${filterTab === "Sort" ? "active" : ""}`}>
              {
                SortOptionsDomain.map((item,index)=>(
                  <RadioButton key={index} label={item.label}  name="sortRadio" 
                  defaultChecked={item.value== sort?.value??false}
                  onChange={(e:any)=>{
                    setSort(item);
                  }}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </nav>
      <div className="filter-footer">
        <div className="btn-group align-right">
          <button className="app-btn min-width" onClick={resetFilter}>Reset</button>
          <button className="app-btn min-width primary" onClick={applyFilter}>Apply</button>
        </div>
      </div>
    </div>
  );
};
export default AdminSubDomainFilter;
