import { UseQueryResult, useQuery } from "@tanstack/react-query";
import apiClient from "utils/apiClient";

export async function getProfileImage(ssoId:string):Promise<string> {
    try{
    const response:any = await apiClient.get(`https://graph.microsoft.com/v1.0/users/${ssoId}/photo/$value`, {
        responseType: 'blob'
      });
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(response.data);
      return blobUrl;
    }catch(e){
        return '';
    }
}

export function useGetUserProfileImage(ssoId:string):UseQueryResult<string> {
  return useQuery(["UserImageurl",ssoId], async () => {
    return getProfileImage(ssoId);
  },
  {
    staleTime: Infinity,
  });
}
