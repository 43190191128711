import Checkbox from "components/common-components/Checkbox";

const DomainList = ({
  DomainList,
  onChange,
}: {
    DomainList: AdminDomain[] | undefined;
  onChange: (item: AdminDomain) => void;
}) => {
  return (
    <div className="skill-checkbox-layout">
      {DomainList?.map((item) => (
        <Checkbox
          key={item.id}
          label={item.domain_name}
          name="filter-check-box"
          value={item.checked}
          onChange={() => {
            onChange(item);
          }}
        />
      ))}
    </div>
  );
};
export default DomainList;
