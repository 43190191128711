// import "../../confirm-delete-modal.scss";
import "../ConfirmDeleteCertification/confirm-delete-modal.scss";

const ConfirmDeleteDomain = () => {
  return (
    <div className="app-modal-content confirm-delete-popup">
      <p className="sub-content">Are you sure you want to delete this Domain?</p>
    </div>
  );
};

export default ConfirmDeleteDomain;

