import React from 'react';

type Mail = {
    subject: string;
    total_recipients: number;
    failed_recipients: number;
    status: string;
    date: string;
};

type MailCardProps = {
    mailItems: Mail;
    onClick: () => void;
};

const MailCard: React.FC<MailCardProps> = ({ mailItems, onClick }) => {
    return (<div className="mail-card-box" onClick={onClick}>
        <div className="mail-card-head">
            <h4 className="mail-subject">
                <span className="title-icon">
                    <svg className="svg-icon email-icon">
                        <use href="#emailIcon">
                            <title>Mail Icon</title>
                        </use>
                    </svg>
                </span>
                <span className="mail-subject-text">{mailItems.subject}</span>
            </h4>
            <span className="app-btn icon-only-sm ghost go-details">
                <svg className="svg-icon right-arrow-icon">
                    <use href="#rightmoveIcon">
                        <title>Go Details</title>
                    </use>
                </svg>
            </span>
        </div>
        <div className="delivery-status">
            <ul>
                <li>
                    Total Recipent:<span className="value">{mailItems.total_recipients}</span>
                </li>
                <li>
                    Undelivered:<span className="value">{mailItems.failed_recipients}</span>
                </li>
            </ul>
        </div>
        <div className="mail-card-bottom">
            <div className={`status-update ${mailItems.status === "Sent" ? "success"
                      : mailItems.status === "Pending" ? "pending"
                      : mailItems.status === "In Progress" ? "in-progress"
                      : mailItems.status === "Failed" ? "failed"
                      : ""
            }`}>
                <span className="status-icon">
                    <svg className="svg-icon">
                        <use
                            href={
                            mailItems.status === "Sent" ? "#circleTickIcon"
                            : mailItems.status === "Pending" ? "#pendingIcon"
                            : mailItems.status === "In Progress" ? "#inProgress"
                            : mailItems.status === "Failed" ? "#crossIcon"
                            : ""
                            }
                        >
                            <title>status Icon</title>
                        </use>
                    </svg>
                </span>
                <span className="status-name">{`${mailItems.status === "Failed" ? mailItems.failed_recipients + " " : ""}${mailItems.status}`}</span>
            </div>
            <div className="mail-date">{mailItems.date}</div>
        </div>
    </div>
);
}
export default MailCard;
