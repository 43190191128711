import "../../../../../src/components/common-components/Accordion/accordion.scss";
import "./home.scss";
import FaqSplash from "./FaqSplash";
import FaqAccordion from "./FaqAccordion";
import Header from "./Header";
const Faq = () => {
  return (
    <div className="layout-wrapper ">
      <div className="home-left-panel faq-left-panel">
        <Header />
        <div className="left-panel-body">
          <FaqSplash />
        </div>
      </div>
      <div className="home-right-panel faq-section">
        <FaqAccordion />
      </div>
    </div>
  );
};

export default Faq;
