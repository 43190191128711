import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useUserStore } from "store/useUserStore";
import { useAdminStore } from "store/adminStore";


export async function getAdminCategories():Promise<Array<Category>|null>{
  const response = await apiClient.get(urls.categories());
  if (response && response.data && response.data.data)
    return response.data.data.categories as Category[];
  return null;
}

export function useGetAdminCategories():UseQueryResult<Array<Category>> {
  const {setCategories} = useAdminStore();
  const {accessToken} = useUserStore();
  return useQuery(["adminCategories",accessToken], async () => {
    const categories = await getAdminCategories();
    const filterCategories = categories?.map((item)=>({...item, checked:false}));
    setCategories(filterCategories??[]);
    return filterCategories;
  },{
    refetchOnWindowFocus:false,
  });
}
