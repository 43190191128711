import Home from "feature/home";
import Admin from "feature/admin";
import Login from "feature/login";
import { Routes, Route } from "react-router-dom";
import PageNotFound from "feature/404";
import { PrivateRoute } from "./privateRoute";
import { Callback } from "./callback";
import { LogoutCallback } from "./logoutCallback";
import Faq from "feature/home/components/FAQ/faq";
import SelectionPortal from "feature/selectionPortal";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/logout-callback" element={<LogoutCallback />} />
      <Route path="/home"
      element={
        <PrivateRoute allowedRole="User">
         <Home />
        </PrivateRoute>
      }
      />
      <Route path="/admin" element={
        <PrivateRoute allowedRole="Admin">
          <Admin />
        </PrivateRoute>
      } />
      <Route path="/data-viewer" element={
        <PrivateRoute allowedRole="DataViewer">
          <Admin />
        </PrivateRoute>
      } />
      <Route path="/select-stream" element={
        <PrivateRoute allowedRole="DataViewer">
          <SelectionPortal />
        </PrivateRoute>
    } />

      <Route path="*" element={
         <PageNotFound />
      } />
    <Route path="/faq" element={
        <Faq />
      } />
    </Routes>
  );
};

export default Router;
