import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserStore } from "store/useUserStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveCustomSkill(
    customSkill:string,
    userId:number
){
    try{
    const requestObject = {
        skill_name: customSkill,
        category_id: 1,
    };
    const response = await apiClient.post(
        urls.customSkill(),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useSaveCustomSkill():UseMutationResult<
    any,
    ApiError | {message: string},
    {customSkill:string}
    >{
        const {accessToken} = useUserStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            customSkill,
        }: {
            customSkill: string;
        }) => {
            const result = await saveCustomSkill(customSkill, 0);
            queryClient.invalidateQueries(["GetAllCustomSkills",accessToken]);
            queryClient.invalidateQueries(["categoryWiseSkills"]);
            return result;
        }
    );
    }