import { useEffect, useRef, useState } from "react";
import "./helpDropdown.scss";
import { Link } from "react-router-dom";
import { useSkillStore } from "store/useSkillStore";
import { useUserStore } from "store/useUserStore";
const HelpDropdown = () => {
  const {setAccessToken} = useUserStore();
  const [showHelp, setShowHelp] = useState(false);
  const closeRef = useRef(null);
  const openMail=()=>{
    const mailAddress ="skillmatrix@experionglobal.com";
    const mailtoLink = `mailto:${mailAddress}`;
    window.open(mailtoLink, '_blank');
  }
  const onMenuClicked = ()=>{
    setShowHelp((prev)=>!prev);
  }
  const handleClickOutside = (event: { target: any; }) => {
    if (closeRef.current && !(closeRef.current as HTMLElement).contains(event.target)) {
      setShowHelp(false);
    }
  };
  const close = (event:any) => {
    if(event.key === 'Escape'){
      setShowHelp(false);
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    window.addEventListener('keydown', close)
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      window.removeEventListener('keydown', close)
    };
    
  }, []);

  return (
    // add the below "active" class based on toggle state, add implement outside click close funtionality too
    <div ref={closeRef} className={`help-wrapper ${showHelp?'active':''}`} onClick={onMenuClicked}>
      <button className="icon-only help-btn">
        <svg className="svg-icon help-icon">
          <use href="#helpIcon"></use>
        </svg>
      </button>
      <div className="help-dropdown">
        <ul>
          <li>
        {/* <a href="/faq" download="FAQ-skill-matrix.pdf" >
              FAQs
            </a> */}
            <Link to="/faq" target="_blank">FAQs</Link>
          </li>
          <li>
            <a href="#" onClick={openMail}>
              Contact Admin
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default HelpDropdown;
