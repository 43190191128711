import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: window.__RUNTIME_CONFIG__?.REACT_APP_CLIENT_ID ?? process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${window.__RUNTIME_CONFIG__?.REACT_APP_TENANT_ID ??process.env.REACT_APP_TENANT_ID}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};


export const loginRequest = {
  scopes: ["User.Read"],
};


export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphProfileImageEndPoint:"https://graph.microsoft.com/v1.0/me/photo/$value",
};
