import React from 'react'
import { useNavigate } from "react-router-dom";
import "./slectionPortal.scss";
import { useUserStore } from "store/useUserStore";

const SelectionPortal = () => {
    const navigate = useNavigate();
    const {userInfo } = useUserStore();

    const handleDataViewer = ()=>{
        if(userInfo.roles.includes("DataViewer")){
            navigate("/data-viewer")
        }
    }

    return (
        <div className="app-selection-portal">
            <div className="app-icon">
                <svg className="svg-icon logo-icon">
                    <use href="#loginLogoIcon"></use>
                </svg>
            </div>
            <div className="app-selection-portal-wrapper">
              <h2 className="selection-portal-heading">Welcome to Skill Matrix</h2>
         

                <span className='selection-portal-sub-heading'>Let’s get started, Please choose your account of preference</span>
               
                    <div className="selection-portal-button-holder">
                        <button className="app-btn emp-btn" onClick={()=>{navigate("/home")}}>
                            <svg className="svg-icon user-icon">
                                <use href="#userIcon">
                                    <title>User Icon</title>
                                </use>
                            </svg> Employee
                        </button>
                        <button className="app-btn data-btn" onClick={handleDataViewer}>
                            <svg className="svg-icon system-icon">
                                <use href="#systemIcon">
                                    <title>System Icon</title>
                                </use>
                            </svg>
                            Data Viewer
                        </button>
                    </div>
                
               
            </div>
        </div>
    )
}

export default SelectionPortal