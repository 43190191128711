import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";


export async function getAdminCertificationsUsers(certificationId:number,offset:number, sort: Option | undefined):Promise<CertificationUsers|null> {
  const response = await apiClient.get(urls.certificationUsers(certificationId,offset, sort));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetAdminCertificationsUsers():UseQueryResult<CertificationUsers> {
  const{selectedCerfifcation,certificationPaginationOffset, sortOptionCertification}=useAdminStore();
  return useQuery(
    [
      "AdminCertificationsUsers",
      selectedCerfifcation,
      certificationPaginationOffset,
      sortOptionCertification,
    ],
    async () => {
      return await getAdminCertificationsUsers(
        selectedCerfifcation?.id ?? 0,
        certificationPaginationOffset,
        sortOptionCertification
      );
    },
    {
      enabled: selectedCerfifcation !== undefined,
      refetchOnWindowFocus: false,
    }
  );
}
