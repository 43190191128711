import React from 'react'
// import "./addcategoreymodal.scss"
import InputField from "components/common-components/InputField";
import { INPUT_MAX_LENGTH } from "utils/constants";

const AddCategoreyModal = ({
  value,
  error,
  onChange,
}: {
  value: string;
  error: string;
  onChange: (skill: string) => void;
}) => {
  return (
    <div className="app-modal-content sm  add-categorey">
    <InputField
      type="text"
      label="Category name"
      placeholder="Enter a new category name"
      value={value}
      onChange={(e: any) => onChange(e.target.value)}
      error={error}
      autoFocus={true}
      maxLength={INPUT_MAX_LENGTH}

    />
  </div>
  )
}

export default  AddCategoreyModal