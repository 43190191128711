import React, { useEffect, useRef, useState } from "react";
import "../../searchPanel.scss"
import { useAdminStore } from "store/adminStore";
import InputField from "components/common-components/InputField";
import { useUserStore } from "store/useUserStore";
import Checkbox from "components/common-components/Checkbox";


interface Props {
    from?: string;
    isSkillPage?:  boolean;
    setEditMode?: any;
}

const CheckBox: React.FC<Props> = ({
    from,
    isSkillPage,
    setEditMode
}) => {
    const {
        setSearchKey,
        groupByCategory,
        groupByDomains,
        setSubDomainSearch,
        setGroupByCategory,
        resetFilters,
        setGroupByDomains,
        resetFiltersDomain
    } = useAdminStore();
    const { userManagedRole } = useUserStore();

    const handleGroupByCategory = () => {
        setSearchKey("")
        setGroupByCategory(groupByCategory ? false : true);
        setEditMode(false)
        resetFilters()
    }

    const handleGroupByDomain = () => {
        setSubDomainSearch("")
        setGroupByDomains(groupByDomains ? false : true);
        resetFiltersDomain()
    }
    
    return (
        <>
            {from == "userData" ? null : isSkillPage ?
              <div className="skilllisting-checkbox-layout">
                <Checkbox label="Group by Category" name="Category"
                  value={groupByCategory}
                  onChange={handleGroupByCategory}
                />
              </div> : from == "SubDomains" ?
                <div className="skilllisting-checkbox-layout">
                  <Checkbox label="Group by Domain" name="Domain"
                    value={groupByDomains}
                    onChange={handleGroupByDomain}
                  />
                </div>
                : null}

        </>
    );
};
export default CheckBox;
