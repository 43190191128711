import "./skill-score-card.scss";
import SkillScore from "components/SkillScore";
import { SkillLevels } from "utils/constants";

const SkillScoreCard = ({
  skillItem,
  setSkillLevel,
}: {
  skillItem: UserSkillItem;
  setSkillLevel: (item: SkillLevel, skillItem: UserSkillItem) => void;
}) => {
  return (
    <div className="skill-score-stack">
      {SkillLevels.map((item, index) => {
        return (
          <div className="skill-score-card" key={index + "div"}>
            <label className={`skill-score-btn `} key={index + "label"}>
              <input
                key={index}
                className="skill-score-radio"
                type="radio"
                checked={item.name === skillItem.skill_rank}
                onChange={(e) => {
                  setSkillLevel(item, skillItem);
                }}
              />
              <span className="skill-score-label">{item.name}</span>
              <SkillScore scoreValue={item.level} />
            </label>
          </div>
        );
      })}
    </div>
  );
};
export default SkillScoreCard;
