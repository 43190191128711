import React, { useState } from "react";

import './skill-score.scss';
interface Props {
  scoreValue?: number;
}

const SkillScore: React.FC<Props> = ({ scoreValue }) => {
  return (  
    <div className="skill-score-wrapper">
      <div className="skill-nodes-holder filled">
        {[...Array(scoreValue)].map((index) => {
        return (          
          <span key={index} className= "skill-nodes filled" />          
        )
      })}
      </div>
      <div className="skill-nodes-holder">
        <span className="skill-nodes" />
        <span className="skill-nodes" />
        <span className="skill-nodes" />
      </div>
    </div>
  );
};
export default SkillScore;
