import Checkbox from "components/common-components/Checkbox";
import { useAdminStore } from "store/adminStore";
import { useSkillStore } from "store/useSkillStore";

const EmailLogList =  ({
    EmailLogList,
    onChange,
  }: {
    EmailLogList: EmailStatus[] | undefined;
    onChange: (item: EmailStatus) => void;
  }) => {  
    return (
      <div className="skill-checkbox-layout">
        {EmailLogList?.map((item) => (
          <Checkbox
            key={item.id}
            label={item.label}
            name="filter-check-box"
            value={item.checked}
            onChange={() => {
              onChange(item);
            }}
          />
        ))}
      </div>
    );
  };
  export default EmailLogList;