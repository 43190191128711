import React from 'react'
import "./reactselectdropdown.scss";
import ReactSelect, { MultiValueProps, OptionProps, components } from 'react-select';
interface Props {
    handleChange?: any;
    title?: string;
    options?: Option[];
    Value?: Option[];
    onChange?: any;
    placeholder?: string;
    validationText?:string;
    isDisabled?: boolean;
}
const ReactSelectDropdown: React.FC<Props> = ({
    handleChange,
    title,
    options,
    Value,
    placeholder,
    validationText,
    isDisabled

}) => {
    const MoreSelectedBadge: React.FC<MoreSelectedBadgeProps> = ({ items }) => {
        const title = items.join(", ");
        const length = items.length;
        const label = `+ ${length}`;

        return (
            <div title={title} className="countSelect">
                {label}
            </div>
        );
    };

    const Option: React.FC<OptionProps<any>> = (props) => {
       
        return (
            <div>
                
                <components.Option {...props}>
                    <div className="checkbox-wrapper">
                        <label className="app-checkbox">
                            <input
                                type="checkbox"
                                checked={props.isSelected}
                                onChange={() => props.selectOption(props.data)}
                            />
                            <span className="checkbox-label ">{props.label}</span>
                        </label>
                    </div>
                </components.Option>
            </div>
        );
    };

    const MultiValue: React.FC<MultiValueProps<any, boolean>> = (props) => {
        const { index, getValue, data } = props;
        const maxToShow = 1;
        const overflow = getValue()
            .slice(maxToShow)
            .map((x) => x.label);
        return index < maxToShow ? (
            <components.MultiValue {...props} />
        ) : index === maxToShow ? (
            <MoreSelectedBadge items={overflow} />
        ) : null;
    };
    return (
        <div className={"reactselectBoxWrapper"}>
            <label>{title}</label>
            <ReactSelect
                options={options}
                isMulti
                closeMenuOnSelect={false}
                placeholder={placeholder}
                hideSelectedOptions={false}
                components={{
                    Option,
                    MultiValue,
                }}
                onChange={handleChange}
                value={Value}
                isSearchable={true}
                isDisabled={isDisabled}
                classNamePrefix="react-multi-select"
            />
            <span className="dropdown-arrow">
                <svg className="svg-icon">
                    <use href="#downArrowIcon">
                        <title>down icon</title>
                    </use>
                </svg>
            </span>
                 <div className={"validationMessage"}>
                <span className={"validationText"}>{validationText}</span>
                </div>
        </div>
    )
}

export default ReactSelectDropdown 