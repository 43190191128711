import React, { useState } from 'react';
import "./pagination.scss";
import { PAGINATION_ITEMS_PER_PAGE } from 'utils/constants';
const SkillPagination = ({ totalItems, itemsPerPage, onPageChange }:
    { totalItems:number, itemsPerPage:number, onPageChange:(page:number)=>void }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page:number) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };
  const renderPaginationItems = () => {
    const paginationItems = [];
    if (totalPages <= 10) {
    for (let page = 1; page <= totalPages; page++) {
      paginationItems.push(
         <button
            key={page}
            onClick={()=>handlePageChange(page)}
            className={`icon-only pagination-num ${currentPage === page ? 'active' : ''}`}> 
              {page}
         </button>
      );
    }
  }else{
    const visiblePages = 10; // Maximum visible pages
    const sidePages = Math.floor(visiblePages / 2);
    let startPage = currentPage - sidePages;
    let endPage = currentPage + sidePages;

    if (startPage <= 0) {
      endPage += Math.abs(startPage) + 1;
      startPage = 1;
    }

    if (endPage > totalPages) {
      startPage -= endPage - totalPages;
      endPage = totalPages;
    }

    for (let page = startPage; page <= endPage; page++) {
      paginationItems.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={`icon-only pagination-num ${
            currentPage === page ? 'active' : ''
          }`}
        >
          {page}
        </button>
      );
    }

    if (startPage > 1) {
      paginationItems.unshift(
        <span key="ellipsis-start" className="pagination-ellipsis">
          ...
        </span>
      );
      paginationItems.unshift(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className="icon-only pagination-num"
        >
          1
        </button>
      );
    }

    if (endPage < totalPages) {
      paginationItems.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          ...
        </span>
      );
      paginationItems.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="icon-only pagination-num"
        >
          {totalPages}
        </button>
      );
    }

  }
    return paginationItems;
  };
  if(totalItems < PAGINATION_ITEMS_PER_PAGE){
    return null;
  }
  return (
    <div className="pagination-footer">
     <button className={`${currentPage=== 1 ?"app-btn arrow-btn-inactive":"app-btn arrow-btn-active"}`}
      onClick={handlePrevPage}>
        <svg className="svg-icon left-move-icon">
          <use href="#leftmoveIcon">
            <title>Add Icon</title>
          </use>
        </svg>
      </button>
      {renderPaginationItems()}
      <button className={`${currentPage === totalPages?"app-btn arrow-btn-inactive":"app-btn arrow-btn-active"}`}
       onClick={handleNextPage}>
        <svg className="svg-icon right-move-icon">
          <use href="#rightmoveIcon">
            <title>Add Icon</title>
          </use>
        </svg>
      </button>
    </div>
  );
};

export default SkillPagination;

