import React, { useState, useEffect } from "react";
// import Masonry from "react-masonry-css";
import "./Skill-set.scss";

import SkillSearch from "feature/home/components/SkillSearch";
import { useGetCategorySkills } from "api/user/useGetCategorySkills";

import "./Skill-set.scss";

import { CUSTOM_SKILL } from "utils/constants";
import CustomSkillsModal from "./CustomSkillsModal";
import SelectSkillLevel from "components/SelectSkillLevel";
import Masonry from 'react-smart-masonry';

interface Props {
  handleAddSkills: any;
  dragSkill: any;
  setDragSkill: any;
  filterToggle: any;
  setFilterToggle: any;
  revertHandleList:any;
  skillType:any;
}

const SkillSet: React.FC<Props> = ({
  handleAddSkills,
  dragSkill,
  setDragSkill,
  filterToggle,
  setFilterToggle,
  revertHandleList,
  skillType,
}) => {

  const { data: skills } = useGetCategorySkills();
  const [dragClass, setDragClass] = useState("");

  let draggingRef = React.useRef(null);
  const handleOnDragStart = (skillsItem: any, e: any, dragClass: string) => {
    setDragSkill(skillsItem);
    let draggingObj: any = draggingRef.current;
    setDragClass(dragClass);
    // e.dataTransfer.setDragImage(draggingObj, 0, 0);
  };
  const [showEditCustomModal, setshowEditCustomModal] = useState(false);
  const handleModal = () => {
    setshowEditCustomModal(!showEditCustomModal);
  };
  const [skillsItem,setSkillsItem] = useState<UserSkillItem>({}as UserSkillItem);
  const [skillLevelPos, setSkillLevelPos] = useState({ x: 0, y: 0, visible: true });
  const [showSkillLevel, setShowSkillLevel] = useState(false);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const isMobile = width <= 991;
 
  const showSkillLevelPopup = (dragSkill:any)=>{
    if (!isMobile) {
      return;
    }
    const droppedItem: UserSkillItem = {
      id: 0,
      category_id: dragSkill.category_id,
      skill_id: dragSkill.skill_id,
      skill_rank: "",
      skill_type: skillType,
      skill_name: dragSkill.skill_name,
    };
    setSkillsItem(droppedItem);
    setSkillLevelPos({ x: 0, y: 0, visible: true });
    setShowSkillLevel(true);
  }
  return (
    <div className="skill-stack">
      <SkillSearch filterToggle={filterToggle} setFilterToggle={setFilterToggle} revertHandleList={revertHandleList} />
      {skills && skills.categories && skills.categories.length === 0 && (
        <div className="no-result-found">
          <p> Sorry, we didn't find the skill you are looking for! </p>
        </div>

      )}
      <Masonry
        columns={2} gap={10}
        className="my-masonry-grid"
      >        
        {skills?.categories?.map((skill, skillCategoryIndex) => {
          return (
            <div
              className={`skill-set-card ${skill.name === CUSTOM_SKILL ? "add-by-you" : ""}`}
              key={skillCategoryIndex+"-"+skill.name}
            >
              <h5>
                <span>{skill.name}</span>
                {
                  skill.name === CUSTOM_SKILL?(
                    <button className="icon-only"  onClick={handleModal}>
                    <svg className="svg-icon edit-icon">
                      <use href="#editIcon">
                        <title>Edit Icon</title>
                      </use>
                    </svg>
                  </button>
                  ):null
                }
              </h5>
              {skill?.skills?.map((skillsItem, skillsIndex) => (
                <>
                  {/* to get get purple border add className "added" to below button */}
                  <button className="skill-item dragging" key={skillsIndex +"-"+ skillsItem.name}>
                    {skillsItem.name.length > 6 ? (<span className="skill-label" title={skillsItem.name}>{skillsItem.name}</span>): 
                    <span className="skill-label">{skillsItem.name}</span>}
                    <svg className="svg-icon icon-drag">
                      <use href="#dragIcon"></use>
                    </svg>
                  </button>
                  <button
                    className="skill-item"
                    key={skillsIndex}
                    ref={draggingRef}
                    draggable={!skillsItem.is_user_skill ?? false}
                    onClick={()=>{
                      if(!skillsItem.is_user_skill){
                        const category: UserSkillDropItem = {
                          skill_name: skillsItem.name,
                          category_id: skill.id,
                          skill_id: skillsItem.id,
                          skill_rank: "Beginner",
                          skill_type: "Primary",
                        };
                        showSkillLevelPopup(category)
                      }
                    }}
                    onDragStart={(e: any) => {
                      const category: UserSkillDropItem = {
                        skill_name: skillsItem.name,
                        category_id: skill.id,
                        skill_id: skillsItem.id,
                        skill_rank: "Beginner",
                        skill_type: "Primary",
                      };
                      handleOnDragStart(
                        category,
                        e,
                        `${skill.name === "Added by you" ? "add-by-you-btn" : ""}`
                      );
                    }}
                  >
                    {skillsItem.name.length > 20 ? (<span className="skill-label" title={skillsItem.name}>{skillsItem.name}</span>): 
                    <span className="skill-label">{skillsItem.name}</span>}
                    <svg className="svg-icon icon-drag">
                      <use href="#dragIcon"></use>
                    </svg>
                  </button>
                </>
              ))}
            </div>
          );
        })}
      </Masonry>

      {showEditCustomModal ? (
        <CustomSkillsModal setshowEditCustomModal={setshowEditCustomModal} />
      ) : (
        ""
      )}
      {showSkillLevel?(
          <SelectSkillLevel
          revertHandleList={revertHandleList}
          skillItem={skillsItem}
          skillLevelPos={skillLevelPos}
          setSkillLevelPos={setSkillLevelPos}
          isEdit={false}
        />
      ):null}
   
    </div>
  );
};

export default SkillSet;
