import AdminModal from "components/common-components/AdminModel";
import React, { useEffect, useState } from "react";
import AddDomainModal from "./AddDomainModal";
import AddSubDomainDataModal from "./SubDomainDataModal";
import Modal from "components/common-components/Modal";
import "./adminDomainListing.scss";
import SkillPagination from "components/common-components/SkillPagination";
import { useAdminStore } from "store/adminStore";
import { PAGINATION_ITEMS_PER_PAGE } from "utils/constants";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { ErrorMessages, messages } from "utils/messageConstants";
import DomainListingModal from "./DomainListingModal";
import { useUpdateCategory } from "api/admin/useUpdateCategory";
import { useGetAdminDomain } from "api/admin/useGetAllDomains";
import { useSaveAdminDomain } from "api/admin/useSaveDomain";
import { useUpdateDomain } from "api/admin/useUpdateDomain";
import { useUserStore } from "store/useUserStore";

interface Props {
  editMode?: any;
}
const DomainLisiting: React.FC<Props> = ({ editMode }) => {
  const { data: Domain } = useGetAdminDomain();
  const [openSubDomain, setOpenSubDomain] = useState(false);
  const [headerState, setheaderState] = useState(false);
  const [domainState, setDomainState] = useState(false)
  const [subDomainState, setSubDomainState] = useState(false)
  const [editModalState, setEditModalState] = useState(false)
  const { mutateAsync: save, isLoading } = useSaveAdminDomain();
  const { mutateAsync: updateDomainData } = useUpdateDomain();
  const [domain, setDomain] = useState<any>();
  const [error, setError] = useState("");
  const [domainData, setDomainData] = useState<any>();
  const [errorMessage, setErrorMessage] = useState("");
  const [newDomain, setNewDomain] = useState("");
  const [editState, setEditState] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isTooltipActive, setTooltipActive] = useState([false]);
  const refs: Array<any> = [];
  const { userManagedRole } = useUserStore();

  useEffect(() => {
    const tooltipVisible: boolean[] = [];
    refs.map((ref, i) => {
      const element = ref?.current as HTMLElement;
      if (element) {
        tooltipVisible.push(element.getBoundingClientRect().width + 1 < element.scrollWidth);
      }
    });

    if (JSON.stringify(tooltipVisible) !== JSON.stringify(isTooltipActive)) {
      setTooltipActive(tooltipVisible);
    }
  }, []);

  const handleAddModal = () => {
    setOpenSubDomain(!openSubDomain);
    setDomainState(!setDomainState);
    setNewDomain("")
    setError("")
  };

  const closeSkillModal = () => {
    setSubDomainState(!setSubDomainState);
    setheaderState(!setheaderState)
    setEditModalState(false)
    setErrorMessage("")
    setDomain({})
    setLoading(true)
    setEditState(false)
  }
  const handleBoxModal = (domain: any) => {
    setSubDomainState(true)
    setDomainData(domain)
    setDomain(domain)
  }

  const { setCategoryListPaginationOffset } = useAdminStore();
  const SaveDomain = async () => {
    try {
      if (isLoading) return;
      if (newDomain.trim() === "") {
        setError("Domain name is mandatory");
        return;
      }
      const response = await save({ domain_name: newDomain.trim() });
      if (response.data && response.data.domain.id) {
        setDomain({ ...domain, id: response.data.domain.id, domain_name: response.data.domain.domain_name, sub_domain_count: 0 })
        showSuccessToast(messages.DOMAIN_SAVE_SUCCESS);
        setDomainState(true);
        setNewDomain("")
        setError("")

      } else if (response.response.data.message) {
        setError(response.response.data.message);
      } else {
        setError(ErrorMessages.FAILED_TO_SAVE);


      }
    } catch (e) {
      setError(ErrorMessages.FAILED_TO_SAVE);
    }
  }

  const updateDomain = async () => {
    if (domain.domain_name === "") {
      setErrorMessage(ErrorMessages.DOMAIN_NAME_MISSING);
      return;
    }
    const response = await updateDomainData({ domainId: domain?.id, domainName: domain?.domain_name });
    if (response.data) {
      setDomain({ ...domain, id: response.data.domain.id, domain_name: response.data.domain.domain_name })
      showSuccessToast(messages.DOMAIN_UPDATE_SUCCESS);
      setEditState(false)
      setErrorMessage("");
    }
    else if (response?.response?.data?.message) {
      setErrorMessage(response?.response?.data?.message);

    }
    else {
      setErrorMessage(ErrorMessages.FAILED_TO_SAVE);

    }
  };


  return (
    <>
      <div className="skilllist-grid-view">
        {Domain?.total_count === 0 ? " No Domain(s) Found" : null}
        <div className="skilllist-layout">
{     userManagedRole? null: <div className="layout fr-5 addCategoryWrap">
            <button className="category-item add" onClick={handleAddModal}>
              <span className="title">Add Domain</span>
              <span>
                <svg className="svg-icon plus-icon">
                  <use href="#plusIcon">
                    <title>Add Icon</title>
                  </use>
                </svg>
              </span>
            </button>
          </div>
}          <div className="layout fr-5">
            {Domain?.domains?.map((domainItem: any, domainIndex: number) => (
              <button className="category-item-set" key={domainIndex} onClick={() => handleBoxModal(domainItem)}>

                <div className="category-item-content">
                  <div className="skillTitle"
                    {...(isTooltipActive[domainIndex] && {
                      "title": `${domainItem.domain_name}`,
                    })}
                    key={domainIndex}
                    title={domainItem.domain_name.length > 30 ? domainItem.domain_name : ''}
                  >
                    {" "}
                    <h6 className="title">{domainItem.domain_name}
                    </h6>
                  </div>
                  <div className="count">
                    <span className="number">{domainItem.sub_domain_count}</span> Sub Domain added
                  </div>
                </div>
                <div className="folder-bg">
                  <svg className="svg-icon closed-folder-icon" preserveAspectRatio="xMinYMin meet">
                    <use href="#closedFolder">
                      <title>Folder Icon</title>
                    </use>
                  </svg>
                </div>
              </button>
            ))}
          </div>
          <div className="pagination-wrapper">
            <SkillPagination
              itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
              totalItems={Domain?.total_count ?? 0}
              onPageChange={(page) => {
                const offset = (page - 1) * PAGINATION_ITEMS_PER_PAGE;
                setCategoryListPaginationOffset(offset);
              }}
            />
          </div>
        </div>
      </div>

      {openSubDomain && (
        <Modal
          title="Add Domain"
          size="sm"
          handleClose={() => handleAddModal()}
          isBackArrow={false}
          placementFull="placement-full"
          withEditBtn={false}
          hidefooter={false}
          secondaryButton="Cancel"
          primaryButton={"Save"}

          onProceed={SaveDomain}
          onCancel={handleAddModal}
        >
          <AddDomainModal onChange={(domain) => {
            setError("");
            setNewDomain(domain);
          }}
            value={newDomain}
            error={error}
          ></AddDomainModal>
        </Modal>
      )}

      {subDomainState && <AdminModal
        title={domain?.domain_name}
        subTitleCount={domain?.sub_domain_count}
        subTitle="sub domains"
        categoryModal={true}
        logo={true}
        size=""
        handleClose={() => closeSkillModal()}
        isBackArrow={true}
        placementFull="placement-full"
        headerWrapper=""
        withEditBtn={false}
        hidefooter={true}
        isHeaderBottom={false}
        showClose={true}
        headerState={headerState}
        onProceed={updateDomain}
        setCategory={setDomain}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        category={domain}
        editState={editState}
        setEditState={setEditState}
      >
        <AddSubDomainDataModal setheaderState={setheaderState}
          editModalState={editModalState}
          setEditModalState={setEditModalState}
          data={domainData}
          setCategory={setDomain}
          category={domain}
          loading={loading}
          setLoading={setLoading}
        ></AddSubDomainDataModal>
      </AdminModal>}

      {domainState && <AdminModal
        title={domain?.domain_name}
        subTitleCount={domain?.sub_domain_count}
        subTitle="sub domains"
        categoryModal={true}
        logo={true}
        size=""
        handleClose={() => handleAddModal()}
        isBackArrow={true}
        placementFull="placement-full"
        headerWrapper=""
        withEditBtn={false}
        hidefooter={true}
        isHeaderBottom={false}
        showClose={true}
        headerState={true}
        onProceed={updateDomain}
        setCategory={setDomain}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        category={domain}
        editState={editState}
        setEditState={setEditState}
      >
        <DomainListingModal data={domain}
          setDomain={setDomain}
          domain={domain}
        ></DomainListingModal>
      </AdminModal>}
    </>
  );
};
export default DomainLisiting;
