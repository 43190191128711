import { useEffect } from "react";
import { clearAllStores } from "store";

export function LogoutCallback() {
  useEffect(() => {
    async function clear() {
      clearAllStores();
      window.location.href = window.location.origin;
    }
    clear();
  }, []);
  return (
    <div >
      
    </div>
  );
}
