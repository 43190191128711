import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";


export async function getUserViewById(userId:string|number|undefined | null):Promise<UserViewId|null> {
  const response = await apiClient.get(urls.UserViewById(userId));
  if (response && response.data && response.data.data)
    return response.data.data;
  return null;
}

export function useGetUserViewById(userId:string|number|undefined | null):UseQueryResult<UserViewId> {
  return useQuery(["GetUserViewById", userId], async () => {
    return await getUserViewById(userId??0);
  },{
    enabled: userId !== undefined
  });
}
