import React, { useState, useEffect, useRef } from "react";
import "./certificationGridWrapper.scss";
import { Link } from "react-router-dom";
import CertificateUserListing from "./CertificateUserListing";
import AdminModal from "components/common-components/AdminModel";
import { useGetAdminCertifications } from "api/admin/useGetAdminCertifications";
import { useAdminStore } from "store/adminStore";

import { useExportCertificationUsers } from "api/admin/useExportCertificationUsers";
import { PAGINATION_ITEMS_PER_PAGE } from "utils/constants";
import SkillPagination from "components/common-components/SkillPagination";
import { useUserStore } from "store/useUserStore";
interface Props {
  addButtonText?: string;
  handleAdd?: any;
  editMode?:boolean;
  onEditClicked:(certification:CertificationHolderItem)=>void;
}
const CertificationsGridView: React.FC<Props> = ({
  addButtonText, handleAdd,editMode,onEditClicked
}) => {
  const {selectedCerfifcation,setSelectedCertification,setCertificationPaginationOffset,setInitiateCertificationExportById,setCertificationListPaginationOffset}=useAdminStore();
  const {data:adminCertifications} = useGetAdminCertifications();
  const [showModal, setShowModal] = useState(false);
  const [SkillCategory, setSkillCategory] = useState('');
  const {data,refetch}= useExportCertificationUsers();
  const [count, setCount] = useState(1);
  const { userManagedRole} = useUserStore();
  const handleModal = (SkillCategory:string) => {
    setShowModal(!showModal);
    setSkillCategory(SkillCategory);
  };
  const downloadSkillReport = ()=>{
    setInitiateCertificationExportById(true);
    refetch();
    if(data){
      setInitiateCertificationExportById(false);
    }
  }
    //scrollbar condition
    const parentRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      if (parentRef.current) {
        parentRef.current.scrollTop = 0;
      }
     
    }, [count]);
  if(adminCertifications && adminCertifications.certifications && adminCertifications.certifications.length === 0){
    return (
      <div className="certification-grid-view">
            <div className="certification-layout" ref={parentRef}>
              <div
                className="layout fr-5 blue-shade">
                No data
              </div>
          </div>
        </div>
    )
  }
  return (
    <>
      <div className="certification-grid-view">
            <div className="certification-layout" ref={parentRef}>
              <div className="layout fr-5 blue-shade">
            {adminCertifications?.certifications.map((certificationItem, certificationIndex) => (
              <div className="certification-item-set" key={certificationIndex}>
                <Link to="#" className="certification-item-link" onClick={()=>{
                  if(certificationItem.user_count>0){
                    setSelectedCertification(certificationItem);
                    handleModal(SkillCategory);
                  }
                   }}>
                  <div className="certification-item-name">{certificationItem.certification_name}</div>
                      <div className="certification-num-edit-wrapper">
                        <div className="certification-count">
                          {certificationItem.user_count}
                          <span className="certification-added">{certificationItem.user_count <= 1 ? "Result":"Results"}</span>
                        </div>
                      </div>
                    </Link>
                    {editMode &&(
                      <button className="icon-only edit-icon-btn" onClick={()=>onEditClicked(certificationItem)}>
                      <svg className="svg-icon edit-icon">
                        <use href="#editIcon">
                          <title>Edit Icon</title>
                        </use>
                      </svg>
                    </button>
                    )}
                  </div>
                ))}
              </div>
        </div>
{   userManagedRole? null :     <div className="add-certification-footer-btn with-bg">
          <button className="app-btn primary icon-btn-text" onClick={handleAdd}>
            <svg className="svg-icon plus-icon">
              <use href="#plusIcon">
                <title>Add Icon</title>
              </use>
            </svg>
            <span className="button-text">{addButtonText}</span>
          </button>
        </div>
}          <SkillPagination
            itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
            totalItems={adminCertifications?.total??0}
            onPageChange={(page)=>{
              const offset = (page-1)* PAGINATION_ITEMS_PER_PAGE;
              setCertificationListPaginationOffset(offset);
              setCount(count+1)
            }}
        />
      </div>      
      {showModal ? (
        <AdminModal
          title={selectedCerfifcation?.certification_name}
          count={selectedCerfifcation?.user_count}
          size="extra-md"
          handleClose={() => {
            setSelectedCertification(undefined)
            setCertificationPaginationOffset(0);
            setShowModal(false)
          }}
          isBackArrow={false}
          placementFull="placement-full"
          headerWrapper="header-wrapper"
          withEditBtn={false}
          hidefooter={true}
          showClose={true}
          secondaryButton=""
          countText={selectedCerfifcation && selectedCerfifcation?.user_count <=1 ?"User":"Users"}
          onProceed={downloadSkillReport}
          from={"Certifications"}
        >
          <CertificateUserListing downloadReport={downloadSkillReport}/>
        </AdminModal>
      ) : (
        ""
      )}
    </>
  );
};
export default CertificationsGridView;
