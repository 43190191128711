import { INPUT_MAX_LENGTH } from "utils/constants";
import "./admin-skill-modal.scss";
import InputField from "components/common-components/InputField";

const AddCertificationModal = ({
    value,
    onChange,
    error
}:{
    value:string;
    onChange:(val:string)=>void;
    error:string;
}) => {
  return (
    <div className="app-modal-content sm add-skill">
      <InputField
        type="text"
        label="Name"
        placeholder="certification name"
        value={value}
        onChange={(e:any)=>{
            const {value}= e.target;
            onChange(value);
        }}
        error={error}
        maxLength={INPUT_MAX_LENGTH}
      />
    </div>
  );
};
export default AddCertificationModal;
