import { useMsal } from "@azure/msal-react";
import { useLoginUser } from "api/admin/useUserInfo";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "store/useUserStore";
import { loginRequest } from "utils/adLogin/authConfig";
import { wait } from "utils/userUtils";

export function Callback() {
  const { setAccessToken,setIdToken } = useUserStore();
  const { instance, accounts } = useMsal();
  const {mutateAsync:loginUser} = useLoginUser();

  const { userInfo } = useUserStore();
  const navigate = useNavigate();

  const requestTokenSilent = async () => {
    try {
      await instance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then((response) => {
          const accessToken = response.accessToken;
          const idToken = response.idToken;
          setIdToken(idToken);
          setAccessToken(accessToken);
        });
    } catch (e) {
      console.log("err", e);
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      await wait(500);
      await requestTokenSilent();
      await loginUser({user:''});
      if(userInfo && userInfo.user_identity.length > 0){
        if(userInfo.roles.includes('User')){
            navigate('home');
        }else if(userInfo.roles.includes('User')){
            navigate('admin');
        }
      }
      else{
      navigate("/");
      }

    })();
  }, [userInfo, navigate]);
  return (
    <div>
     
    </div>
  );
}
