import React, { useEffect, useState } from "react";
import "../../../../../admin/components/AdminSkillsWrapper/SkillsGridView/EditSkillModal/admin-skill-modal.scss";
import InputField from "components/common-components/InputField";

import CreateCategory from "feature/admin/components/CreateCategory";
import CategoryList from "feature/admin/components/CategoryList";
import { useSkillStore } from "store/useSkillStore";
import { useGetCategories } from "api/useGetCategories";
import { deepClone } from "utils/userUtils";
import { INPUT_MAX_LENGTH } from "utils/constants";

const ReviewSkillModal = (
  { skillUnderReview,
    skillName,
    errorMessage,
    setErrorMessage,
    setSkillName,
    setCategory
  }:{
    skillUnderReview:NotificationPayload;
    skillName: string;
    setCategory: (category: Category | undefined) => void;
    setSkillName: (name: string) => void;
    errorMessage: string;
    setErrorMessage: (error: string) => void;
 }) => {
  
  const [toggleCategory, setToggleCategory] = useState(false);
  const { data } = useGetCategories();
  const { categories } = useSkillStore();
  const getInitialCategories =()=> categories?.map((item: Category) => {
    return { ...item, checked: item.checked ?? false };
  });
  const [categoryList, setCategoryList] = useState<Category[] | undefined>(deepClone(getInitialCategories()));
  
  const handletoggleCategory = () => {
    setToggleCategory(true);
  };
  const closeToggleCategory = () => {
    setToggleCategory(false);
  };
  const unCheckCategories = (item: Category) => {
    if (categoryList) {
      const filteredList = categoryList.map((category) => ({
        ...category,
        checked: item.id === category.id,
      }));
      setCategoryList(filteredList);
    }
  };
  useEffect(()=>{
    setCategoryList(deepClone(getInitialCategories()));
  },[categories]);

  return (
    <div className="app-modal-content sm review-skill">
      <InputField 
        type="text"
        label="Name of the skill" 
        placeholder="Name of the skill" 
        value={skillName}
        onChange={(e:any)=> {
          setSkillName(e.target.value)
          setErrorMessage('')
        }}
        error={errorMessage}
        maxLength={INPUT_MAX_LENGTH}
      />
      <div className="input-span-text">Added By<span className="span-subset">{skillUnderReview.userName}</span></div>
      <div className={`skill-checkbox-search-wrapper ${toggleCategory? 'back-drop': ''}`}>
        <div className="skill-btn-text-layout">
          <div className="skill-header">
            <h4 className="skill-main-header">Category</h4>
            <span className="skill-sub-header">Select which categories the skill belong to </span>
          </div>
          <button className="app-btn secondary icon-btn-text"  onClick={handletoggleCategory}>
            <svg className="svg-icon plus-icon">
              <use href="#plusIcon">
                <title>Add Icon</title>
              </use>
            </svg>
            <span className="button-text">Create New category</span>
          </button>
        </div>
        {toggleCategory ? <CreateCategory closeToggleCategory={closeToggleCategory} /> : ""}
        <CategoryList
          categoryList={categoryList}
          onChange={(item) => {
            item.checked = !item.checked;
            setCategory(item.checked ? item : undefined);
            unCheckCategories(item);
          }}
        />
        
      </div>
    </div>
  );
};
export default ReviewSkillModal;
