import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";


export async function getAdminSkillUsers(skillId:number,skillPaginationOffset:number, sort: Option | undefined):Promise<UserAdminSkills|null> {
  const response = await apiClient.get(urls.AdminSkillUsers(skillId,skillPaginationOffset, sort));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetSkillUsers():UseQueryResult<UserAdminSkills> {
  const {selectedSkill,skillPaginationOffset, sortOptionSkills} = useAdminStore();   
  return useQuery(
    ["adminSkillUsers", selectedSkill, skillPaginationOffset, sortOptionSkills],
    async () => {
      return getAdminSkillUsers(
        selectedSkill?.skill_id ?? 0,
        skillPaginationOffset,
        sortOptionSkills
      );
    },
    {
      enabled: selectedSkill !== undefined && selectedSkill.skill_id !== 0,
      refetchOnWindowFocus: false,
    }
  );
}
