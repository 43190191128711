import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSkillStore } from "store/useSkillStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateUserSkill(
    droppedSkill:UserSkillItem
){
    const requestObject:UserSkillDropItem = {
        skill_rank: droppedSkill.skill_rank
      };
    const response = await apiClient.put(
        urls.deleteUserSkills(droppedSkill.id),
        requestObject,
    );
    return response.data;
}

export function useUpdateUserSkill():UseMutationResult<
    any,
    ApiError | {message: string},
    {userSkill:UserSkillItem}
    >{
        const {skillSearchKey,categories} = useSkillStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            userSkill,
        }: {
            userSkill: UserSkillItem;
        }) => {
            const result = await updateUserSkill(userSkill);
            queryClient.invalidateQueries(["GetAllUserSkills"]);
            queryClient.invalidateQueries(["categoryWiseSkills",skillSearchKey,categories]);
            return result;
        }
    );
    }