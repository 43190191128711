import React from "react";

const MicrosoftButton = ({
  buttonLabel,
  onClick,
}: {
  buttonLabel: string;
  onClick: () => void;
}) => {
  return (
    <button
      className="app-btn bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
      onClick={onClick}
    >
      <div className="microsoft-btn">
        <svg className="svg-icon microsoft-logo-icon">
          <use href="#microsoftLoginIcon">
            <title>Microsoft Logo Icon</title>
          </use>
        </svg>
        <span>{buttonLabel}</span>
      </div>
    </button>
  );
};
export default MicrosoftButton;
