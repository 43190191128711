
import React, { useMemo } from 'react';
import Checkbox from 'components/common-components/Checkbox';
import RadioButton from 'components/common-components/RadioButton';
import ReactSelectDropdown from 'components/common-components/ReactSelectDropdown';
import { useGetSkillList } from 'api/admin/useSkillList';
interface SkillType {
  primaryType?: string;
  secondaryType?: string;
  additionalType?: string;
  value: string;
  label: string;
  checked: boolean;
  name: string;
}
interface Props {
  isDefaultCheckedLevel?: null | boolean;
  handleChangeDesignation?: (selectedOptions: Option[]) => void;
  handleChangeSkill?: (selectedOptions: Option[]) => void;
  firstValue?: Option[];
  secondValue?: Option[];
  checkBoxOption?: any;
  radioBoxOption?: SkillType[];
  checkSelectedValueState?: Option[];
  radioSelectedValueState?: Option[];
  onChange?: (item: Option) => void;
  handleRadioChange?: (item: SkillType) => void;
  thirdValue?: Option[];
  handleChangeDepartment?: (selectedOptions: Option[]) => void;
  radiosetTempValue?: React.Dispatch<React.SetStateAction<Option[]>>;
  designationLists?: Option[];
  departmentLists?: Option[];
}

const SkillDataFilter: React.FC<Props> = ({
  handleChangeDesignation,
  handleChangeSkill,
  firstValue,
  secondValue,
  checkBoxOption,
  radioBoxOption,
  checkSelectedValueState,
  radioSelectedValueState,
  onChange,
  handleRadioChange,
  thirdValue,
  handleChangeDepartment,
  radiosetTempValue,
  designationLists,
  departmentLists
}) => {
  const { data: skillList } = useGetSkillList();

  const skillLists: Option[] = useMemo(() => skillList?.map((item: SkillList) => (
    {
      label: item.skill_name,
      value: item.skill_name,
    })) ?? [], [skillList]);

  const clearRadio = () => {
    radiosetTempValue?.([])
  }
  return (
    <div className="user-filter-wrapper">

      <ReactSelectDropdown
        options={skillLists}
        title={"Skill Name"}
        handleChange={handleChangeSkill}
        Value={firstValue}
        placeholder={"Select the skill name"}
        isDisabled={false}
      />
      <ReactSelectDropdown
        options={designationLists}
        title={"Designation"}
        handleChange={handleChangeDesignation}
        Value={secondValue}
        placeholder={"Select the designation"}
        isDisabled={false}

      />
      <ReactSelectDropdown
        options={departmentLists}
        title={"Department"}
        handleChange={handleChangeDepartment}
        Value={thirdValue}
        placeholder={"Select the department"}
        isDisabled={false}


      />
      <div className='skill-level-section'>
        <div className='skill-level-heading'>Skill Level</div>
        {checkBoxOption?.map((item: Option, key: number) => {
          const tick = checkSelectedValueState?.filter((level: Option) => level.value === item.value);
          return (
            <Checkbox key={key} label={item.label} name="filter-checkbox" value={tick?.length} onChange={() => { onChange?.(item) }} />
          );
        })}
      </div>
      <div className="skill-type-section">
        <div className="flex-jsb">
          <span className="skill-type-heading">Skill Type</span>
          <button className='app-btn link-btn' onClick={() => { clearRadio() }}>Clear</button>
        </div>
        {Object.values(
          (radioBoxOption ?? []).reduce((acc, item) => {
            if (!acc[item.name]) acc[item.name] = [];
            acc[item.name].push(item);
            return acc;
          }, {} as Record<string, SkillType[]>)
        ).map((group, index) => (
          <div className="skill-radiobutton-holder" key={index}>
            {group?.map((item, i) => (
              <span className={"radiobutton " + (radioSelectedValueState?.some((level: Option) => level.value === item.value) ? "blue-shade" : "")} key={i}>
                <RadioButton
                  label={item.label}
                  name={item.name}
                  defaultChecked={radioSelectedValueState?.filter((level: Option) => level.value === item.value).length}
                  onChange={() => handleRadioChange?.(item)}
                />
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SkillDataFilter;

