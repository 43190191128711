import AdminModal from "components/common-components/AdminModel";
import React, { useEffect, useState } from "react";
import AddCategoreyModal from "./AddCategoreyModal";
import AddSkillDataModal from "./SkillDataModal"
import Modal from "components/common-components/Modal";
import "./adminSkillListing.scss";
import SkillPagination from "components/common-components/SkillPagination";
import { useAdminStore } from "store/adminStore";
import { PAGINATION_ITEMS_PER_PAGE } from "utils/constants";
import { useGetAdminCategory } from "api/admin/useGetAllCategories";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { ErrorMessages, messages } from "utils/messageConstants";
import CategoryListingModal from "./CategoreyListingModal";
import { useSaveCategory } from "api/admin/useSaveCategory";
import { useUpdateCategory } from "api/admin/useUpdateCategory";
import { useUserStore } from "store/useUserStore";


interface Props {
  editMode?: any;
}
const SkillLisiting: React.FC<Props> = ({ editMode }) => {
  const { data: skills } = useGetAdminCategory();
  const [openSkillCategory, setOpenSkillCategory] = useState(false);
  const [headerState, setheaderState] = useState(false);
  const [categoryState, setCategoryState] = useState(false)
  const [skillState, setSkillState] = useState(false)
  const [editModalState, setEditModalState] = useState(false)
  const { mutateAsync: save, isLoading } = useSaveCategory();
  const { mutateAsync: updateCategoryData } = useUpdateCategory();
  const [category, setCategory] = useState<any>();
  const [error, setError] = useState("");
  const [categoryData, setCategoryData] = useState<any>();
  const [errorMessage, setErrorMessage] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [editState, setEditState] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isTooltipActive, setTooltipActive] = useState([false]);
  const { userManagedRole } = useUserStore();
  const refs: Array<any> = [];


  useEffect(() => {
    const tooltipVisible: boolean[] = [];
    refs.map((ref, i) => {
      const element = ref?.current as HTMLElement;
      if (element) {
        tooltipVisible.push(element.getBoundingClientRect().width + 1 < element.scrollWidth);
      }
    });

    if (JSON.stringify(tooltipVisible) !== JSON.stringify(isTooltipActive)) {
      setTooltipActive(tooltipVisible);
    }
  }, []);





  const handleAddModal = () => {
    setOpenSkillCategory(!openSkillCategory);
    setCategoryState(!setCategoryState);
    setNewCategory("")
    setError("")


  };
  const closeSkillModal = () => {
    setSkillState(!setSkillState);
    setheaderState(!setheaderState)
    setEditModalState(false)
    setErrorMessage("")
    setCategory({})
    setLoading(true)
    setEditState(false)

  }
  const handleBoxModal = (category: any) => {
    setSkillState(true)
    setCategoryData(category)
    setCategory(category)
  }

  const { setCategoryListPaginationOffset } = useAdminStore();
  const SaveCategory = async () => {
    try {
      if (isLoading) return;
      if (newCategory.trim() === "") {
        setError("Category name is mandatory");


        return;
      }
      const response = await save({ category_name: newCategory.trim() });
      if (response.data && response.data.category.id) {
        setCategory({ ...category, id: response.data.category.id, name: response.data.category.name, skill_count: 0 })
        showSuccessToast(messages.CATEGORY_SAVE_SUCCESS);
        setCategoryState(true);
        setNewCategory("")
        setError("")

      } else if (response.response.data.message) {
        setError(response.response.data.message);
      } else {
        setError(ErrorMessages.FAILED_TO_SAVE);


      }
    } catch (e) {
      setError(ErrorMessages.FAILED_TO_SAVE);
    }
  }

  const updateCategory = async () => {
    if (category.name === "") {
      setErrorMessage(ErrorMessages.CATEGORY_NAME_MISSING);
      return;
    }
    const response = await updateCategoryData({ categoryId: category?.id, categoryName: category?.name });
    if (response.data) {
      setCategory({ ...category, id: response.data.id, name: response.data.category_name })
      showSuccessToast(messages.CATEGORY_UPDATE_SUCCESS);
      setEditState(false)
      setErrorMessage("");
    }
    else if (response?.response?.data?.message) {
      setErrorMessage(response?.response?.data?.message);

    }
    else {
      setErrorMessage(ErrorMessages.FAILED_TO_SAVE);

    }
  };


  return (
    <>
      <div className="skilllist-grid-view">
        {skills?.categories?.total_count === 0 ? " No Categories(s) Found" : null}
        <div className="skilllist-layout">
          {userManagedRole ? null : <div className="layout fr-5 addCategoryWrap">
            <button className="category-item add" onClick={handleAddModal}>
              <span className="title">Add Category</span>
              <span>
                <svg className="svg-icon plus-icon">
                  <use href="#plusIcon">
                    <title>Add Icon</title>
                  </use>
                </svg>
              </span>
            </button>
          </div>}
          <div className="layout fr-5">
            {skills?.categories?.map((skillsItem: any, skillsIndex: number) => (
              <button className="category-item-set" key={skillsIndex} onClick={() => handleBoxModal(skillsItem)}>

                <div className="category-item-content">
                  <div className="skillTitle"
                    {...(isTooltipActive[skillsIndex] && {
                      "title": `${skillsItem.name}`,
                    })}
                    key={skillsIndex}
                    title={skillsItem.name.length > 30 ? skillsItem.name : ''}
                  >
                    {" "}
                    <h6 className="title">{skillsItem.name}
                    </h6>
                  </div>
                  <div className="count">
                    <span className="number">{skillsItem.skill_count}</span> Skills added
                  </div>
                </div>
                <div className="folder-bg">
                  <svg className="svg-icon closed-folder-icon" preserveAspectRatio="xMinYMin meet">
                    <use href="#closedFolder">
                      <title>Folder Icon</title>
                    </use>
                  </svg>
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="pagination-wrapper">
          <SkillPagination
            itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
            totalItems={skills?.total ?? 0}
            onPageChange={(page) => {
              const offset = (page - 1) * PAGINATION_ITEMS_PER_PAGE;
              setCategoryListPaginationOffset(offset);
            }}
          />
        </div>
      </div>

      {openSkillCategory && (
        <Modal
          title="Add Category"
          size="sm"
          handleClose={() => handleAddModal()}
          isBackArrow={false}
          placementFull="placement-full"
          withEditBtn={false}
          hidefooter={false}
          secondaryButton="Cancel"
          primaryButton={"Save"}

          onProceed={SaveCategory}
          onCancel={handleAddModal}
        >
          <AddCategoreyModal onChange={(category) => {
            setError("");
            setNewCategory(category);
          }}
            value={newCategory}
            error={error}
          ></AddCategoreyModal>
        </Modal>
      )}

      {/* box model */}
      {skillState && <AdminModal
        title={category?.name}
        subTitleCount={category?.skill_count}
        subTitle="skills"

        categoryModal={true}

        logo={true}
        size=""
        handleClose={() => closeSkillModal()}
        isBackArrow={true}
        placementFull="placement-full"
        headerWrapper=""
        withEditBtn={false}
        hidefooter={true}

        isHeaderBottom={false}
        showClose={true}
        headerState={headerState}
        onProceed={updateCategory}
        setCategory={setCategory}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        category={category}
        editState={editState}
        setEditState={setEditState}

      >
        <AddSkillDataModal setheaderState={setheaderState}
          editModalState={editModalState}
          setEditModalState={setEditModalState}
          data={categoryData}
          setCategory={setCategory}
          category={category}
          loading={loading}
          setLoading={setLoading}
        ></AddSkillDataModal>
      </AdminModal>}

      {categoryState && <AdminModal
        title={category?.name}
        subTitleCount={category?.skill_count}
        subTitle=" skills"
        categoryModal={true}
        logo={true}
        size=""
        handleClose={() => handleAddModal()}
        isBackArrow={true}
        placementFull="placement-full"
        headerWrapper=""
        withEditBtn={false}
        hidefooter={true}
        // secondaryButton="edit"
        isHeaderBottom={false}
        showClose={true}
        headerState={true}
        onProceed={updateCategory}
        setCategory={setCategory}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        category={category}
        editState={editState}
        setEditState={setEditState}



      >
        <CategoryListingModal data={category}
          setCategory={setCategory}
          category={category}
        ></CategoryListingModal>


      </AdminModal>}



    </>
  );
};
export default SkillLisiting;