import React, { useState, useEffect } from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import WelcomeSection from "./components/WelcomeSection";
import Certifications from "./components/Certifications";
import Domains from "./components/Domains";
import SkillsGrid from "./components/SkillsGrid";

import "./home.scss";
import SkillSet from "components/SkillSet";
import FilterSidepanel from "./components/FilterSidepanel";
import { useGetCategories } from "api/useGetCategories";

import { useSkillStore } from "store/useSkillStore";

const Home = () => {
  const { data } = useGetCategories();
  const {certifications, setSkillSearchKey, domains}= useSkillStore();
  const [filterToggle, setFilterToggle] = useState(false);

  const [tabSwitch, setTabSwitch] = useState("Skills");
  const [dragSkill, setDragSkill] = useState({});

  const [showAddSkills, setShowAddSkills] = useState(false);
  const [showSkillList, setShowSkillList] = useState(true);

  const [currentSkill, setCurrentSkill] = useState("");
  const [draggedSkills, setDraggedSkills] = useState<Object[]>([]);
  const [draggedSkillsPrimary, setDraggedSkillsPrimary] = useState<Object[]>([]);
  const [draggedSkillsSecondary, setDraggedSkillsSecondary] = useState<Object[]>([]);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [skillType, setSkillType] = useState("");

  const handleskillList = (skillTypeArg: string) => {
    setSkillType(skillTypeArg);
    setShowSkillList(false);
    setShowAddSkills(true);
  };
  const revertHandleList =()=>{
    setShowSkillList(true);
    setShowAddSkills(false);
    setSkillSearchKey("")
  }
  const handleAddSkills = (skillsItem: any) => {
    // setDragSkill(skillsItem);
    // setCurrentSkill(skillsItem.id);
    // switch (skillType) {
    //   case 'primary':
    //     break;
    //   case 'secondary':
    //     break;
    //   case 'additional':
    //     setDraggedSkills((prevSkills: Object[]) => [dragSkill, ...prevSkills]);
    //     break;
    // }
    //test added========>
  };

  const handleTabSwitch = (handleTabSwitch: string) => {
    setTabSwitch(handleTabSwitch);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const isMobile = width <= 991;


  

  return (
    <div className="layout-wrapper">
      <div className={`home-left-panel ${showAddSkills ? "small-screen" : ""}`}>
        <Header />
        <div className="mobile-screen-welcome">
          <WelcomeSection filterToggle={filterToggle} setFilterToggle={setFilterToggle} />
        </div>
        <div className={`left-panel-body ${showAddSkills ? "toggle" : ""}`}>        
          
          <div className="app-tab-wrapper app-tab-horizontal">
            <nav>
              <ul>
                <li>
                  <button
                    className={`app-btn ${tabSwitch === "Skills" ? "active" : ""}`}
                    onClick={() => handleTabSwitch("Skills")}
                  >
                    Skills
                  </button>
                </li>
                <li>
                  <button
                    className={`app-btn ${tabSwitch === "Certifications" ? "active" : ""}`}
                    onClick={() => handleTabSwitch("Certifications")}
                  >
                    Certifications<span className="count">{certifications.length}</span>
                  </button>
                </li>
                {/* <li>
                  <button
                    className={`app-btn ${tabSwitch === "Domains" ? "active" : ""}`}
                    onClick={() => handleTabSwitch("Domains")}
                  >
                    Domains<span className="count">{domains.length}</span>
                  </button>
                </li> */}
              </ul>
            </nav>
          </div>
          <SkillsGrid
            handleskillList={handleskillList}
            tabSwitch={tabSwitch}
            dragSkill={dragSkill}
            currentSkill={currentSkill}
            setCurrentSkill={setCurrentSkill}
            draggedSkills={draggedSkills}
            setDraggedSkills={setDraggedSkills}
            draggedSkillsPrimary={draggedSkillsPrimary}
            setDraggedSkillsPrimary={setDraggedSkillsPrimary}
            draggedSkillsSecondary={draggedSkillsSecondary}
            setDraggedSkillsSecondary={setDraggedSkillsSecondary}
          />
<div className={isMobile ? (tabSwitch === "Skills" ? "home-mobile home-mobile-none" : "home-mobile") : "faq-accordion-wrapper domain-accordion-wrapper"}>
            <Certifications tabSwitch={tabSwitch} />
          {/* <Domains tabSwitch={tabSwitch} /> */}
          </div>
        </div>
      </div>
      <div className={`home-right-panel ${showSkillList ? "toggle" : ""}`}>
        <WelcomeSection filterToggle={filterToggle} setFilterToggle={setFilterToggle} />
        <SkillSet
          skillType={skillType}
          revertHandleList={revertHandleList}
          handleAddSkills={handleAddSkills}
          dragSkill={dragSkill}
          setDragSkill={setDragSkill}
          filterToggle={filterToggle}
          setFilterToggle={setFilterToggle}
        />
        <Footer />
      </div>
      <FilterSidepanel filterToggle={filterToggle} setFilterToggle={setFilterToggle} />
    </div>
  );
};

export default Home;
