import Checkbox from "components/common-components/Checkbox";
import { useAdminStore } from "store/adminStore";
import { useSkillStore } from "store/useSkillStore";

const CategoryList = ({
  categoryList,
  onChange,
}: {
  categoryList: Category[] | undefined;
  onChange: (item: Category) => void;
}) => {

  return (
    <div className="skill-checkbox-layout">
      {categoryList?.map((item) => (
        <Checkbox
          key={item.id}
          label={item.name}
          name="filter-check-box"
          value={item.checked}
          onChange={() => {
            onChange(item);
          }}
        />
      ))}
    </div>
  );
};
export default CategoryList;
