import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateSkill(
    skillId:number,
    skillName:string,
    categoryId:number,
){
    try{
    const requestObject = {
        skill_name: skillName,
        category_id: categoryId,
        is_active: true,
      }
    const response = await apiClient.put(
        urls.AdminSkillById(skillId),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useUpdateSkill():UseMutationResult<
    any,
    ApiError | {message: string},
    {skillId:number;skillName:string;categoryId:number}
    >{
        const {skillAdded,categories,businessUnits,sortOption,searchkey} = useAdminStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            skillId,
            skillName,
            categoryId,
        }: {
            skillId:number;
            skillName: string;
            categoryId:number;
        }) => {
            const result = await updateSkill(skillId,skillName, categoryId);
            queryClient.invalidateQueries(["AdminSkills",skillAdded,categories,businessUnits,sortOption,searchkey]);
            queryClient.invalidateQueries(["GetCategoryById"]);
            return result;
        }
    );
    }