import ConfirmDeleteCertification from "components/common-components/ConfirmDeleteCertification";
import ConfirmDeleteCustomSkill from "components/common-components/ConfirmDeleteCustomSkill";
import ConfirmDeleteDomain from "components/common-components/ConfirmDeleteDomain";
import ConfirmDeleteModal from "components/common-components/ConfirmDeleteModal";
import ConfirmReplaceSkill from "components/common-components/ConfirmReplaceSkill";
import Modal from "components/common-components/Modal";
import { confirmAlert } from "react-confirm-alert";

export const customConfirmAlert = (customComponent: (onClose: () => void) => React.ReactNode) => {
    confirmAlert({
      overlayClassName: "bg-blend-lighten",
      willUnmount: () => {
        window.onpopstate = null;
        // const html = document.getElementsByTagName("html")[0];
        // html.classList.add("overflow-auto");
        // html.style.overflow = "auto";
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }: { onClose: () => void }) => {
        window.onpopstate = () => {
          onClose();
        };
        return customComponent(onClose);
      },
    });
  };

  export const confirmDeleteSkill = () => {
    return new Promise<boolean>((res) => {
      customConfirmAlert((onClose: () => void) => {
        return (
            <Modal
            size="sm"
            title="Confirm Delete"
            secondaryButton="Cancel"
            primaryButton="Confirm"
            placement="placement-bottom"
            handleClose={()=>{
              onClose();
              res(false);
            }}
            onProceed={()=>{
              onClose();
              res(true);
            }}
            onCancel = {()=>{
              onClose();
              res(false);
            }}
            >
            <ConfirmDeleteModal
            />
          </Modal>
        );
      });
    });
  };

  export const confirmDeleteCustomSkill = () => {
    return new Promise<boolean>((res) => {
      customConfirmAlert((onClose: () => void) => {
        return (
            <Modal
            size="sm"
            title="Confirm Delete"
            secondaryButton="Cancel"
            primaryButton="Confirm"
            placement="placement-bottom"
            handleClose={()=>{
              onClose();
              res(false);
            }}
            onProceed={()=>{
              onClose();
              res(true);
            }}
            onCancel = {()=>{
              onClose();
              res(false);
            }}
            >
            <ConfirmDeleteCustomSkill/>
            
          </Modal>
        );
      });
    });
  };

  export const confirmDeleteCertification = () => {
    return new Promise<boolean>((res) => {
      customConfirmAlert((onClose: () => void) => {
        return (
            <Modal
            size="sm"
            title="Confirm Delete"
            secondaryButton="Cancel"
            primaryButton="Confirm"
            placement="placement-bottom"
            handleClose={()=>{
              onClose();
              res(false);
            }}
            onProceed={()=>{
              onClose();
              res(true);
            }}
            onCancel = {()=>{
              onClose();
              res(false);
            }}
            >
            <ConfirmDeleteCertification/>
            
          </Modal>
        );
      });
    });
  };

  export const confirmReplaceSkill = () => {
    return new Promise<boolean>((res) => {
      customConfirmAlert((onClose: () => void) => {
        return (
            <Modal
            size="sm"
            title="Confirm Replace Skill"
            secondaryButton="Cancel"
            primaryButton="Confirm"
            handleClose={()=>{
              onClose();
              res(false);
            }}
            onProceed={()=>{
              onClose();
              res(true);
            }}
            onCancel = {()=>{
              onClose();
              res(false);
            }}
            >
            <ConfirmReplaceSkill/>
            
          </Modal>
        );
      });
    });
  };

  export const confirmDeleteDomain = () => {
    return new Promise<boolean>((res) => {
      customConfirmAlert((onClose: () => void) => {
        return (
            <Modal
            size="sm"
            title="Confirm Delete"
            secondaryButton="Cancel"
            primaryButton="Confirm"
            placement="placement-bottom"
            handleClose={()=>{
              onClose();
              res(false);
            }}
            onProceed={()=>{
              onClose();
              res(true);
            }}
            onCancel = {()=>{
              onClose();
              res(false);
            }}
            >
            <ConfirmDeleteDomain/>
            
          </Modal>
        );
      });
    });
  };

