import React, { useEffect, useState } from "react";
import "./certifications.scss";
import Modal from "components/common-components/Modal";
import AddCertification from "./AddCertification";
import ViewCertification from "./ViewCertification";
import { useGetUserCertifications } from "api/user/useGetUserCertificates";
import { getFormattedDate } from "utils/dateUtil";
import { useSaveCertification } from "api/user/useSaveCertifcation";
import { getSelectedCertification, validateCertificate } from "./helper";
import { useSkillStore } from "store/useSkillStore";
import { useUpdateCertification } from "api/user/useUpdateCertificate";
import { useDeleteCertification } from "api/user/useDeleteCertification";
import { confirmDeleteCertification, confirmDeleteDomain } from "utils/confirmModals";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { messages } from "utils/messageConstants";
import PageLoader from "components/common-components/PageLoader";
interface Props {
  tabSwitch: string;
}
const Certifications: React.FC<Props> = ({ tabSwitch }) => {
  const { data: certifications } = useGetUserCertifications();
  const { mutateAsync: saveCertificate } = useSaveCertification();
  const { mutateAsync: updateCertificate } = useUpdateCertification();
  const { mutateAsync: deleteCertificate } = useDeleteCertification();
  const {
    certification,
    certificationDate,
    setCertification,
    setCertificationDate,
    clearCertification,
  } = useSkillStore();


  const [showModal, setShowModal] = useState(false);
  const [viewCertificate, setViewCertificate] = useState(false);
  const [editCertification, setEditCertification] = useState(false);
  const [certificationError, setCertificationError] = useState<CertificationError>(
    {} as CertificationError
  );

  const showResponseToast = (response: any) => {
    if (response.data) {
      showSuccessToast(messages.CERTIFICAION_SAVE_SUCCESS);
      setShowModal(false);
      setEditCertification(false);
      onCloseModal();
    } else if (response.response.data.message) {
      showErrorToast(response.response.data.message);
    } else {
      showErrorToast(messages.CERTIFICAION_SAVE_FAILED);
    }
  };

  const saveCertification = async () => {
    const [certificationError, isValid] = validateCertificate(certification, certificationDate);
    setCertificationError(certificationError);
    if (isValid) {
      if (editCertification) {
        const response = await updateCertificate({
          certification,
          certification_date: certificationDate,
        });
        showResponseToast(response);
      } else {
        const response = await saveCertificate({
          certification,
          certification_date: certificationDate,
        });
        showResponseToast(response);
      }

    }
  };

  const onCloseModal = () => {
    clearCertification();
    setShowModal(false);
  };

  const handleModal = () => {
    setEditCertification(false);
    setCertificationError({} as CertificationError);
    clearCertification();
    setShowModal((prev) => !prev);
  };

  const onViewCertification = () => {
    const [certificate, certificateDate] = getSelectedCertification(certification);
    setCertification(certificate);
    setCertificationDate(certificateDate);
    setViewCertificate(false);
    setEditCertification(true);
    setShowModal(true);
  };

  const onEditCertification = (certification: Certification) => {
    const [certificate, certificateDate] = getSelectedCertification(certification);
    setCertification(certificate);
    setCertificationDate(certificateDate);
    setEditCertification(true);
    setShowModal(true);
  };

  const onDeleteCertification = async (certification: Certification) => {
    const confirm = await confirmDeleteCertification();
    if (confirm) {
      await deleteCertificate({ certificationId: certification?.id ?? 0 });
    }
  };

  return (
    <>
      <div className={`certification-wrapper ${tabSwitch === "Certifications" ? "show-this" : ""}`}>
        <div className="certification-item-layout">
          {certifications && certifications.length == 0 ? (
            <div className="no-data-content">
              <p className="no-content-message">No certifications added</p>
            </div>
          ) : null}
          {certifications?.map((item, index) => (
            <CertificationItem
              key={index}
              certification={item}
              onClick={(certification: Certification) => {
                setCertification(certification);
                setViewCertificate(true);
              }}
              onDelete={async (certification: Certification) => {
                await onDeleteCertification(certification);
              }}
              onEdit={(certification: Certification) => {
                setCertification(certification);
                onEditCertification(certification);
              }}
            />
          ))}
        </div>

        <div className="certificate-footer-btn">
          <button className="app-btn icon-btn-text" onClick={handleModal}>
            <svg className="svg-icon plus-icon">
              <use href="#plusIcon">
                <title>Add Icon</title>
              </use>
            </svg>
            <span className="button-text">Add New Certification</span>
          </button>
        </div>
      </div>
      <div className="accordion-section section-header faq-header">
        <input type="checkbox" className="accordion-radio" id="faq1" name="faq-accordion-group" />
        <label className="accordion-title" htmlFor="faq1">
          <div className="label-header certification-wrapper">
            <div className="certificate-header">
              <h5 className="page-header">
                Certifications <span className="status-count">{certifications?.length ?? 0}</span>
              </h5>
              <button className="app-btn add-new-icon-btn" onClick={handleModal}>

                <svg className="svg-icon plus-icon">
                  <use href="#plusIcon">
                    <title>Add Icon</title>
                  </use>
                </svg>
                <span className="button-text">Add New</span>
              </button>

            </div>
            {/* <span className="app-btn accordion-arrow-icon dropdown-arrow-icon">
                <svg className="svg-icon dropdown-icon">
                  <use href="#dropdownArrowIcon"></use>
                </svg>
              </span> */}
          </div>
        </label>
        {/* <div className="accordion-content"> */}
        <div>
          <div className="certification-item-layout">
            {certifications && certifications.length == 0 ? (
              <div className="no-data-content">
                <p className="no-content-message">No certifications added</p>
              </div>
            ) : null}
            {certifications?.map((item, index) => (
              <CertificationItem
                key={index}
                certification={item}
                onClick={(certification: Certification) => {
                  setCertification(certification);
                  setViewCertificate(true);
                }}
                onDelete={async (certification: Certification) => {
                  await onDeleteCertification(certification);
                }}
                onEdit={(certification: Certification) => {
                  setCertification(certification);
                  onEditCertification(certification);
                }}
              />
            ))}
          </div>

        </div>
      </div>
      {showModal && (
        <>
          <Modal
            title={editCertification ? "Edit Certification" : "Add Certification"}
            primaryButton={editCertification ? "Save" : "Add"}
            secondaryButton="Cancel"
            handleClose={onCloseModal}
            onCancel={onCloseModal}
            isBackArrow={true}
            placementFull="placement-full"
            withEditBtn={false}
            hidefooter={false}
            footerClass="modal-footer-zindex"
            onProceed={async () => {
              await saveCertification();
            }}
          >
            <AddCertification
              certificationError={certificationError}
              setCertificationError={setCertificationError}
              isEdit={editCertification}
            />
            {/* <PageLoader /> */}
          </Modal>
        </>
      )}
      {viewCertificate && (
        <Modal
          title="Certification"
          primaryButton="Edit"
          handleClose={() => setViewCertificate(false)}
          isBackArrow={true}
          placementFull="placement-full"
          withEditBtn={true}
          hidefooter={true}
          onProceed={onViewCertification}
        >
          <ViewCertification certificate={certification} />
        </Modal>
      )}
    </>
  );
};

const CertificationItem = ({
  certification,
  onClick,
  onDelete,
  onEdit,
}: {
  certification: Certification;
  onClick: (certification: Certification) => void;
  onDelete: (certification: Certification) => void;
  onEdit: (certification: Certification) => void;
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const modalRef = React.useRef(null);
  useEffect(() => {
    const handleEscapeKeyPress = (event: any) => {
      if (event.key === "Escape") {
        setShowOptions(false);
      }
    };

    const handleClickOutsideModal = (event: any) => {
      if (modalRef.current && !(modalRef.current as HTMLElement).contains(event.target)) {
        setShowOptions(false);
      }
    };

    if (showOptions) {
      document.addEventListener("keydown", handleEscapeKeyPress);
      document.addEventListener("click", handleClickOutsideModal);
    }

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
      document.removeEventListener("click", handleClickOutsideModal);
    };
  }, [showOptions]);

  return (
    <div className="certification-list-wrapper">
      <div className="certification-left-section" onClick={() => onClick(certification)}>
        <div className="certification-title">{certification.certification_name}</div>
        <div className="certification-content">
          <div className="issused-by-name">
            Issued By: <span className="issue-name">{certification.issued_by}</span>
          </div>
          <CertificationDate certification={certification} />
        </div>
      </div>
      <div className="option-dropdown-wrapper active" ref={modalRef}>
        <button className="icon-only icon-option" onClick={() => setShowOptions((prev) => !prev)}>
          <span className="svg-wrapper">
            <svg className="svg-icon">
              <use href="#menuDotIcon"></use>
            </svg>
          </span>
        </button>
        {showOptions && (
          <div className="option-dropdown">
            <ul>
              <li>
                <button
                  className="app-btn link-btn"
                  onClick={() => {
                    setShowOptions(false);
                    onEdit(certification);
                  }}
                >
                  Edit
                </button>
              </li>
              <li>
                <button
                  className="app-btn link-btn"
                  onClick={() => {
                    setShowOptions(false);
                    onDelete(certification);
                  }}
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
const CertificationDate = ({ certification }: { certification: Certification }) => {
  if (certification.status === "Not Expirable" || certification.expiry_date == null) {
    return <p></p>;
  } else if (certification.status === "Expiring") {
    return (
      <>
        <div className="expiry-date-tag">
          <div className="issused-by-date">
            Expiry:{" "}
            <span className="issue-name">{getFormattedDate(certification.expiry_date ?? "")}</span>
          </div>
          <div className="app-tag expiry">
            <svg className="svg-icon expiry-icon">
              <use href="#expiryIcon"></use>
            </svg>
            Expiring soon
          </div>
        </div>
      </>
    );
  } else if (certification.status === "Expired") {
    return (
      <div className="expiry-warning">
        <svg className="svg-icon warning-icon">
          <use href="#warningIcon"></use>
        </svg>
        <span className="expired-name">Expired on</span>
        <span className="expiry-warning-date">
          {getFormattedDate(certification.expiry_date ?? "")}
        </span>
      </div>
    );
  } else {
    return (
      <div className="expiry-date-tag">
        <div className="issused-by-date">
          Expiry:{" "}
          <span className="issue-name">{getFormattedDate(certification.expiry_date ?? "")}</span>
        </div>
      </div>
    );
  }
};
export default Certifications;