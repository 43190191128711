import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";
import { handleDownload } from "utils/downloadFile";

export async function getAllDefaultUser(sort: Option | undefined):Promise<any> {
  const response = await apiClient.get(urls.exportDefaultUsers(sort));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useAllDefaultUsersExport():UseQueryResult<any> {
  const {initiateSkillExport,sortOptionDefaultSkills}= useAdminStore();
  return useQuery(
    ["AllDefaultUsersExport", sortOptionDefaultSkills],
    async () => {
      const result = await getAllDefaultUser(sortOptionDefaultSkills);
      if (result && result.file_url) {
        await handleDownload(result);
      }
      return result;
    },
    {
      enabled: initiateSkillExport === true,
    }
  );
}

