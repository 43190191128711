import React, { useState } from "react";
import "./skill-search.scss";
import InputField from "components/common-components/InputField";
import Modal from "components/common-components/Modal";
import AddCustom from "../Footer/AddCustom";
import { useSkillStore } from "store/useSkillStore";
import { useSaveCustomSkill } from "api/user/useSaveCustomSkill";
import { ErrorMessages, messages } from "utils/messageConstants";
import { showSuccessToast } from "utils/toastUtil";
interface Props {
  filterToggle: any;
  setFilterToggle: any;
  revertHandleList:()=>void;
}

const SkillSearch: React.FC<Props> = ({ filterToggle, setFilterToggle,revertHandleList }) => {
  const { mutateAsync: save,isLoading } = useSaveCustomSkill();
  const [customSkill, setCustomSkill] = useState<string>("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { skillSearchKey, setSkillSearchKey } = useSkillStore();
  const handleModal = () => {
    setCustomSkill("");
    setError("");
    setShowModal(!showModal);
  };
  const handleFilter = () => {
    setFilterToggle(!filterToggle);
  };

  const saveCustomSkill = async () => {
    try{
      if(isLoading) return;
      if (customSkill === "") {
        setError(ErrorMessages.SKILL_NAME_REQUIRED);
        return;
      }
      const response = await save({ customSkill: customSkill });
      if (response && response.data && response.data.id) {
        showSuccessToast(messages.SKILL_SAVE_SUCCESS);
        setShowModal(false);
      }else if(response.response.data.message){
        setError(response.response.data.message);
      }else {
        setError(ErrorMessages.FAILED_TO_SAVE);
      }
  }catch(e){
    setError(ErrorMessages.FAILED_TO_SAVE);
    setShowModal(false);
  }
  }

  return (
    <>
      <div className="skill-search-wrapper">
        <div className="skill-mbl-header">
          <div className="back-btn-header-wapper">
            <button className="icon-only back-arrow" onClick={revertHandleList} >
              <svg className="svg-icon back-arrow-icon">
                <use href="#backArrowIcon"></use>
              </svg>
            </button>
            <div className="page-header">Choose your Skills</div>
          </div>
          <button className="icon-only btn-icon-wrapper" onClick={handleModal}>
            <svg className="svg-icon plus-icon">
              <use href="#plusIcon"></use>
            </svg>
            <span className="btn-text">Custom Skill</span>
          </button>
        </div>
        <div className="search-filter-wrapper">
          <div className="search-field">
            <InputField
              type="text"
              label=""
              placeholder="Type and search for skill"
              value={skillSearchKey}
              onChange={(e: any) => setSkillSearchKey(e.target.value)}
            />
            <svg className="svg-icon search-icon">
              <use href="#searchIcon"></use>
            </svg>
          </div>
          <button className="icon-only filter-panel-icon" onClick={handleFilter}>
            <svg className="svg-icon filter-icon">
              <use href="#filterIcon">
                <title>Filter</title>
              </use>
            </svg>
          </button>
        </div>
      </div>
      {showModal ? (
        <Modal
          size="sm"
          title="Add Custom Skill"
          // secondaryButton="Cancel"
          primaryButton="Continue"
          footerMessage="This custom skill will be added to your profile after verification"
          messageIcon="infoIcon"
          btnLoaderIcon={isLoading}
          onProceed={saveCustomSkill}
          handleClose={() => {
            setShowModal(false);
          }}
          placement="placement-bottom"
          
        >
          <AddCustom 
            onChange={(skill) => {
              setError("");
              setCustomSkill(skill);
            }}
            value={customSkill}
            error={error}/>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default SkillSearch;
