import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useUserStore } from "store/useUserStore";


export async function getCertificationById(certificateId:string|number,userId:number):Promise<CertificationView|null> {
  const response = await apiClient.get(urls.certificationView(certificateId,userId));
  if (response && response.data && response.data.data)
    return response.data.data.certification;
  return null;
}

export function useGetCertificateById(certificateId:string|number|undefined):UseQueryResult<CertificationView> {
  const {userInfo} = useUserStore();
  return useQuery(["GetCertificationById"], async () => {
    return await getCertificationById(certificateId??0,userInfo.id);
  },{
    enabled: certificateId !== undefined
  });
}
