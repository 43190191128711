import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";

export async function getAdminDesignation():Promise<any|null> {
  const response = await apiClient.get(urls.DesignationList());
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetDesignation():UseQueryResult<any> {
  return useQuery(["AdminDesignation"], async () => {
    return await getAdminDesignation();
  },{
    refetchOnWindowFocus:false
  });
}
