import { useGetCertificateById } from "api/user/useGetCertificateById";
import "./viewCertification.scss";
import { getFormattedDate } from "utils/dateUtil";

const ViewCertification = ({ certificate }: { certificate: Certification }) => {
  const { data: certification } = useGetCertificateById(certificate?.id ?? 0);

  const getValue = (value:string|undefined)=>{
    return value && value.length > 0 ? value:"-";
  }
  const getDate=(date:string|undefined)=>{
    const expiryDate = getValue(date);
    if(date && expiryDate.length > 0){
      return getFormattedDate(expiryDate);
    }
    return "No Expiry";
  }
  const getTags = (certification:CertificationView|undefined)=>{
    if(certification && certification.certification_tags && certification.certification_tags.length > 0){
      return certification.certification_tags.map((item,index) => (
        <div key={index} className="tag-holder">{item.tag_name}</div>
      ));
   }else{
    return <div>-</div>;
   }
  }
  return (
    <div className="app-modal-content sm">
      <div className="layout-holder">
        <div className="layout-item">
          <h6 className="heading-type-1">Name of the certification</h6>
          <div className="description-type-1">{certification?.certification_name}</div>
        </div>
        <div className="layout-item">
          <h6 className="heading-type-1">Issued by</h6>
          <div className="description-type-1">{certification?.issued_by}</div>
        </div>
      </div>
      <div className="layout-holder fr-2">
        <div className="layout-item">
          <h6 className="heading-type-1">Issue date </h6>
          <div className="description-type-1">{getDate(certification?.issued_date)}</div>
        </div>
        <div className="layout-item">
          <h6 className="heading-type-1">Expiration date </h6>
          <div className="description-type-1">{getDate(certification?.expiry_date)}</div>
        </div>
      </div>
      <div className="layout-holder fr-2">
        <div className="layout-item">
          <h6 className="heading-type-1">Credential ID</h6>
          <div className="description-type-1">{getValue(certification?.credential_id)}</div>
        </div>
        <div className="layout-item">
          <h6 className="heading-type-1">Credential URL </h6>
          {getValue(certification?.credential_url)==='-'?(
            <p className="description-type-1">-</p>
          ):(
            <a href="#" className="description-type-1 link">{getValue(certification?.credential_url)}</a>
          )}
         
        </div>
      </div>
      <div className="layout-holder">
        <div className="layout-item">
          <h6 className="heading-type-1">Relevant Tags</h6>
          <div className="description-type-1">
            <div className="value-tag-holder">
              {/* {certification?.certification_tags?.map((item) => (
                <div className="tag-holder">{item.tag_name}</div>
              ))} */}
              {getTags(certification)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewCertification;
