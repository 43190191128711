import * as d3 from "d3";
import "./chart.scss";
 

export default class BubbleChart  {
  circles:any;
  element:any;
  tooltipFn:Function;
    constructor(element: any, circles: any[], tooltipFn: Function) {
    
      this.circles = circles;
      this.element = element;
      this.tooltipFn = tooltipFn;
      
      let countArray=circles.map(c=>c.count);
      let noZeroCountArray=countArray.filter((c)=>c!==0);
      let max = Math.max(...countArray);
      let min = Math.min(...noZeroCountArray);
      this.drawCircle(min,max);
    }

   
  drawCircle(min:number, max:number){
     d3.select(".g-container").remove();
       const maxRadius = 500;
       const minRadius = 100; 
       const circlesMax = Math.max(...this.circles.map((o:any) => o.count))
       const circlesMin = Math.min(...this.circles.map((o:any) => o.count))
       const radiusScale = d3.scaleLinear()
          .domain([Math.max(min,circlesMin), Math.min(max,circlesMax)]) 
          .range([minRadius, maxRadius]); 
          this.circles.sort((a: { count: number; }, b: { count: number; }) => b.count - a.count);

      const scaledCircles = this.circles.filter((e:any)=>{
                  if(e.count>=min && e.count<=max){
                      return true;
                  }
                  else{
                    return false;
                  }
                }).map((e: any) => { 
                  let c={
                    "name": e.name,
                    "r": radiusScale(e.count),
                    "count": e.count,
                    "expert_count":e.skill_rank_expert_count,
                    "intermediate_count": e.skill_rank_intermediate_count,
                    "beginner_count":e.skill_rank_beginner_count }
                
                    return c;
                });
   
       d3.packSiblings(scaledCircles);
      let g=d3.select( this.element)
            .append("g")
            .classed("g-container",true)
            .selectAll()
            .data(scaledCircles)
            .enter()
            .append("g")
            .classed("c-container", true)
            .attr('data-value', (d: any) => { return (d.count) })
           
       g.append('circle')
         .attr('cx', (d: any) => d.x)
         .attr('cy', (d: any) => d.y)
         .attr('r', (d: any) => { return (d.r) })
         .attr('data-value', (d: any) => { return (d.count) })
       
  
         .on("mouseover",  (e:any,d:any) =>{
              let clientRect =e.target.getBoundingClientRect();
              let tooltipVerticalPosition = Math.min(window.innerHeight - 75,
                                            Math.max(190,clientRect.top +clientRect.height / 2)
                                            );
      
      
                  if(clientRect.right + 180 > window.innerWidth){
                    this.tooltipFn(
                      window.innerWidth - clientRect.left ,
                      tooltipVerticalPosition ,
                        true,d,
                      true
                      );
                      }
                  else{
                    this.tooltipFn(
                    clientRect.right,
                    tooltipVerticalPosition,
                    true,d,
                    false
                  );
              }})
       
         .on("mouseout",  (e:any,d:any)=> {
                    let clientRect =e.target.getBoundingClientRect();
                    let tooltipVerticalPosition = Math.min(window.innerHeight - 75,
                                                  Math.max(190,clientRect.top +clientRect.height / 2)
                                                  );
            
                          if(clientRect.right + 180 > window.innerWidth){
                            this.tooltipFn(
                              window.innerWidth - clientRect.left ,
                              tooltipVerticalPosition ,
                              false,
                              d,
                              true
                            )}
                          else{
                            this.tooltipFn(
                              clientRect.right,
                              tooltipVerticalPosition,
                              false,
                              d,
                              false
                            );
                          };
              });;
   
           g.select("circle")
           .attr("class",(d:any)=>"circle c-"+Math.round(d3.scaleLinear().domain([1,500]).range([1,3])(d.r)));
   
           let htmlContainer = g.append('foreignObject')
           .classed('node-text', true)
           .attr('x',(d:any)=>d.x-d.r)
           .attr('y', (d: any) => d.y-d.r)
           .attr('width',(d:any)=>d.r*2)
           .attr('height',(d:any)=>d.r*2)
           .attr('font-size',(d:any)=>d.r/5)
           
           let text=htmlContainer.append('xhtml:div')
           .classed('node-text-container',true)
   
           text.append('xhtml:div')
           .attr('xmlns','http://www.w3.org/1999/xhtml')
           .attr('style',(d:any)=>`padding:${d.r/10}px`)
           .classed('node-skill',true)
           .text((d: any) => d.name)
           
   
           let countWrapper=text.append('xhmtml:div')
           .classed('count-wrapper',true)
   
           countWrapper.append('xhtml:div')
               .classed('count-text', true)
               .attr('style', (d: any) => `font-size:${d.r / 6}px`)  
    }
}