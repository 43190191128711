import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useUserStore } from "store/useUserStore";
import { useSkillStore } from "store/useSkillStore";


export async function getUserCertifications(userId:number):Promise<Array<Certification>|null> {
  try{
  const response = await apiClient.get(urls.certificationsList(userId));
  if (response && response.data && response.data.data)
    return response.data.data.certifications;
  }catch(e){
    return [] as Certification[];
  }
  return null;
}

export function useGetUserCertifications():UseQueryResult<Array<Certification>> {
  const {userInfo,accessToken} = useUserStore();
  const {setCertifications} = useSkillStore();
  const userId = userInfo.id;
  return useQuery(["GetAllUserCertifications",accessToken], async () => {
    const data= await getUserCertifications(userId);
    if(data!=null){
      setCertifications(data);
    }
    return data;
  },{
    refetchOnWindowFocus:false,
  });
}
