import React, { useEffect, useState } from 'react'
import"./skilldatamodal.scss"
import InputField from 'components/common-components/InputField';
import { useGetCategoryById } from 'api/admin/useCategoryById';
import PageLoader from 'components/common-components/PageLoader';
import { useSaveSkill } from 'api/admin/useSaveSkill';
import { ErrorMessages, messages } from "utils/messageConstants";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { INPUT_MAX_LENGTH } from 'utils/constants';
import { useUpdateSkill } from "api/admin/useUpdateSkill";
import { useUserStore } from "store/useUserStore";



  interface Props {
    setheaderState?:any;
    editModalState?:boolean;
    setEditModalState?:any;
    data?:any;
    setCategory?:any;
    category?:any;
    loading?: boolean;
    setLoading?: any;

  }
 
const AddSkillDataModal: React.FC<Props>  = ({setheaderState,editModalState, setEditModalState, data, setCategory,category,loading,setLoading}) => {
  const { data: Category, isLoading,refetch,isFetching} = useGetCategoryById(data?.id);
  const { mutateAsync: saveSkill } = useSaveSkill();
  const [skillName, setSkillName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editSkill, setEditSkill] = useState<Boolean>(false);
  const [openEditSkillIndex, setOpenEditSkillIndex] = useState<number | null>(null);
  const { mutateAsync: updateSkillData } = useUpdateSkill();
  const [editSkillName, setEditSkillName] = useState<any>()
  const [error, setError] = useState("");
  const { userManagedRole } = useUserStore();


  const handleEdit=()=>{
  setheaderState(true);
  setEditModalState(true)
  setLoading(false)
  }


  const handleSkillEdit = (skillsIndex: number,skillItem: any) => {
    setOpenEditSkillIndex(skillsIndex);
    setEditSkill(true);
    setEditSkillName(skillItem)
    setErrorMessage("")

  };

  const handleSkillClose = () => {
    setOpenEditSkillIndex(null);
    setEditSkill(false);
    setError("")
    setErrorMessage("")

  };

  const onSaveSkill = async () => {
    if (skillName.trim() === "") {
      setErrorMessage(ErrorMessages.SKILL_NAME_MISSING);
      return;
    }
      const response = await saveSkill({ skillName: skillName.trim(), categoryId: data?.id });
      if (response.data) {
        const updatedCategory = await refetch(); // Refetch category data
        setCategory({
          ...category ,
          skill_count: updatedCategory.data?.skill_count
        });
        showSuccessToast(messages.SKILL_SAVE_SUCCESS);
        setErrorMessage("");
        setSkillName("")
      } else if(response?.response?.data?.message){
        setErrorMessage(response?.response?.data?.message);
     }else{
      setErrorMessage(messages.SKILL_SAVE_DUPLICATE);
   }
 };

  const updateSkill = async()=>{
    if (editSkillName.skill_name.trim() === "") {
      setError(ErrorMessages.SKILL_NAME_MISSING);
      return;
    }
      const response = await updateSkillData({skillId: editSkillName?.skill_id, skillName: editSkillName.skill_name.trim(), categoryId: data?.id });
      if (response.data) {
        setEditSkillName({...editSkillName,skill_name: response.data.skill_name})
        showSuccessToast(messages.SKILL_UPDATED);
        setError("");
        setOpenEditSkillIndex(null);
        setEditSkill(false);
      } else if(response?.response?.data?.message){
        setError(response?.response?.data?.message);
     } 
          else {
        setError(messages.SKILL_SAVE_DUPLICATE);
      }
    
};

if (loading && (isLoading || isFetching)) {
  return <PageLoader />;
}
  return (
    
    <div className="app-modal-content sm  add-skill-data">
   <div className={editModalState?'skill-data-heading edit-mode': 'skill-data-heading'}>
    <div className={editModalState?'skill-data-heading-th1 th-close':'skill-data-heading-th1 th-open'}> 
                <div className='skill-data-title'>SKILL NAME</div>
                <div className='skill-data-subtitle'> USER COUNT</div>
   </div>
    <div className={editModalState?'skill-data-heading-th2 th-open':'skill-data-heading-th2 th-close'}>
                    <div className='skill-data-title'> SKILL Name</div>
                    <div className='skill-heading-edit-mode'>
                    <InputField
        type="text"
        value={skillName}
        placeholder="Add a new skill here"
        onChange={(e: any) => {
          setErrorMessage("");
          setSkillName(e.target.value);
        }}
        maxLength={INPUT_MAX_LENGTH}
        error={errorMessage}
      />
                          <button className='app-btn secondary addskillname-btn' title='add' onClick={onSaveSkill}>
                            <svg className="svg-icon plus-icon">
                              <use href="#plusIcon">
                                <title>Add Icon</title>
                              </use>
                            </svg>
                          </button>
                    </div>
       </div>
     </div>

      <div className={Category && Category.skills && Category.skills.length > 9 ? 'skill-data-main hasScroll' : 'skill-data-main'}>
      
         {Category?.skills?.map((skillItem, skillsIndex) => (
          <div className={editModalState?'skill-data-wrapper edit-mode':'skill-data-wrapper'}>            
                  <div className='skill-data-details' key={skillsIndex} >
                    {skillsIndex !== openEditSkillIndex?(                   
                       <div className='skill-data-details'>
                      <div className='skill-data-name'>{skillItem?.skill_name}</div>
                      <div className={editModalState?'skill-data-count th-close':'skill-data-count th-open'}> {skillItem?.user_count}</div>
                      <div className={editModalState || skillsIndex === openEditSkillIndex ?'skill-edit-btn th-open':'skill-edit-btn th-close'}>
                     <button className="app-btn icon-only-sm" title='edit' onClick={() => handleSkillEdit(skillsIndex,skillItem)}>
                                <svg className="svg-icon edit-icon">
                                  <use href="#editIcon">
                                    <title>Edit Icon</title>
                                  </use>
                                </svg>
                              </button>
                      </div>
                      </div>
):null}
                 </div>
                  <div className={editSkill && skillsIndex === openEditSkillIndex ?'skill-data-edit-wrapper th-open':'skill-data-edit-wrapper th-close'}>
                  <InputField type="text" value={editSkillName?.skill_name}
                                        onChange={(e:any)=>{
                                          setEditSkillName({...editSkillName,skill_name: e.target.value});
                                          setError("");
                                        }}
                                        maxLength={INPUT_MAX_LENGTH}
                                        error={error}
                                  ></InputField>
               <button className='app-btn icon-only-sm skildata-btn' title='close' onClick={handleSkillClose}>
                         <svg className="svg-icon close-icon">
                              <use href="#closeIcon">
                                <title>Close Icon</title>
                              </use>
                             </svg>
                      </button>
               <button className='app-btn icon-only-sm skildata-btn' title='add' onClick={updateSkill}>
                          <svg className="svg-icon plus-icon">
                              <use href="#tickIcon">
                                <title>Tick Icon</title>
                              </use>
                            </svg>
                      </button>
                  </div>
          </div>  ))}
     </div>
     {editModalState === false && userManagedRole === false?        
     <div className='skill-data-botttom'>
                   
                   <div className='modification-status-holder '>
                       <div className='skill-edit-btn th-open th-open'>
                                 <button className="app-btn secondary  edit-icon-btn " onClick={()=>{
                                   handleEdit();}}>
                                                 <svg className="svg-icon edit-icon">
                                                   <use href="#editIcon">
                                                     <title>Edit Icon</title>
                                                   </use>
                                                 </svg>
                                                 <span>Edit</span>
                               </button>
                         </div>
                   </div>
                  </div>
           :      
           <div className='skill-data-botttom'>        
          </div>
}
      
  </div>
  )
}

export default  AddSkillDataModal

