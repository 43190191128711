import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "routes";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "utils/adLogin/authConfig";
import { ReactQueryClientWrapper } from "utils/reactQueryHelper";
import Icons from "components/common-components/Icons";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  return (
    <ReactQueryClientWrapper>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Router />
          <ToastContainer />
        </BrowserRouter>
        <Icons />
      </MsalProvider>
    </ReactQueryClientWrapper>
  );
}

export default App;
