import React, { RefObject } from "react";
import "../../searchPanel.scss"
import { useAdminStore } from "store/adminStore";
import InputField from "components/common-components/InputField";
import { useUserStore } from "store/useUserStore";

interface Props {
  from?: string;
  currentTab?: string;
 toggleOptions ?: boolean;
  mobileCloseRef?: RefObject<HTMLDivElement>; 
  handleEditMode?: () => void;
  downloadCategoriesExport?: () => void;
  downloadDomainsExport?: () => void; 
  download?: () => void;
  handleOptionToggle?: any;
}

const MobileThreeDot: React.FC<Props> = ({
  from,
  currentTab,
  toggleOptions,
  mobileCloseRef,
  handleEditMode,
  downloadCategoriesExport,
  downloadDomainsExport,
  download,
  handleOptionToggle,
}) => {
  const {
    groupByCategory,
    groupByDomains,
    skillViewToggle,
  } = useAdminStore();
  const { userManagedRole } = useUserStore();

  return (
    <>
      <div
              className={`option-dropdown-wrapper ${toggleOptions ? "active" : ""}${from === "userData" || from === "emailLog"? "disable" : ""}`}
              ref={mobileCloseRef}
            >
              <button className="icon-only icon-option" onClick={handleOptionToggle}>
                <svg className="svg-icon menu-dot-icon">
                  <use href="#menuDotIcon"></use>
                </svg>
              </button>
              <div className="option-dropdown">
                <ul>
                  <li>
                    {userManagedRole ? null : groupByCategory || groupByDomains || from === "SubDomains" ? null : <button
                      className="app-btn link-btn"
                      disabled={skillViewToggle === "bubbleView" && currentTab === "Skills"}
                      onClick={handleEditMode}
                    >
                      <svg className="svg-icon edit-icon">
                        <use href="#editIcon">
                          <title>Edit Icon</title>
                        </use>
                      </svg>
                      <span>Edit</span>
                    </button>
                    }
                  </li>
                  <li>
                    {groupByCategory ?
                      (<button className="app-btn link-btn" onClick={downloadCategoriesExport}>
                        <svg className="svg-icon export-icon">
                          <use href="#downIcon">
                            <title>Download Icon</title>
                          </use>
                        </svg>
                        <span>Download</span>
                      </button>) :
                      (groupByDomains?
                        <button className="app-btn link-btn" onClick={downloadDomainsExport}>
                        <svg className="svg-icon export-icon">
                          <use href="#downIcon">
                            <title>Download Icon</title>
                          </use>
                        </svg>
                        <span>Download</span>
                      </button>
                      :<button className="app-btn link-btn" onClick={download}>
                        <svg className="svg-icon export-icon">
                          <use href="#downIcon">
                            <title>Download Icon</title>
                          </use>
                        </svg>
                        <span>Download</span>
                      </button>)}
                  </li>
                </ul>
              </div>
            </div>
    </>
  );
};
export default MobileThreeDot;
