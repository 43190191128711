import PageLoader from 'components/common-components/PageLoader';
import React, { useEffect } from 'react'
import "./userDataModal.scss";

interface Props {
  userView?: any;
  setLoading?: any;
  loading?: boolean
}
const UserDataModal: React.FC<Props>
  = ({ userView,setLoading,loading}) => {  


    useEffect(()=>{
      if(userView){
        setLoading(false)
      }
    },[userView])

    if (loading) {
      return <PageLoader />;
    }  
     
    return (
      <div className='user-modal-wrapper'>
        <div className='sub-section primary-skill-section'>
          <div className='sub-section-header'>Primary Skill</div>
          <div className='sub-section-skillholder'>
{   userView?.skill_info?.skills.Primary?         <div className='box-holder'>
              <span className='left-section' > {userView?.skill_info?.skills.Primary?`${userView?.skill_info?.skills.Primary[0]?.skill_name}`: 'NA'}</span><span className='right-section'>{userView?.skill_info?.skills.Primary?`${userView?.skill_info?.skills.Primary[0]?.skill_rank}`: 'NA'}</span>
            </div>: "No Data"
}          </div>
        </div>
        <div className=' sub-section secondary-skill-section'>
          <div className='sub-section-header'>Secondary Skill</div>
          <div className='sub-section-skillholder'>
{ userView?.skill_info?.skills.Secondary?           <div className='box-holder'>
              <span className='left-section' >{userView?.skill_info?.skills.Secondary?`${userView?.skill_info?.skills.Secondary[0]?.skill_name}`: 'NA'}</span><span className='right-section'>{userView?.skill_info?.skills.Secondary?`${userView?.skill_info?.skills.Secondary[0]?.skill_rank}`: 'NA'}</span>
            </div> : "No Data"
}          </div>
        </div>
        <div className=' sub-section additional-skill-section'>
          <div className='sub-section-header'>Additional Skills</div>
          <div className='sub-section-skillholder'>
          {userView?.skill_info?.skills.Additional?
               userView?.skill_info?.skills.Additional.map((item: any)=>{
                return(
                <div className='box-holder'>
                  <span className='left-section' > {item.skill_name}</span><span className='right-section'>{item.skill_rank}</span>
                </div>
               )})
        : "No Data"}
                      </div>
        </div>
        <div className='sub-section certification-section'>
        <div className='sub-section-header'>Certifications</div>
        <div className="certification-list-wrapper">
                   {userView?.certification_info?.certifications.length > 0?
               userView?.certification_info?.certifications.map((item: any)=>{
                return(
                  <div className='certification-holder'>
              <div className="certification-title">{item.certification_name}</div>
              <div className="certification-content">
                <div className="expiry-date-tag">
                {item.expiry_date}
                </div>
          </div> 
          </div>
          )})
        : "No Data"}
      </div>
        </div>
        {/* <div className='sub-section additional-domain-section'>
          <div className='sub-section-header'>Domains</div>
        <div className="domain-list-wrapper">
                   {userView?.domain_info?.domains.length > 0?
               userView?.domain_info?.domains.map((item: any)=>{
                return(
                  <div className='domain-holder'>
              <div className='sub-section-domainholder'>
<div className='domain-box-holder'>
  <div>
  <div className='domain-name' > {item.sub_domain_name}</div>
  <div className='sub-domain-name' > {item.domain_name}</div>
  </div>
              <div className='domain-wrapper'>
              <div className='domain-level'>
              {item.proficiency_level}
              </div>
              </div>
            </div>
        </div>
          </div>
          )})
        : "No Data"}
      </div>
        </div> */}

      </div>
    )
  }

export default UserDataModal

