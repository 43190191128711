import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";


export async function getAdminSkills(categories:string, businessUnits:string,sort:string,search:string,offset:number):Promise<AdminSkills|null> {
  const response = await apiClient.get(urls.adminSkills(categories,businessUnits,sort,search,offset));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetAdminSkills():UseQueryResult<AdminSkills> {
  const {skillAdded,categories,businessUnits,sortOption,searchkey,skillListPaginationOffset,setSortOption} = useAdminStore();
  const selectedCategories = categories.filter(item=>item.checked===true).map(item=>item.id).join(",");
  const selectedBusinessUnits = businessUnits.filter(item=>item.checked===true).map(item=>item.id).join(",");
  const sort= sortOption?.value ?? "";
  return useQuery(["AdminSkills",skillAdded,categories,businessUnits,sortOption,searchkey,skillListPaginationOffset], async () => {
    const categorySkills =  await getAdminSkills(selectedCategories,selectedBusinessUnits,sort,searchkey,skillListPaginationOffset);
    return categorySkills;
  },{
    refetchOnWindowFocus:false
  });
}
