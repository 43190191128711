import React, { useEffect, useMemo, useCallback } from 'react';
import { useAdminStore } from "store/adminStore";
import { filterOptions } from "utils/constants";
import { useGetDesignation } from 'api/admin/useDesignation';
import { useGetDepartment } from 'api/admin/useDepartment';
import SkillDataFilter from './SkillDataFilter';
import SelectBox from 'components/common-components/ReactSelect';
import DomainDataFilter from './DomainDataFilter';
import CertificationDataFilter from './CertificationDataFilter';
import "./userDataFilter.scss";

const UserDataFilter: React.FC<UserDataFilter> =({
  handleChangeDesignation, handleChangeSkill, firstValue, secondValue, checkBoxOption, radioBoxOption,
  checkSelectedValueState, radioSelectedValueState, onChange, handleRadioChange, thirdValue, handleChangeDepartment,
  radiosetTempValue, handleChangeCertificationName, handleChangeCertificationIssuer, handleChangeCertificationStatus,
  tempCertificationName, tempCertificationIssuer, tempCertificationStatus, tempNonInitiated, nonInitatedCheck,
  handleChangeDomainName, handleChangeSubDomainName, handleChangeDomainStatus, handleChangeMinDuration,
  handleChangeMaxDuration, handleChangeProfociencyLevel, tempNonInitiatedDomain, tempDomainName, tempSubDomainName,
  tempDomainStatus, tempMinDuration, tempMaxDuration, setTempCertificationIssuer, setTempCertificationName,
  setTempCertificationStatus, setTempUserDesignation, setTempDomainName, setTempDomainStatus,
  setTempNonInitiatedDomain, setTempSubDomainName, setTempMaxDuration, setTempMinDuration, setTempDepartment,
}) => {
  const { data: designations } = useGetDesignation();
  const { data: departmentList } = useGetDepartment();
  const {
    filterSelection, setFilterSelection, setFilterEnable, setDepartment, setUserDesignation, setCertificationIssuer,
    setCertificationName, setCertificationStatus, setSkillLevel, setSkillName, setSkillType, setFilterEnableIcon,
    setNonInitiated, setDomainName, setSubDomainName, setMaxDuration, setMinDuration, setDomainStatus,
    setNonInitatedDomain, setUserSearch,
  } = useAdminStore();

  const designationLists: Option[] = useMemo(() => designations?.designations.map((item: Designation) => (
    {
      label: item,
      value: item,
    })) ?? [], [designations]);

  const departmentLists: Option[] = useMemo(() => departmentList?.departments.map((item: DepartmentList) => (
    {
      label: item,
      value: item,
    })) ?? [], [departmentList]);

  const resetFilters = useCallback(() => {
    setFilterEnable(false);
    setFilterEnableIcon(false);
    setTempDepartment([]);
    setTempUserDesignation([]);
    setDepartment([]);
    setUserDesignation([]);
    setCertificationIssuer([]);
    setCertificationName([]);
    setCertificationStatus([]);
    setSkillLevel([]);
    setSkillName([]);
    setSkillType([]);
    setNonInitiated([]);
    setSubDomainName([]);
    setMaxDuration([]);
    setMinDuration([]);
    setDomainStatus([]);
    setNonInitatedDomain([]);
    setDomainName([]);
    setUserSearch('');
  }, [setFilterEnable, setFilterEnableIcon, setTempDepartment, setTempUserDesignation, setDepartment, setUserDesignation,
    setCertificationIssuer, setCertificationName, setCertificationStatus, setSkillLevel, setSkillName, setSkillType,
    setNonInitiated, setSubDomainName, setMaxDuration, setMinDuration, setDomainStatus, setNonInitatedDomain, setDomainName,
    setUserSearch]);

  useEffect(() => {
    resetFilters();
  }, [filterSelection, resetFilters]);

  const handleCategoryChange = (item: Option) => {
    resetFilters();
    setFilterSelection(item.value);
  };

  const commonProps = {
    handleChangeDesignation, secondValue, handleChangeDepartment, designationLists, departmentLists,
    nonInitatedCheck, setTempUserDesignation, setTempDepartment
  };

  return (
    <div className="user-filter-section">
      <div className='category-list'>
        <SelectBox
          label="Category"
          value={filterOptions.find(option => option.value === filterSelection)}
          name="category"
          options={filterOptions}
          classNamePrefix="react-select-box"
          isSearchable={false}
          onChange={handleCategoryChange}
        />
      </div>
      {filterSelection === "Certifications" && (
        <CertificationDataFilter
          {...commonProps}
          handleChangeCertificationName={handleChangeCertificationName}
          handleChangeCertificationIssuer={handleChangeCertificationIssuer}
          handleChangeCertificationStatus={handleChangeCertificationStatus}
          tempCertificationName={tempCertificationName}
          tempCertificationIssuer={tempCertificationIssuer}
          tempCertificationStatus={tempCertificationStatus}
          tempNonInitiated={tempNonInitiated}
          setTempCertificationIssuer={setTempCertificationIssuer}
          setTempCertificationName={setTempCertificationName}
          setTempCertificationStatus={setTempCertificationStatus}
        />
      )}
      {filterSelection === "Skills" && (
        <SkillDataFilter
          {...commonProps}
          handleChangeSkill={handleChangeSkill}
          firstValue={firstValue}
          checkBoxOption={checkBoxOption}
          radioBoxOption={radioBoxOption}
          checkSelectedValueState={checkSelectedValueState}
          radioSelectedValueState={radioSelectedValueState}
          onChange={onChange}
          handleRadioChange={handleRadioChange}
          thirdValue={thirdValue}
          radiosetTempValue={radiosetTempValue}
        />
      )}
      {/* {filterSelection === "Domains" && (
        <DomainDataFilter
          {...commonProps}
          handleRadioChange={handleRadioChange}
          handleChangeDomainName={handleChangeDomainName}
          handleChangeSubDomainName={handleChangeSubDomainName}
          handleChangeDomainStatus={handleChangeDomainStatus}
          handleChangeMinDuration={handleChangeMinDuration}
          handleChangeMaxDuration={handleChangeMaxDuration}
          handleChangeProfociencyLevel={handleChangeProfociencyLevel}
          tempNonInitiatedDomain={tempNonInitiatedDomain}
          tempDomainName={tempDomainName}
          tempSubDomainName={tempSubDomainName}
          tempDomainStatus={tempDomainStatus}
          tempMinDuration={tempMinDuration}
          tempMaxDuration={tempMaxDuration}
          setTempDomainName={setTempDomainName}
          setTempDomainStatus={setTempDomainStatus}
          setTempNonInitiatedDomain={setTempNonInitiatedDomain}
          setTempSubDomainName={setTempSubDomainName}
          setTempMaxDuration={setTempMaxDuration}
          setTempMinDuration={setTempMinDuration}
        />
      )} */}
    </div>
  );
};
export default UserDataFilter;

