import { STATUS_CODES } from "http";

export const SkillLevels: SkillLevel[] = [
  {
    level: 3,
    name: "Expert",
  },
  {
    level: 2,
    name: "Intermediate",
  },
  {
    level: 1,
    name: "Beginner",
  },
];

export const enum SkillType {
  Primary = "Primary",
  Secondary = "Secondary",
  Additional = "Additional",
}

export const enum SkillRank {
  Beginner = "Beginner",
  Intermediate = "Intermediate",
  Expert = "Expert",
}

export const Months: Option[] = [
  { value: "01", label: "Jan" },
  { value: "02", label: "Feb" },
  { value: "03", label: "Mar" },
  { value: "04", label: "Apr" },
  { value: "05", label: "May" },
  { value: "06", label: "Jun" },
  { value: "07", label: "Jul" },
  { value: "08", label: "Aug" },
  { value: "09", label: "Sep" },
  { value: "10", label: "Oct" },
  { value: "11", label: "Nov" },
  { value: "12", label: "Dec" },
];

export const NUMBEROFMONTHS: Option[] = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
];

export const NUMBEROFYEARS: Option[] = Array.from({ length: 51 }, (_, index) => ({
  value: index.toString(),
  label: index.toString(),
}));

export const Domain: Option[] = [{ value: "01", label: "Health" }];
export const subDomain: Option[] = [{ value: "01", label: "Enter the name of the sub domain" }];
export const proficiencyLevel: Option[] = [
  { value: "Beginner", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Expert", label: "Expert" },
];

export const CertificationStatus: Option[] = [
  { value: "Expired", label: "Expired" },
  { value: "Expiring", label: "Expiring" },
  { value: "Active", label: "Active" },
  { value: "Not Expirable", label: "Not Expirable" },
];

export const domainStatus: Option[] = [
  { value: "true", label: "Active" },
  { value: "false", label: "In Active" },
];

export const durationYears: Option[] = [{ value: "01", label: "No of years" }];
export const durationMonths: Option[] = [{ value: "01", label: "No of months" }];
export const status: Option[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const SortOptions: Option[] = [
  {
    label: "By Category",
    value: "category:desc",
  },
  {
    label: "A-Z",
    value: "skill_name:asc",
  },
  {
    label: "Z-A",
    value: "skill_name:desc",
  },
  {
    label: "Skill Level",
    value: "skill_level:desc",
  },
];

export const SortOptionsDomain: Option[] = [
  {
    label: "By Domain",
    value: "domain:desc",
  },
  {
    label: "A-Z",
    value: "sub_domain_name:asc",
  },
  {
    label: "Z-A",
    value: "sub_domain_name:desc",
  },
  {
    label: "Proficiency Level",
    value: "proficiency_level:desc",
  },
];

export const getMonth = (month: string) => Months.find((item) => item.value === month) ?? null;
export const getMonthNumber = (number_month: string) =>
  NUMBEROFMONTHS.find((item) => item.value === number_month) ?? null;
export const getProficiency = (proficiency: string) =>
  proficiencyLevel.find((item) => item.value === proficiency) ?? null;
export const getStatus = (statuses: string) =>
  status.find((item) => item.value === statuses) ?? null;
export const getYearNumber = (number_year: string) =>
  NUMBEROFYEARS.find((item) => item.value === number_year) ?? null;

export const skills: Option[] = [
  { value: "Cloud Solution Architect", label: "Cloud Solution Architect" },
  { value: "Cloud Specialist", label: "Cloud Specialist" },
  { value: "Cloud Developer", label: "Cloud Developer" },
  { value: "DevOps Specialist", label: "DevOps Specialist" },
  { value: "Cloud Administrator", label: "Cloud Administrator" },
  { value: "Cloud Security", label: "Cloud Security" },
  { value: "CEH", label: "CEH" },
  { value: "CCNP", label: "CCNP" },
  { value: "CCNA", label: "CCNA" },
];

export const CUSTOM_SKILL = "Added by you";

export const getDefaultCertification = (): Certification => ({
  certification_name: "",
  credential_id: "",
  credential_url: "",
  is_expirable: true,
  issued_by: "",
  tags: [],
  expiry_date: undefined,
  issued_date: undefined,
});

export const getDefaultCertificationDate = () => ({
  issueMonth: null,
  expiryMonth: null,
  expiryYear: "",
  issueYear: "",
});

export const DefaultCertification: Certification = {
  certification_name: "",
  credential_id: "",
  credential_url: "",
  is_expirable: true,
  issued_by: "",
  tags: [],
  expiry_date: undefined,
  issued_date: undefined,
};

export const DefaultCertificationDate: CertificationDate = {
  issueMonth: null,
  expiryMonth: null,
  expiryYear: "",
  issueYear: "",
};

export const getDefaultDomain = (): Domain => ({
  domain_name: "",
  sub_domain_name: "",
  proficiency_level: "",
  active: "",
  duration: undefined,
});

export const getDefaultDomainDate = () => ({
  durationMonth: null,
  durationYear: "",
});

export const DefaultDomain: Domain = {
  domain_name: "",
  sub_domain_name: "",
  proficiency_level: "",
  active: "",
  duration: undefined,
};

export const DefaultDomainDate: DomainDate = {
  durationMonth: null,
  durationYear: null,
};
export const PAGINATION_ITEMS_PER_PAGE = 50;
export const PAGINATION_ITEMS_QUERY_BUILDER_PER_PAGE = 10;
export const USER_TAB = "userTab";

export const INPUT_MAX_LENGTH = 71;
export const INPUT_MAX_LENGTH_SUBJECT = 150;

export const UserNotifications: UserNotifications[] = [
  {
    id: 2,
    message: "The skill you added has been approved",
    payload: {
      userId: 1,
      skillId: 16,
      subject: "User Added Skill",
      lastName: "M",
      firstName: "Sajid",
      skillName: "HTML",
      userEmail: "d",
      templateName: "user-added-skill",
      category: "Language",
    },
    status: "Sent",
    updated_at: "2023-05-20T12:45:01",
  },
  {
    id: 3,
    message: "The skill you added has been approved",
    payload: {
      userId: 1,
      skillId: 16,
      subject: "User Added Skill",
      lastName: "M",
      firstName: "Sajid",
      skillName: "Flutter",
      userEmail: "d",
      templateName: "user-added-skill",
      category: "Language",
    },
    status: "Sent",
    updated_at: "2023-05-20T07:45:01",
  },
  {
    id: 4,
    message: "The skill you added has been approved",
    payload: {
      userId: 1,
      skillId: 16,
      subject: "User Added Skill",
      lastName: "M",
      firstName: "Sajid",
      skillName: "Svelte",
      userEmail: "d",
      templateName: "user-added-skill",
      category: "Language",
    },
    status: "Sent",
    updated_at: "2023-05-18T17:45:01",
  },
  {
    id: 5,
    message: "The skill you added has been approved",
    payload: {
      userId: 1,
      skillId: 16,
      subject: "User Added Skill",
      lastName: "M",
      firstName: "Sajid",
      skillName: "React JS",
      userEmail: "d",
      templateName: "user-added-skill",
      category: "Language",
    },
    status: "Sent",
    updated_at: "2023-05-17T17:45:01",
  },
  {
    id: 6,
    message: "The skill you added has been approved",
    payload: {
      userId: 1,
      skillId: 16,
      subject: "User Added Skill",
      lastName: "M",
      firstName: "Sajid",
      skillName: "React Native",
      userEmail: "d",
      templateName: "user-added-skill",
      category: "Language",
    },
    status: "Sent",
    updated_at: "2023-05-16T17:45:01",
  },
];

export const RejectReasons = {
  IrrlevantSkill: "Irrelevant skill",
  LackOfEvidence: "Lack of evidence",
  OutdatedSkill: "Outdated skill",
};

export const UNAUTHORIZED = 401;

export const LOGIN_TOAST_ID = 1;

export const tooltipSkill = {
  name: "",
  count: 0,
  expertCount: 0,
  intermediateCount: 0,
  beginnerCount: 0,
};

export const View: Option[] = [
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

export const initialView: Option = {
  value: "25",
  label: "25",
};
export const filterOptions: Option[] = [
  { value: "Skills", label: "Skills" },
  { value: "Certifications", label: "Certifications" },
  // { value: "Domains", label: "Domains" },
];

export const initialFilterValue: Option = {
  value: "Skills",
  label: "Skills",
};

export const Sort: Option[] = [
  {
    label: "employee_id_asc",
    value: "employee_id:asc",
  },
  {
    label: "employee_id_desc",
    value: "employee_id:desc",
  },
  {
    label: "first_name_last_name_asc",
    value: "first_name_last_name:asc",
  },
  {
    label: "first_name_last_name_desc",
    value: "first_name_last_name:desc",
  },
  {
    label: "designation_asc",
    value: "designation:asc",
  },
  {
    label: "designation_desc",
    value: "designation:desc",
  },
  {
    label: "department_asc",
    value: "department:asc",
  },
  {
    label: "department_desc",
    value: "department:desc",
  },
  {
    label: "skills_count_asc",
    value: "skills_count:asc",
  },
  {
    label: "skills_count_desc",
    value: "skills_count:desc",
  },
  {
    label: "certifications_count_asc",
    value: "certifications_count:asc",
  },
  {
    label: "certifications_count_desc",
    value: "certifications_count:desc",
  },
  {
    label: "domains_count_asc",
    value: "domains_count:asc",
  },
  {
    label: "domains_count_desc",
    value: "domains_count:desc",
  },
  {
    label: "skill_name_asc",
    value: "skill_name:asc",
  },
  {
    label: "skill_name_desc",
    value: "skill_name:desc",
  },
  {
    label: "skill_type_asc",
    value: "skill_type:asc",
  },
  {
    label: "skill_type_desc",
    value: "skill_type:desc",
  },
  {
    label: "certification_name_asc",
    value: "certification_name:asc",
  },
  {
    label: "certification_name_desc",
    value: "certification_name:desc",
  },
  {
    label: "certification_issuer_asc",
    value: "certification_issuer:asc",
  },
  {
    label: "certification_issuer_desc",
    value: "certification_issuer:desc",
  },
  {
    label: "certification_status_asc",
    value: "certification_status:asc",
  },
  {
    label: "certification_status_desc",
    value: "certification_status:desc",
  },
  {
    label: "domain_asc",
    value: "domain:asc",
  },
  {
    label: "domain_desc",
    value: "domain:desc",
  },
  {
    label: "sub_domain_name_asc",
    value: "sub_domain_name:asc",
  },
  {
    label: "sub_domain_name_desc",
    value: "sub_domain_name:desc",
  },
  {
    label: "status_asc",
    value: "status:asc",
  },
  {
    label: "status_desc",
    value: "status:desc",
  },
  {
    label: "proficiency_level_asc",
    value: "proficiency_level:asc",
  },
  {
    label: "proficiency_level_desc",
    value: "proficiency_level:desc",
  },
  {
    label: "duration_asc",
    value: "duration:asc",
  },
  {
    label: "duration_desc",
    value: "duration:desc",
  },
  {
    label: "skill_level_asc",
    value: "skill_level:asc",
  },
  {
    label: "skill_level_desc",
    value: "skill_level:desc",
  },
  {
    label: "certification_expiry_asc",
    value: "certification_expiry:asc",
  },
  {
    label: "certification_expiry_desc",
    value: "certification_expiry:desc",
  },
  {
    label: "date_asc",
    value: "date:asc",
  },
  {
    label: "date_desc",
    value: "date:desc",
  },

];

export const SKILLLEVEL: Option[] = [
  {
    value: "Beginner",
    label: "Beginner",
    checked: false,
  },
  {
    value: "Intermediate",
    label: "Intermediate",
    checked: false,
  },
  {
    value: "Expert",
    label: "Expert",
    checked: false,
  },
];

export const NONINITIATED: Option[] = [
  {
    value: "Non Initiated",
    label: "Non Initiated",
    checked: false,
  },
];

export const ISVERIFIED = 1;

export const SKILLTYPE = [
  {
    value: "Primary",
    label: "Primary",
    checked: false,
    name: "PrimaryType",
  },
  {
    value: "Non-Primary",
    label: "Non-Primary",
    checked: false,
    name: "PrimaryType",
  },
  {
    value: "Secondary",
    label: "Secondary",
    checked: false,
    name: "SecondaryType",
  },
  {
    value: "Non-Secondary",
    label: "Non-Secondary",
    checked: false,
    name: "SecondaryType",
  },
  {
    value: "Additional",
    label: "Additional",
    checked: false,
    name: "AdditionalType",
  },
  {
    value: "Non-Additional",
    label: "Non-Additional",
    checked: false,
    name: "AdditionalType",
  },
];

export const SORTVALUEMOBILEDEFAULT = [
  {
    label: "ID",
    value: "Id",
  },
  {
    label: "Employee",
    value: "Employee",
  },
  {
    label: "Designation",
    value: "Designation",
  },
  {
    label: "Department",
    value: "Department",
  },
  {
    label: "Primary Skill",
    value: "Primary Skill",
  },
];

export const SORTBYMOBILE = [
  {
    label: "Ascending",
    value: "Ascending",
  },
  {
    label: "Descending",
    value: "Descending",
  },
];

export const SORTVALUEMOBILESKILLFILTER = [
  {
    label: "ID",
    value: "Id",
  },
  {
    label: "Employee",
    value: "Employee",
  },
  {
    label: "Designation",
    value: "Designation",
  },
  {
    label: "Department",
    value: "Department",
  },
  {
    label: "Skill Name",
    value: "Skill Name",
  },
  {
    label: "Skill Type",
    value: "Skill Type",
  },
];

export const SORTVALUEMOBILECERTIFICATIONFILTER = [
  {
    label: "ID",
    value: "Id",
  },
  {
    label: "Employee",
    value: "Employee",
  },
  {
    label: "Designation",
    value: "Designation",
  },
  {
    label: "Department",
    value: "Department",
  },
  {
    label: "Certification Name",
    value: "Certification Name",
  },
  {
    label: "Issued By",
    value: "Issued By",
  },
  {
    label: "Status",
    value: "Status",
  },
];

export const SORTVALUEMOBILEDOMAINFILTER = [
  {
    label: "ID",
    value: "Id",
  },
  {
    label: "Employee",
    value: "Employee",
  },
  {
    label: "Designation",
    value: "Designation",
  },
  {
    label: "Department",
    value: "Department",
  },
  {
    label: "Domain Name",
    value: "Domain Name",
  },
  {
    label: "Sub Domain Name",
    value: "Sub Domain Name",
  },
  {
    label: "Status",
    value: "Status",
  },
  {
    label: "Duration",
    value: "Duration",
  },
];

export const SORTVALUEMOBILEUSERSKILL = [
  {
    label: "Employee",
    value: "Employee",
  },
  {
    label: "Designation",
    value: "Designation",
  },
  {
    label: "Skill Type",
    value: "Skill Type",
  },
  {
    label: "Skill Level",
    value: "Skill Level",
  },
];

export const SORTVALUEMOBILEUSERCERTIFICATION = [
  {
    label: "Employee",
    value: "Employee",
  },
  {
    label: "Designation",
    value: "Designation",
  },
  {
    label: "Issued By",
    value: "Issued By",
  },
  {
    label: "Expiry",
    value: "Expiry",
  },
];

export const SORTVALUEMOBILEUSERDOMAIN = [
  {
    label: "Employee",
    value: "Employee",
  },
  {
    label: "Designation",
    value: "Designation",
  },
  {
    label: "Proficiency Level",
    value: "Proficiency Level",
  },
  {
    label: "Duration",
    value: "Duration",
  },
];

export const FILTER_SELECTION = {
  SKILLS: "Skills",
  CERTIFICATIONS: "Certifications",
  DOMAINS: "Domains",
};

export const DEFAULTUSERCOLUMN = [
  { header: "No", className: "col-no" },
  { header: "ID", className: "col-id", sortKey: "sortBy", index: 0 },
  { header: "Employee", className: "col-employee", sortKey: "sortByEmployee", index: 1 },
  { header: "Designation", className: "col-designation", sortKey: "sortByDesignation", index: 2 },
  { header: "Department", className: "col-department", sortKey: "sortByDepartment", index: 3 },
  { header: "Skill Count", className: "col-skillcount", sortKey: "sortBySkillCount", index: 4 },
  {
    header: "Certification Count",
    className: "col-certificationscount",
    sortKey: "sortByCertificationCount",
    index: 5,
  },
  // { header: "Domain Count", className: "col-domainscount", sortKey: "sortByDomainCount", index: 6 },
  { header: "Primary Skill", className: "col-skillname", sortKey: "sortBySkillname", index: 7 },
  { header: "Skill Level", className: "col-skilllevel" },
];

export const USERFILTERSKILLCOLUMN = [
  { header: "No", className: "col-no" },
  { header: "ID", className: "col-id", sortKey: "sortBy", index: 0 },
  { header: "Employee", className: "col-employee", sortKey: "sortByEmployee", index: 1 },
  { header: "Designation", className: "col-designation", sortKey: "sortByDesignation", index: 2 },
  { header: "Department", className: "col-department", sortKey: "sortByDepartment", index: 3 },
  { header: "Skill Name", className: "col-skillname", sortKey: "sortBySkillname", index: 7 },
  { header: "Skill Type", className: "col-skillType", sortKey: "sortBySkillType", index: 8 },
  { header: "Skill Level", className: "col-skilllevel" },
];

export const USERFILTERCERTIFICATIONCOLUMN = [
  { header: "No", className: "col-no" },
  { header: "ID", className: "col-id", sortKey: "sortBy", index: 0 },
  { header: "Employee", className: "col-employee", sortKey: "sortByEmployee", index: 1 },
  { header: "Designation", className: "col-designation", sortKey: "sortByDesignation", index: 2 },
  { header: "Department", className: "col-department", sortKey: "sortByDepartment", index: 3 },
  {
    header: "Certification Name",
    className: "col-certificationname",
    sortKey: "sortByCertificationName",
    index: 9,
  },
  {
    header: "Certifications Issuer",
    className: "col-certificationsissuer",
    sortKey: "sortByCertificationIssuer",
    index: 10,
  },
  {
    header: "Certifications Status",
    className: "col-certificationsstatus",
    sortKey: "sortByCertificationStatus",
    index: 11,
  },
];

export const USERFILTERDOMAINCOLUMN = [
  { header: "No", className: "col-no" },
  { header: "ID", className: "col-id", sortKey: "sortBy", index: 0 },
  { header: "Employee", className: "col-employee", sortKey: "sortByEmployee", index: 1 },
  { header: "Designation", className: "col-designation", sortKey: "sortByDesignation", index: 2 },
  { header: "Department", className: "col-department", sortKey: "sortByDepartment", index: 3 },
  {
    header: "Domain Name",
    className: "col-domainname",
    sortKey: "sortByDomainName",
    index: 12,
  },
  {
    header: "Sub Domain Name",
    className: "col-domainname",
    sortKey: "sortBySubDomainName",
    index: 13,
  },
  {
    header: "Domain Status",
    className: "col-domainstatus",
    sortKey: "sortByDomainStatus",
    index: 14,
  },
  {
    header: "Proficiency Level",
    className: "col-proficiencylevel",
    sortKey: "sortByProficiencyLevel",
    index: 15,
  },
  {
    header: "col-duration",
    className: "col-domainstatus",
    sortKey: "sortByDuration",
    index: 16,
  },
];

export const emailLogFilterOptions = [
  { id: 1, name:"sent", label: "All sent", checked: false },
  { id: 2, name:"failed", label: "Failed", checked: false },
  { id: 3, name:"pending", label: "Pending", checked: false },
  {id: 4, name:"in_progress", label:"In Progress", checked: false},
]

export const ADMINEMAILLOGCOLUMN = [
  { header: "No", className: "col-no" },
  { header: "Date", className: "col-date", sortKey: "sortByDate", index:19 },
  { header: "Subject", className: "col-subject" },
  { header: "Status", className: "col-status" },
  { header: "Total", className: "col-total-recipients" },
  { header: "Delivered", className: "col-delivered-recipients" },
  { header: "Undelivered", className: "col-undelivered-receipent" },
  { header: "Action", className: "col-action" },
]

export const undeliveredEmailFilterOptions = [
  
  { id: 1, name:"mail_sending_issues", label: "E-mail Address Issues (Admin Actionable)", checked: false },
  { id: 2, name:"network_and_server_issues", label: "Network and Server Issues (IT Support)", checked: false },
  { id: 3, name:"configuration_security_and_unknown_issues", label: "Configuration, Security, and Unknown Issues (IT Support)", checked: false },
  { id: 4, name:"quota_and_attachment_issues", label: "E-mail limit and Attachment Issues (Admin Actionable)", checked: false },
]