import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import { useUserStore } from "store/useUserStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveDomain(
    domain_name:string,
){
    try{
    const requestObject = {
        domain_name
    };
    const response = await apiClient.post(
        urls.saveDomains(),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useSaveAdminDomain():UseMutationResult<
    any,
    ApiError | {message: string},
    {domain_name:string}
    >{
        const {subDomainSearch,domainListPaginationOffset, domains} = useAdminStore();
        const {accessToken} = useUserStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            domain_name,
        }: {
            domain_name: string;
        }) => {
            const result = await saveDomain(domain_name,);
            queryClient.invalidateQueries(["domains",accessToken]);
            queryClient.invalidateQueries(["adminDomains",accessToken])
            queryClient.invalidateQueries(["AdminDomain",subDomainSearch,domainListPaginationOffset,domains]);

            return result;
        }
    );
    }