import React, { ReactNode, useEffect, useState, useRef } from "react";
import "./admin-modal.scss";
import "../../../feature/admin/components/ActionHeader/SearchPanel/searchPanel.scss"
import InputField from "../InputField";
import LoadingButton from "../LoadingButton";
import { INPUT_MAX_LENGTH, SORTVALUEMOBILEUSERCERTIFICATION, SORTVALUEMOBILEUSERDOMAIN, SORTVALUEMOBILEUSERSKILL } from "utils/constants";
import UserProfileImage from "components/UserImage";
import { SORTBYMOBILE } from "utils/constants";
import RadioButton from "../RadioButton";
import { useAdminStore } from "store/adminStore";
import { sortOptionsMap } from "utils/helpers";


interface Props {
  size?: string;
  title?: string;

  subTitle?: string;
  subTitleCount?: string;
  isHeaderBottom?: boolean;
  count?: number;
  countText?: string;
  skillType?: string;
  headerWrapper?: string;
  children?: ReactNode;
  primaryButton?: string;
  secondaryButton?: string;
  messageIcon?: any;
  footer?: any;
  handleClose?: any;
  placement?: string;
  placementFull?: string;
  isBackArrow?: boolean;
  withEditBtn?: boolean;
  hidefooter?: boolean;
  SkillCategory?: string;
  showClose?: boolean;
  setToggleReviewModal?: any;
  setRejectModal?: any;
  onProceed?: () => void;
  isSkillUsers?: boolean;
  btnLoaderIcon?: boolean;
  logo?: boolean;
  categoryModal?: boolean;
  headerState?: boolean;
  setCategory?: any;
  errorMessage?: string;
  setErrorMessage?: any;
  category?: any;
  editState?: boolean,
  setEditState?: any,
  userData?: boolean,
  from?: string
  userView?: any
}
const AdminModal: React.FC<Props> = ({

  size,
  title: categoryTitle,
  subTitle,
  subTitleCount: categoryCount,
  isHeaderBottom,
  count,
  countText,
  skillType,
  headerWrapper,
  children,
  primaryButton,
  secondaryButton,
  messageIcon,
  handleClose,
  placement,
  placementFull,
  isBackArrow,
  withEditBtn,
  hidefooter,
  SkillCategory,
  showClose,
  setToggleReviewModal,
  setRejectModal,
  isSkillUsers,
  btnLoaderIcon,
  onProceed,
  logo = false,
  categoryModal = false,
  headerState,
  setCategory,
  setErrorMessage,
  errorMessage,
  category,
  editState,
  setEditState,
  userData, from,
  userView,
}) => {

  const User = require("../../../assets/temp-images/query1.png");
  const [title, setTitle] = useState(categoryTitle)
  const [subTitleCount, setSubTitleCount] = useState(categoryCount)
  const [data, setData] = useState(category)
  const [tempSortOrder, setTempSortOrder] = useState([{ label: "Ascending", value: "Ascending" }]);
  const [tempSortBy, setTempSortBy] = useState([{ label: "Employee", value: "Employee" }]);
  const [sortToggle, setSortToggle] = useState(false);
  const mobileSortRef = useRef(null);

  const {
    setSortBy,
    setSortOrder,
    sortBy,
    sortOrder,
    setSortOptionSkills,
    setSortOptionCertification,
    setSortOptionDomain
  } = useAdminStore();

  const handleRejectModal = () => {
    setRejectModal(true);
    setToggleReviewModal(false);
  };
  const handleEdit = () => {
    setEditState(true);
  }

  useEffect(() => {
    setSubTitleCount(categoryCount)
  }, [categoryCount])

  useEffect(() => {
    if (errorMessage) {
      setEditState(true)
    }
  }, [errorMessage]);

  const handleSortApply = () => {
    setSortBy(tempSortBy);
    setSortOrder(tempSortOrder);

    if (from && sortOptionsMap[from] && (tempSortBy.length > 0 || tempSortOrder.length > 0)) {
      const sortOptions = sortOptionsMap[from];
      const sortByLabel = tempSortBy[0].label;
      const sortOrderLabel = tempSortOrder[0].label;
      const sortOption = sortOptions[sortByLabel][sortOrderLabel];

      if (sortOption) {
        switch (from) {
          case "Skills":
            setSortOptionSkills(sortOption);
            break;
          case "Certifications":
            setSortOptionCertification(sortOption);
            break;
          case "Domains":
            setSortOptionDomain(sortOption);
            break;
          default:
            break;
        }
      }
      handleSortToggle();
    }
  };

  const handleReset = () => {
    setTempSortBy([{ label: "Employee", value: "Employee" }]);
    setTempSortOrder([{ label: "Ascending", value: "Ascending" }]);
    setSortBy([{ label: "Employee", value: "Employee" }]);
    setSortOrder([{ label: "Ascending", value: "Ascending" }]);
    setSortOptionSkills({
      label: "first_name_last_name_asc",
      value: "first_name_last_name:asc"
    })
    setSortOptionCertification({
      label: "first_name_last_name_asc",
      value: "first_name_last_name:asc"
    })
    setSortOptionDomain({
      label: "first_name_last_name_asc",
      value: "first_name_last_name:asc"
    })

  }

  const handleChangeSortBy = (selectedOptions: Option) => {
    setTempSortBy([selectedOptions]);
  };

  const handleChangeSortOrder = (selectedOptions: Option) => {
    setTempSortOrder([selectedOptions]);
  };

  const handleSortToggle = () => {
    setSortToggle(!sortToggle);
    if (!sortToggle) {
      setTempSortBy(sortBy);
      setTempSortOrder(sortOrder);
    }
  }

  useEffect(() => {
    handleReset()
  }, [])

  const handleClickOutsideSort = (event: { target: any }) => {
    const sortPanel = document.querySelector('.sort-panel');
    const isClickInsideSortPanel = sortPanel && sortPanel.contains(event.target);

    if (!isClickInsideSortPanel && mobileSortRef.current && !(mobileSortRef.current as HTMLElement).contains(event.target)) {
      setSortToggle(false);
    }
  };
  const closeSort = (event: any) => {
    if (event.key === "Escape") {
      setSortToggle(false)
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSort, false);
    window.addEventListener("keydown", closeSort);
    return () => {
      document.removeEventListener("click", handleClickOutsideSort, false);
      window.removeEventListener("keydown", closeSort);
    };
  }, [sortToggle]);

  const isSkillPage = isSkillUsers !== undefined && isSkillUsers;
  return (
    <div className={`admin-c-modal ${categoryModal ? "categoryModal" : ""}`}>
      <div
        className={`modal-wrapper ${size ? "extra-md" : "md"} ${placement ? "placement-bottom" : ""
          } ${placementFull ? "placement-full" : ""}`}
      >
        <div
          className={`modal-header purple-shade  ${headerWrapper ? "header-wrapper" : ""} ${isSkillPage ? "purple-shade" : "blue-shade"
            } ${isHeaderBottom === false ? "hide-header-bottom" : ""} ${headerWrapper ? "header-wrapper" : ""}`}
        >
          <div className="modal-header-top">
            <div className="modal-header-wrapper modal-header-wrapper-mobile">
              {logo && (
                <div className="modal-header-icon">
                  <div className="profile-img-holder">{userView ?
                    <UserProfileImage first_name={userView?.user_info?.first_name} ssoId={userView?.user_info?.sso_login_id} /> : category ?
                      <>
                        <svg className="svg-icon folder-icon">
                          <use href="#smallClosedFolder">
                            <title>Folder Icon</title>
                          </use>
                        </svg>
                      </> : ""}
                  </div>
                </div>
              )}
              <div className="modal-header-content">
                {(headerState == true && editState == false) ? (
                  <div className="custom-head-editicon">
                    <div>
                      <h4>{title}</h4>
                      <span className="modal-sub-header">
                        <span className="sub-title-count">{subTitleCount}</span>
                        {subTitle}</span>
                    </div>


                    <button className="app-btn icon-only-sm app-btn-edit" onClick={() => { handleEdit(); setData(category) }} title='edit'>
                      <svg className="svg-icon edit-icon">
                        <use href="#editIcon">
                          <title>Edit Icon</title>
                        </use>
                      </svg>
                    </button>

                  </div>

                ) : editState == true ? ("") : (
                  from == "userdata" ?
                    <div>
                      <h4>{userView ? `${userView?.user_info?.first_name} ${userView?.user_info?.last_name}` : ''}</h4>
                      <span className="modal-sub-header">
                        <span className="sub-title-count">{subTitleCount}</span>
                        {userView ? `ID: ${userView?.user_info?.employee_id !== null ? userView?.user_info?.employee_id : `-`}` : ''}</span>

                    </div> :
                    <div>
                      <h4>{title}</h4>
                      <span className="modal-sub-header">
                        <span className="sub-title-count">{subTitleCount}</span>
                        {subTitle}</span>

                    </div>

                )}
                {editState && <div className="custom-head-edit-holder">
                  <div className="custom-head-edit" >
                    <InputField type="text" value={title}
                      onChange={(e: any) => {
                        if (subTitle === "sub domains") {
                          setCategory({ ...category, domain_name: e.target.value.trim() });
                        } else {
                          setCategory({ ...category, name: e.target.value.trim() });
                        }
                        setTitle(e.target.value);
                        setErrorMessage("");
                      }}
                      maxLength={INPUT_MAX_LENGTH}
                      error={errorMessage}
                    ></InputField>

                    <button className="app-btn icon-only-sm skildata-btn" title='close' onClick={() => {
                      setEditState(false);
                      setErrorMessage("");
                      if (subTitle == "sub domains") {
                        setTitle(data?.domain_name);
                        setCategory(data)
                      } else {
                        setTitle(data?.name);
                        setCategory(data)
                      }
                    }}>
                      <svg className="svg-icon cross-icon">
                        <use href="#closeIcon">
                          <title>Close Icon</title>
                        </use>
                      </svg>
                    </button>
                    <button className="app-btn icon-only-sm skildata-btn " title='add' onClick={() => {
                      if (onProceed) {
                        onProceed();
                      }
                    }}>
                      <svg className="svg-icon tick-icon">
                        <use href="#tickIcon">
                          <title>Tick Icon</title>
                        </use>
                      </svg>
                    </button>

                  </div>

                  <span className={errorMessage ? "modal-sub-header error" : "modal-sub-header"}>
                    <span className="sub-title-count">{subTitleCount}</span>
                    {subTitle}</span>



                </div>}
              </div>
            </div>

            <button
              className={`icon-only close-icon close-icon-mobile ${showClose ? "show-close" : ""
                }`}
              onClick={() => handleClose()}
            >
              <svg className="svg-icon close-icon">
                <use href="#closeIcon">
                  <title>Close Icon</title>
                </use>
              </svg>
            </button>
          </div>
          <div className="modal-header-bottom">
            {
              from == "userdata" ? (<div className="user-info-section">

                <div className="info-section-left">{userView?.user_info?.designation !== 'Unknown' ? userView?.user_info?.designation : ""}</div>
                <div className="info-section-right">
                  {userView ? <div className="user-count-added">
                    {userView?.skill_info?.skill_count} <span className="count-name">Skills</span>
                  </div> :
                    null
                  }                  {userView ? <div className="user-count-added">
                    {userView?.certification_info?.certification_count} <span className="count-name">{userView?.certification_info?.certifications.length > 0 ? 'Certifications' : 'Certification'}</span>
                  </div> : null}
                  {/* {userView ? <div className="user-count-added">
                    {userView?.domain_info?.domain_count} <span className="count-name">{userView?.domain_info?.domains.length > 0 ? 'Domains' : 'Domain'}</span>
                  </div> : null} */}
                </div>

              </div>) :
                (<div className="user-sub-content">
                  <div className="user-count-added">
                    {count} <span className="count-name">{countText}</span>
                  </div>
                  {<button
                    className={`app-btn secondary user-sort-icon `}
                    onClick={handleSortToggle}
                    ref={mobileSortRef}
                  >
                    <svg className="svg-icon filter-icon">
                      <use href="#upDownIcon">
                        <title>Sort icon</title>
                      </use>
                    </svg>
                  </button>
                  }
                  <div className={`sort-panel ${sortToggle ? "active" : ''}`} onClick={(e) => e.stopPropagation()}>
                    <div className="sort-panel-head">
                      <span className="sort-panel-labels">Sort by</span>
                    </div>
                    <div className="sort-panel-body">
                      {(from === "Skills" ? SORTVALUEMOBILEUSERSKILL.map((item: Option, key: number) => {
                        return (
                          <RadioButton key={`${item.label}-${tempSortBy[0]?.label}`} label={item.label} name="sortBy"
                            defaultChecked={tempSortBy?.[0]?.label === item.label}
                            onChange={() => { handleChangeSortBy(item) }} />
                        );
                      }) : from === "Certifications" ? SORTVALUEMOBILEUSERCERTIFICATION.map((item: Option, key: number) => {
                        return (
                          <RadioButton key={`${item.label}-${tempSortBy[0]?.label}`} label={item.label} name="sortBy"
                            defaultChecked={tempSortBy?.[0]?.label === item.label}
                            onChange={() => { handleChangeSortBy(item) }} />
                        );
                      }) : SORTVALUEMOBILEUSERDOMAIN.map((item: Option, key: number) => {
                        return (
                          <RadioButton key={`${item.label}-${tempSortBy[0]?.label}`} label={item.label} name="sortBy"
                            defaultChecked={tempSortBy?.[0]?.label === item.label}
                            onChange={() => { handleChangeSortBy(item) }} />
                        );
                      }))}
                    </div>
                    <div className="sort-panel-footer">
                      <div className="sort-panel-footer-top">
                        <span className="sort-panel-labels">Sort order</span>
                        <div className="inline-radio-group">
                          {SORTBYMOBILE.map((item: Option, key: number) => {
                            return (
                              <RadioButton key={`${item.label}-${tempSortOrder[0]?.label}`} label={item.label} name="sortOrder"
                                defaultChecked={tempSortOrder?.[0]?.label === item.label}
                                onChange={() => { handleChangeSortOrder(item) }} />
                            );
                          })}
                        </div>
                      </div>
                      <div className="sort-panel-footer-bottom">
                        <button className="app-btn  secondary" onClick={() => {
                          handleReset();
                        }}>Reset</button>
                        <button className="app-btn  primary"
                          onClick={() => {
                            handleSortApply()
                          }
                          }>Apply</button>
                      </div>
                    </div>
                  </div>

                  <div className="user-search-btn-wrapper">
                    <button
                      className="app-btn icon-btn-text primary hide-on-mobile"
                      onClick={onProceed}
                    >
                      <svg className="svg-icon down-icon">
                        <use href="#downIcon">
                          <title>Download</title>
                        </use>
                      </svg>
                      <span className="button-text">Download Report</span>
                    </button>
                  </div>
                </div>)}
          </div>
        </div>
        <div className="modal-body custom-scrollbar">{children}</div>
        <div className={`modal-footer  ${hidefooter ? "hide-footer" : ""}`}>
          <div className="footer-btns">
            {secondaryButton === "Reject" ? (
              <button
                className="app-btn min-width"
                onClick={handleRejectModal}
                disabled={btnLoaderIcon}
              >
                {secondaryButton}
              </button>
            ) : secondaryButton === "Cancel" ? (
                <button className="app-btn min-width" onClick={handleClose} disabled={btnLoaderIcon}>
                {secondaryButton}
              </button>
            ) : secondaryButton === "edit" ? (
              <button className="edit-icon-btn">
                <svg className="svg-icon edit-icon">
                  <use href="#editIcon">
                    <title>Edit Icon</title>
                  </use>
                </svg>
                {secondaryButton}
              </button>
            ) : (
              ""
            )}

            {primaryButton ? (
              <button className="app-btn min-width primary" onClick={onProceed}>
                {btnLoaderIcon ? <LoadingButton /> : primaryButton}
              </button>
            ) : (
              ""
            )}
            { }
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminModal;
