import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useSkillStore } from "store/useSkillStore";


export async function getAllUserSkills():Promise<UserSkills|null> {
  const response = await apiClient.get(urls.userSkills());
  if (response && response.data && response.data.data)
    return response.data.data.user_skills;
  return null;
}

export function useGetUserSkills():UseQueryResult<UserSkills> {
  const {setPrimarySkill,setSecondarySkill,setAdditionalSkill}  = useSkillStore();
  return useQuery(["GetAllUserSkills",], async () => {
    const data = await getAllUserSkills();
    setPrimarySkill(data?.Primary!== undefined ? data.Primary[0]:undefined);
    setSecondarySkill(data?.Secondary!== undefined? data.Secondary[0]:undefined);
    setAdditionalSkill(data?.Additional !== undefined ? data.Additional:undefined);
    return data;
  },{
    refetchOnWindowFocus:false,
  });
}
