import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { getCertificationDate } from "utils/dateUtil";
import { isNumber } from "utils/helpers";
import { urls } from "utils/urls";

async function saveCertification(
    certification: CertificationToSave
){
    try{
    const response = await apiClient.post(
        urls.certifications(),
        certification,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useSaveCertification():UseMutationResult<
    any,
    ApiError | {message: string},
    {certification:Certification; certification_date: CertificationDate}
    >{
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            certification,
            certification_date
        }: {
            certification:Certification; certification_date: CertificationDate
        }) => {
            const tags = certification.tags.filter(item=>isNumber(item.value)).map(item=>item.value);
            const customTags =certification.tags.filter(item=>!isNumber(item.value)).map(item=>item.value);
            const issueDate = getCertificationDate(certification_date,"IssueDate") ;
            const expiryDate =  getCertificationDate(certification_date,"ExpiryDate");
            const certificate:CertificationToSave = {
                certification_name: certification.certification_name,
                issued_by: certification.issued_by,
                issued_date: issueDate,
                expiry_date: expiryDate === ''?null: expiryDate,
                is_expirable: certification.is_expirable,
                credential_url: certification.credential_url,
                credential_id: certification.credential_id,
                tags: tags,
                custom_tags:customTags,
            }
            const result = await saveCertification(certificate);
            queryClient.invalidateQueries(["GetAllUserCertifications"]);
            return result;
        }
    );
    }    
  