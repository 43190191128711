import React from "react";
import "./custom-skill-popup.scss";
import InputField from "components/common-components/InputField";
import { INPUT_MAX_LENGTH } from "utils/constants";

const AddCustomSkill = ({
  value,
  error,
  onChange,
}: {
  value: string;
  error: string;
  onChange: (skill: string) => void;
}) => {
  return (
    <div className="app-modal-content custom-skill-popup">
      <form>
        <InputField
          type="text"
          placeholder="Add a Custom Skill"
          value={value}
          onChange={(e: any) => onChange(e.target.value)}
          error={error}
          autoFocus={true}
          maxLength={INPUT_MAX_LENGTH}
        />
      </form>
    </div>
  );
};
export default AddCustomSkill;
