import React, { useState, useEffect } from "react";
import "./react-select.scss";
import { useAdminStore } from "store/adminStore";
import { useAllFilterSkillsUsersExport } from "api/admin/useExportAllFilterSkillsUsers";
import { useAllDefaultUsersExport } from "api/admin/useExportDefaultUsers"
import { initialView, View } from "utils/constants";
import { useGetUserViewById } from "api/admin/useUserView";
import { useAllFilterCertificationsUsersExport } from "api/admin/useExportAllFilterCertificationsUsers";
import { useAllFilterDomainUsersExport } from "api/admin/useExportAllFilterDomainsbUsers";
import AdminModal from "components/common-components/AdminModel";
import UserDefaultDataDetails from "./defaultUser";
import SearchPanel from "../../ActionHeader/SearchPanel";
import SelectBox from "components/common-components/ReactSelect";
import Pagination from "components/common-components/Pagination";
import UserDataModal from "./UserDetailModal";
import UserFilterDataDetails from "./userFilter";
import UserCertificationTable from "./UserCertificationTable";
import UserDomainTable from "./UserDomainTable";
import { FILTER_SELECTION } from "utils/constants";
interface Props {
  tabSwitch: string;
}

const UserDataDetails: React.FC<Props> = ({ tabSwitch }) => {
  const {
    skillLevel, skillName, userDesignation, skillType,
    filterEnableIcon, department, filterSelection,
    filterEnable, certificationIssuer, certificationName,
    certificationStatus, domainName, subdomainName,
    nonInitatedDomain, minDuration, maxDuration,
    domainStatus, setQueryBuilderListPaginationOffset,
    setQueryBuilderPaginationOffset, setCurrentPage,
  } = useAdminStore();
  const { refetch: refetchSkillsUsers } = useAllFilterSkillsUsersExport(skillName, skillLevel, skillType, userDesignation, department);
  const { refetch: refetchDefaultUsers } = useAllDefaultUsersExport();
  const { refetch: refetchCertificationsUsers } = useAllFilterCertificationsUsersExport(
    certificationName, certificationIssuer, certificationStatus, userDesignation, department
  );
  const { refetch: refetchDomainUsers } = useAllFilterDomainUsersExport(
    domainName, subdomainName, nonInitatedDomain, minDuration, maxDuration, domainStatus, userDesignation, department
  );
  const [userDataList, setDataUserList] = useState<any>(null);
  const [userId, setUserId] = useState<number | null>()
  const { data: userView } = useGetUserViewById(userId);
  const [viewLimit, setViewLimit] = useState<any>(initialView);
  const [skillState, setSkillState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(25)

  useEffect(() => {
    setCurrentPage(1)
    setQueryBuilderPaginationOffset(25);
  }, [filterEnable, filterSelection])

  const downloadExport = filterEnableIcon ?
    (filterSelection === FILTER_SELECTION.SKILLS ?
      refetchSkillsUsers :
      filterSelection === FILTER_SELECTION.CERTIFICATIONS ?
        refetchCertificationsUsers :
        refetchDomainUsers) :
    refetchDefaultUsers;

  function handleAddSkillModal(): void {
    throw new Error("Function not implemented.");
  }

  function handleUserModal() {
    setSkillState(false)
  }

  const handlePageChange = (page: number) => {
    const offset = (page - 1) * limit;
    setQueryBuilderListPaginationOffset(offset);
  };

  const commonProps = {
    setUserId,
    setSkillState,
    setLoading,
    onUpdateDefaultUserList: (updatedList: any) => setDataUserList(updatedList),
    setLimit,
  };

  let ContentComponent;

  if (filterEnable) {
    switch (filterSelection) {
      case "Skills":
        ContentComponent = <UserFilterDataDetails {...commonProps} />;
        break;
      // case "Certifications":
      //   ContentComponent = <UserCertificationTable {...commonProps} />;
      //   break;
      // default:
      //   ContentComponent = <UserDomainTable {...commonProps} />;
      default:
        ContentComponent = <UserCertificationTable {...commonProps} />;
    }
  } else {
    ContentComponent = <UserDefaultDataDetails {...commonProps} />;
  }
  return (
    <>
      <SearchPanel
        tabSwitch={tabSwitch}
        setSkillSViewToggle={() => console.log('')}
        searchPlaceholder="Search for a User"
        addButtonText="Add Skill"
        setEditMode={false}
        pageName="Users"
        isSkillPage={false}
        from={'userData'}
        download={downloadExport}
        handleAdd={handleAddSkillModal}
      />
      <div className="user-details-section">
        {ContentComponent}
        <div className="user-details-footer">
          <div className="item-view-section">
            <span className="item-view-details">View</span>
            <div className="selection-box">
              <SelectBox
                label=""
                name="category"
                options={View}
                value={View.find(option => option.value === viewLimit.value)}
                classNamePrefix="react-select-box-page"
                isSearchable={false}
                onChange={(item: Option) => {
                  setViewLimit(item.value);
                  setQueryBuilderListPaginationOffset(0);
                  setQueryBuilderPaginationOffset(item.value);
                }}
              />
              <span className="dropdown-arrow">
                <svg className="svg-icon">
                  <use href="#downArrowIcon">
                    <title>down icon</title>
                  </use>
                </svg>
              </span>
            </div>
            <span className="item-view-details">items per page of {userDataList?.total_count} entries</span>
          </div>
          <Pagination
            itemsPerPage={limit}
            totalItems={userDataList?.total_count ?? 0}
            onPageChange={handlePageChange}
            limit={limit}
          />
        </div>
      </div>
      <div className="mobile-pagination">
        <Pagination
          itemsPerPage={limit}
          totalItems={userDataList?.total_count ?? 0}
          onPageChange={handlePageChange}
          limit={limit}
        />
      </div>
      {skillState && <AdminModal
        title={`${userView?.user_info?.first_name} ${userView?.user_info?.last_name}`}
        subTitle="ID: 666"
        categoryModal={true}
        logo={true}
        showClose={true}
        isHeaderBottom={true}
        headerState={true}
        isBackArrow={true}
        count={2}
        countText={"dee"}
        withEditBtn={false}
        hidefooter={true}
        from={"userdata"}
        placementFull="placement-full"
        size=""
        headerWrapper="header-wrapper"
        userView={userView}
        handleClose={handleUserModal}
      >
        <UserDataModal userView={userView} setLoading={setLoading} loading={loading}></UserDataModal>
      </AdminModal>}
    </>
  );
};
export default UserDataDetails;

