import React, { useEffect, useState, useRef } from "react";
import "./skillGridWrapper.scss";
import UserListingModal from "./UserListingModal";
import AdminModal from "components/common-components/AdminModel";
import { Link } from "react-router-dom";
import EditSkillModal from "./EditSkillModal";
import { useGetAdminSkills } from "api/admin/useGetSkills";
import { useAdminStore } from "store/adminStore";
import { ErrorMessages, messages } from "utils/messageConstants";
import { showSuccessToast, showErrorToast } from "utils/toastUtil";
import { useUpdateSkill } from "api/admin/useUpdateSkill";
import { useGetSkillExport } from "api/admin/useExportSkillUsers";
import SkillPagination from "components/common-components/SkillPagination";
import { PAGINATION_ITEMS_PER_PAGE } from "utils/constants";
import { useUserStore } from "store/useUserStore";
interface Props {
  addButtonText?: string;
  editMode?: any;
  handleAdd: any;
}
const SkillsGridView: React.FC<Props> = ({ addButtonText, editMode, handleAdd}) => {
  const { data: skills } = useGetAdminSkills();
  const { mutateAsync: updateSkillData } = useUpdateSkill();
  const { data, refetch } = useGetSkillExport();

  const refs: Array<any> = [];
  const [isTooltipActive, setTooltipActive] = useState([false]);

  useEffect(() => {
    const tooltipVisible: boolean[] = [];
    refs.map((ref, i) => {
      const element = ref?.current as HTMLElement;
     if (element) {
     tooltipVisible.push(element.getBoundingClientRect().width + 1 < element.scrollWidth);
       }
    });

    if (JSON.stringify(tooltipVisible) !== JSON.stringify(isTooltipActive)) {
      setTooltipActive(tooltipVisible);
     }
  },[]);
  
  const {
    selectedSkill,
    categories,
    setSelectedSkill,
    setSkillAdded,
    setSkillPaginationOffset,
    setInitiateSkillExportById,
    setSkillListPaginationOffset,
  } = useAdminStore();
  const { userManagedRole} = useUserStore();
  const [showModal, setShowModal] = useState(false);
  const [SkillCategory, setSkillCategory] = useState("");
  const [skillName, setSkillName] = useState("");
  const [selectedCategory, setCategory] = useState<Category | undefined>(undefined);
  
  const [errorMessage, setErrorMessage] = useState("");
  const [count, setCount] = useState(1);

  const getCategoryById = (categoryId:number)=>{
    return categories.find(item=>item.id===categoryId);
  }
  const handleModal = () => {
    setSkillPaginationOffset(0);
    setShowModal(!showModal);
  };
  const [toggleEditSkillModal, setToggleEditSkillModal] = useState(false);

  const handleEditSkillModal = () => {
    setToggleEditSkillModal(!toggleEditSkillModal);
    setErrorMessage("")
  };

  const updateSkill = async()=>{
      if (skillName === "") {
        setErrorMessage(ErrorMessages.SKILL_NAME_MISSING);
        return;
      }
      if (selectedCategory) {
        const response = await updateSkillData({skillId: selectedSkill?.skill_id??0, skillName, categoryId: selectedCategory?.id });
        if (response.data) {
          setSelectedSkill(undefined);
          setSkillAdded(true);
          showSuccessToast(messages.SKILL_SAVE_SUCCESS);
          setErrorMessage("");
          handleEditSkillModal();
        } else if(response?.response?.data.message) {
          setErrorMessage(response?.response?.data.message);
        }else{
          setErrorMessage(messages.SKILL_SAVE_DUPLICATE)
        }
      } else {
        setErrorMessage(messages.SELECT_CATEGORY);
      }
  };

  const downloadSkillReport = ()=>{
    setInitiateSkillExportById(true);
    refetch();
    if(data){
      setInitiateSkillExportById(false);
    }
  }
  //scrollbar condition
  const parentRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      if (parentRef.current) {
        parentRef.current.scrollTop = 0;
      }
     
    }, [count]);
  return (
    <>
      <div className="skills-grid-view">
        <div className="skill-layout" ref={parentRef}>
          <div className={`layout fr-5  purple-shade `}>
            {skills?.total === 0 ? " No Skill(s) Found" : null}
            {skills?.skills?.map((skillsItem: AdminSkill, skillsIndex: number) => {
              refs.push(React.createRef());

              return (
                <div className="skill-item-set" key={skillsIndex}>
                  <Link
                    to="#"
                    className="skill-item-link"
                    onClick={() => {
                      if (editMode) {
                        handleEditSkillModal();
                        setSelectedSkill(skillsItem);
                        setSkillName(skillsItem.skill_name);
                        setCategory(getCategoryById(skillsItem.category_id));
                      } else if (skillsItem.user_count > 0) {
                        setSelectedSkill(skillsItem);
                        handleModal();
                      }
                    }}
                  >
                    {" "}
                    <div className="skill-item-top">
                      <div
                        className="skill-item-name "
                        {...(isTooltipActive[skillsIndex] && {
                          "title": `${skillsItem.skill_name}`,
                        })}
                        key={skillsIndex}
                        title={skillsItem.skill_name.length >15 ?skillsItem.skill_name:''}
                      >
                        {" "}
                        {skillsItem.skill_name}
                      </div>

                      <div className="skill-counts">{skillsItem.user_count}</div>
                    </div>
                    <div className="skill-item-bottom">
                      <div className="skill-num-edit-wrapper">
                        <div className="skill-count">{skillsItem.skill_rank_expert_count}</div>
                        <div className="skill-level">Expert</div>
                      </div>
                      <div className="skill-num-edit-wrapper">
                        <div className="skill-count">{skillsItem.skill_rank_intermediate_count}</div>
                        <div className="skill-level">Intermediate</div>
                      </div>
                      <div className="skill-num-edit-wrapper">
                        <div className="skill-count">{skillsItem.skill_rank_beginner_count}</div>
                        <div className="skill-level">Beginner</div>
                      </div>
                    </div>
                  </Link>
                  {editMode ? (
                    <button
                      className="icon-only edit-icon-btn"
                      onClick={() => {
                        handleEditSkillModal();
                        setSelectedSkill(skillsItem);
                        setSkillName(skillsItem.skill_name);
                        setCategory(getCategoryById(skillsItem.category_id));
                      }}
                    >
                      <svg className="svg-icon edit-icon">
                        <use href="#editIcon">
                          <title>Edit Icon</title>
                        </use>
                      </svg>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        </div>
{  userManagedRole? null : <div className="add-skill-footer-btn with-bg">
          <button className="app-btn primary icon-btn-text" onClick={handleAdd}>
            <svg className="svg-icon plus-icon">
              <use href="#plusIcon">
                <title>Add Icon</title>
              </use>
            </svg>
            <span className="button-text">{addButtonText}</span>
          </button>
        </div>
}        <SkillPagination
          itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
          totalItems={skills?.total??0}
          onPageChange={(page)=>{
            const offset = (page-1)* PAGINATION_ITEMS_PER_PAGE;
            setSkillListPaginationOffset(offset);
            setCount(count+1)
          }}
        />
      </div>

      {showModal ? (
        <>
          <AdminModal
            title={selectedSkill?.skill_name}
            subTitle={selectedSkill?.category_name}
            count={selectedSkill?.user_count??0}
            size="extra-md"
            handleClose={() => {
              setShowModal((prev)=>!prev)
              setSelectedSkill(undefined);
            }}
            isBackArrow={false}
            placementFull="placement-full"
            headerWrapper="header-wrapper"
            withEditBtn={false}
            hidefooter={true}
            SkillCategory={SkillCategory}
            showClose={true}
            countText={selectedSkill && selectedSkill?.user_count <=1 ?"User":"Users"}
            onProceed={downloadSkillReport}
            isSkillUsers={true}
            from={"Skills"}
          >
            <UserListingModal downloadReport={downloadSkillReport}/>
          </AdminModal>
        </>
      ) : (
        ""
      )}
      {toggleEditSkillModal ? (
        <AdminModal
          title="Edit Skill"
          size=""
          handleClose={() => {
            handleEditSkillModal();
            setSelectedSkill(undefined);
          }}
          isBackArrow={true}
          placementFull="placement-full"
          headerWrapper=""
          withEditBtn={false}
          hidefooter={false}
          primaryButton="Save Skill"
          secondaryButton="Cancel"
          isHeaderBottom={false}
          onProceed={updateSkill}
        >
          <EditSkillModal 
          setCategory={setCategory}
          setSkillName={setSkillName}
          skillName={skillName}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}/>
        </AdminModal>
      ) : (
        ""
      )}
    </>
  );
};
export default SkillsGridView;
