import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";
import { handleDownload } from "utils/downloadFile";

export async function getAllFilterDomainsUser(
  domain_names: string,
  sub_somain_names: string,
  proficiency_level: string,
  min_duration: string,
  max_duration: string,
  domain_status: string,
  designations: string,
  departments: string,
  sort: Option | undefined,
  has_certification: Option[]
): Promise<any> {
  const response = await apiClient.get(
    urls.exportDomainsUsers(
      domain_names,
      sub_somain_names,
      proficiency_level,
      min_duration,
      max_duration,
      domain_status,
      designations,
      departments,
      sort,
      has_certification
    )
  );
  if (response && response.data && response.data.data) return response.data.data;
  return null;
}

export function useAllFilterDomainUsersExport(
  domain_names: any,
  sub_somain_names: any,
  proficiency_level: any,
  min_duration: any,
  max_duration: any,
  domain_status: any,
  designations: any,
  department_value: any
): UseQueryResult<any> {
  const {
    initiateSkillExport,
    domainName,
    subdomainName,
    nonInitatedDomain,
    minDuration,
    maxDuration,
    domainStatus,
    userDesignation,
    sortOptionDomain,
    department,
    nonInitiated,
  } = useAdminStore();
  const DomainNames = domain_names.map((item: any) => item.value).join(",");
  const SubDomainNames = sub_somain_names.map((item: any) => item.value).join(",");
  const ProficiencyLevel = proficiency_level.map((item: any) => item.value).join(",");
  const MinDuration = min_duration.map((item: any) => item.value).join(",");
  const MaxDuration = max_duration.map((item: any) => item.value).join(",");
  const DomainStatus = domain_status.map((item: any) => item.value).join(",");
  const Designations = designations.map((item: any) => item.value).join(",");
  const Departments = department_value.map((item: any) => item.value).join(",");
  return useQuery(
    [
      "AllFilteredDomainsUsersExport",
      domainName,
      subdomainName,
      nonInitatedDomain,
      minDuration,
      maxDuration,
      domainStatus,
      userDesignation,
      sortOptionDomain,
      department,
      nonInitiated,
    ],
    async () => {
      const result = await getAllFilterDomainsUser(
        DomainNames,
        SubDomainNames,
        ProficiencyLevel,
        MinDuration,
        MaxDuration,
        DomainStatus,
        Designations,
        Departments,
        sortOptionDomain,
        nonInitiated
      );
      if (result && result.file_url) {
        await handleDownload(result);
      }
      return result;
    },
    {
      enabled: initiateSkillExport === true,
    }
  );
}
