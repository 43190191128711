import { create } from 'zustand';
import { DefaultCertification, DefaultCertificationDate, DefaultDomain, getDefaultCertification, getDefaultCertificationDate,
  DefaultDomainDate, getDefaultDomain, getDefaultDomainDate } from './../utils/constants';
import produce from 'immer';
import { persist } from "zustand/middleware";

export const useSkillStore = create<SkillStore>()(
  persist(
    (set) => ({
  categories: [],
  categorySkills:[],
  skillSearchKey:'',
  primarySkill:undefined,
  secondarySkill: undefined,
  additionalSkill: undefined,
  certification:DefaultCertification,
  certificationDate:DefaultCertificationDate,
  certifications:[],
  certificationTags:[],
  domain:DefaultDomain,
  domainDate:DefaultDomainDate,
  domains:[],
  subDomainStatus:[],
  setCertificationTags:(tags:Option[])=>
  set(
    produce(
      (state)=>{
        state.certificationTags=tags;
      }
    )
  ),
  clearStore:()=>
  set(
    produce(
      (state)=>{
        state.categories =[];
        state.categorySkills=[];
        state.skillSearchKey ='';
        state.primarySkill=undefined;
        state.secondarySkill= undefined;
        state.additionalSkill= undefined;
        state.certification=DefaultCertification;
        state.certificationDate=DefaultCertificationDate;
        state.certifications=[];
        state.domain=DefaultDomain;
        state.domainDate=DefaultDomainDate;
        state.domains=[];
        state.subDomainStatus=[]
      }
    )
  ),
  setSubDomainStatus:(subDomainStatus:Array<Domain>)=>
  set(
    produce((state)=>{
      state.subDomainStatus=subDomainStatus;
    })
  ),
  setDomains:(domains:Domain[])=>
  set(
    produce((state)=>{
      state.domains=domains;
    })
  ),
  clearDomain:()=> 
  set(
    produce((state)=>{
      state.domain = getDefaultDomain();
      state.domainDate = getDefaultDomainDate();
    })
  ),
  setDomain:(domain:Domain)=>
  set(
    produce((state)=>{
      state.domain=domain;
    })
  ),
  setDomainDate:(domainDate:DomainDate)=>
  set(
    produce((state)=>{
      state.domainDate=domainDate;
    })
  ),
  clearDomainDate:()=> 
  set(
    produce((state)=>{
      state.domainDate = getDefaultDomainDate();
    })
  ),
  setCertifications:(certifications:Certification[])=>
  set(
    produce((state)=>{
      state.certifications=certifications;
    })
  ),
  setCertificationDate:(certificationDate:CertificationDate)=>
  set(
    produce((state)=>{
      state.certificationDate=certificationDate;
    })
  ),
  clearCertificationDate:()=> 
  set(
    produce((state)=>{
      state.certificationDate = getDefaultCertificationDate();
    })
  ),
  clearCertification:()=> 
  set(
    produce((state)=>{
      state.certification = getDefaultCertification();
      state.certificationDate = getDefaultCertificationDate();
    })
  ),
  setCertification:(certification:Certification)=>
  set(
    produce((state)=>{
      state.certification=certification;
    })
  ),
  setSkillSearchKey:(key:string)=>
    set(
      produce((state)=>{
        state.skillSearchKey=key;
      })
    ),
  setSkills:(skills:Skill)=>
    set(
      produce((state)=>{
        state.skills=skills;
      })
    ),
  setPrimarySkill:(skills:UserSkillItem|undefined)=>
    set(
      produce((state)=>{
        state.primarySkill=skills;
      })
    ),
  setSecondarySkill:(skills:UserSkillItem|undefined)=>
    set(
      produce((state)=>{
        state.secondarySkill=skills;
      })
    ),
  setAdditionalSkill:(skills:UserSkillItem[]|undefined)=>
    set(
      produce((state)=>{
        state.additionalSkill=skills;
      })
    ),
  deleteAdditionalSkill:(skill:UserSkillItem)=>
    set(
      produce((state)=>{
        const skills = state.additionalSkill??[];
        const filteredSkills = skills.filter((item: UserSkillItem) => item.skill_name !== skill.skill_name);
        state.additionalSkill= filteredSkills;
      })
    ),
  updateAdditionalSkill:(skill:UserSkillItem)=>
    set(
      produce((state)=>{
        const list = state.additionalSkill.map((item: UserSkillItem) =>
                item.skill_name === skill.skill_name ? { ...item,skill_rank: skill.skill_rank } : item);
        state.additionalSkill = list;
      })
    ),
  addAdditionalSkill:(skill:UserSkillItem|undefined)=>
    set(
      produce((state)=>{
        const additionalSkills = state.additionalSkill??[];
        additionalSkills.length === 0 ? additionalSkills.push(skill):additionalSkills.unshift(skill);
        state.additionalSkill = additionalSkills;
      })
    ),
  setCategories:(categories: Category[])=>
      set(
        produce((state)=>{
          state.categories = categories;
        })
      ),
  setCategory:(category: Category)=>
  set(
    produce((state)=>{
      const categories = state.categories;
      const selectedCategory = categories.find(
        (item: Category) => item.name === category.name
      );
      if (selectedCategory) {
        const isChecked = selectedCategory.checked ??true;
        const list = categories.map((item: Category) =>
          item.name === selectedCategory.name ? { ...selectedCategory, checked:!isChecked } : item
        );
        state.categories = list;
      } 
    })
  ),
  setCategorySkills:(categories: Category[])=>
      set(
        produce((state)=>{
          state.categorySkills = categories;
        })
      ),
}),{
  name:"skill-store"
}
)
);

