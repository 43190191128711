import React, { useEffect, useState } from "react";
import { useAdminStore } from "store/adminStore";
import { ADMINEMAILLOGCOLUMN, emailLogFilterOptions, Sort, undeliveredEmailFilterOptions } from "utils/constants";
import { useGetEmailLog } from "api/admin/useGetAllEmailLogs";
import PageLoader from "components/common-components/PageLoader";
import SortButton from "components/sortButton";
import CommonTable from "../../AdminSkillsWrapper/UserDataDetails/CommonTable";
import MailCard from "./MailCard";
import MailTableRow from "./MailTableRow";
import EmailLogModal from "./EmailLogModal";

interface Props {
  onUpdateDefaultUserList?: any;
  setLimit?: any;
  emailLogStatus: any;
  setEmailLogStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
  undeliveredEmailStatus: EmailStatus[];
  setUndeliveredEmailStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
  recipientEmailStatus: EmailStatus[];
  setRecipientEmailStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
}

type SortStatus = {
  [key: string]: string;
};

type SortStatusKeys = keyof SortStatus;

const EmailLogComponent: React.FC<Props> = ({
  onUpdateDefaultUserList,
  setLimit,
  emailLogStatus,
  setEmailLogStatus,
  undeliveredEmailStatus,
  setUndeliveredEmailStatus,
  recipientEmailStatus,
  setRecipientEmailStatus,
}) => {
  const { setSortOptionEmailLog, setEmployeeNameSearch, setEmailLogPaginationOffset, 
    setEmailLogListPaginationOffset, setEmailLogRecipientsPaginationOffset, setCurrentPage,
    setEmailLogRecipientsListPaginationOffset, setSubjectSearch } = useAdminStore();
  const { isLoading: mailLogLoading, data: mailLogData } =
    useGetEmailLog(emailLogStatus);
  const [showEmail, setShowEmail] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState<{ value: string; label: string; }[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const sortByColumnsInitialState: SortStatus = {
    sortByDate: "desc",
  };
  const [sortByColumn, setSortByColumn] = useState<SortStatus>(sortByColumnsInitialState);
  const [mail, setMail] = useState<any>();
  const [activeTab, setActiveTab] = useState<string>('total');
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const sortByOnClick = (column: SortStatusKeys, columnNo: number) => {
    const sortOrder = sortByColumn[column] === "asc" ? "desc" : "asc";
    const updatedSortByColumn = {
      ...Object.fromEntries(Object.keys(sortByColumn).map((key) => [key, "disabled"])),
      [column]: sortOrder,
    };
    setSortByColumn(updatedSortByColumn);
    setSortOptionEmailLog(Sort[columnNo * 2 + (sortOrder === "asc" ? 0 : 1)]);
  };

  useEffect(() => {
    if (!mailLogLoading) {
      onUpdateDefaultUserList(mailLogData);
      setLimit(mailLogData?.limit)
    }
  }, [mailLogLoading, mailLogData, onUpdateDefaultUserList]);

  const setupInitialState = () => {
    setSortByColumn(sortByColumnsInitialState);
    setSortOptionEmailLog({
      label: "date_desc",
      value: "date:desc"
    });
    setCurrentPage(1)
    setEmailLogPaginationOffset(25)
    setEmployeeNameSearch("");
    setEmailLogListPaginationOffset(0)
    setSubjectSearch("")

  };


  useEffect(() => {
    setupInitialState();
  }, []);

  const renderSortButton = (column: SortStatusKeys, index: number) => (
    <SortButton
      isAscending={sortByColumn[column]}
      onClick={() => sortByOnClick(column, index)}
      icon="#sortIcon"
    />
  );

  const [showModal, setShowModal] = useState(false);
  const handleModal = (mail: any) => {
    setShowModal(!showModal);
    setMail(mail)
  };

  const handleClose = () => {
    setUndeliveredEmailStatus(undeliveredEmailFilterOptions);
    setRecipientEmailStatus(emailLogFilterOptions)
    setEmailLogRecipientsPaginationOffset(25)
    setEmailLogRecipientsListPaginationOffset(0) 
    setEmployeeNameSearch("");
    setActiveTab("total")
    setShowEmail(false)
    setShowModal((prev) => !prev);
    setSelectAll(false);
    setSelectedRecipients([]);    
  }

  const renderMails = (isMobile: boolean = false) => {
    if (mailLogLoading) return <PageLoader />;
    if (isMobile) {
      return (
        <div className="mail-log-details">
          {mailLogData?.total_count === 0 ? " No Email Log(s) Found" : null}
          {mailLogData?.custom_notifications.map((mailItems, mailIndex) => (
            <MailCard key={mailIndex} mailItems={mailItems} onClick={() => handleModal(mailItems)} />
          ))}
        </div>
      );
    } else {
      return (
        <>
          {mailLogData?.custom_notifications.map((mailItems, mailIndex) => (
            <MailTableRow
              key={mailIndex}
              mailItems={mailItems}
              offset={mailLogData?.offset}
              index={mailIndex}
              onClick={() => handleModal(mailItems)}
            />
          ))}
        </>
      );
    }
  };

  return (
    <>
      <div className="user-details-Wrappper">
        <div className="user-details-Table email-log-wrapper">
          {mailLogLoading ? (
            <PageLoader />
          ) : (
            <CommonTable
              columns={ADMINEMAILLOGCOLUMN}
              data={mailLogData?.custom_notifications}
              renderSortButton={renderSortButton}
              renderRow={renderMails}
              placeholder={" No Emails(s) Found"}
              fixedColumn={1}
            />
          )}
        </div>
      </div>
      <div className="user-details-mobile-Wrappper">
        <div className="user-section">{renderMails(true)}</div>
      </div>
      <EmailLogModal
        subject={subject}
        message={message}
        showModal={showModal}
        handleClose={handleClose}
        mail={mail}
        showEmail={showEmail}
        setShowEmail={setShowEmail}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        undeliveredEmailStatus={undeliveredEmailStatus}
        setUndeliveredEmailStatus={setUndeliveredEmailStatus}
        emailLogStatus={emailLogStatus}
        setEmailLogStatus={setEmailLogStatus}
        recipientEmailStatus={recipientEmailStatus}
        setRecipientEmailStatus={setRecipientEmailStatus}
        selectedRecipients={selectedRecipients}
        setSelectedRecipients={setSelectedRecipients}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        setSubject={setSubject}
        setMessage={setMessage}
      />
    </>
  );
};
export default EmailLogComponent;