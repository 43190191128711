import { Months } from "utils/constants";
import { ErrorMessages } from "utils/messageConstants";
import { showErrorToast } from "utils/toastUtil";

export const validateCertificate = (certification:Certification, certificationDate: CertificationDate):[CertificationError,boolean] => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');  
    const certificationError = {} as CertificationError;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    let isValid = true;
    if (certification.certification_name === "") {
      certificationError.certificationName = "Certification name required";
      isValid=false;
    }
    if (certification.issued_by === "") {
      certificationError.issued_by = "Issued by required";
      isValid=false;
    }
    if (certificationDate.issueMonth === null) {
      certificationError.issueMonth = "Issue month required";
      isValid=false;
    }
    if (certification.is_expirable && certificationDate.expiryMonth === null) {
      certificationError.expiryMonth = "Expiry month required";
      isValid=false;
    }
    if (certificationDate.issueYear === "") {
      certificationError.issueYear = "Issue Year required";
      isValid=false;
    }
    if (certificationDate.issueYear !== "" && certificationDate.issueYear.length !== 4) {
      certificationError.issueYear = "Invalid year";
      isValid=false;
    }
    if(certificationDate.issueMonth!==null && certificationDate.issueYear!=="" && certificationDate.issueYear.length===4){
      const selectedMonthNumber = new Date(
        Date.parse(`${certificationDate.issueMonth.value} 1, ${certificationDate.issueYear}`)
      ).getMonth() + 1;
      if(Number(certificationDate.issueYear) > currentYear){
        certificationError.issueYear = "Invalid year";
        isValid=false;
        showErrorToast(ErrorMessages.CERTIFICATION_ISSUE_DATE);
        return [ certificationError,isValid];
      }
      else if (     
        (Number(certificationDate.issueYear) === currentYear && selectedMonthNumber > currentMonth)
      ) {
        certificationError.issueMonth = "Invalid month";
        isValid=false;
        showErrorToast(ErrorMessages.CERTIFICATION_ISSUE_DATE);
        return [ certificationError,isValid];
      }
    }
    if (certification.is_expirable && certificationDate.expiryYear === "") {
      certificationError.expiryYear = "Expiry year required";
      isValid=false;
    }
    if (certification.is_expirable && certificationDate.expiryYear !== "" && certificationDate.expiryYear.length !== 4) {
      certificationError.expiryYear = "Invalid year";
      isValid=false;
      return [ certificationError,isValid];
    }
    if(!isValidDates(certification,certificationDate)){
      certificationError.expiryYear = "Invalid expiry";
      showErrorToast(ErrorMessages.CERTIFICATION_EXPIRY_DATE);
      isValid=false;
    }
    if(certification.credential_url!=="" && !regex.test(certification.credential_url)){
      certificationError.credentialUrl ="Invalid url";
      isValid=false;
    }
    return [certificationError,isValid];
  };
  const isValidDates = (certification:Certification,certificationDate: CertificationDate)=>{
    if(certification.is_expirable){
      if(certificationDate.issueMonth!== null && certificationDate.issueYear !== "" && certificationDate.issueYear.length === 4 &&
        certificationDate.expiryMonth !== null && certificationDate.expiryYear !== "" && certificationDate.expiryYear.length === 4  )
        {
          return isValidExpiryDate(certificationDate);
        }
      }
    return true;
  }
  const isValidExpiryDate = (certificationDate:CertificationDate) => {
    const issueDate = new Date(`${certificationDate.issueYear}-${certificationDate.issueMonth?.value}-01`);
    const expiryDate = new Date(`${certificationDate.expiryYear}-${certificationDate.expiryMonth?.value}-01`);
    return  issueDate < expiryDate;
  };

  export const getSelectedCertification = (certificate:Certification):[Certification,CertificationDate]=>{    const certification:Certification ={
      certification_name:certificate.certification_name,
      credential_id:certificate.credential_id,
      credential_url:certificate.credential_url,
      is_expirable:certificate.is_expirable,
      issued_by:certificate.issued_by,
      tags:certificate.tags??[],
      expiry_date:undefined,
      issued_date:undefined,
      id:certificate.id,
    };
    const certificationDate:CertificationDate ={
      issueMonth:null,
      expiryMonth:null,
      expiryYear:'',
      issueYear:'',
    }
    const issueDates=  certificate.issued_date?.split("-")??[];
    const expiryDates=  certificate.expiry_date?.split("-")??[];
    if(issueDates){
      certificationDate.issueYear=issueDates[0];
      certificationDate.issueMonth = Months.find(e=>e.value===issueDates[1])??null;
      certificationDate.expiryYear=expiryDates[0];
      certificationDate.expiryMonth = Months.find(e=>e.value===expiryDates[1])??null;
    }
    return [certification,certificationDate];
  }

