import React, { useState } from "react";
import "./domainListingModal.scss";
import InputField from "components/common-components/InputField";
import { INPUT_MAX_LENGTH } from "utils/constants";
import { ErrorMessages, messages } from "utils/messageConstants";
import { showSuccessToast } from "utils/toastUtil";
import { useGetDomainById } from "api/admin/useDomainById";
import { useSaveAdminSubDomain } from "api/admin/useSaveAdminSubDomain";
import { useUpdateAdminSubDomain } from "api/admin/useUpdateAdminSubDomain";


interface Props {
  customheader?: any;
  data?: any;
  setDomain?: any;
  domain?: any;
}

const DomainListingModal: React.FC<Props> = ({ customheader, data, setDomain, domain }) => {
  const [excelState, setExcelState] = useState(false);
  const[uploadState,setUploadState]=useState(false);
  const { mutateAsync: saveSubDomain  } = useSaveAdminSubDomain();
  const { data: Domain, isLoading,refetch} = useGetDomainById(data?.id);
  const [subDomainName, setSubDomainName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openEditSubDomainIndex, setOpenEditSubDomainIndex] = useState<number | null>(null);
  const [editSubDomain, setEditSubDomain] = useState<Boolean>(false);
  const [editSubDomainName, setEditSubDomainName] = useState<any>()
  const [error, setError] = useState("")
  const { mutateAsync: updateSubDomainData } = useUpdateAdminSubDomain();

  const handleSkillEdit = (subdomainIndex: number, subdomainItem: any) => {
    setOpenEditSubDomainIndex(subdomainIndex);
    setErrorMessage("")
    setEditSubDomain(true);
    setEditSubDomainName(subdomainItem)

  };

  const handleSkillClose = () => {
    setOpenEditSubDomainIndex(null);
    setEditSubDomain(false);
    setError("")
    setErrorMessage("")

  };

  const uploadDataManualy=()=>{
    setUploadState(true);
    setExcelState(true)

  }

  const onSaveSubDomain = async () => {
    if (subDomainName.trim() === "") {
      setErrorMessage(ErrorMessages.SUB_DOMAIN_NAME_MISSING);
      return;
    }
      const response = await saveSubDomain({ subDomainName: subDomainName.trim(), domain_id: data?.id });
      if (response.data) {
        const updatedDomain = await refetch();
        setDomain({
          ...domain ,
          sub_domain_count: updatedDomain.data?.sub_domain_count
        });
        showSuccessToast(messages.SUBDOMAIN_SAVE_SUCCESS);
        setErrorMessage("");
        setSubDomainName("")
      } else if(response?.response?.data?.message){
        setErrorMessage(response?.response?.data?.message);
     }else{
       setErrorMessage(messages.SUBDOMAIN_SAVE_DUPLICATE);
    }
  };

  const updateSubDomain = async()=>{
    if (editSubDomainName.sub_domain_name.trim() === "") {
      setError(ErrorMessages.SUB_DOMAIN_NAME_MISSING);
      return;
    }
      const response = await updateSubDomainData({subDomainId: editSubDomainName?.sub_domain_id, subDomainName: editSubDomainName.sub_domain_name.trim(), domainId: data?.id });
      if (response.data) {
        setEditSubDomainName({...editSubDomainName,sub_domain_name: response.data.sub_domain_name})
        showSuccessToast(messages.SUBDOMAIN_UPDATE_SUCCESS);
        setError("");
        setOpenEditSubDomainIndex(null);
        setEditSubDomain(false);
          } 
         else if(response?.response?.data?.message){
          setError(response?.response?.data?.message);
       } 
          else {
        setError(messages.SUBDOMAIN_SAVE_DUPLICATE);
      }
    
};

  return (
    <div className="app-modal-content sm add-skill-data">
      
        {!excelState  ? (
          <div className="upload-catgeory-mainpage">
            <div className="folder-icon-holder">
              <svg className="svg-icon folder2-icon">
                <use href="#folderIcon2">
                  <title>Add Icon</title>
                </use>
              </svg>
            </div>
            <div className="add-category-details">
              <span> Exciting!</span>
              <span>You’ve just created a new domain</span>
              <span> Ready to add sub domains? </span>
              <span>Give it a try now</span>
            </div>
            <div className="add-category-buttons-holder">
              <button className="app-btn secondary addmanual-btn" onClick={uploadDataManualy}> Add Manually</button>
            </div>
          </div>
        ) : uploadState ? (
            <>
          <div className='skill-data-heading add-category-wrapper'>
            <div className='skill-data-heading-th2 th-open'>
                        <div className='skill-data-title'> SUB DOMAIN Name</div>
                        <div className='skill-heading-edit-mode'>
                        <InputField type="text"
                                value={subDomainName}
                                        onChange={(e:any)=>{
                                          setErrorMessage("");
                                          setSubDomainName(e.target.value);                                         
                                          setErrorMessage("");
                                        }}
                                        maxLength={INPUT_MAX_LENGTH}
                                        error={errorMessage}
                                        placeholder='Add a new sub domain here'
                                  ></InputField>
                          <button className='app-btn secondary addskillname-btn' onClick={onSaveSubDomain}>
                            <svg className="svg-icon plus-icon">
                              <use href="#plusIcon">
                                <title>Add Icon</title>
                              </use>
                            </svg>
                          </button>
                        </div>
            </div>
              </div>
              <div className={Domain && Domain.sub_domains && Domain.sub_domains.length > 9 ? 'skill-data-main hasScroll' : 'skill-data-main'}>

                {Domain?.sub_domains?.map((domainItem, domainIndex) => (
                  <div className='skill-data-wrapper edit-mode'>
                    <div className='skill-data-details' key={domainIndex} >
                      {domainIndex !== openEditSubDomainIndex ? (
                        <div className='skill-data-details'>
                          <div className='skill-data-name'>{domainItem?.sub_domain_name}</div>
                          <div className="skill-edit-btn th-open">
                            <button className="app-btn icon-only-sm" title='edit' onClick={() => handleSkillEdit(domainIndex, domainItem)}>
                              <svg className="svg-icon edit-icon">
                                <use href="#editIcon">
                                  <title>Edit Icon</title>
                                </use>
                              </svg>
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className={editSubDomain && domainIndex === openEditSubDomainIndex ? 'skill-data-edit-wrapper th-open' : 'skill-data-edit-wrapper th-close'}>
                      <InputField type="text" value={editSubDomainName?.sub_domain_name}
                        onChange={(e: any) => {
                          setEditSubDomainName({ ...editSubDomainName, sub_domain_name: e.target.value });
                          setError("");
                        }}
                        maxLength={INPUT_MAX_LENGTH}
                      error={error}
                      ></InputField>
                      <button className='app-btn icon-only-sm skildata-btn' title='close' onClick={handleSkillClose}>
                        <svg className="svg-icon close-icon">
                          <use href="#closeIcon">
                            <title>Close Icon</title>
                          </use>
                        </svg>
                      </button>
                      <button className='app-btn icon-only-sm skildata-btn' title='add' onClick={updateSubDomain}>
                        <svg className="svg-icon plus-icon">
                          <use href="#tickIcon">
                            <title>Tick Icon</title>
                          </use>
                        </svg>
                      </button>
                    </div>
                  </div>))}
              </div>
              </>
          
          
          ) :
       (
        <div className="upload-excelsheet-wrapper ">
          <div className="upload-details-wrapper">
            <div className="upload-header">
              <div className="excel-icon-holder">
                  <svg className="svg-icon excel-icon">
                    <use href="#excelIcon">
                      <title>excel Icon</title>
                    </use>
                  </svg> 
              </div>
             
           Uploading Excell Sheet   
            </div>
            <div className="upload-status">43%</div>
          </div>
          <div className="progress-bar-holder">
            <div className="progress-bar-border">
              <div className="progress-bar" style={{ width: "70%" }}></div>
            </div>
          </div>
          <div className="upload-button-holder ">
            <button className="app-btn secondary canceluplod-btn">Cancel upload</button>
          </div>
              </div>
              
          )}
        
    </div>
  );
};

export default DomainListingModal;
