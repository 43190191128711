import React, { useState, useEffect, useRef } from "react";
import "./notification.scss";
import { useGetAdminNotifications } from "api/admin/useGetNotifications";
import { getTimeDifference } from "utils/dateUtil";
import UserProfileImage from "components/UserImage";
import { useUserStore } from "store/useUserStore";

interface Props {
  handleReviewModal?: any;
  onSkillReviewClicked: (item: NotificationPayload) => void;
}

const NotificationSection: React.FC<Props> = ({
  handleReviewModal,
  onSkillReviewClicked,
}) => {
  const { data: notifications } = useGetAdminNotifications();
  const closeRef = useRef(null);
  const [toggleNotification, setToggleNotification] = useState(false);
  const { userManagedRole } = useUserStore();
  const handleNotification = () => {
    setToggleNotification(!toggleNotification);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    window.addEventListener('keydown', close)
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      window.removeEventListener('keydown', close)
    };
  }, []);
  const handleClickOutside = (event: { target: any; }) => {
    if (closeRef.current && !(closeRef.current as HTMLElement).contains(event.target)) {
      setToggleNotification(false);
    }
  };
  const handleNotificationClick = (payload: NotificationPayload) => {
    setToggleNotification(false);
    onSkillReviewClicked(payload);
  }
  const close = (event: any) => {
    if (event.key === 'Escape') {
      setToggleNotification(false);
    }
  }
  const isNotificationPending = notifications && notifications.length > 0;

  return (
    <>
      <div className={`user-notification-dropdown ${toggleNotification ? "dropdown-open" : ""}`} ref={closeRef}>
        {/* active class used for notification dot , dropdown-open class to open the notification  */}
        {userManagedRole ? null : <button
          className={`icon-only notification-nav nav-dropdown-btn ${isNotificationPending ? "active" : ""}`}
          onClick={handleNotification}>
          <svg className="svg-icon notification-icon">
            <use href="#notificationIcon"></use>
          </svg>
          <span className="menu-arrow"> </span>
        </button>}
        {userManagedRole ? null : <div className="sub-menu-header-menu-wrapper type1 mega-sub-menu">
          <div className="notification-header">
            <div className="notification-header-title">
              <button className="icon-only back-arrow" onClick={handleNotification}>
                <svg className="svg-icon back-arrow-icon">
                  <use href="#backArrowIcon"></use>
                </svg>
              </button>
              <div className="page-header">Notifications<span className="status-count">{notifications ? notifications.length : ""}</span></div>
            </div>
          </div>
{ toggleNotification &&  <NotificationList notifications={notifications} handleReviewModal={handleNotificationClick} />
}        </div>}
      </div>
    </>
  );
};

const NotificationList = ({ notifications, handleReviewModal }: {
  notifications: NotificationItem[] | undefined;
  handleReviewModal: (notificationPayload: NotificationPayload) => void;
}) => {
  if (notifications && notifications.length > 0) {
    return (
      <div className="notification-main-content">
        {notifications.map((notification: NotificationItem, index) => (
          <div className="notification-item" key={index}>
            <div className="notification-pic">
              <div className="profile-img-holder">
                <UserProfileImage first_name={notification.payload.userName} ssoId={notification.payload.SSO_login_id} />
              </div>
            </div>
            <div className="notification-layout-set">
              <div className="notification-admin-content">
                <span className="bold-name">{notification.payload.userName}</span> has added
                <span className="bold-name"> '{notification.payload.skillName}'</span> as a skill
                <span className="notification-date date-check">{getTimeDifference(notification.updated_at)}</span>
              </div>
              <button className="app-btn secondary" onClick={() => {
                const notificationPayload = notification.payload;
                notificationPayload.notififcationId = notification.id;
                handleReviewModal(notificationPayload);
              }}>
                Review
              </button>
            </div>
          </div>
        ))}

      </div>
    )
  }
  return (
    <div className="notification-main-content">
      <div className="notification-item">
        <div className="notification-layout-set">
          <div className="notification-admin-content">
            <span className="bold-name">No notifications</span>
          </div>
        </div>
      </div>
    </div>
  )

}
export default NotificationSection;
