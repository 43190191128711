import React, { useEffect, useState } from "react";
import "../../AdminFilter/adminFilter.scss";
import { emailLogFilterOptions, undeliveredEmailFilterOptions } from "utils/constants";
import EmailLogList from "../../EmailLogList";
import { active } from "d3";

interface Props {
  handleAdminFilter?: any;
  emailLogStatus?: EmailStatus[];
  setEmailLogStatus?: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
  undeliveredEmailStatus?: EmailStatus[];
  setUndeliveredEmailStatus?: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
  recipientEmailStatus?: EmailStatus[];
  setRecipientEmailStatus?: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
  activeTab?: string;
  setActiveTab?: (tab: string) => void;
}

const AdminEmailLogFilter: React.FC<Props> = ({
  handleAdminFilter,
  emailLogStatus,
  setEmailLogStatus,
  activeTab,
  undeliveredEmailStatus,
  setUndeliveredEmailStatus,
  recipientEmailStatus,
  setRecipientEmailStatus,
}: Props) => {

  const [filterOptions, setFilterOptions] = useState<EmailStatus[]>(emailLogFilterOptions);
  const [recipientFilterOptions, setRecipientFilterOptions] = useState<EmailStatus[]>(emailLogFilterOptions);
  const [undeliveredFilterOptions, setUndeliveredFilterOptions] = useState<EmailStatus[]>(undeliveredEmailFilterOptions)

  useEffect(() => {
    if (setEmailLogStatus && emailLogStatus) {
      if (emailLogStatus.length > 0) {
        setFilterOptions(emailLogStatus);
      } else {
        const initialFilters = emailLogFilterOptions.map((item) => ({ ...item, checked: false }));
        setFilterOptions(initialFilters);
        setEmailLogStatus(initialFilters);
      }
    }
    if (setRecipientEmailStatus && recipientEmailStatus) {
      if (recipientEmailStatus.length > 0) {
        setRecipientFilterOptions(recipientEmailStatus);
      } else {
        const initialRecipientFilters = emailLogFilterOptions.map((item) => ({ ...item, checked: false }));
        setRecipientFilterOptions(initialRecipientFilters);
        setRecipientEmailStatus(initialRecipientFilters);
      }
    }
    if (setUndeliveredEmailStatus && undeliveredEmailStatus) {
      if (undeliveredEmailStatus.length > 0) {
        setUndeliveredFilterOptions(undeliveredEmailStatus);
      } else {
        const initialUndeliveredFilters = undeliveredEmailFilterOptions.map((item) => ({ ...item, checked: false }));
        setUndeliveredFilterOptions(initialUndeliveredFilters);
        setUndeliveredEmailStatus(initialUndeliveredFilters);
      }
    }
  }, [emailLogStatus, undeliveredEmailStatus, recipientEmailStatus]);

  const [filterTab, setFilterTab] = useState("Email");
  const handleFilterTab = (filterMenu: string) => {
    setFilterTab(filterMenu);
  };

  const onEmailStatusChange = (emailStatus: EmailStatus) => {
    if (undeliveredFilterOptions) {
      const undeliveredFilteredList = undeliveredFilterOptions.map((item) => {
        if (item.id === emailStatus.id) {
          console.log("enter")
          return { ...item, checked: !emailStatus.checked };
        }
        return item;
      });
      setUndeliveredFilterOptions(undeliveredFilteredList);
    }

    if (recipientFilterOptions) {
      const recipientFilteredList = recipientFilterOptions.map((item) => {
        if (item.id === emailStatus.id) {
          return { ...item, checked: !emailStatus.checked };
        }
        return item;
      });
      setRecipientFilterOptions(recipientFilteredList);
    }

    if (filterOptions) {
      const filteredList = filterOptions?.map((item) => {
        if (item.id === emailStatus.id) {
          return { ...item, checked: !emailStatus.checked };
        }
        return item;
      });
      setFilterOptions(filteredList);
    }

  };

  const resetFilters = () => {

    const resetFiltersEmailLog = filterOptions.map((item: EmailStatus) => ({ ...item, checked: false }));
    setFilterOptions(resetFiltersEmailLog);
    if (setEmailLogStatus) {
      setEmailLogStatus(resetFiltersEmailLog);
    }

    const resetRecipientEmailLog = recipientFilterOptions.map((item: EmailStatus) => ({ ...item, checked: false }));
    setRecipientFilterOptions(resetRecipientEmailLog);
    if (setRecipientEmailStatus) {
      setRecipientEmailStatus(resetRecipientEmailLog);
    }

    const resetUndeliveredOptions = undeliveredFilterOptions.map((item: EmailStatus) => ({ ...item, checked: false }));
    setUndeliveredFilterOptions(resetUndeliveredOptions);
    if (setUndeliveredEmailStatus) {
      setUndeliveredEmailStatus(resetUndeliveredOptions);
    }
  };

  const applyFilter = () => {
    // if (setEmailLogStatus && emailLogStatus){
    //   setEmailLogStatus(filterOptions);   
    // }
    // if (setUndeliveredEmailStatus && undeliveredFilterOptions) {    
    //   setUndeliveredEmailStatus(undeliveredFilterOptions);
    // }
    // if (setRecipientEmailStatus && recipientEmailStatus) {    
    //   setRecipientEmailStatus(recipientFilterOptions);
    // }
    // if (handleAdminFilter) {
    //   handleAdminFilter();
    // }
    if (activeTab === 'undelivered' && setUndeliveredEmailStatus) {
      setUndeliveredEmailStatus(undeliveredFilterOptions);
    } else if (activeTab === 'total' && setRecipientEmailStatus) {
      setRecipientEmailStatus(recipientFilterOptions);
    } else if (setEmailLogStatus) {
      setEmailLogStatus(filterOptions);
    }
    if (handleAdminFilter) {
      handleAdminFilter();
    }
  };

  return (
    <div className="admin-filter-drop-down filter-width-custom">
      < div className="filter-header" >
        <button className="back-btn" onClick={handleAdminFilter} >
          <svg className="svg-icon plus-icon" >
            <use href="#backArrowIcon" >
              <title>Back Button</title>
            </use >
          </svg >
          Filter
        </button >
      </div >
      <nav className="filter-nav" >
        <div className="filter-menu" >
          <div className="filter-menu-item two-col" >
            {activeTab === 'undelivered' ? (
              <button
                className={`filter-tab-btn`}
                onClick={() => handleFilterTab("Email")}
              >
                Failure Reasons
              </button>
            ) : (
              <button
                className={`filter-tab-btn`}
                onClick={() => handleFilterTab("Email")}
              >
                Status
              </button>
            )}
            <div
              className={`filter-menu-list ${filterTab === "Email" ? "active" : ""}`}
              style={{ "--itemCount": filterOptions.length } as React.CSSProperties}
            >
              {activeTab === 'undelivered' ? (
                <EmailLogList
                  EmailLogList={undeliveredFilterOptions}
                  onChange={(item) => {
                    onEmailStatusChange(item);
                  }}
                />
              ) : activeTab === 'total' ? (
                <EmailLogList
                  EmailLogList={recipientFilterOptions}
                  onChange={(item) => {
                    onEmailStatusChange(item);
                  }}
                />
              ) : (
                <EmailLogList
                  EmailLogList={filterOptions}
                  onChange={(item) => {
                    onEmailStatusChange(item);
                  }}
                />
              )}
            </div>
          </div >
        </div >
      </nav >
      <div className="filter-footer" >
        <div className="btn-group align-right" >
          <button className="app-btn min-width" onClick={resetFilters} >
            Reset
          </button >
          <button className="app-btn min-width primary" onClick={applyFilter} >
            Apply
          </button >
        </div >
      </div >
    </div >
  );
};

export default AdminEmailLogFilter;