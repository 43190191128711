import "../../../../../../../../components/common-components/Pagination/pagination.scss";
interface Props {
    totalItems: number;
    itemsPerPage: number;
    limit: number;
    onPageChange: (page: number) => void;
    recipientsCurrentPage: number;
    setRecipientsCurrentPage: (page: number) => void;
}

const EmailRecipientPagination = ({ totalItems, itemsPerPage, onPageChange, limit, recipientsCurrentPage, setRecipientsCurrentPage }: Props) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (page: number) => {
        setRecipientsCurrentPage(page);
        onPageChange(page);
    };

    const handlePrevPage = () => {
        if (recipientsCurrentPage > 1) {
            handlePageChange(recipientsCurrentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (recipientsCurrentPage < totalPages) {
            handlePageChange(recipientsCurrentPage + 1);
        }
    };
    const renderPaginationItems = () => {
        const paginationItems = [];
        if (totalPages <= 10) {
            for (let page = 1; page <= totalPages; page++) {
                paginationItems.push(
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`icon-only pagination-num ${recipientsCurrentPage === page ? 'active' : ''}`}>
                        {page}
                    </button>
                );
            }
        } else {
            const visiblePages = 10; // Maximum visible pages
            const sidePages = Math.floor(visiblePages / 2);
            let startPage = recipientsCurrentPage - sidePages;
            let endPage = recipientsCurrentPage + sidePages;

            if (startPage <= 0) {
                endPage += Math.abs(startPage) + 1;
                startPage = 1;
            }

            if (endPage > totalPages) {
                startPage -= endPage - totalPages;
                endPage = totalPages;
            }

            for (let page = startPage; page <= endPage; page++) {
                paginationItems.push(
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`icon-only pagination-num ${recipientsCurrentPage === page ? 'active' : ''
                            }`}
                    >
                        {page}
                    </button>
                );
            }

            if (startPage > 1) {
                paginationItems.unshift(
                    <span key="ellipsis-start" className="pagination-ellipsis">
                        ...
                    </span>
                );
                paginationItems.unshift(
                    <button
                        key={1}
                        onClick={() => handlePageChange(1)}
                        className="icon-only pagination-num"
                    >
                        1
                    </button>
                );
            }

            if (endPage < totalPages) {
                paginationItems.push(
                    <span key="ellipsis-end" className="pagination-ellipsis">
                        ...
                    </span>
                );
                paginationItems.push(
                    <button
                        key={totalPages}
                        onClick={() => handlePageChange(totalPages)}
                        className="icon-only pagination-num"
                    >
                        {totalPages}
                    </button>
                );
            }

        }
        return paginationItems;
    };
    if (totalItems < limit) {
        return null;
    }
    return (
        <div className="pagination-footer">
            <button className={`${recipientsCurrentPage === 1 ? "app-btn arrow-btn-inactive" : "app-btn arrow-btn-active"}`}
                onClick={handlePrevPage}>
                <svg className="svg-icon left-move-icon">
                    <use href="#leftmoveIcon">
                        <title>Add Icon</title>
                    </use>
                </svg>
            </button>
            {renderPaginationItems()}
            <button className={`${recipientsCurrentPage === totalPages ? "app-btn arrow-btn-inactive" : "app-btn arrow-btn-active"}`}
                onClick={handleNextPage}>
                <svg className="svg-icon right-move-icon">
                    <use href="#rightmoveIcon">
                        <title>Add Icon</title>
                    </use>
                </svg>
            </button>
        </div>
    );
};

export default EmailRecipientPagination;

