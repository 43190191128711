import React, { RefObject, useEffect } from "react";
import "../../searchPanel.scss"
import { useAdminStore } from "store/adminStore";
import RadioButton from "components/common-components/RadioButton";
import { SORTBYMOBILE, SORTVALUEMOBILECERTIFICATIONFILTER, SORTVALUEMOBILEDEFAULT, SORTVALUEMOBILEDOMAINFILTER, SORTVALUEMOBILESKILLFILTER } from "utils/constants";
import { sortOptionsMap } from "utils/helpers";

interface Props {
    from?: string;
    handleSortToggle: () => void;
    mobileSortRef?: any;
    sortToggle?: boolean;
    setSortToggle: React.Dispatch<React.SetStateAction<boolean>>;
    setIsFilterApply: React.Dispatch<React.SetStateAction<boolean>>;
    tempSortOrder: Option[];
    setTempSortOrder: (selectedOptions: Option[]) => void;
    tempSortBy: Option[];
    setTempSortBy: (selectedOptions: Option[]) => void;

}

const UserFilterSort: React.FC<Props> = ({
    from,
    handleSortToggle,
    mobileSortRef,
    setSortToggle,
    sortToggle,
    setIsFilterApply,
    tempSortOrder,
    setTempSortOrder,
    tempSortBy,
    setTempSortBy,

}) => {

    const {
        filterEnableIcon,
        setSortBy,
        setSortOrder,
        setSortOptionDefaultSkills,
        filterSelection,
        setSortOptionCertification,
        setSortOptionDomain,
        setSortOptionSkills,
        filterEnable
    } = useAdminStore();

    const handleSortApply = () => {
        setSortBy(tempSortBy)
        setSortOrder(tempSortOrder)
        if (filterEnable === false) {
            if (tempSortBy.length > 0 || tempSortOrder.length > 0) {
                if (tempSortBy[0].label === "Id" && tempSortOrder[0].label === "Descending") {
                    setSortOptionDefaultSkills({
                        label: "employee_id_desc",
                        value: "employee_id:desc"
                    },
                    )
                }
                else if (tempSortBy[0].label === "Id" && tempSortOrder[0].label === "Ascending") {
                    setSortOptionDefaultSkills({
                        label: "employee_id_asc",
                        value: "employee_id:asc"
                    },
                    )
                }
                else if (tempSortBy[0].label === "Employee" && tempSortOrder[0].label === "Ascending") {
                    setSortOptionDefaultSkills({
                        label: "first_name_last_name_asc",
                        value: "first_name_last_name:asc"
                    },
                    )
                }
                else if (tempSortBy[0].label === "Employee" && tempSortOrder[0].label === "Descending") {
                    setSortOptionDefaultSkills({
                        label: "first_name_last_name_desc",
                        value: "first_name_last_name:desc"
                    },
                    )
                }
                else if (tempSortBy[0].label === "Designation" && tempSortOrder[0].label === "Descending") {
                    setSortOptionDefaultSkills({
                        label: "designation_desc",
                        value: "designation:desc"
                    },
                    )
                }
                else if (tempSortBy[0].label === "Designation" && tempSortOrder[0].label === "Ascending") {
                    setSortOptionDefaultSkills({
                        label: "designation_asc",
                        value: "designation:asc"
                    },
                    )
                }
                else if (tempSortBy[0].label === "Primary Skill" && tempSortOrder[0].label === "Ascending") {
                    setSortOptionDefaultSkills({
                        label: "skill_name_asc",
                        value: "skill_name:asc"
                    },
                    )
                }
                else if (tempSortBy[0].label === "Primary Skill" && tempSortOrder[0].label === "Descending") {
                    setSortOptionDefaultSkills({
                        label: "skill_name_desc",
                        value: "skill_name:desc"
                    },
                    )
                }
                handleSortToggle();
                setIsFilterApply(false)

            }
        }
        else if (filterSelection && sortOptionsMap[filterSelection] && (tempSortBy.length > 0 || tempSortOrder.length > 0)) {
            const sortOptions = sortOptionsMap[filterSelection];
            const sortByLabel = tempSortBy[0].label;
            const sortOrderLabel = tempSortOrder[0].label;
            const sortOption = sortOptions[sortByLabel][sortOrderLabel];
            if (sortOption) {
                switch (filterSelection) {
                    case "Skills":
                        setSortOptionSkills(sortOption);
                        break;
                    case "Certifications":
                        setSortOptionCertification(sortOption);
                        break;
                    case "Domains":
                        setSortOptionDomain(sortOption);
                        break;
                    default:
                        break;
                }
            }
            handleSortToggle();
        }
        else {
            handleSortToggle()
        }
    };


    const handleReset = () => {
        if (from == "userData") {
            setTempSortBy([{ label: "Employee", value: "Employee" }]);
            setTempSortOrder([{ label: "Ascending", value: "Ascending" }]);
            setSortBy([{ label: "Employee", value: "Employee" }]);
            setSortOrder([{ label: "Ascending", value: "Ascending" }]);
            setSortOptionDefaultSkills({
                label: "first_name_last_name_asc",
                value: "first_name_last_name:asc"
            },
            )
            setSortOptionSkills({
                label: "first_name_last_name_asc",
                value: "first_name_last_name:asc"
            })
            setSortOptionCertification({
                label: "first_name_last_name_asc",
                value: "first_name_last_name:asc"
            })
            setSortOptionDomain({
                label: "first_name_last_name_asc",
                value: "first_name_last_name:asc"
            })
        }
    }

    const handleChangeSortBy = (selectedOptions: Option) => {
        setTempSortBy([selectedOptions]);
    };

    const handleChangeSortOrder = (selectedOptions: Option) => {
        setTempSortOrder([selectedOptions]);
    };

    const handleClickOutsideSort = (event: { target: any }) => {
        const sortPanel = document.querySelector('.sort-panel');
        const isClickInsideSortPanel = sortPanel && sortPanel.contains(event.target);

        if (!isClickInsideSortPanel && mobileSortRef.current && !(mobileSortRef.current as HTMLElement).contains(event.target)) {
            setSortToggle(false);
            setIsFilterApply(false);
        }
    };
    const closeSort = (event: any) => {
        if (event.key === "Escape") {
            setSortToggle(false)
            setIsFilterApply(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutsideSort, false);
        window.addEventListener("keydown", closeSort);
        return () => {
            document.removeEventListener("click", handleClickOutsideSort, false);
            window.removeEventListener("keydown", closeSort);
        };
    }, [sortToggle]);


    useEffect(() => {
        handleReset()
    }, [filterSelection])
    return (
        <>
            <div className={`sort-panel ${sortToggle ? "active" : ''}`}>
                <div className="sort-panel-head">
                    <span className="sort-panel-labels">Sort by</span>
                </div>
                <div className="sort-panel-body">
                    {filterEnableIcon ? (filterSelection === "Skills" ? SORTVALUEMOBILESKILLFILTER.map((item: Option, key: number) => {
                        return (
                            <RadioButton key={key} label={item.label} name="sortBy"
                                defaultChecked={tempSortBy?.[0]?.label === item.label}
                                onChange={() => { handleChangeSortBy(item) }} />
                        );
                    }) : filterSelection === "Certifications" ? SORTVALUEMOBILECERTIFICATIONFILTER.map((item: Option, key: number) => {
                        return (
                            <RadioButton key={key} label={item.label} name="sortBy"
                                defaultChecked={tempSortBy?.[0]?.label === item.label}
                                onChange={() => { handleChangeSortBy(item) }} />
                        );
                    }) : SORTVALUEMOBILEDOMAINFILTER.map((item: Option, key: number) => {
                        return (
                            <RadioButton key={key} label={item.label} name="sortBy"
                                defaultChecked={tempSortBy?.[0]?.label === item.label}
                                onChange={() => { handleChangeSortBy(item) }} />
                        );
                    })) : SORTVALUEMOBILEDEFAULT.map((item: Option, key: number) => {
                        return (
                            <RadioButton key={key} label={item.label} name="sortBy"
                                defaultChecked={tempSortBy?.[0]?.label === item.label}
                                onChange={() => { handleChangeSortBy(item) }} />
                        );
                    })}
                </div>
                <div className="sort-panel-footer">
                    <div className="sort-panel-footer-top">
                        <span className="sort-panel-labels">Sort order</span>
                        <div className="inline-radio-group">
                            {SORTBYMOBILE.map((item: Option, key: number) => {
                                return (
                                    <RadioButton key={key} label={item.label} name="sortOrder"
                                        defaultChecked={tempSortOrder?.[0]?.label === item.label}
                                        onChange={() => { handleChangeSortOrder(item) }} />
                                );
                            })}
                        </div>
                    </div>
                    <div className="sort-panel-footer-bottom">
                        <button className="app-btn  secondary" onClick={() => {
                            handleReset();
                        }}>Reset</button>
                        <button className="app-btn  primary"
                            onClick={() => {
                                handleSortApply()
                            }
                            }>Apply</button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default UserFilterSort;
