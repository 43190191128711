import React, { useState } from "react";
import "../../searchPanel.scss"
import { useUserStore } from "store/useUserStore";
import ComposeModal from "feature/admin/components/AdminSkillsWrapper/UserDataDetails/ComposeModal";
import Modal from "components/common-components/Modal";
import { useSaveMail } from "api/admin/useSaveMail";
import { ErrorMessages, messages } from "utils/messageConstants";
import { showSuccessToast } from "utils/toastUtil";

interface Props {
    from?: string;
}
const ComposeMail: React.FC<Props> = ({
    from

}) => {
    const { userManagedRole } = useUserStore();
    const { mutateAsync: saveMail, isLoading } = useSaveMail();
    const [toggleComposeModal, setToggleComposeModal] = useState(false);
    const [selectedRecipients, setSelectedRecipients] = useState<{ value: string; label: string; }[]>([]);
    const [subject, setSubject] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState({
        toError: "",
        subjectError: "",
        bodyError: "",
    });


    const handlecomposeModal = () => {
        setSelectedRecipients([])
        setErrorMessage({
            toError: "",
            subjectError: "",
            bodyError: "",
        })
        setSubject("")
        setMessage("")
        setToggleComposeModal(!toggleComposeModal);
    }

    function cleanHtmlContent(htmlContent: any) {
        // Create a temporary DOM element to parse the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;
 
        // Select all <p> tags
        const pTags = tempDiv.querySelectorAll('p');
 
        pTags.forEach(p => {
            // Convert <p> tags to <div> tags
            const div = document.createElement('div');
            div.innerHTML = p.innerHTML;
            p?.parentNode?.replaceChild(div, p);
        });
 
        return tempDiv.innerHTML;
    }
 
    const onSaveMail = async () => {
        let validationError = false;
        if (selectedRecipients.length === 0 && subject?.trim() === "" && message?.trim() === "") {
            setErrorMessage({
                toError: ErrorMessages.SKILL_NAME_MISSING,
                subjectError: ErrorMessages.SKILL_NAME_MISSING,
                bodyError: ErrorMessages.SKILL_NAME_MISSING
            });
            validationError = true;
        } else if (subject?.trim() === "") {
            setErrorMessage({
                toError: selectedRecipients.length === 0 ? ErrorMessages.SKILL_NAME_MISSING : "",
                subjectError: ErrorMessages.SKILL_NAME_MISSING,
                bodyError: message?.trim() === "" ? ErrorMessages.SKILL_NAME_MISSING : ""
            });
            validationError = true;
        } else {
            setErrorMessage({
                toError: selectedRecipients.length === 0 ? ErrorMessages.SKILL_NAME_MISSING : "",
                subjectError: "",
                bodyError: message?.trim() === "" ? ErrorMessages.SKILL_NAME_MISSING : ""
            });
        }

        if (!validationError) {
            const response = await saveMail({ selected_recipients: selectedRecipients, subject: subject.trim(), body: cleanHtmlContent(message.trim()) });
            if (response.data) {
                showSuccessToast(messages.MAIL_SEND);
                setToggleComposeModal(false);
                setErrorMessage({
                    toError: "",
                    subjectError: "",
                    bodyError: "",
                });
                setSelectedRecipients([]);
                setSubject("");
                setMessage("");
            } else {
                console.log("error");
            }
        }
    };

    return (
        <>
        <div>
                <div className={`skill-right-field ${from === "userData" ? "user-tab" : ""}`}>
                    {from == "userData" ? <button className="app-btn secondary filter-panel-icon" onClick={handlecomposeModal} >
                        <svg className="svg-icon export-icon">
                            <use href="#mailIcon">
                                <title>Mail Icon</title>
                            </use>
                        </svg>
                        <span className="btn-text">Compose</span>
                    </button> : null
                    }
                    </div>
            {userManagedRole ? null : (toggleComposeModal ? (
                <Modal
                    title="Composing"
                    primaryButton="Send"
                    secondaryButton="Cancel"
                    onCancel={handlecomposeModal}
                    isBackArrow={true}
                    backArrow={true}
                    placementFull="placement-full"
                    withEditBtn={false}
                    hidefooter={false}
                    footerClass="modal-footer-zindex"
                    handleClose={handlecomposeModal}
                    handlebackground={true}
                    from="compose-data"
                    onProceed={onSaveMail}
                    btnLoaderIcon={isLoading}
                >
                    <ComposeModal
                        selectedRecipients={selectedRecipients}
                        setSelectedRecipients={setSelectedRecipients}
                        subject={subject}
                        setSubject={setSubject}
                        message={message}
                        setMessage={setMessage}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                    ></ComposeModal>
                </Modal>

            ) : (
                ""
            ))}
            </div>
        </>
    );
};
export default ComposeMail;
