import React, { useEffect, useState } from "react";
import "../userDataDetails.scss";
import "../react-select.scss";
import { useAdminStore } from "store/adminStore";
import UserProfileImage from "components/UserImage";
import { Sort, USERFILTERSKILLCOLUMN } from "utils/constants";
import { useGetQueryBuilderFilterUsers } from "api/admin/useQueryBuilderFilterUsers";
import SortButton from "components/sortButton";
import PageLoader from "components/common-components/PageLoader";
import CommonTable from "../CommonTable";

interface Props {
  tabSwitch?: any;
  onUpdateDefaultUserList?: any;
  setUserId?: any;
  setSkillState?: any;
  setLoading?: any;
  setLimit?: any;
}

type SortStatus = {
  [key: string]: string;
};

type SortStatusKeys = keyof SortStatus;

const UserFilterDataDetails: React.FC<Props> = ({
  tabSwitch,
  onUpdateDefaultUserList,
  setUserId,
  setSkillState,
  setLoading,
  setLimit
}) => {
  const {
    skillLevel,
    skillName,
    userDesignation,
    skillType,
    filterEnable,
    department,
    setSortOptionSkills,
  } = useAdminStore();

  const sortByColumnsInitialState: SortStatus = {
    sortBy: "disabled",
    sortByEmployee: "asc",
    sortByDesignation: "disabled",
    sortBySkillname: "disabled",
    sortByDepartment: "disabled",
    sortBySkillType: "disabled",
  };


  const { isLoading: filterUserLoading, data: filterUser } =
    useGetQueryBuilderFilterUsers(
      skillName,
      skillLevel,
      skillType,
      userDesignation,
      department
    );

  const [sortByColumn, setSortByColumn] = useState<SortStatus>(
    sortByColumnsInitialState
  );

  const sortByOnClick = (column: SortStatusKeys, columnNo: number) => {
    const sortOrder =
      sortByColumn[column] === "asc" ? "desc" : "asc";
    const updatedSortByColumn = {
      ...Object.fromEntries(
        Object.keys(sortByColumn).map((key) => [key, "disabled"])
      ),
      [column]: sortOrder,
    };
    setSortByColumn(updatedSortByColumn);
    setSortOptionSkills(Sort[columnNo * 2 + (sortOrder === "asc" ? 0 : 1)]);
  };

  const setupInitialState = () => {
    setSortByColumn(sortByColumnsInitialState);
    setSortOptionSkills({
      label: "first_name_last_name_asc",
      value: "first_name_last_name:asc",
    });
  };

  useEffect(() => {
    setupInitialState();
  }, [filterEnable]);

  const openModal = () => {
    setSkillState(true);
  };

  useEffect(() => {
    if (!filterUserLoading) {
      onUpdateDefaultUserList(filterUser);
      setLimit(filterUser?.limit)
    }
  }, [filterUserLoading, filterUser, onUpdateDefaultUserList]);

  const renderSortButton = (
    column: SortStatusKeys,
    index: number
  ) => (
    <SortButton
      isAscending={sortByColumn[column]}
      onClick={() => sortByOnClick(column, index)}
      icon="#sortIcon"
    />
  );

  const renderUserSkills = (isMobile: boolean = false) => {
    const skills = filterUser?.user_skills || [];
    const offset = filterUser?.offset || 0;

    if (filterUserLoading) {
      return <PageLoader />;
    }
    else if(filterUser?.user_skills.length === 0){
     return <td className="nodata-container" colSpan={8}>No Data</td>
    }

    return skills.map((item: any, index: number) => {
      if (isMobile) {
        return (
          <div key={index} className="user-skill-details">
                   <div className="image-section" >
              <button className="icon-only" onClick={() => { openModal(); setUserId(item.id); setLoading(true) }}>
                        <UserProfileImage first_name={item.first_name} ssoId={item.sso_login_id} />
                      </button>
                    </div>
                    <div className="user-detail-section">
                     <span className="user-title">{item.first_name} {item.last_name}</span>
                      <div className="user-sub-details">
                       <span className="user-id">id:{item.employee_id}</span>
                         <span className="user-designation">{item.designation}</span>
                      </div>
                       <span className="skill-level">{item.skill_type}</span>
                      <div className='box-holder'>
                         <span className='left-section' > {item.skill_name === null ? 'NA' : item.skill_name}</span>
                        <span className='right-section'> {item.skill_rank === null ? 'NA' : item.skill_rank}</span>
                      </div>
                     </div>
                  </div>        );
      } else {
        return (
          <tr key={index}>
            <td>{offset + index + 1}</td>
            <td>{item.employee_id}</td>
            <td>
              <span className="userwrapper">
                <div className="profile-img-holder">
                  <button
                    className="icon-only"
                    onClick={() => {
                      openModal();
                      setUserId(item.id);
                      setLoading(true);
                    }}
                  >
                    <UserProfileImage
                      first_name={item.first_name}
                      ssoId={item.sso_login_id}
                    />
                  </button>
                </div>
                <span>
                  {item.first_name} {item.last_name}
                </span>
              </span>
            </td>
            <td>{item.designation}</td>
            <td>{item.department || "-"}</td>
            <td>{item.skill_name || "-"}</td>
            <td>{item.skill_type || "-"}</td>
            <td>{item.skill_rank || "-"}</td>
          </tr>
        );
      }
    });
  };
  return (
    <>
      <div className="user-details-Wrappper">
        <div className="user-details-Table filter">
          {filterUserLoading ? (
            <PageLoader />
          ) : (
            <CommonTable
                columns={USERFILTERSKILLCOLUMN}
                data={filterUser?.user_skills}
              renderSortButton={renderSortButton}
                renderRow={renderUserSkills}
                placeholder={" No Users(s) Found"}
            />
          )}

        </div>
      </div>
      <div className="user-details-mobile-Wrappper">
        <div className="user-section">{renderUserSkills(true)}</div>
      </div>
    </>
  );
};
export default UserFilterDataDetails;



