import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";


export async function getAdminSubDomainUsers(sub_domain_id:number,subDomainPaginationOffset:number, sort: Option | undefined):Promise<UserAdminSubDomains|null> {
  const response = await apiClient.get(urls.AdminSubDomainUsers(sub_domain_id,subDomainPaginationOffset, sort));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetSubDomainUsers():UseQueryResult<UserAdminSubDomains> {
  const {selectedSubDomain,subDomainPaginationOffset, sortOptionDomain} = useAdminStore();   
  return useQuery(
    ["adminSubDomainUsers", selectedSubDomain, subDomainPaginationOffset, sortOptionDomain],
    async () => {
      return getAdminSubDomainUsers(
        selectedSubDomain?.sub_domain_id ?? 0,
        subDomainPaginationOffset,
        sortOptionDomain
      );
    },
    {
      enabled: selectedSubDomain !== undefined && selectedSubDomain.sub_domain_id !== 0,
      refetchOnWindowFocus: false,
    }
  );
}
