import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useUserStore } from "store/useUserStore";
import { useAdminStore } from "store/adminStore";


export async function getCertificationMasters():Promise<Array<CertificationMaster>|null>{
  const response = await apiClient.get(urls.certificationMasters());
  if (response && response.data && response.data.data)
    return response.data.data.certifications as CertificationMaster[];
  return null;
}

export function useGetCertfificationMasters():UseQueryResult<Array<CertificationMaster>> {
  const {accessToken} = useUserStore();
  const {setCertificationList} = useAdminStore();
  return useQuery(["adminCertificationMasters",accessToken], async () => {
    const CertfificationNameList =  await getCertificationMasters();
    if (CertfificationNameList) {
      const CertfificationNameValue= CertfificationNameList?.map((item: any) => ({
        value: item?.certification_name,
        label: item?.certification_name,
        checked: false
      }));
      setCertificationList(CertfificationNameValue??[]);
      return CertfificationNameList;
    }
  },{
    refetchOnWindowFocus:false,
  });
}
