export const getDefaultUser=()=>({
    name:"Steve Harvey",
    jobTitle:"Designation",
  });
  export const getDefaultUserInfo=():UserInfo=>({
    first_name:"Steve",
    last_name:"Harvey",
    designation:"Developer",
    id:0,
    email:"",
    sso_login_id:"",
    roles:["User"],
    user_identity:"",
  });

  export function wait(ms: number) {
    return new Promise((res) => setTimeout(res, ms));
  }

  export function deepClone<T>(obj:T):T{
    const serializedData = JSON.stringify(obj);
    const deserializedData = JSON.parse(serializedData);
    return deserializedData;
}
 
  export const generateUniqueId = () => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
};
