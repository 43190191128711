import React, { useState } from "react";
import "./adminSkillsWrapper.scss";
import SkillsGridView from "./SkillsGridView";
import SkillsBubbleView from "./SkillsBubbleView";
import SearchPanel from "../ActionHeader/SearchPanel";
import { useGetBusinessUnits } from "api/useGetBusinessUnits";
import { useGetAdminCategories } from "api/admin/useGetCategories";
import { useGetPrimarySkillCount } from "api/admin/useGetPrimarySkills";
import { useGetSkillExport } from "api/admin/useExportAllSkills";
import { useAdminStore } from "store/adminStore";
import AdminModal from "components/common-components/AdminModel";
import AddSkillModal from "./SkillsGridView/AddSkillModal";
import { ErrorMessages, messages } from "utils/messageConstants";
import { showSuccessToast } from "utils/toastUtil";
import { useSaveSkill } from "api/admin/useSaveSkill";
import { useGetAllSkills } from "api/admin/useGetAllSkills";
import SkillLisiting from "./SkillListing";

interface Props {
  tabSwitch?: any;
}
const AdminSkillsWrapper: React.FC<Props> = ({ tabSwitch }) => {
  const { data: skills } = useGetAllSkills();
  const { data: businessUnits } = useGetBusinessUnits();
  const { data: categories } = useGetAdminCategories();
  const { mutateAsync: saveSkill } = useSaveSkill();
  const { data } = useGetPrimarySkillCount();
  const { refetch } = useGetSkillExport();

  const { setInitiateSkillExport, setSkillAdded, skillViewToggle, groupByCategory } = useAdminStore();
  const [editMode, setEditMode] = useState(false);

  const [toogleAddSkillModal, setToggleAddSkillModal] = useState(false);
  const [skillName, setSkillName] = useState("");
  const [selectedCategory, setCategory] = useState<Category | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddSkillModal = () => {
    setSkillName("");
    setErrorMessage("");
    setCategory(undefined);
    setSkillAdded(false);
    setToggleAddSkillModal(!toogleAddSkillModal);
  };

  const downloadSkillsReport = () => {
    setInitiateSkillExport(true);
    refetch();
    if (data) {
      setInitiateSkillExport(false);
    }
  }
  const onSaveSkill = async () => {
    if (skillName.trim() === "") {
      setErrorMessage(ErrorMessages.SKILL_NAME_MISSING);
      return;
    }
    if (selectedCategory) {
      const response = await saveSkill({ skillName, categoryId: selectedCategory?.id });
      if (response.data) {
        setSkillAdded(true);
        showSuccessToast(messages.SKILL_SAVE_SUCCESS);
        setErrorMessage("");
        closeModal();
      } else if (response?.response?.data.message) {
        setErrorMessage(response?.response?.data.message);
      } else {
        setErrorMessage(messages.SKILL_SAVE_DUPLICATE)
      }
    } else {
      setErrorMessage(messages.SELECT_CATEGORY);
    }
  };
  const closeModal = () => {
    setToggleAddSkillModal(false);
  };
  return (
    <>
      <SearchPanel
        skillSViewToggle={skillViewToggle}
        tabSwitch={tabSwitch}
        setSkillSViewToggle={() => console.log('')}
        searchPlaceholder="Search for a Skill"
        addButtonText="Add Skill"
        editMode={editMode}
        setEditMode={setEditMode}
        pageName="Certifications"
        isSkillPage={true}
        download={downloadSkillsReport}
        handleAdd={handleAddSkillModal}
      />

      <div className={`admin-skills-wrapper ${groupByCategory ? 'category-view' : ''}`}>
        {groupByCategory ? (<SkillLisiting editMode={editMode} />
        ) : (skillViewToggle === "bubbleView" ? (
          <SkillsBubbleView />
        ) : (
          <SkillsGridView addButtonText="Add Skill" editMode={editMode} handleAdd={handleAddSkillModal} />

        ))}
        {toogleAddSkillModal ? (
          <AdminModal
            title="Add a Skill"
            size=""
            handleClose={() => handleAddSkillModal()}
            isBackArrow={true}
            placementFull="placement-full"
            headerWrapper=""
            withEditBtn={false}
            hidefooter={false}
            primaryButton="Save Skill"
            secondaryButton="Cancel"
            isHeaderBottom={false}
            onProceed={onSaveSkill}
          >
            <AddSkillModal
              setCategory={setCategory}
              setSkillName={setSkillName}
              skillName={skillName}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          </AdminModal>
        ) : (
          ""
        )}
      </div>

    </>
  );
};
export default AdminSkillsWrapper;
