import React, { createRef, useEffect, useState } from "react";
import "./skillsBubbleView.scss";
import ChartWrapper from "./chartWrapper";
import { useGesture } from "@use-gesture/react";
import { useAdminStore } from "store/adminStore";
import { deepClone } from "utils/userUtils";
import { tooltipSkill } from "utils/constants";


const SkillsBubbleView = () => {
  const { allSkillsForBubbleView 
  } = useAdminStore();
  const skills = deepClone(allSkillsForBubbleView);
  const [sliderVal, setSliderVal] = useState(100);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [startZoom, setStartZoom] = useState(0);
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0});
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipValues, setTooltipValues] = useState(tooltipSkill);
  const zoomStep = 10;
  const zoomRange = { min: 50, max: 1000 };
    const [tooltipLeftSide, setTooltipLeftSide] = useState(false);
  //----------------- sliderRange
  const sliderRange={min:0,max:100}
  let minCount=Math.min(...skills.map((a)=>{return a.count}));
  minCount = Math.max(minCount, 1);
  let maxCount=Math.max(...skills.map((a)=>{return a.count}));
  const [rangeSliderMin, setrangeSliderMin] = useState(minCount);
  const[rangeSliderMax, setrangeSliderMax] = useState(maxCount);
  const[rangeSliderposition,setrangeSliderposition]=useState(false);
  const [sliderTooltipVisible, setsliderTooltipVisible] = useState(false);
  useEffect(() => {
      setrangeSliderMin(minCount);
      setrangeSliderMax(maxCount);
     },[minCount,maxCount]);
   const bubbleHolder = createRef<HTMLDivElement>();
   const increaseZoom = () => {
    setSliderVal(Math.min(zoomRange.max, Number(sliderVal) + zoomStep));
  };
  const setZoom = (zoom: number) => {
    setSliderVal(
      Math.round(Math.max(zoomRange.min, Math.min(zoomRange.max, sliderVal - zoom / 10)))
    );
  };
  const setPinchZoomStart = (state: any) => {
    setStartZoom(sliderVal - state.da[0]);
  };
  const setPinchZoom = (state: any) => {
    setSliderVal(startZoom + state.da[0]);
  };
  const decreaseZoom = (elem: any) => {
    setSliderVal(Math.max(zoomRange.min, Number(sliderVal) - zoomStep));
  };

  const bind = useGesture(
    {
      onDrag: ({ offset: [x, y] }) => {
        setTranslate({ x: x, y: y });
        setTooltipVisible(false);
      },
      onPinch: (state: any) => {
        setPinchZoom(state);
      },
      onPinchStart: (state: any) => {
        setPinchZoomStart(state);
      },
      onWheel: (state: any) => {
        setZoom(state._delta[1]);
      },
    },
    {}
  );
  const isValidSkillsNotExist = skills.every((item)=>item.count===0);

  if(isValidSkillsNotExist){
    return null;
  }
  const rangeSliderMinVal = (e:any) => {
    const value = parseInt(e.target.value);
    if(value <= rangeSliderMax){
        setrangeSliderMin(value);
        setrangeSliderposition(true)
    }  
}
  const rangeSliderMaxVal = (e:any) => {
    const value = parseInt(e.target.value);
    if(value >=rangeSliderMin){
      setrangeSliderMax(value);
      setrangeSliderposition(false);
    }
  }


  
 return (
    <>
      <div className="bubble-wrapper">
        <div
          className="bubble-holder"
          style={{
            transform: `translate(${translate.x}px, ${translate.y}px) scale(${Math.max(sliderVal / 100, 0.5)})`,
            '--slider-val':`${sliderVal}`
          }as React.CSSProperties}
          {...bind()}
          ref={bubbleHolder}
        >
          <ChartWrapper
            circles={skills}
            setTooltipPos={setTooltipPos}
            setTooltipVisible={setTooltipVisible}
            setTooltipValues={ setTooltipValues}
            setTooltipLeftSide={setTooltipLeftSide}
            key={skills.length}
            rangeSliderMin={  rangeSliderMin}
            rangeSliderMax={ rangeSliderMax}
          />
        </div>
        <div
          className={`bubbleview-tooltip ${tooltipVisible == true ? "open" : ""} ${tooltipLeftSide == true ? "tooltip-left": ""}`}
          style={{
            top: `${tooltipPos.y}px`,
            left: tooltipLeftSide == true ? "" : `${tooltipPos.x}px `,
            right: tooltipLeftSide == true ? `${tooltipPos.x}px` : "",

            
          
          }}
        >
          <div className="bubbleview-tooltip-wrapper">
            <div className="bubbleview-tooltip-top">
              <div className="skill-item-name">{tooltipValues.name}</div>
              <div className="skill-item-count">{tooltipValues.count}</div>
            </div>
            <div className="bubbleview-tooltip-bottom">
              <div className="skill-num-wrapper">
                <div className="skill-level">Expert</div>
                <div className="skill-count">{tooltipValues.expertCount}</div>
              </div>
              <div className="skill-num-wrapper">
                <div className="skill-level">Intermediate</div>
                <div className="skill-count">{tooltipValues.intermediateCount}</div>
              </div>
              <div className="skill-num-wrapper">
                <div className="skill-level ">Beginner</div>
                <div className="skill-count">{tooltipValues.beginnerCount}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="zoom-slider-wrapper">
        <div className="range-slider-holder">

            <div className="range-slider-name">
               Employee count :
            </div>
            <div className="range-slider-data" >
                <div className="range-values-data">
                    <span className="rangeValues-heading">Min</span>
                    <span className="rangeValues">{rangeSliderMin}</span>  
                </div>
                 
                <div className="range">
                   
                    <div  className="slider-range-input">
                    <div className="range-slider">
                        <span  className="range-selected"></span>
                        <div className="range-slider-tooltip"
                             style={{
                            visibility: sliderTooltipVisible == true ? 'visible' : "hidden",
                            left: rangeSliderposition == true ?   `calc(${(rangeSliderMin  / maxCount   ) * 100}% - ${(rangeSliderMin  / maxCount ) * 36}px + ((${((rangeSliderMax-rangeSliderMin) / maxCount ) * 100 }% + ${(36 - ((rangeSliderMax-rangeSliderMin) / maxCount )  * 36)}px) /2) - 38px) ` : " ",                            
                            right: rangeSliderposition == false ? `calc(${((maxCount - rangeSliderMax)/ maxCount ) * 100}% - ${((maxCount - rangeSliderMax) / maxCount ) * 36}px + ((${((rangeSliderMax-rangeSliderMin) / maxCount ) * 100 }% + ${(36 - ((rangeSliderMax-rangeSliderMin) / maxCount )  * 36)}px) /2) - 38px) ` :""                              }}>
                              <div className="slider-tooltip-content">
                                <span>{rangeSliderMin}</span> - <span>{rangeSliderMax}</span></div>
                          </div>
                     </div>   
                          <input
                                 className="slider-input"
                                 min={0}
                                 value={rangeSliderMin}
                                 max={maxCount}
                                 step={1}
                                 type="range"
                                 onChange={rangeSliderMinVal}
                                 name="minvalue"  /> 
                                                
                          <div className="slider-middle"  style={{
                                     width: `calc(${((rangeSliderMax-rangeSliderMin) / maxCount ) * 100 }% + ${(36 - ((rangeSliderMax-rangeSliderMin) / maxCount )  * 36)}px)`,
                                    left: rangeSliderposition == true ?  `calc(${(rangeSliderMin  / maxCount   ) * 100}% - ${(rangeSliderMin  / maxCount ) * 36}px)` : " ",
                                    right: rangeSliderposition == false ? `calc(${((maxCount - rangeSliderMax)/ maxCount ) * 100}% - ${((maxCount - rangeSliderMax) / maxCount ) * 36}px)` :""
                             }} onMouseOver={(e) => setsliderTooltipVisible(true)} onMouseOut={(e) => setsliderTooltipVisible(false)}>
                          </div>
                          <input
                          className="slider-input input-right"
                              min={0}
                              value={rangeSliderMax}
                              max={maxCount} 
                              step={1}
                              type="range"
                              onChange={rangeSliderMaxVal}
                              name="maxvalue" />  
                    </div>
                </div>
                <div className="range-values-data">
                        <span className="rangeValues-heading">Max</span>
                        <span className="rangeValues">{rangeSliderMax}</span>  
                 </div>
               
           </div>
        </div>
       
                   
         
        <div className="zoom-slider-holder">
          <button
            className="icon-only zoom-control-btn out"
            onClick={(e) => decreaseZoom(e.target)}
          >
            <svg className="svg-icon zoom-out">
              <use href="#minusIcon">
                <title>Zoom out</title>
              </use>
            </svg>
          </button>
          <div className="range-input-wrapper">
            <input
              className="range-input"
              type="range"
              min={zoomRange.min}
              value={sliderVal}
              max={zoomRange.max}
              onChange={(e) => setSliderVal(Number(e.target.value))}
            />
          </div>

          <button className="icon-only zoom-control-btn in" onClick={(e) => increaseZoom()}>
            <svg className="svg-icon zoom-in">
              <use href="#plusIcon">
                <title>Zoom in</title>
              </use>
            </svg>
          </button>
          <span className="range-value">{sliderVal}%</span>
        </div>
      </div>
    </>
  );
};
export default SkillsBubbleView;
