import React, { RefObject } from "react";
import "../../searchPanel.scss"
import { useAdminStore } from "store/adminStore";
import AdminSubDomainFilter from "feature/admin/components/AdminDomainsWrapper/AdminSubDomainFilter";
import AdminFilter from "feature/admin/components/AdminFilter";
import { useUserStore } from "store/useUserStore";
import FilterSidePanel from "feature/admin/components/FilterSidepanel";
import AdminEmailLogFilter from "feature/admin/components/AdminEmailLog/AdminEmailLogFilter";

interface Props {
  from?: string;
  tabSwitch?: string;
  isSkillPage?: boolean;
  handleAdminFilter: () => void;
  domainToggle?: boolean;
  adminFilter?: boolean;
  closeRef?: RefObject<HTMLDivElement>;
  mobileSortRef?: RefObject<HTMLButtonElement>;
  skillSViewToggle?: string;
  handleSortToggle: () => void;
  isFilterApply?: boolean;
  emailLogToggle?: boolean;
  emailLogStatus?: EmailStatus[];
  setEmailLogStatus?: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
}

const FilterSortGridBubble: React.FC<Props> = ({
  from,
  tabSwitch,
  isSkillPage,
  handleAdminFilter,
  domainToggle,
  adminFilter, closeRef,
  mobileSortRef, skillSViewToggle,
  handleSortToggle, isFilterApply,
  emailLogToggle,
  emailLogStatus,
  setEmailLogStatus,
}) => {

  const {
    groupByCategory,
    groupByDomains,
    filterEnableIcon,
    setSkillsViewToggle
  } = useAdminStore();
  const { userManagedRole } = useUserStore();

  const handleSkillSViewToggle = (skillSView: string) => {
    setSkillsViewToggle(skillSView);
  };

  return (
    <>
      {isSkillPage && groupByCategory === false || from == 'userData' || from === "SubDomains" || from === "emailLog" ? (
        <div className="admin-filter-wrapper" ref={closeRef}
        >
          {(!groupByDomains) && (
            <button
              className={`app-btn secondary filter-panel-icon ${filterEnableIcon ? "filter-applied" : ""} ${adminFilter ? "active" : ""}`}
              onClick={handleAdminFilter}
            >
              <svg className="svg-icon filter-icon">
                <use href="#adminFilterIcon">
                  <title>FilterIcon</title>
                </use>
              </svg>
              <span className="btn-text">Filter</span>
            </button>
          )}

          {from === 'userData' && <button
            className={`app-btn secondary icon-only sort-icon sort-applied ${isFilterApply ? "active" : ""} `}
            onClick={handleSortToggle}
            ref={mobileSortRef}
          >
            <svg className="svg-icon filter-icon">
              <use href="#upDownIcon">
                <title>Sort icon</title>
              </use>
            </svg>
          </button>
          }
          {adminFilter ? (
            <AdminFilter handleAdminFilter={() => handleAdminFilter()} />
          ) : from === 'userData' ? (
            <FilterSidePanel />
          ) : emailLogToggle ? (
            <AdminEmailLogFilter
              handleAdminFilter={() => handleAdminFilter()}
              emailLogStatus={emailLogStatus}
              setEmailLogStatus={setEmailLogStatus}
            />
          ) : domainToggle ? (
            <AdminSubDomainFilter handleAdminFilter={() => handleAdminFilter()} />
          ) : null}
        </div>
      ) : null}

      {userManagedRole ?
        ""
        : tabSwitch === "Skills" && groupByCategory === false ? (
          <div className="bubble-grid-wrapper">
            <button
              className={`icon-only bubble-btn ${skillSViewToggle === "bubbleView" ? "active" : ""
                }`}
              onClick={() => handleSkillSViewToggle("bubbleView")}
            >
              <svg className="svg-icon bubble-icon">
                <use href="#bubbleIcon">
                  <title>Bubble Icon</title>
                </use>
              </svg>
            </button>
            <button
              className={`icon-only grid-btn ${skillSViewToggle === "gridView" ? "active" : ""
                }`}
              onClick={() => handleSkillSViewToggle("gridView")}
            >
              <svg className="svg-icon grid-icon">
                <use href="#gridIcon">
                  <title>Grid Icon</title>
                </use>
              </svg>
            </button>
          </div>
        ) : (
          ""
        )}


    </>
  );
};
export default FilterSortGridBubble;
