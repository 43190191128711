import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateCertificationName(
    certification:CertificationHolderItem
){
    try{
    const requestObject = {
        certification_name: certification.certification_name,
      }
    const response = await apiClient.put(
        urls.editCertificationMaster(certification.id),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useUpdateCertificationMaster():UseMutationResult<
    any,
    ApiError | {message: string},
    {certificationItem:CertificationHolderItem}
    >{
        const queryClient = useQueryClient();
        const {certificationSearch,certificationListPaginationOffset} = useAdminStore();
        return useMutation(
        async ({
            certificationItem
        }: {
            certificationItem:CertificationHolderItem
        }) => {
            const result = await updateCertificationName(certificationItem);
            queryClient.invalidateQueries(["AdminCertifications",certificationSearch,certificationListPaginationOffset]);
            return result;
        }
    );
    }