import React from "react";
import { formatDateWithSuffix } from "utils/dateUtil";

interface Props {
    showEmail?: boolean;
    setShowEmail: (email: boolean) => void;
    userDataList: any;
    customNotificationId: string | number;   
}

const EmailContent: React.FC<Props> = ({ showEmail, setShowEmail, userDataList, customNotificationId,}) => {

    const messageContent = userDataList?.custom_notification_data?.message;    
    return (
        <>
            {showEmail && (
                <div className="email-content">
                    <div className="email-content-holder">
                        <div className="email-content-header">
                            <div className="email-content-title">
                                <h4 className="mail-subject">
                                    <span className="title-icon">
                                        <svg className="svg-icon email-icon">
                                            <use href="#emailIcon">
                                                <title>Mail Icon</title>
                                            </use>
                                        </svg>
                                    </span>
                                    <span className="mail-subject-text"> {userDataList?.custom_notification_data?.subject}</span>
                                </h4>
                                <button onClick={() => setShowEmail(false)} className="close-email-btn">
                                    <span className="app-btn icon-only-sm ghost go-details">
                                        <svg className="svg-icon right-arrow-icon">
                                            <use href="#closeIcon">
                                                <title>close</title>
                                            </use>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            {/* <span>{formatDateWithSuffix(new Date(userDataList?.custom_notification_data?.date))}</span> */}
                            <span>{userDataList?.custom_notification_data?.date}</span>
                        </div>
                        <div className="email-content-block">
                            <span className="email-content-label">Subject</span>
                            <h5 className="email-content-title">
                                {userDataList?.custom_notification_data?.subject}
                            </h5>
                            <span className="email-content-label">Mail content</span>
                            <p className="email-content-text" dangerouslySetInnerHTML={{__html:messageContent }} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default EmailContent;

