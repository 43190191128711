import React, { useState } from "react";
import "./categoreylistingmodal.scss";
import InputField from "components/common-components/InputField";
import { INPUT_MAX_LENGTH } from "utils/constants";
import { useSaveSkill } from "api/admin/useSaveSkill";
import { useGetCategoryById } from "api/admin/useCategoryById";
import { ErrorMessages, messages } from "utils/messageConstants";
import { showSuccessToast } from "utils/toastUtil";
import { useUpdateSkill } from "api/admin/useUpdateSkill";


interface Props {
  customheader?: any;
  data?: any;
  setCategory?: any;
  category?: any;
}

const CategoryListingModal: React.FC<Props> = ({ customheader, data, setCategory, category }) => {
  const [excelState, setExcelState] = useState(false);
  const[uploadState,setUploadState]=useState(false);
  const { mutateAsync: saveSkill } = useSaveSkill();
  const { data: Category, isLoading,refetch} = useGetCategoryById(data?.id);
  const [skillName, setSkillName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openEditSkillIndex, setOpenEditSkillIndex] = useState<number | null>(null);
  const [editSkill, setEditSkill] = useState<Boolean>(false);
  const [editSkillName, setEditSkillName] = useState<any>()
  const [error, setError] = useState("")
  const { mutateAsync: updateSkillData } = useUpdateSkill();

  const handleSkillEdit = (skillsIndex: number, skillItem: any) => {
    setOpenEditSkillIndex(skillsIndex);
    setErrorMessage("")
    setEditSkill(true);
    setEditSkillName(skillItem)

  };

  const handleSkillClose = () => {
    setOpenEditSkillIndex(null);
    setEditSkill(false);
    setError("")
    setErrorMessage("")

  };
  
  // const uploadExcelSheet = () => {
  //   setExcelState(true);
  // };

  const uploadDataManualy=()=>{
    setUploadState(true);
    setExcelState(true)
  }

  const onSaveSkill = async () => {
    if (skillName.trim() === "") {
      setErrorMessage(ErrorMessages.SKILL_NAME_MISSING);
      return;
    }
      const response = await saveSkill({ skillName: skillName.trim(), categoryId: data?.id });
      if (response.data) {
        const updatedCategory = await refetch(); // Refetch category data
        setCategory({
          ...category ,
          skill_count: updatedCategory.data?.skill_count
        });
        showSuccessToast(messages.SKILL_SAVE_SUCCESS);
        setErrorMessage("");
        setSkillName("")
      } else if(response?.response?.data?.message){
        setErrorMessage(response?.response?.data?.message);
     }else{
       setErrorMessage(messages.SKILL_SAVE_DUPLICATE);
    }
  };

  const updateSkill = async()=>{
    if (editSkillName.skill_name.trim() === "") {
      setError(ErrorMessages.SKILL_NAME_MISSING);
      return;
    }
      const response = await updateSkillData({skillId: editSkillName?.skill_id, skillName: editSkillName.skill_name.trim(), categoryId: data?.id });
      if (response.data) {
        setEditSkillName({...editSkillName,skill_name: response.data.skill_name})
        showSuccessToast(messages.SKILL_UPDATED);
        setError("");
        setOpenEditSkillIndex(null);
        setEditSkill(false);
          } 
         else if(response?.response?.data?.message){
          setError(response?.response?.data?.message);
       } 
          else {
        setError(messages.SKILL_SAVE_DUPLICATE);
      }
    
};

  return (
    <div className="app-modal-content sm add-skill-data">
      
        {!excelState  ? (
          <div className="upload-catgeory-mainpage">
            <div className="folder-icon-holder">
              <svg className="svg-icon folder2-icon">
                <use href="#folderIcon2">
                  <title>Add Icon</title>
                </use>
              </svg>
            </div>
            <div className="add-category-details">
              <span> Exciting!</span>
              <span>You’ve just created a new category</span>
              <span> Ready to add skills? </span>
              <span>Give it a try now</span>
            </div>
            <div className="add-category-buttons-holder">
              {/* <button className="app-btn secondary" onClick={uploadExcelSheet}>
                Upload an Excel Sheet
              </button> */}
              <button className="app-btn secondary addmanual-btn" onClick={uploadDataManualy}> Add Manually</button>
            </div>
          </div>
        ) : uploadState ? (
            <>
          <div className='skill-data-heading add-category-wrapper'>
            <div className='skill-data-heading-th2 th-open'>
                        <div className='skill-data-title'> SKILL Name</div>
                        <div className='skill-heading-edit-mode'>
                        <InputField type="text"
                                value={skillName}
                                        onChange={(e:any)=>{
                                          setErrorMessage("");
                                          setSkillName(e.target.value);                                         
                                          setErrorMessage("");
                                        }}
                                        maxLength={INPUT_MAX_LENGTH}
                                        error={errorMessage}
                                        placeholder='Add a new skill here'
                                  ></InputField>
                          <button className='app-btn secondary addskillname-btn' onClick={onSaveSkill}>
                            <svg className="svg-icon plus-icon">
                              <use href="#plusIcon">
                                <title>Add Icon</title>
                              </use>
                            </svg>
                          </button>
                        </div>
            </div>
              </div>
              <div className={Category && Category.skills && Category.skills.length > 9 ? 'skill-data-main hasScroll' : 'skill-data-main'}>

                {Category?.skills?.map((skillItem, skillsIndex) => (
                  <div className='skill-data-wrapper edit-mode'>
                    <div className='skill-data-details' key={skillsIndex} >
                      {skillsIndex !== openEditSkillIndex ? (
                        <div className='skill-data-details'>
                          <div className='skill-data-name'>{skillItem?.skill_name}</div>
                          <div className="skill-edit-btn th-open">
                            <button className="app-btn icon-only-sm" title='edit' onClick={() => handleSkillEdit(skillsIndex, skillItem)}>
                              <svg className="svg-icon edit-icon">
                                <use href="#editIcon">
                                  <title>Edit Icon</title>
                                </use>
                              </svg>
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className={editSkill && skillsIndex === openEditSkillIndex ? 'skill-data-edit-wrapper th-open' : 'skill-data-edit-wrapper th-close'}>
                      <InputField type="text" value={editSkillName?.skill_name}
                        onChange={(e: any) => {
                          setEditSkillName({ ...editSkillName, skill_name: e.target.value });
                          setError("");
                        }}
                        maxLength={INPUT_MAX_LENGTH}
                      error={error}
                      ></InputField>
                      <button className='app-btn icon-only-sm skildata-btn' title='close' onClick={handleSkillClose}>
                        <svg className="svg-icon close-icon">
                          <use href="#closeIcon">
                            <title>Close Icon</title>
                          </use>
                        </svg>
                      </button>
                      <button className='app-btn icon-only-sm skildata-btn' title='add' onClick={updateSkill}>
                        <svg className="svg-icon plus-icon">
                          <use href="#tickIcon">
                            <title>Tick Icon</title>
                          </use>
                        </svg>
                      </button>
                    </div>
                  </div>))}
              </div>
              </>
          
          
          ) :
       (
        <div className="upload-excelsheet-wrapper ">
          <div className="upload-details-wrapper">
            <div className="upload-header">
              <div className="excel-icon-holder">
                  <svg className="svg-icon excel-icon">
                    <use href="#excelIcon">
                      <title>excel Icon</title>
                    </use>
                  </svg> 
              </div>
             
           Uploading Excell Sheet   
            </div>
            <div className="upload-status">43%</div>
          </div>
          <div className="progress-bar-holder">
            <div className="progress-bar-border">
              <div className="progress-bar" style={{ width: "70%" }}></div>
            </div>
          </div>
          <div className="upload-button-holder ">
            <button className="app-btn secondary canceluplod-btn">Cancel upload</button>
          </div>
              </div>
              
          )}
        
    </div>
  );
};

export default CategoryListingModal;
