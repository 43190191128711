import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";

export async function getQueryBuilderFilterCertificationUsers(offset:number, limit: string|number,certification_names: string,certification_issuers: string, certification_status:string, designations: string, departments: string, sort: Option | undefined, search: string, has_certification: Option[]):Promise<QueryBuilderFilterCertificationUser|null> {
  const response = await apiClient.get(urls.AdvanceUserFilterCertificationList(offset, limit,certification_names,certification_issuers, certification_status, designations,departments,sort, search, has_certification));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetQueryBuilderFilterCertificationUsers(certification_names: any,certification_issuers: any, certification_status:any, designations: any, department_value:any, non_initiated: any):UseQueryResult<QueryBuilderFilterCertificationUser> {
  const {queryBuilderListPaginationOffset, queryBuilderPaginationOffset, userSearch,certificationName, certificationIssuer, certificationStatus, userDesignation, department, setFilterUserCount, sortOptionCertification, nonInitiated} = useAdminStore();
  const certificationNames =  certification_names.map((item: any)=>item.value).join(",");
  const certificationIssuers =  certification_issuers.map((item: any)=>item.value).join(",");
  const certificationStatuses =  certification_status.map((item: any)=>item.value).join(",");
  const Designations =  designations.map((item: any)=>item.value).join(","); 
  const Departments =  department_value.map((item: any)=>item.value).join(",");
  return useQuery(["QueryBuilderFilterCertificationUserList",queryBuilderListPaginationOffset, queryBuilderPaginationOffset, userSearch, sortOptionCertification, certificationName, certificationIssuer, certificationStatus, userDesignation, department, nonInitiated ], async () => {
    const filterUsers =  await getQueryBuilderFilterCertificationUsers(queryBuilderListPaginationOffset, queryBuilderPaginationOffset,certificationNames,certificationIssuers, certificationStatuses, Designations,Departments,sortOptionCertification, userSearch, nonInitiated);
    setFilterUserCount(filterUsers?.filtered_user_count?? 0)
    return filterUsers;
  },{
    refetchOnWindowFocus:false
  });
}