import MicrosoftButton from "components/MicrosoftLoginButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { useUserStore } from "store/useUserStore";
import { loginRequest } from "utils/adLogin/authConfig";
import "./login.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useLoginUser } from "api/admin/useUserInfo";
import { getUserRole } from "./helper";
import PageLoader from "components/common-components/PageLoader";
import { showLoginError } from "utils/toastUtil";
import { LOGIN_TOAST_ID } from "utils/constants";

const Login = () => {
  const[isLoginProgress,setLoginProgress]=useState(false);
  const { setAccessToken, setIdToken, setUserManagedRole,
  } = useUserStore();
  const { instance, accounts } = useMsal();
  const { mutateAsync: loginUser } = useLoginUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  queryClient.invalidateQueries(["categories"]);

  useEffect(() => {
    if (accounts.length > 0) {
      onLogin();
    }
  }, [accounts]);

  const requestTokenSilent = async () => {
    setLoginProgress(true);
    try {
      await instance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then((response) => {
          const accessToken = response.accessToken;
          const idToken = response.idToken;
          setIdToken(idToken);
          setAccessToken(accessToken);
        });
    } catch (e) {
      console.log("err", e);
      setLoginProgress(false);
    }
  };

  const onLogin = async () => {
    try{
      await requestTokenSilent();
      const user = await loginUser({ user: "" });
      if (getUserRole(user).includes('Admin')) {
        navigate("admin");
      }
      else if(getUserRole(user).includes('DataViewer')) {
        setUserManagedRole(true)
        navigate("select-stream")
      }

      else if(getUserRole(user).includes('User')) {
        navigate("home");
      }
      else{
        showLoginError("Unable to login. Please try again.",LOGIN_TOAST_ID);
      }
    }catch(error){
      console.log(error)
    }
    finally{
      setLoginProgress(false);
    }
  };

  const handleLogin = async () => {
    await instance.loginRedirect({
      scopes: loginRequest.scopes,
    });
  };
  if(isLoginProgress){
    return <PageLoader />;
  }
  return (
    <div className="app-login">
      <div className="app-icon">
        <svg className="svg-icon logo-icon">
          <use href="#loginLogoIcon"></use>
        </svg>
      </div>
      <div className="app-login-wrapper">
        <div className="login-heading">
          <p className="main-heading">Welcome to Skill Matrix</p>
        </div>
        <div>
          <MicrosoftButton buttonLabel="Login with Microsoft Account" onClick={handleLogin} />
        </div>
      </div>
    </div>
  );
};

export default Login;
