import React, { useEffect, useRef, useState } from "react";
import "./searchPanel.scss";
import AdminModal from "components/common-components/AdminModel";
import ExportModal from "../../AdminSkillsWrapper/SkillsGridView/ExportModal";
import { useAdminStore } from "store/adminStore";
import { useAllCategoriesExport } from "api/admin/useExportAllCategories";
import { useAllDomainsExport } from "api/admin/useExportAllDomains";
import { useUserStore } from "store/useUserStore";
import { useGetPrimarySkillCount } from "api/admin/useGetPrimarySkills";
import UserFilterSort from "./components/UserFilterSort";
import SearchTab from "./components/SearchTabs";
import UserCount from "./components/UsersCount";
import MobileThreeDot from "./components/MobileThreeDot";
import WebviewEditDownload from "./components/WebviewEditDownload";
import CheckBox from "./components/CheckBox";
import FilterSortGridBubble from "./components/FilterSortGridBubble";
// import { emailLogFilterOptions } from "utils/constants";

interface Props {
  skillSViewToggle?: any;
  setSkillSViewToggle?: any;
  tabSwitch: string;
  searchPlaceholder?: string;
  addButtonText?: string;
  editMode?: any;
  setEditMode?: any;
  pageName?: string;
  from?: string;
  isSkillPage: boolean;
  download: () => void;
  handleAdd: () => void;
  emailLogStatus?: EmailStatus[]
  setEmailLogStatus?: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
  emailLogToggle?:boolean
  setEmailLogToggle?:any
  
}

const SearchPanel: React.FC<Props> = ({
  skillSViewToggle,
  tabSwitch,
  searchPlaceholder,
  addButtonText,
  editMode,
  setEditMode,
  from,
  isSkillPage = true,
  download,
  handleAdd,
  emailLogStatus,
  setEmailLogStatus,

}) => {
  const closeRef = useRef(null);
  const mobileCloseRef = useRef(null);
  const mobileSortRef = useRef(null);

  const {
    currentTab,
    sortBy,
    sortOrder,
    filterToggle,
    setFilterToggle,
  } = useAdminStore();

  const [searchToggle, setSearchToggle] = useState<boolean>(false);
  const [toggleExportModal, setToggleExportModal] = useState(false);
  const { data, refetch } = useAllCategoriesExport();
  const { data: dataDomain, refetch: domainRefetch } = useAllDomainsExport();
  const { userManagedRole } = useUserStore();
  const { data: userCount } = useGetPrimarySkillCount();
  const [tempSortOrder, setTempSortOrder] = useState([{ label: "Ascending", value: "Ascending" }]);
  const [tempSortBy, setTempSortBy] = useState([{ label: "Employee", value: "Employee" }]);
  const [isFilterApply, setIsFilterApply] = useState<boolean>(false);
  const [adminFilter, setAdminFilter] = useState(false);
  const [domainToggle, setDomainToggle] = useState(false);
  const [sortToggle, setSortToggle] = useState(false);
  const [toggleOptions, setToggleOptions] = useState(false);
  const [emailLogToggle, setEmailLogToggle] = useState(false);

  const handleAdminFilter = () => {
    if (from === "userData") {
      setFilterToggle(!filterToggle);
      setAdminFilter(false);
    } else if (isSkillPage) {
      setAdminFilter((prev) => !prev);
    } else if (from === "emailLog"){
      setEmailLogToggle(!emailLogToggle);
    }
    else {
      setDomainToggle((prev) => !prev);
    }
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleClickOutside = (event: { target: any }) => {
    if (closeRef.current && !(closeRef.current as HTMLElement).contains(event.target)) {
      setAdminFilter(false);
      setDomainToggle(false);
      setEmailLogToggle(false);
    }
    if (mobileCloseRef.current && !(mobileCloseRef.current as HTMLElement).contains(event.target)) {
      setToggleOptions(false);
    }
  };

  const close = (event: any) => {
    if (event.key === "Escape") {
      setAdminFilter(false);
      setDomainToggle(false);
      setEmailLogToggle(false);

    }
  };

  const handleSortToggle = () => {
    setSortToggle(!sortToggle);
    setIsFilterApply(!sortToggle);
    if (!sortToggle) {
      setTempSortBy(sortBy);
      setTempSortOrder(sortOrder);
    }
  };

  const downloadCategoriesExport = () => {
    refetch();
  };

  const downloadDomainsExport = () => {
    domainRefetch();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    window.addEventListener("keydown", close);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      window.removeEventListener("keydown", close);
    };
  }, [adminFilter, domainToggle, emailLogToggle]);

  return (
    <>
      <div className={`admin-search-panel${from === "userData" ? ' user-data' : ''}`}>
        <div className={`search-btn-wrapper ${searchToggle ? "search-mode" : ""}`}>
          <div className={`skill-left-field${from === "userData" ? ' user-data' : ''}`}>
            <div className="flex-mob">
              <SearchTab from={from} setSearchToggle={setSearchToggle}
                isSkillPage={isSkillPage} searchPlaceholder={searchPlaceholder}
              />
              <FilterSortGridBubble from={from} tabSwitch={tabSwitch}
                isSkillPage={isSkillPage} handleAdminFilter={handleAdminFilter}
                domainToggle={domainToggle} adminFilter={adminFilter} closeRef={closeRef}
                mobileSortRef={mobileSortRef} skillSViewToggle={skillSViewToggle} isFilterApply={isFilterApply}
                handleSortToggle={handleSortToggle} emailLogToggle={emailLogToggle}
                emailLogStatus= {emailLogStatus} setEmailLogStatus={setEmailLogStatus}
                />
                <div className="mob-view-buttons">
                  <WebviewEditDownload
                    handleEditMode={handleEditMode} downloadCategoriesExport={downloadCategoriesExport}
                    downloadDomainsExport={downloadDomainsExport} download={download}
                    from={from} editMode={editMode}
                    handleAdd={handleAdd} addButtonText={addButtonText} />
                  <UserFilterSort from={"userData"} handleSortToggle={handleSortToggle} mobileSortRef={mobileSortRef}
                    setSortToggle={setSortToggle} sortToggle={sortToggle} setIsFilterApply={setIsFilterApply}
                    tempSortOrder={tempSortOrder} setTempSortOrder={setTempSortOrder} tempSortBy={tempSortBy} setTempSortBy={setTempSortBy} />
                </div>
            </div>
            <UserCount from={from} isSkillPage={isSkillPage} />
            <MobileThreeDot toggleOptions={toggleOptions}
              mobileCloseRef={mobileCloseRef} handleEditMode={handleEditMode} downloadCategoriesExport={downloadCategoriesExport}
              downloadDomainsExport={downloadDomainsExport} download={download}
              handleOptionToggle={setToggleOptions} from={from} currentTab={currentTab} />
            <CheckBox from={from} isSkillPage={isSkillPage} setEditMode={setEditMode} />

          </div>
          <div className="web-view-buttons">
            <WebviewEditDownload
              handleEditMode={handleEditMode} downloadCategoriesExport={downloadCategoriesExport}
              downloadDomainsExport={downloadDomainsExport} download={download}
              from={from} editMode={editMode}
              handleAdd={handleAdd} addButtonText={addButtonText} />
            <UserFilterSort from={"userData"} handleSortToggle={handleSortToggle} mobileSortRef={mobileSortRef}
              setSortToggle={setSortToggle} sortToggle={sortToggle} setIsFilterApply={setIsFilterApply}
              tempSortOrder={tempSortOrder} setTempSortOrder={setTempSortOrder} tempSortBy={tempSortBy} setTempSortBy={setTempSortBy} />
          </div>
        </div>
      </div>
      {toggleExportModal && (
        <AdminModal
          title="Lorem Ipsum Export"
          size=""
          handleClose={() => setToggleExportModal(!toggleExportModal)}
          isBackArrow={true}
          placementFull="placement-full"
          headerWrapper=""
          withEditBtn={false}
          hidefooter={false}
          primaryButton="OK"
          isHeaderBottom={false}
          showClose={true}
        >
          <ExportModal></ExportModal>
        </AdminModal>
      )}
    </>
  );
};

export default SearchPanel;

