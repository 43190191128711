import React, { useEffect, useState } from "react";

import "./select-skill-level.scss";
import SkillScoreCard from "components/SkillScoreCard";
import { useSkillStore } from "store/useSkillStore";
import { useSaveUserSkill } from "api/user/useSaveUserSkill";
import { SkillType } from "utils/constants";
import { useUpdateUserSkill } from "api/user/useUpdateUserSkill";
import "../common-components/Accordion/accordion.scss";
import "../common-components/Checkbox/checkbox.scss";
import { deepClone } from "utils/userUtils";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { ErrorMessages, messages } from "utils/messageConstants";
interface Props {
  skillLevelPos?: any;
  setSkillLevelPos: any;
  skillItem: UserSkillItem;
  isEdit: boolean;
  revertHandleList?:any;
}

const SelectSkillLevel: React.FC<Props> = ({ skillLevelPos, setSkillLevelPos, skillItem, isEdit,revertHandleList}) => {
  const { setPrimarySkill, setSecondarySkill, deleteAdditionalSkill } = useSkillStore();
  const { mutateAsync: saveUserSkill } = useSaveUserSkill();
  const { mutateAsync: updateUserSkill } = useUpdateUserSkill();
  const [skillDefToggle, setSkillDefToggle] = useState(true);
  const [skillLevelToggle, setSkillLevelToggle] = useState(0);
  let skillLevelPopover = React.useRef(null);
  const [selectedSkill, setSelectedSkill] = useState(deepClone(skillItem));
  
  useEffect(() => {
    setSelectedSkill(skillItem);
  }, [skillItem]);

  const isSkillRankSelected = selectedSkill.skill_rank.length > 0;

  const handleSkillDefToggle = () => {
    setSkillDefToggle(!skillDefToggle);
  };

  const closeSkillLevel = () => {
    setSkillLevelPos({ x: 0, y: 0, visible: false });
  };
  
  const closeAccordians = ()=>{
    const accordianSelected= document.getElementsByClassName('accordion-radio');
    for(let i=0;i<accordianSelected.length; i++ ){
      const item=accordianSelected[i] as HTMLInputElement;
      item.checked=false;
    }
  }

  const cancelSkillLevel = () => {
    if (skillItem.id !== 0) {
      setSkillLevelPos({ x: 0, y: 0, visible: false });
    } else {
      setSkillLevelPos({ x: 0, y: 0, visible: false });
      if (skillItem.skill_type === SkillType.Primary) {
        setPrimarySkill(undefined);
      } else if (skillItem.skill_type === SkillType.Secondary) {
        setSecondarySkill(undefined);
      } else if (skillItem.skill_type === SkillType.Additional) {
        deleteAdditionalSkill(skillItem);
      }
    }
    closeAccordians();
  };

  useEffect(() => {
    let skillLevelPopoverTop;
    let skillLevelPopoverHeight;
    let toggleAdjust;
    let skillLevelPopoverObj: any = skillLevelPopover.current;
    skillLevelPopoverTop = skillLevelPos.y;
    skillLevelPopoverHeight = skillLevelPopoverObj
      ? skillLevelPopoverObj.getBoundingClientRect().height
      : 0;
    toggleAdjust =
      skillLevelPopoverTop + skillLevelPopoverHeight > window.innerHeight
        ? skillLevelPopoverTop + skillLevelPopoverHeight - window.innerHeight + 10
        : 0;
    setSkillLevelToggle(toggleAdjust);
  }, [skillLevelPos, skillLevelToggle, skillDefToggle]);


  const addAndSaveSkill = async () => {
    if (selectedSkill.id !== 0) {
      const response = await updateUserSkill({ userSkill: selectedSkill });
      showResponse(response,true);
    } else {
      const response = await saveUserSkill({ userSkill: selectedSkill });
      showResponse(response,false);
    }
    closeSkillLevel();
    closeAccordians();
    revertHandleList && revertHandleList();
  };
  const showResponse=(response:any,isEdit:boolean)=>{
    if(response && response.data){
      showSuccessToast(isEdit?messages.SKILL_UPDATED:messages.SKILL_ADDED)
    }else{
      showErrorToast(ErrorMessages.FAILED_TO_SAVE);
    }

  }
  return (
    <>
    <div className={`select-skill-overlay mobile ${skillLevelPos.visible ? "active" : ""}`}></div>
    <div
      className={`select-skill-level ${skillLevelPos.visible ? "active" : ""}`}
      style={
        {
          "--left": skillLevelPos.x + "px",
          "--top": skillLevelPos.y + "px",
          "--toggle-adjust": skillLevelToggle + "px",
        } as React.CSSProperties
      }
      ref={skillLevelPopover}
    >
      <div className="skill-def-wrapper">
        <div className="select-skill-head">
          <h3>{skillItem.skill_name}</h3>
          <button className="icon-only close-icon" onClick={cancelSkillLevel} >
            <svg className="svg-icon close-icon">
              <use href="#closeIcon">
                <title>Close Icon</title>
              </use>
            </svg>
          </button>
        </div>
        <div className="select-skill-content">
          <h5>Select the level of your skill</h5>
          <SkillScoreCard
            skillItem={selectedSkill}
            setSkillLevel={(item: SkillLevel, skillItem: UserSkillItem) => {
              const skill = { ...selectedSkill, skill_rank: item.name };
              setSelectedSkill(skill);
            }}
          />
          <div className="btn-group align-right">
            <button className="app-btn" onClick={cancelSkillLevel}>
              Cancel
            </button>
            <button className="app-btn primary" onClick={addAndSaveSkill} disabled={!isSkillRankSelected}>
              {isEdit? 'Update Skill':'Add Skill'}
            </button>
          </div>
        </div>
        <div className={`skill-def-block active `}>
          <div className="skill-def-head">
            <h5 className="skill-def-title">Skill level definition</h5>
            {/* <button className="app-btn link-btn">
              <span className="toggle-text">{skillDefToggle ? "Hide" : "Show"}</span>
              <svg className="svg-icon">
                <use href="#upArrowIcon"></use>
              </svg>
            </button> */}
          </div>
          <div className="skill-def-content">
            <div className="accordion-section section-header">
              <input
                // defaultChecked={true}
                type="checkbox"
                className="accordion-radio"
                id={"ac1"+selectedSkill.id}
                name="shipping-accordion-group"
              />
              <label className="accordion-title" htmlFor={"ac1"+selectedSkill.id}>
                <div className="label-header">
                  <span className="label-title">Beginner</span>

                  <svg className="svg-icon accordion-arrow-icon">
                    <use xlinkHref="#dropdownArrowIcon">
                      <title>dropdown</title>
                    </use>
                  </svg>
                </div>
              </label>
              <div className="accordion-content">
                <ul>
                  <li>New to the field/skill and in the learning path</li>
                  <li>Limited experience in the assigned field/Skill</li>
                  <li>Need support and guidance to carry out work</li>
                </ul>
              </div>
            </div>
            <div className="accordion-section section-header">
              <input
                type="checkbox"
                className="accordion-radio"
                id={"ac2"+selectedSkill.id}
                
                name="shipping-accordion-group"
              />
              <label className="accordion-title" htmlFor={"ac2"+selectedSkill.id}>
                <div className="label-header">
                  <span className="label-title">Intermediate</span>

                  <svg className="svg-icon accordion-arrow-icon">
                    <use xlinkHref="#dropdownArrowIcon">
                      <title>dropdown</title>
                    </use>
                  </svg>
                </div>
              </label>
              <div className="accordion-content">
                <ul>
                  <li>Able to handle tasks and projects independently.</li>
                  <li>Able to apply the concepts easily.</li>
                  <li>Good in problem-solving.</li>
                  <li>Support team members to complete the tasks.</li>
                </ul>
              </div>
            </div>
            <div className="accordion-section section-header">
              <input
                type="checkbox"
                className="accordion-radio"
                id={"ac3"+selectedSkill.id}
                name="shipping-accordion-group"
              />
              <label className="accordion-title" htmlFor={"ac3"+selectedSkill.id}>
                <div className="label-header">
                  <span className="label-title">Expert</span>

                  <svg className="svg-icon accordion-arrow-icon">
                    <use xlinkHref="#dropdownArrowIcon">
                      <title>dropdown</title>
                    </use>
                  </svg>
                </div>
              </label>
              <div className="accordion-content">
                <ul>
                  <li>Able to review and provide feedback on the team’s work.</li>
                  <li>Able to establish best practices and promote them within the community.</li>
                  <li>Able to deliver training/workshops..</li>
                  <li>Able to review and provide feedback on the team’s work.</li>
                  <li>Up to date with the industry updates on the specified skill and spread the same within the community.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default SelectSkillLevel;
