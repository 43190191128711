import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useSkillStore } from "store/useSkillStore";

import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function deleteUserSkill(
  skillId:number
) {
  return await apiClient.delete(
    urls.deleteUserSkills(skillId)
  );
}

export function useDeleteUserSkill(): UseMutationResult<
  unknown,
  ApiError,
  {
    skillId: number;
  },
  unknown
> {
  const{skillSearchKey,categories} = useSkillStore();
  const queryClient = useQueryClient();
  return useMutation(
    async ({
     skillId
    }: {
      skillId:number;
    }) => {
      await deleteUserSkill(skillId);
      queryClient.invalidateQueries(["GetAllUserSkills"]);
      queryClient.invalidateQueries(["categoryWiseSkills",skillSearchKey,categories]);
    }
  );
}
