const getFileNameFromURL = (response:any) => {
  if(response.file_name){
    return response.file_name
  }else{
    const regex = /skill_matrix(.*).csv/;
    const match = response.file_url.match(regex);
    const fileName = match ? match[1] : '';
    return "skill_matrix_"+fileName+".csv";
  }
  };
  
export const handleDownload = async(response:any) => {
     fetch(response.file_url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = getFileNameFromURL(response); // Specify the desired file name
        document.body.appendChild(a);
        a.click();
        a.remove();
        // Release the URL object
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };