import axios from "axios";
import { urls } from "./urls";
import { useUserStore } from "store/useUserStore";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./adLogin/authConfig";
import { UNAUTHORIZED } from "./constants";
import { throttledError } from "./toastUtil";
import { clearAllStores } from "store";


const apiClient = axios.create({
    baseURL: urls.baseUrl,
    headers: { 
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use(
    function(config){
        if(config && config.headers){            
            config.headers['Accept']= 'application/json';
            config.headers['Authorization'] ='Bearer '+useUserStore.getState().accessToken;
            config.headers['X-Id-Token'] = useUserStore.getState().idToken;
            config.headers['X-User-Identity'] = useUserStore.getState().userInfo.user_identity??'';
        }
        return config;
    },
    function (error){
        return Promise.reject(error);
    }
)

apiClient.interceptors.response.use(
        (response) => response,
        async (error) => {
        const instance = new PublicClientApplication(msalConfig);
        try{
          const { status ,config} = error.response;
          if (status !== UNAUTHORIZED && status !== 0) {
            return Promise.reject(error)
          }
          else {
                
                const response = await instance.acquireTokenSilent({
                    scopes: ['User.Read'], 
                    account: instance.getAllAccounts()[0],
                    forceRefresh: true
                  });
                  const accessToken = response.accessToken;
                  const idToken = response.idToken;
                  useUserStore.setState((state) => {
                    return {
                      ...state,
                      accessToken: accessToken,
                      idToken:idToken,
                    };
                  });
              error.config.headers["Authorization"] =
                "Bearer " + useUserStore.getState().accessToken;
              error.config.headers["X-Id-Token"] =
                "Bearer " + useUserStore.getState().idToken;
              apiClient.request(error.config);
              return apiClient(config)
            }
          }
          catch (e) {
            console.log('---->>refresh token failed',e);
            throttledError();
            instance.logoutRedirect({
              postLogoutRedirectUri: `${window.location.origin}/logout-callback`,
            }).then(()=>{
              clearAllStores();
            })
          }
          let parsed_error = Object.assign({}, error);
          return Promise.reject(parsed_error);
        }
      );

export default apiClient;