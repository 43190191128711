import SelectBox from 'components/common-components/ReactSelect';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { useAdminStore } from 'store/adminStore';
import { OptionProps, MultiValueProps } from 'react-select';
import { useGetAllAppUsersList } from 'api/admin/useAllAppUserList';
import { useGetNonUsersList } from 'api/admin/useNonUsers';
import { useGetAllFilterUsersList } from 'api/admin/useAllFilter';

interface SenderSectionProps {
    selectedRecipients: Option[];
    promiseOptions: (inputValue: string) => Promise<Option[]>;
    Option: React.FC<OptionProps<Option>>;
    setSelectedRecipients: React.Dispatch<React.SetStateAction<Option[]>>;
    MultiValue: React.FC<MultiValueProps<Option, boolean>>;
    errorMessage: any
    setErrorMessage: any 
}

const SenderSection: React.FC<SenderSectionProps> = ({ selectedRecipients,
      promiseOptions, Option, setSelectedRecipients, MultiValue, errorMessage, setErrorMessage}) => {
        const {
            filterEnable,
            status,
            filterUserCount,
            skillLevel,
            skillType,
            department,
            userDesignation,
            skillName
        } = useAdminStore();

const { data: allAppUsersList, refetch: appUsersRefetch} = useGetAllAppUsersList();
const { data: nonUsersList, refetch: nonUsersRefetch } = useGetNonUsersList();
const { data: allFilterUsersList, refetch: filterUsersRefetch } = useGetAllFilterUsersList(
    skillName,           
    skillLevel,
    skillType,
    userDesignation,
    department,
);

    const Options = [
        { value: `All ${status?.current_active_app_users} users`, label: `All ${status?.current_active_app_users} users` },
        { value: "Non Initiators", label: "Non Initiators" },

    ]

    const filterOptions = [
        { value: `All ${status?.current_active_app_users} users`, label: `All ${status?.current_active_app_users} users` },
        { value: `Filtered ${filterUserCount} users`, label: `Filtered ${filterUserCount} users` },
        { value: "Non Initiators", label: "Non Initiators" },
    ]

    const handleQuillChange = async (selectedValue: Option) => {
        let sourceList: Option[] = []
        switch (selectedValue.label) {
            case `All ${status?.current_active_app_users} users`:
                sourceList = allAppUsersList || []
                break;
            case 'Non Initiators':
                sourceList = nonUsersList || []
                break;
            case `Filtered ${filterUserCount} users`:
                sourceList = allFilterUsersList || []
                break;    
            default:
                return;
        }
        setSelectedRecipients((prevRecipients) => {
            const existingRecipients = new Set(prevRecipients.map(item => item.value));
            const newRecipients = sourceList
                .filter((item: any) => !existingRecipients.has(item.value))
                .map((item: any) => ({ value: item.value, label: item.label }));
            return [...prevRecipients, ...newRecipients];
        });  
        setErrorMessage({...errorMessage, toError: ""})      
    };

    const handleAddRecipientClick = async (selectedOption: Option) => {
        await handleQuillChange(selectedOption);
      };

    return(
    <div className={`compose-sender-section ${errorMessage.toError? 'error': ""}`}>
    <span className='sender-tag'>To</span>
    <div className='sender-input-section'>
        <div className="selectBox-Wrapper  ">
            <AsyncSelect
                isClearable={true}
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                closeMenuOnSelect={true}
                placeholder="Type and press enter to add the recipients"
                hideSelectedOptions={true}
                components={{
                    Option: Option,
                    MultiValue,
                }}
                isSearchable={true}
                classNamePrefix="react-multi-select"
                value={selectedRecipients}
                onChange={(selectedOptions: any) => {
                    setErrorMessage({...errorMessage, toError: ""})      
                    setSelectedRecipients(selectedOptions)}}
            />

        </div>
        <div className='add-recipient-section '>
            <div className='error-message'>
                <svg className="svg-icon">
                    <use href="#infoCircleIcon">
                        <title>infoCircle icon</title>
                    </use>
                </svg>
                Please fill this field to send the mail
            </div>
            <div className="selection-box">
                <SelectBox
                    label=""
                    name="category"
                    options={filterEnable ? filterOptions : Options}
                    placeholder='Add Recipients'
                    value={null}
                    classNamePrefix="react-add-recipient"
                    isSearchable={false}
                    onChange={handleAddRecipientClick}
                />
                <span className="dropdown-arrow">
                    <svg className="svg-icon">
                        <use href="#downArrowIcon">
                            <title>down icon</title>
                        </use>
                    </svg>
                </span>
            </div>
        </div>
    </div>
</div>
);
            }
export default SenderSection;
