import Modal from "components/common-components/Modal"
import { useState } from "react";
import InputField from "components/common-components/InputField";
import "./edit-custom-skill.scss";
import { confirmDeleteCustomSkill } from "utils/confirmModals";
import { useUpdateCustomSkills } from "api/user/useUpdateCustomSkills";
import { useGetCategorySkills } from "api/user/useGetCategorySkills";
import { getCustomSkills } from "./helper";
import { useDeleteSkill } from "api/user/useDeleteSkill";
import { deepClone } from "utils/userUtils";
import { showSuccessToast } from "utils/toastUtil";
import { ErrorMessages, messages } from "utils/messageConstants";

const CustomSkillsModal = ({setshowEditCustomModal}:{setshowEditCustomModal:(show:boolean)=>void})=>{
    
    const {data: skillCategories} = useGetCategorySkills();
    const customSkills = getCustomSkills(skillCategories);
    const [customSkillsList,setCustomSkillsList] = useState(deepClone(customSkills));

    const {mutateAsync:updateCustomSkills,isLoading}= useUpdateCustomSkills();
    const {mutateAsync:deleteCustomSkill} = useDeleteSkill();
   
    const updateValue = (customSkill:Skills,editedName:string)=>{
        const updatedArray = customSkillsList.map(item => {
            if (item.id === customSkill.id) {
              
              return { ...item, name: editedName,error:"" };
            }
            return item; 
          });
        setCustomSkillsList(updatedArray);    
    }
    const deleteSkill = async(skill:Skills)=>{
        const confirm = await confirmDeleteCustomSkill();
        if(confirm){
            await deleteCustomSkill({skillId: skill.id});
            showSuccessToast(messages.SKILL_DELETED)
            const filteredList = customSkillsList.filter((item)=>item.id !== skill.id);
            setCustomSkillsList(filteredList);
            if(filteredList.length===0){
              setshowEditCustomModal(false);
            }
        }
    }
    const updateSkills = async()=>{
      try{
        const customSkills:CustomSkill[] = customSkillsList.map((item)=>({
          id:item.id,
          skill_name:item.name,
        }))
        const response= await updateCustomSkills({
          customSkills:customSkills
        });
        if(response && response.data && response.data.error_message && response.data.error_message.length === 0){
          showSuccessToast(messages.SKILL_UPDATED);
          setshowEditCustomModal(false);
        }
        else if(response && response.data && response.data.error_message && response.data.error_message.length >0){
          const errorList = response.data.error_message;
          const updatedSkills = customSkillsList.map((item) => {
            const matchingItem = errorList.find((errorItem:any) => errorItem.id === item.id);
            if (matchingItem) {
              return { ...item, error: matchingItem.message };
            }
            return item;
          });
          setCustomSkillsList(updatedSkills);
        }else{
          showSuccessToast(messages.SKILL_UPDATED);
          setshowEditCustomModal(false);
        }
    }catch(e){
      showSuccessToast(ErrorMessages.FAILED_TO_SAVE);
      setshowEditCustomModal(false);
     }
    }

    return(
        <Modal
        size="sm"
        title="Edit Custom Skills"
        primaryButton="Save"
        isBackArrow={false}
        onProceed={updateSkills}
        handleClose={() => setshowEditCustomModal(false)}
        placement="placement-bottom"
        btnLoaderIcon={isLoading}
      >
        {
            customSkillsList?.map((item:Skills)=>(
            <div className="app-modal-content edit-custom-skill">
                <div className="input-icon-wrapper">
                  <InputField type="text" placeholder="Add a Custom Skill" 
                    value ={item.name} 
                    error={item.error??""}
                    onChange={(e:any)=>{
                     updateValue(item,e.target.value);
                  }}/>
                  <button className="icon-only" onClick={async()=> await deleteSkill(item)}>
                     <svg className="svg-icon delete-icon">
                     <use href="#deleteIcon"></use>
                     </svg>
                 </button>
               </div>
            </div>
            ))
        }
        
      </Modal>
    )
}

export default CustomSkillsModal;