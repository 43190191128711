import { create } from 'zustand';
import produce from "immer";
import { persist } from "zustand/middleware";

export const useAdminStore = create<AdminStore>()(
  persist(
    (set) => ({
      businessUnits: [],
      categories: [],
      skillAdded: false,
      sortOption: undefined,
      searchkey: "",
      selectedSkill: undefined,
      certificationSearch: "",
      status: undefined,
      skillPaginationOffset: 0,
      selectedCerfifcation: undefined,
      certificationPaginationOffset: 0,
      initiateSkillExport: false,
      currentTab: "Skills",
      skillListPaginationOffset: 0,
      skillViewToggle: "gridView",
      initiateSkillExportById: false,
      certificationListPaginationOffset: 0,
      allSkillsForBubbleView: [],
      initiateCertificationExportById: false,
      groupByCategory: false,
      categoryListPaginationOffset: 0,
      categoryPaginationOffset: 0,
      queryBuilderPaginationOffset: 0,
      queryBuilderListPaginationOffset: 0,
      userSearch: "",
      designation: [],
      skillName: [],
      userDesignation: [],
      skillType: [],
      skillLevel: [],
      skillList: [],
      filterEnable: false,
      filterEnableIcon: false,
      department: [],
      departmentList: [],
      sortBy: [],
      sortOrder: [],
      allUsers: [],
      filterUserCount: 0,
      groupByDomains: false,
      domains: [],
      selectedSubDomain: undefined,
      subDomainPaginationOffset: 0,
      subDomainListPaginationOffset: 0,
      subDomainSearch: "",
      domainPaginationOffset: 0,
      domainListPaginationOffset: 0,
      filterSelection: "Skills",
      certificationList: [],
      certificationName: [],
      certificationIssuer: [],
      certificationStatus: [],
      sortOptionCertification: undefined,
      sortOptionSkills: undefined,
      sortOptionDomain: undefined,
      sortOptionDefaultSkills: undefined,
      nonInitiated: [],
      domainName: [],
      subdomainName: [],
      domainStatus: [],
      minDuration: [],
      maxDuration: [],
      nonInitatedDomain: [],
      currentPage: 1,
      filterToggle: false,
      subjectSearch: "",
      emailLogPaginationOffset: 0,
      emailLogListPaginationOffset: 0,
      sortOptionEmailLog: undefined,
      emailLogStatus: [],
      employeeNameSearch: "",
      emailLogRecipientsPaginationOffset: 0,
      emailLogRecipientsListPaginationOffset: 0,

      setEmailLogRecipientsListPaginationOffset: (offset: number | string) =>
        set(
          produce((state) => {
            state.emailLogRecipientsListPaginationOffset = offset;
          })
        ),

      setEmailLogRecipientsPaginationOffset: (offset: number | string) =>
        set(
          produce((state) => {
            state.emailLogRecipientsPaginationOffset = offset;
          })
        ),

      setEmployeeNameSearch: (key: string) =>
        set(
          produce((state) => {
            state.employeeNameSearch = key;
          })
        ),
      setEmailLogStatus: (emailStatus: EmailStatus[]) =>
        set(
          produce((state) => {
            state.emailLogStatus = emailStatus;
          })
        ),

      setSortOptionEmailLog: (sortOption: Option | undefined) =>
        set(
          produce((state) => {
            state.sortOptionEmailLog = sortOption;
          })
        ),

      setEmailLogListPaginationOffset: (offset: number | string) =>
        set(
          produce((state) => {
            state.emailLogListPaginationOffset = offset;
          })
        ),

      setEmailLogPaginationOffset: (offset: number | string) =>
        set(
          produce((state) => {
            state.emailLogPaginationOffset = offset;
          })
        ),

      setSubjectSearch: (key: string) =>
        set(
          produce((state) => {
            state.subjectSearch = key;
          })
        ),
      setFilterToggle: (filterToggle: boolean) =>
        set(
          produce((state) => {
            state.filterToggle = filterToggle;
          })
        ),
      setCurrentPage: (page: number) =>
        set(
          produce((state) => {
            state.currentPage = page;
          })
        ),
      setNonInitatedDomain: (nonInitatedDomain: Array<Option>) =>
        set(
          produce((state) => {
            state.nonInitatedDomain = nonInitatedDomain;
          })
        ),

      setMinDuration: (minDuration: Array<Option>) =>
        set(
          produce((state) => {
            state.minDuration = minDuration;
          })
        ),

      setMaxDuration: (maxDuration: Array<Option>) =>
        set(
          produce((state) => {
            state.maxDuration = maxDuration;
          })
        ),

      setDomainStatus: (domainStatus: Array<Option>) =>
        set(
          produce((state) => {
            state.domainStatus = domainStatus;
          })
        ),

      setSubDomainName: (subdomainName: Array<Option>) =>
        set(
          produce((state) => {
            state.subdomainName = subdomainName;
          })
        ),

      setDomainName: (domainName: Array<Option>) =>
        set(
          produce((state) => {
            state.domainName = domainName;
          })
        ),

      setNonInitiated: (nonInitiated: Array<Option>) =>
        set(
          produce((state) => {
            state.nonInitiated = nonInitiated;
          })
        ),

      setSortOptionDefaultSkills: (sortOption: Option | undefined) =>
        set(
          produce((state) => {
            state.sortOptionDefaultSkills = sortOption;
          })
        ),

      setSortOptionDomain: (sortOption: Option | undefined) =>
        set(
          produce((state) => {
            state.sortOptionDomain = sortOption;
          })
        ),

      setSortOptionSkills: (sortOption: Option | undefined) =>
        set(
          produce((state) => {
            state.sortOptionSkills = sortOption;
          })
        ),

      setSortOptionCertification: (sortOption: Option | undefined) =>
        set(
          produce((state) => {
            state.sortOptionCertification = sortOption;
          })
        ),

      setCertificationStatus: (certificationStatus: Array<Option>) =>
        set(
          produce((state) => {
            state.certificationStatus = certificationStatus;
          })
        ),

      setCertificationIssuer: (certificationIssuer: Array<Option>) =>
        set(
          produce((state) => {
            state.certificationIssuer = certificationIssuer;
          })
        ),

      setCertificationName: (certificationName: Array<Option>) =>
        set(
          produce((state) => {
            state.certificationName = certificationName;
          })
        ),

      setCertificationList: (certificationList: Array<Option>) =>
        set(
          produce((state) => {
            state.certificationList = certificationList;
          })
        ),

      setFilterSelection: (selection: string) =>
        set(
          produce((state) => {
            state.filterSelection = selection;
          })
        ),
      setDomainListPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.domainListPaginationOffset = offset;
          })
        ),

      setDomainPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.domainPaginationOffset = offset;
          })
        ),

      setSubDomainSearch: (key: string) =>
        set(
          produce((state) => {
            state.subDomainSearch = key;
          })
        ),

      setSubDomainListPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.subDomainListPaginationOffset = offset;
          })
        ),

      setSubDomainPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.subDomainPaginationOffset = offset;
          })
        ),

      setSelectedSubDomain: (subdomain: AdminSubDomain | undefined) =>
        set(
          produce((state) => {
            state.selectedSubDomain = subdomain;
          })
        ),
      setDomains: (domains: AdminDomain[]) =>
        set(
          produce((state) => {
            state.domains = domains;
          })
        ),
      setGroupByDomains: (status: boolean) =>
        set(
          produce((state) => {
            state.groupByDomains = status;
          })
        ),

      setFilterUserCount: (filterUserCount: number) =>
        set(
          produce((state) => {
            state.filterUserCount = filterUserCount;
          })
        ),
      setAllUsers: (allUsers: Array<Option>) =>
        set(
          produce((state) => {
            state.allUsers = allUsers;
          })
        ),

      setSortBy: (sortBy: Array<Option>) =>
        set(
          produce((state) => {
            state.sortBy = sortBy;
          })
        ),

      setSortOrder: (sortOrder: Array<Option>) =>
        set(
          produce((state) => {
            state.sortOrder = sortOrder;
          })
        ),

      setDepartmentList: (departmentList: Array<Option>) =>
        set(
          produce((state) => {
            state.departmentList = departmentList;
          })
        ),

      setDepartment: (department: Array<Option>) =>
        set(
          produce((state) => {
            state.department = department;
          })
        ),

      setFilterEnableIcon: (status: boolean) =>
        set(
          produce((state) => {
            state.filterEnableIcon = status;
          })
        ),

      setFilterEnable: (status: boolean) =>
        set(
          produce((state) => {
            state.filterEnable = status;
          })
        ),

      setSkillList: (skillList: Array<Option>) =>
        set(
          produce((state) => {
            state.skillList = skillList;
          })
        ),
      setSkillType: (skillType: Array<Option>) =>
        set(
          produce((state) => {
            state.skillType = skillType;
          })
        ),

      setSkillLevel: (skillLevel: Array<Option>) =>
        set(
          produce((state) => {
            state.skillLevel = skillLevel;
          })
        ),

      setUserDesignation: (userDesignation: Array<Option>) =>
        set(
          produce((state) => {
            state.userDesignation = userDesignation;
          })
        ),

      setSkillName: (skillName: Array<Option>) =>
        set(
          produce((state) => {
            state.skillName = skillName;
          })
        ),

      setDesignation: (designation: Array<Option>) =>
        set(
          produce((state) => {
            state.designation = designation;
          })
        ),
      setUserSearch: (key: string) =>
        set(
          produce((state) => {
            state.userSearch = key;
          })
        ),
      setQueryBuilderPaginationOffset: (offset: number | string) =>
        set(
          produce((state) => {
            state.queryBuilderPaginationOffset = offset;
          })
        ),
      setQueryBuilderListPaginationOffset: (offset: number | string) =>
        set(
          produce((state) => {
            state.queryBuilderListPaginationOffset = offset;
          })
        ),
      setInitiateCertificationExportById: (status: boolean) =>
        set(
          produce((state) => {
            state.initiateCertificationExportById = status;
          })
        ),

      setGroupByCategory: (status: boolean) =>
        set(
          produce((state) => {
            state.groupByCategory = status;
          })
        ),
      setCategoryListPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.categoryListPaginationOffset = offset;
          })
        ),
      setCategoryPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.categoryPaginationOffset = offset;
          })
        ),
      setAllSkillsForBubbleView: (skills: SkillNameCount[]) =>
        set(
          produce((state) => {
            state.allSkillsForBubbleView = skills;
          })
        ),
      setCertificationListPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.certificationListPaginationOffset = offset;
          })
        ),
      setInitiateSkillExportById: (status: boolean) =>
        set(
          produce((state) => {
            state.initiateSkillExportById = status;
          })
        ),
      setSkillsViewToggle: (toggle: string) =>
        set(
          produce((state) => {
            state.skillViewToggle = toggle;
          })
        ),
      setSkillListPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.skillListPaginationOffset = offset;
          })
        ),
      setCurrentTab: (tab: string) =>
        set(
          produce((state) => {
            state.currentTab = tab;
          })
        ),
      setInitiateSkillExport: (status: boolean) =>
        set(
          produce((state) => {
            state.initiateSkillExport = status;
          })
        ),
      setCertificationPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.certificationPaginationOffset = offset;
          })
        ),
      setSelectedCertification: (certification: CertificationHolderItem | undefined) =>
        set(
          produce((state) => {
            state.selectedCerfifcation = certification;
          })
        ),
      setSkillPaginationOffset: (offset: number) =>
        set(
          produce((state) => {
            state.skillPaginationOffset = offset;
          })
        ),
      setStatus: (status: Status | undefined) =>
        set(
          produce((state) => {
            state.status = status;
          })
        ),
      clearStore: () =>
        set(
          produce((state) => {
            state.businessUnits = [];
            state.categories = [];
            state.skillAdded = false;
            state.sortOption = undefined;
            state.searchkey = "";
            state.selectedSkill = undefined;
            state.certificationSearch = "";
            state.initiateSkillExport = false;
            state.skillPaginationOffset = 0;
            state.currentTab = "Skills";
            state.skillSViewToggle = "gridView";
            state.initiateSkillExportById = false;
            state.status = undefined;
            state.selectedCerfifcation = undefined;
            state.certificationPaginationOffset = 0;
            state.skillListPaginationOffset = 0;
            state.skillViewToggle = "gridView";
            state.certificationListPaginationOffset = 0;
            state.allSkillsForBubbleView = [];
            state.groupByCategory = false;
            state.groupByDomains = false;
            state.categoryPaginationOffset = 0;
            state.categoryListPaginationOffset = 0;
            state.queryBuilderPaginationOffset = 0;
            state.queryBuilderListPaginationOffset = 0;
            state.userSearch = "";
            state.designation = [];
            state.skillName = [];
            state.userDesignation = [];
            state.skillType = [];
            state.skillLevel = [];
            state.skillList = [];
            state.filterEnable = false;
            state.filterEnableIcon = false;
            state.department = [];
            state.departmentList = [];
            state.sortBy = [];
            state.sortOrder = [];
            state.allUsers = [];
            state.domains = [];
            state.subDomainPaginationOffset = 0;
            state.subDomainListPaginationOffset = 0;
            state.domainSearch = "";
            state.filterSelection = "Skills";
            state.certificationList = [];
            state.certificationName = [];
            state.certificationIssuer = [];
            state.certificationStatus = [];
            state.sortOptionCertification = undefined;
            state.sortOptionDomain = undefined;
            state.sortOptionDefaultSkills = undefined;
            state.sortOptionSkills = undefined;
            state.nonInitiated = [];
            state.domainName = [];
            state.subdomainName = [];
            state.domainStatus = [];
            state.minDuration = [];
            state.maxDuration = [];
            state.nonInitatedDomain = [];
            state.currentPage = 1;
            state.filterToggle = false;
            state.subjectSearch = "";
            state.sortOptionEmailLog = undefined;
            state.emailLogPaginationOffset = 0;
            state.emailLogListPaginationOffset = 0;
            state.emailLogStatus = [];
            state.employeeNameSearch = "";
            state.emailLogRecipientsListPaginationOffset=0;
            state.emailLogRecipientsPaginationOffset=0;
            // state.emailCheckStatus = false;
          })
        ),
      setCertificationSearch: (key: string) =>
        set(
          produce((state) => {
            state.certificationSearch = key;
          })
        ),
      setSelectedSkill: (skill: AdminSkill | undefined) =>
        set(
          produce((state) => {
            state.selectedSkill = skill;
          })
        ),
      setSearchKey: (search: string) =>
        set(
          produce((state) => {
            state.searchkey = search;
          })
        ),
      resetFilters: () =>
        set(
          produce((state) => {
            const resetCategories =
              state.categories?.map((item: Category) => ({ ...item, checked: false })) ?? [];
            state.categories = resetCategories;
            state.sortOption = undefined;
          })
        ),
      resetFiltersDomain: () =>
        set(
          produce((state) => {
            const resetDomains =
              state.domains?.map((item: AdminDomain) => ({ ...item, checked: false })) ?? [];
            state.domains = resetDomains;
            state.sortOption = undefined;
          })
        ),
      resetFiltersEmailLog: () =>
        set(
          produce((state) => {
            const resetEmailLog =
              state.emailStatus?.map((item: EmailStatus) => ({ ...item, checked: false })) ?? [];
            state.emailStatus = resetEmailLog;
          })
        ),
      setSortOption: (sortOption: Option | undefined) =>
        set(
          produce((state) => {
            state.sortOption = sortOption;
          })
        ),
      setSkillAdded: (skillAdded: boolean) =>
        set(
          produce((state) => {
            state.skillAdded = skillAdded;
          })
        ),
      setCategories: (categories: Category[]) =>
        set(
          produce((state) => {
            state.categories = categories;
          })
        ),
      setBusinessUnits: (businessUnits: BusinessUnit[]) =>
        set(
          produce((state) => {
            state.businessUnits = businessUnits;
          })
        ),
      updateBusinessUnit: (businessUnit: BusinessUnit) =>
        set(
          produce((state) => {
            const businessUnits = state.businessUnits;
            const filteredList = businessUnits.map((item: BusinessUnit) => {
              if (item.id === businessUnit.id) {
                return businessUnit;
              } else {
                return item;
              }
            });
            state.businessUnits = filteredList;
          })
        ),
    }),
    { name: "admin-store" }
  )
);