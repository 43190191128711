import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";
import { handleDownload } from "utils/downloadFile";

export async function getAllFilterSkillsUser(
  skill_names: string,
  skill_levels: string,
  skill_types: string,
  designations: string,
  departments: string,
  sort: Option | undefined
): Promise<any> {
  const response = await apiClient.get(
    urls.exportSkillsUsers(skill_names, skill_levels, skill_types, designations, departments, sort)
  );
  if (response && response.data && response.data.data) return response.data.data;
  return null;
}

export function useAllFilterSkillsUsersExport(skill_names: any,skill_levels: any, skill_types:any, designations: any, department_value:any ):UseQueryResult<any> {
  const {initiateSkillExport,skillName, skillLevel, skillType, userDesignation, sortOptionSkills, department}= useAdminStore();
 const SkillNames =  skill_names.map((item: any)=>item.value).join(",");
 const skillTypes =  skill_levels.map((item: any)=>item.value).join(",");
 const skillLevels =  skill_types.map((item: any)=>item.value).join(",");
 const Designations =  designations.map((item: any)=>item.value).join(",");
 const Departments =  department_value.map((item: any)=>item.value).join(",");
  return useQuery(["AllFilteredUsersExport",skillName, skillLevel, skillType, userDesignation, department], async () => {
    const result = await getAllFilterSkillsUser(
      SkillNames,
      skillTypes,
      skillLevels,
      Designations,
      Departments,
      sortOptionSkills
    );
    if(result && result.file_url){
      await handleDownload(result);
    }
    return result;
  },{
    enabled: initiateSkillExport === true,
  });
}

