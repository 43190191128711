import React from "react";
import "./faqSplash.scss";

const FaqSplash = () => {
  const faqimage = require("../../../../../assets/temp-images/faq.png");

  const openMail=()=>{
    const mailAddress ="skillmatrix@experionglobal.com";
    const mailtoLink = `mailto:${mailAddress}`;
    window.open(mailtoLink, '_blank');
  }


  return (
    <div className="faq-main-wrapper">
      <div className="faq-img-wrapper">
        <img className="page-img" alt="user" src={faqimage}></img>
      </div>
      <div className="faq-wrapper">
        <p className="faq-heading">Frequently Asked Questions</p>
        <div className="faq-content">
          <p>
            Please contact <a className="faq-mail" href="#" onClick={openMail}>skillmatrix@experionglobal.com </a>for further support
          </p>
        </div>
      </div>
    </div>
  );
};
export default FaqSplash;
