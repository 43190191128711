import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";

export async function getQueryBuilderDefaultUsers(offset:number, limit: string|number, search: string,  sort: Option | undefined):Promise<QueryBuilderDefaultUser|null> {
  const response = await apiClient.get(urls.defaultUserList(offset, limit, search, sort));
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetQueryBuilderDefaultUsers():UseQueryResult<QueryBuilderDefaultUser> {
  const {queryBuilderListPaginationOffset, queryBuilderPaginationOffset, userSearch,sortOptionDefaultSkills} = useAdminStore();
  return useQuery(["QueryBuilderDefaultUserList",queryBuilderListPaginationOffset, queryBuilderPaginationOffset, userSearch, sortOptionDefaultSkills], async () => {
    const defaultUsers =  await getQueryBuilderDefaultUsers(queryBuilderListPaginationOffset, queryBuilderPaginationOffset, userSearch, sortOptionDefaultSkills);
    return defaultUsers;
  },{
    refetchOnWindowFocus:false
  });
}