import { UseQueryResult, useQuery } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { useUserStore } from "store/useUserStore";


export async function getProfileImage(ssoId:string):Promise<string> {
    try{
    const response:any = await apiClient.get(`https://graph.microsoft.com/v1.0/users/${ssoId}/photo/$value`, {
        responseType: 'blob'
      });
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(response.data);
      return blobUrl;
    }catch(e){
        console.log('profile image error',e);
        return '';
    }
}

export function useGetProfileImage():UseQueryResult<string> {
  const {userInfo}=useUserStore();
  return useQuery(["AdminImageurl",userInfo.sso_login_id], async () => {
    return getProfileImage(userInfo.sso_login_id);
  },
  {
    staleTime: Infinity,
  });
}
