import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function deleteUserSkill(
  certificationId:number
) {
  return await apiClient.delete(
    urls.certificationById(certificationId)
  );
}

export function useDeleteCertification(): UseMutationResult<
  unknown,
  ApiError,
  {
    certificationId: number;
  },
  unknown
> {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
        certificationId
    }: {
        certificationId:number;
    }) => {
      await deleteUserSkill(certificationId);
      queryClient.invalidateQueries(["GetAllUserCertifications"]);
    }
  );
}
