import React, { useEffect, useState, useRef } from "react";
import "./subdomainGridWrapper.scss"
import SubDomainUserListing from "./UserSubDomainListingModal";
import AdminModal from "components/common-components/AdminModel";
import { Link } from "react-router-dom";
import { useGetAdminSubDomains } from "api/admin/useGetSubDomains";
import { useAdminStore } from "store/adminStore";
import { ErrorMessages, messages } from "utils/messageConstants";
import { showSuccessToast, showErrorToast } from "utils/toastUtil";
import { useUpdateSkill } from "api/admin/useUpdateSkill";
import SkillPagination from "components/common-components/SkillPagination";
import { PAGINATION_ITEMS_PER_PAGE } from "utils/constants";
import { useUserStore } from "store/useUserStore";
import { useGetSubDomainUserExport } from "api/admin/useExportSubDomainUsers";
interface Props {
  addButtonText?: string;
  editMode?: any;
  handleAdd: any;
}
const SubDomainGridView: React.FC<Props> = ({ addButtonText, editMode, handleAdd}) => {
  const { data: skills } = useGetAdminSubDomains();
  const { mutateAsync: updateSkillData } = useUpdateSkill();
  const { data, refetch } = useGetSubDomainUserExport();

  const refs: Array<any> = [];
  const [isTooltipActive, setTooltipActive] = useState([false]);

  useEffect(() => {
    const tooltipVisible: boolean[] = [];
    refs.map((ref, i) => {
      const element = ref?.current as HTMLElement;
     if (element) {
     tooltipVisible.push(element.getBoundingClientRect().width + 1 < element.scrollWidth);
       }
    });

    if (JSON.stringify(tooltipVisible) !== JSON.stringify(isTooltipActive)) {
      setTooltipActive(tooltipVisible);
     }
  },[]);
  
  const {
    selectedSubDomain,
    categories,
    setSelectedSubDomain,
    selectedSkill,
    setSelectedSkill,
    setSkillAdded,
    setSubDomainPaginationOffset,
    setInitiateSkillExportById,
    setSubDomainListPaginationOffset,
  } = useAdminStore();
  const { userManagedRole} = useUserStore();
  const [showModal, setShowModal] = useState(false);
  const [SkillCategory, setSkillCategory] = useState("");
  const [skillName, setSkillName] = useState("");
  const [selectedCategory, setCategory] = useState<Category | undefined>(undefined);
  
  const [errorMessage, setErrorMessage] = useState("");
  const [count, setCount] = useState(1);

  const getCategoryById = (categoryId:number)=>{
    return categories.find(item=>item.id===categoryId);
  }
  const handleModal = () => {
    setSubDomainPaginationOffset(0);
    setShowModal(!showModal);
  };
  const [toggleEditSkillModal, setToggleEditSkillModal] = useState(false);

  const handleEditSkillModal = () => {
    setToggleEditSkillModal(!toggleEditSkillModal);
  };

  const updateSkill = async()=>{
      if (skillName === "") {
        setErrorMessage(ErrorMessages.SKILL_NAME_MISSING);
        return;
      }
      if (selectedCategory) {
        const response = await updateSkillData({skillId: selectedSkill?.skill_id??0, skillName, categoryId: selectedCategory?.id });
        if (response.data) {
          setSelectedSkill(undefined);
          setSkillAdded(true);
          showSuccessToast(messages.SKILL_SAVE_SUCCESS);
          setErrorMessage("");
          handleEditSkillModal();
        } else if(response?.response?.data.message) {
          setErrorMessage(response?.response?.data.message);
        }else{
          setErrorMessage(messages.SKILL_SAVE_DUPLICATE)
        }
      } else {
        setErrorMessage(messages.SELECT_CATEGORY);
      }
  };

  const downloadSubDomainUserReport = ()=>{
    setInitiateSkillExportById(true);
    refetch();
    if(data){
      setInitiateSkillExportById(false);
    }
  }
  //scrollbar condition
  const parentRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      if (parentRef.current) {
        parentRef.current.scrollTop = 0;
      }
     
    }, [count]);
  return (
    <>
      <div className="skills-grid-view">
        <div className="skill-layout" ref={parentRef}>
          <div className={`layout fr-5  purple-shade `}>
            {skills?.total === 0 ? " No Sub Domain(s) Found" : null}
            {skills?.sub_domains?.map((sub_domainsItem: AdminSubDomain, sub_domainsIndex: number) => {
              refs.push(React.createRef());

              return (
                <div className="skill-item-set" key={sub_domainsIndex}>
                  <Link
                    to="#"
                    className="skill-item-link"
                    onClick={() => {
                     if (sub_domainsItem.user_count > 0) {
                        setSelectedSubDomain(sub_domainsItem);
                        handleModal();
                      }
                    }}
                  >
                    {" "}
                    <div className="skill-item-top">
                      <div
                      className="skill-item-names"
                        // className="skill-item-name "
                        {...(isTooltipActive[sub_domainsIndex] && {
                          "title": `${sub_domainsItem.sub_domain_name}`,
                        })}
                        key={sub_domainsIndex}
                        title={sub_domainsItem.sub_domain_name.length >30 ?sub_domainsItem.sub_domain_name:''}
                      >
                        {" "}
                        {sub_domainsItem.sub_domain_name}
                      </div>

                      <div className="skill-counts">{sub_domainsItem.user_count}</div>
                    </div>
                    <div className="skill-item-bottom">
                      <div className="skill-num-edit-wrapper">
                        <div className="skill-count">{sub_domainsItem.proficiency_level_expert_count}</div>
                        <div className="skill-level">Expert</div>
                      </div>
                      <div className="skill-num-edit-wrapper">
                        <div className="skill-count">{sub_domainsItem.proficiency_level_intermediate_count}</div>
                        <div className="skill-level">Intermediate</div>
                      </div>
                      <div className="skill-num-edit-wrapper">
                        <div className="skill-count">{sub_domainsItem.proficiency_level_beginner_count}</div>
                        <div className="skill-level">Beginner</div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
 <SkillPagination
          itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
          totalItems={skills?.total??0}
          onPageChange={(page)=>{
            const offset = (page-1)* PAGINATION_ITEMS_PER_PAGE;
            setSubDomainListPaginationOffset(offset);
            setCount(count+1)
          }}
        />
      </div>

      {showModal ? (
        <>
          <AdminModal
            title={selectedSubDomain?.sub_domain_name}
            subTitle={selectedSubDomain?.domain_name}
            count={selectedSubDomain?.user_count??0}
            size="extra-md"
            handleClose={() => {
              setShowModal((prev)=>!prev)
              setSelectedSubDomain(undefined);
            }}
            isBackArrow={false}
            placementFull="placement-full"
            headerWrapper="header-wrapper"
            withEditBtn={false}
            hidefooter={true}
            SkillCategory={SkillCategory}
            showClose={true}
            countText={selectedSubDomain && selectedSubDomain?.user_count <=1 ?"User":"Users"}
            onProceed={downloadSubDomainUserReport}
            isSkillUsers={true}
            from={"Domains"}
          >
            <SubDomainUserListing downloadReport={downloadSubDomainUserReport}/>
          </AdminModal>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default SubDomainGridView;
