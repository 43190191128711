import React, { useState, useEffect } from "react";
import { useAdminStore } from "store/adminStore";
import SelectBox from "components/common-components/ReactSelect";
import Pagination from "components/common-components/Pagination";
import { emailLogFilterOptions, initialView, undeliveredEmailFilterOptions, View } from "utils/constants";
import SearchPanel from "../ActionHeader/SearchPanel";
import EmailLog from "./EmailLog";
interface Props {
  tabSwitch?: any;
}

const AdminEmailLog: React.FC<Props> = ({ tabSwitch }) => {
  const {
    setCurrentPage,
    setEmailLogListPaginationOffset,
    setEmailLogPaginationOffset,
    setEmployeeNameSearch,
    setSubjectSearch
  } = useAdminStore();

  const [userDataList, setDataUserList] = useState<any>(null);
  const [viewLimit, setViewLimit] = useState<any>(initialView);
  const [limit, setLimit] = useState(25);
  const [emailLogStatus, setEmailLogStatus] = useState<EmailStatus[]>(emailLogFilterOptions);
  const [recipientEmailStatus, setRecipientEmailStatus] = useState<EmailStatus[]>(emailLogFilterOptions);
  const [undeliveredEmailStatus, setUndeliveredEmailStatus] = useState<EmailStatus[]>(undeliveredEmailFilterOptions)


  function handleAddSkillModal(): void {
    throw new Error("Function not implemented.");
  }

  function download(): void {
    throw new Error("Function not implemented.");
  }

  const handlePageChange = (page: number) => {
    const offset = (page - 1) * limit;
    setEmailLogListPaginationOffset(offset);
  };

  return (
    <>
      <SearchPanel
        tabSwitch={tabSwitch}
        setSkillSViewToggle={() => console.log('')}
        searchPlaceholder="Start typing to search for subject"
        addButtonText="Add Skill"
        setEditMode={false}
        isSkillPage={false}
        from={'emailLog'}
        download={download}
        handleAdd={handleAddSkillModal}
        emailLogStatus = {emailLogStatus}
        setEmailLogStatus = {setEmailLogStatus}
      />
      <div className="user-details-section">
        <EmailLog 
          onUpdateDefaultUserList={(updatedList: any) => setDataUserList(updatedList)} 
          setLimit={setLimit}
          emailLogStatus={emailLogStatus} 
          setEmailLogStatus={setEmailLogStatus}
          recipientEmailStatus = {recipientEmailStatus}
          setRecipientEmailStatus = {setRecipientEmailStatus}
          undeliveredEmailStatus = {undeliveredEmailStatus}
          setUndeliveredEmailStatus = {setUndeliveredEmailStatus}
          />
        <div className="user-details-footer">
          <div className="item-view-section">
            <span className="item-view-details">View</span>
            <div className="selection-box">
              <SelectBox
                label=""
                name="category"
                options={View}
                value={View.find(option => option.value === viewLimit.value)}
                classNamePrefix="react-select-box-page"
                isSearchable={false}
                onChange={(item: Option) => {
                  setViewLimit(item.value);
                  setEmailLogListPaginationOffset(0);
                  setEmailLogPaginationOffset(item.value);
                  setCurrentPage(1);
                }}
              />
              <span className="dropdown-arrow">
                <svg className="svg-icon">
                  <use href="#downArrowIcon">
                    <title>down icon</title>
                  </use>
                </svg>
              </span>
            </div>
            <span className="item-view-details">items per page of {userDataList?.total_count} entries</span>
          </div>
          <Pagination
            itemsPerPage={limit}
            totalItems={userDataList?.total_count ?? 0}
            onPageChange={handlePageChange}
            limit={limit}
          ></Pagination>
        </div>
      </div>
      <div className="mobile-pagination">
        <Pagination
          itemsPerPage={limit}
          totalItems={userDataList?.total_count ?? 0}
          onPageChange={handlePageChange}
          limit={limit} />
      </div>
    </>
  );
};
export default AdminEmailLog;

