import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";

export async function getNonUsersList():Promise<AllUsers|null> {
  const response = await apiClient.get(urls.nonUsersList());
  if (response && response.data && response.data.data )
    return response.data.data;
  return null;
}

export function useGetNonUsersList():UseQueryResult<AllUsers | any> {
  return useQuery(["nonUsersList"], async () => {
    const data= await getNonUsersList();
    if (data != null) {
        const userValue = data?.users?.map((item: any) => {
          if (item?.first_name || item?.last_name) {
            return {
              value: item.email,
              label: `${item.first_name} ${item.last_name}`,
              checked: false,
            };
          } 
          else {
            return {
              value: item.email,
              label: item.email,
              checked: false,
            };
          }
        });
        return userValue;
      }
  },{
    refetchOnWindowFocus:false,
  });
}