import { QueryClient, UseMutationResult, useMutation } from "@tanstack/react-query";
import { useAdminStore } from "store/adminStore";

import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveCertificationMaster(
    name:string,
){
    try{
    const requestObject = {
          certification_name: name
        }
    const response = await apiClient.post(
        urls.certificationMasters(),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useSaveCertificationMaster():UseMutationResult<
    any,
    ApiError | {message: string},
    {name:string}
    >{
        const queryClient= new QueryClient();
        const {certificationSearch,certificationListPaginationOffset} = useAdminStore();
        return useMutation(
        async ({
            name,
        }: {
            name: string;
        }) => {
            const result = await saveCertificationMaster(name);
            queryClient.invalidateQueries([["AdminCertifications",certificationSearch,certificationListPaginationOffset]]);
            return result;
        }
    );
    }