import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserStore } from "store/useUserStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateAllUserNotifications(
    ids:number[],
){
    try{
    const requestObject = {
        notifications: ids.map((id)=>({
            notification_id: id,
            is_seen: true
        }))
      };
    const response = await apiClient.put(
        urls.notifications(),
        requestObject,
    );
    return response.data ;
    }catch(err){
        return err ;
    }
}

export function useUpdateAllNotifications():UseMutationResult<
    any,
    ApiError | {message: string},
    {notificationIds:number[]}
    >{
        const {accessToken}=useUserStore();
        const queryClient = useQueryClient();
        return useMutation(
        async ({
            notificationIds,
        }: {
            notificationIds:number[];
        }) => {
            const result = await updateAllUserNotifications(notificationIds);
            queryClient.invalidateQueries(["userNotifications",accessToken]);
            return result;
        }
    );
    }