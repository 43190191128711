import React, { useMemo } from 'react';
import Checkbox from 'components/common-components/Checkbox';
import ReactSelectDropdown from 'components/common-components/ReactSelectDropdown';
import { CertificationStatus, NONINITIATED } from 'utils/constants';
import { useGetCertfificationMasters } from 'api/user/useGetCertificationMaster';
import { useGetProviders } from 'api/user/useGetProviders';
import { useAdminStore } from "store/adminStore";

interface Props {
  handleChangeDesignation?: (selectedOptions: Option[]) => void;
  secondValue?: Option[];
  thirdValue?: Option[];
  handleChangeDepartment?: (selectedOptions: Option[]) => void;
  handleChangeCertificationName?: (selectedOptions: Option[]) => void;
  handleChangeCertificationIssuer?: (selectedOptions: Option[]) => void;
  handleChangeCertificationStatus?: (selectedOptions: Option[]) => void;
  tempCertificationName?: Option[];
  tempCertificationIssuer?: Option[];
  tempCertificationStatus?: Option[];
  designationLists?: Option[];
  departmentLists?: Option[];
  tempNonInitiated?: Option[];
  nonInitatedCheck?: (item: Option) => void;
  setTempCertificationIssuer?: any
  setTempCertificationName?: any
  setTempCertificationStatus?: any
  setTempUserDesignation?: any
  setTempDepartment?: any





}

const CertificationDataFilter : React.FC<Props> = ({
  handleChangeDesignation,
  secondValue,
  thirdValue,
  handleChangeDepartment,
  handleChangeCertificationName,
  handleChangeCertificationIssuer,
  handleChangeCertificationStatus,
  tempCertificationName,
  tempCertificationIssuer,
  tempCertificationStatus,
  designationLists,
  departmentLists,
  tempNonInitiated,
  nonInitatedCheck,
  setTempCertificationIssuer,
  setTempCertificationName,
  setTempCertificationStatus,
  setTempUserDesignation,
  setTempDepartment




}) => {
  const { data: certificationList } = useGetCertfificationMasters();
  const { data: certificationIssuersList} = useGetProviders();
  const { 
    nonInitiated,
    setCertificationName,
    setCertificationIssuer,
    setCertificationStatus,
    setUserDesignation,
    setDepartment,
    setFilterEnable,
    setFilterEnableIcon
    
  } = useAdminStore();



  const certificationLists: Option[] = useMemo(() => {
    return certificationList?.map((item: CertificationMaster) => ({
      label: item.certification_name,
      value: item.certification_name,
    })) ?? [];
  }, [certificationList]);

  const certificationIssuersLists:Option[] = useMemo(()=>{
    return certificationIssuersList?.map((item:CertificationProvider)=>(
    {
      label:item.certification_issuer_name,
      value:item.certification_issuer_name,
    })) ?? [];
  },[certificationIssuersList]);
  
  return (
    <div className="user-filter-wrapper">
      <ReactSelectDropdown
        options={certificationLists}
        title="Certification Name"
        handleChange={handleChangeCertificationName}
        Value={tempCertificationName}
        placeholder="Select certification name"
        isDisabled={(tempNonInitiated?.length ?? 0) > 0 ? true : false}

      />
      <ReactSelectDropdown
        options={certificationIssuersLists}
        title="Certification Issuer"
        handleChange={handleChangeCertificationIssuer}
        Value={tempCertificationIssuer}
        placeholder="Select certification issuer"
        isDisabled={(tempNonInitiated?.length ?? 0) > 0 ? true : false}

      />
      <ReactSelectDropdown
        options={CertificationStatus}
        title="Certification Status"
        handleChange={handleChangeCertificationStatus}
        Value={tempCertificationStatus}
        placeholder="Select certification status "
        isDisabled={(tempNonInitiated?.length ?? 0) > 0 ? true : false}

      />
       <ReactSelectDropdown
        options={designationLists}
        title="Designation"
        handleChange={handleChangeDesignation}
        Value={secondValue}
        placeholder="Select the designation"
        isDisabled={false}

      />
       <ReactSelectDropdown
        options={departmentLists}
        title="Department"
        handleChange={handleChangeDepartment}
        Value={thirdValue}
        placeholder="Select the department "
        isDisabled={false}

      />
       <div className='skill-level-section'>
        <div className='skill-level-heading'>Non Initiative</div>
        {NONINITIATED?.map((item: Option, key: number) => {
          const tick = tempNonInitiated?.filter((level: Option) => level.value === item.value);
          return (
            <Checkbox key={key} label={item.label} name="filter-checkbox" value={tick?.length} onChange={() => {
              nonInitatedCheck?.(item)
              setTempCertificationIssuer([]);
              setTempCertificationName([]);
              setTempCertificationStatus([]);
              setTempUserDesignation([]);
              setTempDepartment([]);
              setCertificationName([]);
              setCertificationIssuer([]);
              setCertificationStatus([]);
              setUserDesignation([]);
              setDepartment([]);
              setFilterEnable(false);
              setFilterEnableIcon(false);

               }} />
          );
        })}
      </div>
     
    </div>
  );
}

export default CertificationDataFilter ;

