import { useEffect, useRef, useState } from "react";
import "./user-listing.scss"
import { useAdminStore } from "store/adminStore";
import { useGetSkillUsers } from "api/admin/useGetSkillusers";
import SkillPagination from "components/common-components/SkillPagination";
import { PAGINATION_ITEMS_PER_PAGE } from "utils/constants";
import UserProfileImage from "components/UserImage";
import SortButton from "components/sortButton";
import { Sort } from "utils/constants";


type SortStatus = {
  [key: string]: string;
};

type SortStatusKeys = keyof SortStatus;

const UserListing = ({ downloadReport }: { downloadReport: () => void; }) => {
  const { data: users } = useGetSkillUsers();
  const { setSkillPaginationOffset, setSortOptionSkills } = useAdminStore();
  const [count, setCount] = useState(1);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const sortByColumnsInitialState: SortStatus = {
    sortByEmployee: "asc",
    sortByDesignation: "disabled",
    sortBySkillLevel: "disabled",
    sortBySkillType: "disabled",
  };

  const [sortByColumn, setSortByColumn] = useState<SortStatus>(
    sortByColumnsInitialState
  );

  const sortByOnClick = (column: SortStatusKeys, columnNo: number) => {
    const sortOrder =
      sortByColumn[column] === "asc" ? "desc" : "asc";
    const updatedSortByColumn = {
      ...Object.fromEntries(
        Object.keys(sortByColumn).map((key) => [key, "disabled"])
      ),
      [column]: sortOrder,
    };
    setSortByColumn(updatedSortByColumn);
    setSortOptionSkills(Sort[columnNo * 2 + (sortOrder === "asc" ? 0 : 1)]);
  };

  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.scrollTop = 0;
    }
  }, [count]);

  const renderSortButton = (
    column: SortStatusKeys,
    index: number
  ) => (
    <SortButton
      isAscending={sortByColumn[column]}
      onClick={() => sortByOnClick(column, index)}
      icon="#sortIcon"
    />
  );

  return (
    <div className="app-modal-content user-listing-app sm">
      <div className="skill-heading">
        <div className="admin-skill-header skill-name"><span className="sortWrapper">NAME{renderSortButton("sortByEmployee", 1)}
        </span></div>
        <div className="admin-skill-designation skill-designation"><span className="sortWrapper">DESIGNATION{renderSortButton("sortByDesignation", 2)}
        </span></div>
        <div className="admin-skill-bu skill-level"><span className="sortWrapper">SKILL LEVEL{renderSortButton("sortBySkillLevel", 17)}
        </span></div>
        <div className="admin-skill-level skill-type"><span className="sortWrapper">SKILL TYPE{renderSortButton("sortBySkillType", 8)}
        </span></div>
      </div>
      <div className="user-skill-item-wrapper" ref={parentRef}>
        {
          users?.users.map((user, index) => (
            <div className="user-skill-row" key={index}>
              <div className="user-skill-detail">
                <div className="profile-img-holder">
                  <UserProfileImage first_name={user.first_name} ssoId={user.sso_login_id} />
                </div>
                <div className="user-skill-name-id">
                  <div className="user-skill-name">{user.first_name} {user.last_name}</div>
                  <div className="user-skill-id">ID: {user.employee_id}</div>
                </div>
              </div>
              <div className="user-skill-designation">{user.designation}</div>
              <div className="user-skill-bu">{user.skill_rank}</div>
              <div className={`user-skill-level ${user.skill_type.toLowerCase()}`} >{user.skill_type}</div>
            </div>
          ))
        }
      </div>

      <SkillPagination
        itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
        onPageChange={(page) => {
          const offset = (page - 1) * PAGINATION_ITEMS_PER_PAGE;
          setSkillPaginationOffset(offset);
          setCount(count + 1)
        }}
        totalItems={users?.total ?? 0} />

      <div className="download-footer-btn">
        <button className="app-btn icon-btn-text primary" onClick={downloadReport}>
          <svg className="svg-icon down-icon">
            <use href="#downIcon">
              <title>Add Icon</title>
            </use>
          </svg>
          <span className="button-text">Download Report</span>
        </button>
      </div>
    </div>
  );
};
export default UserListing;
