import { toast } from 'react-toastify';
export const showSuccessToast = (message:string)=> toast.success(message,{theme: "colored",});
export const showErrorToast = (message:string)=> toast.error(message,{theme: "colored",});
export const showWarningToast = (message:string)=> toast.error(message,{theme:"colored"});
export const showLoginError = (message:string,toastId:number)=> toast.error(message,{theme:"colored",toastId},);

export function throttle(callback: () => void, limit: number) {
    let wait = false; 
    return function () {
      if (!wait) {
        callback(); 
        wait = true; 
        setTimeout(function () {
          wait = false; 
        }, limit);
      }
    };
  }

export const throttledError = throttle(() => toast.error("Session expired. Logging out."), 60 * 1000);