import { ActionMeta, MultiValue, PropsValue, SingleValue } from "react-select";
import "./react-select.scss";
import { useGetCertificationTags } from "api/user/useGetTags";

import CreatableSelect from "react-select/creatable";
import { useSkillStore } from "store/useSkillStore";
import { useState } from "react";
type MultiOnChange = (val: MultiValue<Option>, actionMeta: ActionMeta<Option>) => void;

interface Props {
  placeholder?: string;
  values: Option | null | PropsValue<Option>;
  inputLabel?: string;
  isMulti?: boolean;
  classNamePrefix?: string;
  onMultiChange?: MultiOnChange;
  onChange:(option:Option)=>void,
  error?: string;
}

const CertificationTags: React.FC<Props> = ({
  placeholder,
  values,
  inputLabel,
  isMulti,
  classNamePrefix,
  onMultiChange,
  onChange,
  error,

}) => {

  const {data}= useGetCertificationTags();
  const {certificationTags,setCertificationTags}= useSkillStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue.trim());
      setIsLoading(false);
      setCertificationTags([...certificationTags, newOption]);
      onChange(newOption);
    }, 1000);
  };
  
  const createOption = (label: string) => ({
    label,
    value: label,
  });

  return (
    <div className={"selectBoxWrapper"}>
      <label>{inputLabel}</label>
      <CreatableSelect
        isDisabled={isLoading}
        isLoading={isLoading}
        options={certificationTags}
        placeholder={placeholder}
        isMulti
        value={values}
        classNamePrefix={classNamePrefix}
        onChange={onMultiChange}
        isSearchable
        menuPosition="fixed"
        onCreateOption={handleCreate}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
      />
      <div className={"validationMessage"}>
        <span className={"validationText"}>{error}</span>
      </div>
    </div>
  );
};
export default CertificationTags;
