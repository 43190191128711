import "./confirm-delete-modal.scss";

const ConfirmDeleteCertification = () => {
  return (
    <div className="app-modal-content confirm-delete-popup">
      <p className="sub-content">Are you sure that you want to delete this certification?.</p>
    </div>
  );
};

export default ConfirmDeleteCertification;

