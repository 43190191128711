import React, { useState } from "react";
import "../../SkillsGridView/EditSkillModal/admin-skill-modal.scss";
const ExportModal = () => {
  return (
    <div className="app-modal-content sm export-modal">
      <p className="export-content">The files will be sent to your mail-admin module. Pleasecheck your inbox.</p>
    </div>
  );
};
export default ExportModal;
