import "./PageLoader.scss";

const PageLoader = () => {
  return (
    <div className="page-loader">
      <svg className="loader">
        <use href="#loaderDots">
          <title>loading</title>
        </use>
      </svg>
    </div>
  );
};
export default PageLoader;
