import { CUSTOM_SKILL } from "utils/constants"

export const getCustomSkills = (skillCategories:Categories|undefined):Skills[]=>{
    if(skillCategories){
        const customSkillCategory= skillCategories.categories.find((item)=> item.name === CUSTOM_SKILL);
        if(customSkillCategory && customSkillCategory.skills && customSkillCategory.skills.length > 0){
           const customSkills: Skills[] = customSkillCategory?.skills?.map((skill:Skills)=>({
            ...skill,error:"",
           }));
           return customSkills??[];
        }
    }
    return [];

}