import { useNavigate } from "react-router-dom";
import "./no-page-found.scss";
import { useUserStore } from "store/useUserStore";


function PageNotFound() {
  const pageNotFound = require("../../assets/temp-images/no-network.png");
  const {userInfo} = useUserStore();
  const navigate = useNavigate();
  const redirect = () => {
    if(userInfo && userInfo.roles && userInfo.roles.length > 0){
      if(userInfo.roles[0] === "Admin"){
        navigate('admin');
      }else{
        navigate('home');
      }
    }else{
      navigate('/');
    }
  };
  return (
    <div className="admin-wrapper">
        <div className="no-page-found">
      <div className="no-page-content">
        <div className="logo-icon-wrapper">
          <svg className="svg-icon logo-icon">
            <use href="#loginLogoIcon"></use>
          </svg>
        </div>

        <div className="no-page-image">
          <img className="page-img" alt="user" src={pageNotFound}></img>
        </div>
        <div className="no-page-btn-wrapper">
          <p className="no-page-content-msg">Ooops!...Something went wrong.</p>
          <button className="app-btn primary lg" title="Apply" onClick={redirect}>
            Go to home
          </button>
        </div>
      </div>
    </div>
    </div>
  
  );
}

export default PageNotFound;
