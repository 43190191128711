import React, { useState } from "react";
import "./adminCertificationsWrapper.scss";
import CertificationsGridView from "./CertificationsGridView";
import SearchPanel from "../ActionHeader/SearchPanel";
import { useAllCertificationsExport } from "api/admin/useExportAllCertifications";
import { useAdminStore } from "store/adminStore";
import AdminModal from "components/common-components/AdminModel";
import AddCertificationModal from "./AddCertification";
import { useSaveCertificationMaster } from "api/admin/useAddCertificationMaster";
import { showErrorToast, showSuccessToast } from "utils/toastUtil";
import { messages } from "utils/messageConstants";
import { useUpdateCertificationMaster } from "api/admin/useUpdateCertificationMaster";
interface Props {
  skillSViewToggle?: any;
  tabSwitch?: any;
}
const AdminCertificationsWrapper: React.FC<Props> = ({
  skillSViewToggle,
  tabSwitch
}) => {
   const {data,refetch}= useAllCertificationsExport();
   const{setInitiateSkillExport}=useAdminStore();
   const {mutateAsync:saveCertificationMaster}= useSaveCertificationMaster();
   const {mutateAsync:updateCertificationMaster} = useUpdateCertificationMaster();
   const [showAddModal,setShowModal]= useState(false);
   const [certification,setCertification]=useState('');
   const [certificationHolderItem,setCertificationHolderItem] = useState<CertificationHolderItem|undefined>(undefined);
   const [error,setError]=useState("");
   const[editMode,setEditMode]=useState(false);

   const downloadCertificationsExport = ()=>{
    // setInitiateSkillExport(true);
    refetch();
    // if(data){
    //   setInitiateSkillExport(false);
    // }
  }
  const handleAddModal = ()=>{
    setCertification('');
    setError('');
    setShowModal((prev)=>!prev);
    setCertificationHolderItem(undefined);
  }
  const saveCertification = async()=>{
    if(certification.trim().length ===0){
      setError("Enter certification master name");
      return;
    }
    if(editMode && certificationHolderItem){
      certificationHolderItem.certification_name = certification;
      const response=await updateCertificationMaster({certificationItem:certificationHolderItem});
      handleSaveReponse(response);
    }else{
      const response=await saveCertificationMaster({name:certification});
      handleSaveReponse(response);
    }
  }
  const handleSaveReponse = (response:any)=>{
    if(response.data){
      handleAddModal();
      showSuccessToast(messages.CERTIFICATION_MASTER_SAVED)
    }else if(response.response.data.message){
      setError(response.response.data.message);
    }else{
      showErrorToast(messages.CERTIFICATION_MASTER_SAVE_FAILED);
    }
  }
  const subTitle = editMode?"The changes will reflect in the certification master.":"The newly added certification will be included in the certification master.";
  const primaryButtonText = editMode?"Save Changes":"Save Certification";
  const title =editMode?"Edit certification name":"Add new certification";
  return (
    <>
      <SearchPanel
        skillSViewToggle={skillSViewToggle}
        tabSwitch={tabSwitch}
        searchPlaceholder="Search for a Certification"
        addButtonText="Add Certification"
        isSkillPage={false}
        download={downloadCertificationsExport}
        handleAdd={handleAddModal}
        editMode={editMode}
        setEditMode={setEditMode}
      />
      <div className="admin-certifications-wrapper">
        <CertificationsGridView
           editMode={editMode}
           addButtonText="Add Certification"
           handleAdd={handleAddModal}
           onEditClicked={async(certificationItem)=>{
             setCertificationHolderItem({...certificationItem});
             setCertification(certificationItem.certification_name);
             setShowModal(true);
           }} />
      </div>
      {showAddModal ? (
        <AdminModal
          title={title}
          size=""
          handleClose={() => handleAddModal()}
          isBackArrow={true}
          placementFull="placement-full"
          headerWrapper=""
          withEditBtn={false}
          hidefooter={false}
          primaryButton={primaryButtonText}
          secondaryButton="Cancel"
          isHeaderBottom={false}
          onProceed={saveCertification}
          subTitle={subTitle}
        >
          <AddCertificationModal 
            error={error}
            onChange={(val)=>{
              setCertification(val);
              setError('');
            }}
            value={certification}
          />
        </AdminModal>
      ) : (
        ""
      )}
    </>
  );
};
export default AdminCertificationsWrapper;
