import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useSkillStore } from "store/useSkillStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function deleteSkill(
  skillId:number
) {
  return await apiClient.delete(
    urls.skillById(skillId,true)
  );
}

export function useDeleteSkill(): UseMutationResult<
  unknown,
  ApiError,
  {
    skillId: number;
  },
  unknown
> {
  const {skillSearchKey,categories} = useSkillStore();
  const queryClient = useQueryClient();
  return useMutation(
    async ({
        skillId
    }: {
        skillId:number;
    }) => {
      await deleteSkill(skillId);
      queryClient.invalidateQueries(["categoryWiseSkills",skillSearchKey,categories]);
      queryClient.invalidateQueries(["GetAllUserSkills"]);
    }
  );
}
