import React, { useState } from 'react';
import Modal from 'components/common-components/Modal';
import RecipentsModalComponent from '../RecipentsModal/components';
import { formatDateWithSuffix } from 'utils/dateUtil';
import { showErrorToast, showSuccessToast } from 'utils/toastUtil';
import { messages } from 'utils/messageConstants';
import { useSaveMail } from 'api/admin/useSaveMail';
import { useAdminStore } from 'store/adminStore';

// type Mail = {
//   custom_notification_id?: string;
//   date: string;
//   failed_recipients: number;
//   subject: string;
//   total_recipients: number;
// };

type EmailLogModalProps = {
    subject: string;
    message: string;
    showModal: boolean;
    handleClose: () => void;
    mail: any;    
    showEmail?: boolean;
    setShowEmail: (email: boolean) => void;
    activeTab: string;
    setActiveTab: (tab: string) => void;
    emailLogStatus: EmailStatus[];
    setEmailLogStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
    undeliveredEmailStatus: EmailStatus[];
    setUndeliveredEmailStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>;
    selectedRecipients: { value: string, label: string }[];
    setSelectedRecipients: React.Dispatch<React.SetStateAction<{ value: string; label: string; }[]>>
    setSubject: (tab: string) => void;
    setMessage: (tab: string) => void;
    recipientEmailStatus: EmailStatus[]
    setRecipientEmailStatus: React.Dispatch<React.SetStateAction<EmailStatus[]>>
    selectAll: boolean;
    setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;

};

const EmailLogModal: React.FC<EmailLogModalProps> = ({
  subject,
  message,
  showModal,
  handleClose,
  mail,
  showEmail,
  setShowEmail,
  activeTab,
  setActiveTab,
  undeliveredEmailStatus,
  setUndeliveredEmailStatus,
  emailLogStatus,
  setEmailLogStatus,
  recipientEmailStatus,
  setRecipientEmailStatus,
  selectedRecipients,
  setSelectedRecipients,
  setSubject,
  setMessage,
  selectAll,
  setSelectAll,
}) => {
  const { mutateAsync: saveMail, isLoading } = useSaveMail();
  const { setEmployeeNameSearch, setEmailLogRecipientsPaginationOffset,
    setEmailLogRecipientsListPaginationOffset} = useAdminStore();
  const [allRecipients, setAllRecipients] = useState<{ value: string; label: string }[]>([]);
  const [recipientsCurrentPage , setRecipientsCurrentPage] = useState(1);
  

  const onSaveMail = async () => {
    let validationError = false;
    if (selectedRecipients.length === 0 || subject.trim() === "" || message.trim() === "") {
      showErrorToast(messages.MAIL_FAILED);
      validationError = true;
    }
    if (!validationError) {
      const response = await saveMail({
        selected_recipients: selectedRecipients,
        subject: subject.trim(),
        body: message.trim(),
      });

      if (response.data) {
        showSuccessToast(messages.MAIL_SEND);
        setSelectedRecipients([]);
      } else {
        console.log('error');
      }
    }
  };

  const onCancel = () => {
    setSelectAll(false);
    setSelectedRecipients([])
    setEmployeeNameSearch("");
    setEmailLogRecipientsPaginationOffset(25);
    setEmailLogRecipientsListPaginationOffset(0);  
    setRecipientsCurrentPage(1);
    setUndeliveredEmailStatus([]);
  }

  return showModal ? (

    <Modal
      size="xl"
      titleIcon="emailIcon"
      title={mail?.subject}
      titleButton="view mail"
      // headerBottomText={formatDateWithSuffix(new Date(mail?.date))}
      headerBottomText={mail?.date}
      primaryButton="Re-send"
      secondaryButton="Reset"
      isBackArrow={false}
      handleClose={handleClose}
      placementFull="placement-full"
      customClass="h-full mobile-card-header view-mail-btn "
      showEmail={showEmail}
      setShowEmail={setShowEmail}
      activeTab={activeTab}
      onProceed={onSaveMail}
      btnLoaderIcon={isLoading}
      undeliveredTab={mail?.failed_recipients}
      onCancel={onCancel}
      >
      <div className="app-modal-content">
        <RecipentsModalComponent
          showEmail={showEmail}
          setShowEmail={setShowEmail}
          customNotificationId={mail?.custom_notification_id}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          undeliveredEmailStatus={undeliveredEmailStatus}
          setUndeliveredEmailStatus={setUndeliveredEmailStatus}
          selectedRecipients={selectedRecipients}
          setSelectedRecipients={setSelectedRecipients}
          setSubject={setSubject}
          setMessage={setMessage}
          emailLogStatus={emailLogStatus}
          setEmailLogStatus={setEmailLogStatus}
          recipientEmailStatus={recipientEmailStatus}
          setRecipientEmailStatus={setRecipientEmailStatus}
          mail={mail}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          allRecipients={allRecipients} 
          setAllRecipients={setAllRecipients}
          recipientsCurrentPage={recipientsCurrentPage}
          setRecipientsCurrentPage = {setRecipientsCurrentPage}
        />
      </div>
    </Modal>
  ) : null;
};

export default EmailLogModal;