import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveMail(
recipients: string[], // Change the type to string[]
  subject: string,
  body: string,
  is_scheduled: boolean = false,
) {
  try {
    const requestObject = {
    recipients,
      subject,
      body,
      is_scheduled: is_scheduled,
    };
    const response = await apiClient.post(
      urls.mail(),
      requestObject,
    );
    return response.data;
  } catch (err) {
    return err;
  }
}

export function useSaveMail(): UseMutationResult<
  any,
  ApiError | { message: string },
  {
    selected_recipients: Option[];
    subject: string;
    body: string;
  }
> {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      selected_recipients,
      subject,
      body
    }: {
      selected_recipients: Option[];
      subject: string;
      body: string;
    }) => {
      const recipients = selected_recipients.map(item => item.value);
      const result = await saveMail(recipients, subject, body);
      queryClient.invalidateQueries(["mail"]);
      return result;
    }
  );
}