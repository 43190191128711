import React, { ReactNode, useState } from "react";
import "./modal.scss";
import LoadingButton from "../LoadingButton";
interface Props {
  customClass?: string;
  size?: string;
  title?: string;
  titleIcon?: string;
  titleButton?: string;
  headerBottomText?: string;
  footerClass?: string;
  children?: ReactNode;
  primaryButton?: string;
  secondaryButton?: string;
  footerMessage?: string;
  messageIcon?: any;
  footer?: any;
  handleClose: any;
  placement?: string;
  onProceed?: () => void;
  onCancel?: () => void;
  placementFull?: string;
  isBackArrow?: boolean;
  backArrow?: boolean;
  withEditBtn?: boolean;
  hidefooter?: boolean;
  btnLoaderIcon?: boolean;
  handlebackground?: boolean;
  from?: string;
  showEmail?:boolean;
  setShowEmail?:any;
  activeTab?: string;
  undeliveredTab?:number
}

const Modal: React.FC<Props> = ({
  customClass,
  size,
  title,
  titleIcon,
  titleButton,
  headerBottomText,
  footerClass,
  children,
  primaryButton,
  secondaryButton,
  footerMessage,
  messageIcon,
  handleClose,
  placement,
  placementFull,
  isBackArrow,
  backArrow,
  withEditBtn,
  hidefooter,
  handlebackground,
  onProceed,
  onCancel,
  btnLoaderIcon,
  from,
  showEmail,
  setShowEmail,
  activeTab,
  undeliveredTab,
}) => {
  // const [showMailToggle, setShowMailToggle] = useState(false);

  const toggleViewMail = () => {
    setShowEmail(!showEmail)
  }

  return (
    <div className={`app-c-modal ${customClass}`}>
      <div
        className={`modal-wrapper ${size ? size : "md"} ${placement ? "placement-bottom" : ""} ${
          placementFull ? "placement-full" : ""
        }`}
      >
        <div className={`modal-header ${handlebackground ? "blue-shade" : ""}`}>
          <button className="icon-only back-arrow " onClick={() => handleClose()}>
            <svg className="svg-icon back-arrow-icon">
              <use href="#backArrowIcon">
                <title>Back Arrow Icon</title>
              </use>
            </svg>
          </button>
          <div>
            <h4 className="header-title">
              {titleIcon && (
                <svg className={`svg-icon ${titleIcon}`}>
                  <use href={`#${titleIcon}`}>
                    <title>{titleIcon}</title>
                  </use>
                </svg>
              )}
              <span className="title-content">
                {title}
              </span>
              {titleButton && (
                <button className="btn-view-mail" onClick={()=>toggleViewMail()}> 
                  <svg className="svg-icon eye-icon">
                  <use href={showEmail ? "#eyeOffIcon" : "#eyeIcon"}>
                    <title>{showEmail ? "Hide" : "View"} mail</title>
                  </use>
                </svg>
                {showEmail ? "Hide mail" : "View mail"}
              </button>
            )}
            </h4>
            {headerBottomText && (
              <div className="modal-header-bottom">
                <span className="modal-header-bottom-text">{headerBottomText}</span>
              </div>
            )}
          </div>

          <button
            className={`icon-only close-icon  ${backArrow ? "hide-all" : ""} ${
              isBackArrow ? "hide" : ""
            }`}
            onClick={() => handleClose()}
          >
            <svg className="svg-icon md close-icon">
              <use href="#closeIcon">
                <title>Close Icon</title>
              </use>
            </svg>
          </button>
          <button
            className={`icon-only btn-icon-wrapper  ${withEditBtn ? "show" : ""}`}
            onClick={onProceed}
          >
            <svg className="svg-icon edit-icon">
              <use href="#editIcon">
                <title>Edit Icon</title>
              </use>
            </svg>
            <span className="btn-text">Edit</span>
          </button>
        </div>

        <div
          className={`modal-body custom-scrollbar ${from == "compose-data" ? "compose-data" : " "}`}
        >
          {children}
        </div>
        <div
          className={`modal-footer ${footerMessage ? "space-between" : ""} ${
            hidefooter ? "hide-footer" : ""
          } ${footerClass}`}
        >
          <div className="footer-content">
            {footerMessage ? (
              <p className="footer-msg">
                {messageIcon ? (
                  <span className="icon-only footer-msg-icon">
                    <svg className="svg-icon info-icon">
                      <use href={`#${messageIcon}`}></use>
                    </svg>
                  </span>
                ) : (
                  ""
                )}
                {footerMessage}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className="footer-btns">
            {activeTab === "total" || activeTab === "delivered" ? 
              null : secondaryButton ? (
              <button className="app-btn min-width secondary" 
              onClick={onCancel}
              disabled={undeliveredTab===0?true:btnLoaderIcon}
              >
                {btnLoaderIcon ? <LoadingButton /> : secondaryButton}
              </button>
            ) : (
              ""
            )}
            {activeTab === "total" || activeTab === "delivered" ?
              null : primaryButton ? (
              <button
                className="app-btn min-width primary"
                onClick={onProceed}
                disabled={undeliveredTab===0?true:btnLoaderIcon}
              >
                {btnLoaderIcon ? <LoadingButton /> : primaryButton}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
{
  /* <LoadingButton />  */
}
