import React from 'react';
import TotalMails from '../TotalMails';
import DeliveredMails from "../DeliveredMails";
import UndeliveredMails from "../UndeliveredMails";

interface TabContentProps {
    activeTab: string;
    recipientEmailStatus: EmailStatus[];
    undeliveredEmailStatus: EmailStatus[];
    selectedRecipients: { value: string, label: string }[];
    setSelectedRecipients: React.Dispatch<React.SetStateAction<{ value: string; label: string; }[]>>;
    customNotificationId: string | number;
    userType: string;
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    setDataUserList: React.Dispatch<React.SetStateAction<any>>;
    selectAll: boolean;
    setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
    allRecipients: { value: string, label: string }[];
    setAllRecipients:  React.Dispatch<React.SetStateAction<{ value: string; label: string; }[]>>;
}

const TabContent: React.FC<TabContentProps> = ({
    activeTab, recipientEmailStatus, undeliveredEmailStatus, selectedRecipients, setSelectedRecipients,
    customNotificationId, userType, setLimit, setDataUserList, selectAll, setSelectAll, allRecipients, setAllRecipients,
}) => {
    const commonProps = {
        customNotificationId,
        userType,
        setLimit,
        onUpdateRecipientList: setDataUserList,
    };
    return(
        <div className="tab-content">
            {activeTab === 'total' && (
                <TotalMails
                    {...commonProps}
                    placeholder="No Recipient(s) Found"
                    recipientEmailStatus={recipientEmailStatus}
                />
            )}
            {activeTab === 'delivered' && (
                <DeliveredMails
                    {...commonProps}
                    placeholder="No Recipient(s) Found"
                />
            )}
            {activeTab === 'undelivered' && (
                <UndeliveredMails
                    {...commonProps}
                    undeliveredEmailStatus={undeliveredEmailStatus}
                    selectedRecipients={selectedRecipients}
                    setSelectedRecipients={setSelectedRecipients}
                    selectAll={selectAll} setSelectAll={setSelectAll}
                    allRecipients={allRecipients} setAllRecipients={setAllRecipients}
                    placeholder="No Recipient(s) Found"
                />
            )}
        </div>
);
}
export default TabContent;