import React, { useState } from "react";

import "./toogle.scss";

const ToogleBtn = ({
  toggleState,
  onToggleChange,
}: {
  toggleState: boolean;
  onToggleChange: () => void;
}) => {
  return (
    <div>
      <label className="toggle-switch">
        <input
          type="checkbox"
          checked={toggleState}
          className="toggle-input-holder"
          onChange={() => onToggleChange()}
        />
        <div className="toggle-ui">
          <div className="toogle-handler">
            <div className="toogle-lock-state"></div>
            <div className="toogle-unlock-state"></div>
          </div>
        </div>
      </label>
    </div>
  );
};
export default ToogleBtn;
