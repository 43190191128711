import React, { createRef } from 'react';

import BubbleChart from './bubbleChart';
import './rangeSlider.scss';

type bubbleProps = {
  circles: SkillNameCount[];
  setTooltipPos: any;
  setTooltipVisible: any;
  setTooltipValues:any;
  setTooltipLeftSide:any;
  rangeSliderMax:any;
  rangeSliderMin:any;
};

type bubbleState = {
  containerSize: { w: number; h: number };
};
class ChartWrapper extends React.Component<bubbleProps, bubbleState> {
  bblChart:any;
  
  constructor(props: bubbleProps) {
    super(props);
    this.tooltip = this.tooltip.bind(this);
  }

  state: bubbleState = {
    containerSize: { w: 100, h: 0 },
  };

  bubblewrapRef = createRef<SVGSVGElement>();
  bubbleContainerRef = createRef<HTMLDivElement>();

  tooltip(x: number, y: number, visible: boolean, skill:any, s: boolean) {
    const{setTooltipLeftSide} =this.props;
    const{ setTooltipValues}=this.props
    const { setTooltipVisible } = this.props;
    const { setTooltipPos } = this.props;
    setTooltipPos({ x: x, y: y });
    setTooltipVisible(visible);
    setTooltipValues({
      name:skill.name,
      count:skill.count,
      expertCount:skill.expert_count,
      intermediateCount:skill.intermediate_count,
      beginnerCount: skill.beginner_count,
    })
   setTooltipLeftSide(s);
    

  }

  componentDidMount(): void {
    const { circles = [] } = this.props;
    let width = this.bubbleContainerRef.current?.getBoundingClientRect().width || 0;
    let height = this.bubbleContainerRef.current?.getBoundingClientRect().height || 0;
    this.setState((state) => ({
      containerSize: {
        w: width,
        h: height,
      },
    }));
    this.bblChart=new BubbleChart(this.bubblewrapRef.current, circles, this.tooltip);
    }
  componentDidUpdate(prevProps: bubbleProps): void {
    const { rangeSliderMin, rangeSliderMax } = this.props;
   if (
      prevProps.rangeSliderMin !== rangeSliderMin ||
      prevProps.rangeSliderMax !== rangeSliderMax
    ) {
    this.bblChart.drawCircle(rangeSliderMin, rangeSliderMax);
     
    }
  }

   
    render(){
        return (
            <>
                <div ref={this.bubbleContainerRef} className='chart-wrapper'>
                    <svg
                        className='graph-wrapper'
                        ref={this.bubblewrapRef}
                        viewBox={`-2500 -${(5000 * this.state.containerSize.h / this.state.containerSize.w) / 2} 5000 ${5000 * this.state.containerSize.h / this.state.containerSize.w}`}
                        version='1.1'
                        xmlns="http://www.w3.org/2000/svg" 
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                        
                    </svg>
                
                </div>
                
                </>
        );
    }
}

export default ChartWrapper;