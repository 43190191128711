
import { useGetUserProfileImage } from "api/user/useGetUserImage";
import Avatar from "components/Avatar";

const UserProfileImage = ({ssoId,first_name}:{ssoId:string;first_name:string})=>{
    const {data}=useGetUserProfileImage(ssoId);
    if(data){
       return( 
       <img
        className="user-img"
        alt="user"
        src={data}/>
       );
    }else{
        return <Avatar name={first_name}/>;
    }
}

export default UserProfileImage;