import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { urls } from "utils/urls";
import apiClient from "utils/apiClient";
import { useAdminStore } from "store/adminStore";
import { handleDownload } from "utils/downloadFile";

export async function getAdminSkillExport(selectedCerfifcation:CertificationHolderItem|undefined):Promise<any> {
  if(selectedCerfifcation){
  const response = await apiClient.get(urls.exportCertificationUsers(selectedCerfifcation.id));
  if (response && response.data && response.data.data )
    return response.data.data;
  }
  return null;
}

export function useExportCertificationUsers():UseQueryResult<any> {
  const {initiateCertificationExportById,setInitiateCertificationExportById,selectedCerfifcation}= useAdminStore();
  return useQuery(["AdminCertificationUsersExport"], async () => {
    const result= await getAdminSkillExport(selectedCerfifcation);
    if(result && result.file_url){
      await handleDownload(result);
    }
    setInitiateCertificationExportById(false);
    return result;
  },{
    enabled: initiateCertificationExportById === true,
  });
}

